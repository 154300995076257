import { useSetRecoilState } from 'recoil';

import { useAnnotationOptions } from 'hooks/useAnnotation';
import usePhraseHasMeaningAnnotations from 'methods/phrase-has-meaning/hooks/usePhraseHasMeaningAnnotations';
import {
  Phrases_Semantic_Entities_Consensus_Annotations_Entities_State,
  Phrases_Semantic_Entities_Consensus_Annotations_Ids_State,
  Phrases_Semantic_Entities_Options_Entities_State,
  Phrases_Semantic_Entities_Predictions_Entities_State,
  Phrases_Semantic_Entities_Predictions_Ids_State,
} from 'modules/phrases/store';
import { Status } from 'shared/constants';
import { AnnotationConfig, DataItem } from 'types';

type useAnnotationsParams = {
  parentItem: DataItem;
  languageId: number;
}

const annotationConfig: AnnotationConfig = {
  parentIdField: 'baseAssetId',
  itemIdField: 'targetAssetId',
};

export default function useAnnotations(params: useAnnotationsParams) {
  const { parentItem, languageId } = params;

  const updateConsensusAnnotationsEntities = useSetRecoilState(Phrases_Semantic_Entities_Consensus_Annotations_Entities_State);
  const updateConsensusAnnotationsIds = useSetRecoilState(Phrases_Semantic_Entities_Consensus_Annotations_Ids_State);
  const updatePredictionsEntities = useSetRecoilState(Phrases_Semantic_Entities_Predictions_Entities_State);
  const updatePredictionsIds = useSetRecoilState(Phrases_Semantic_Entities_Predictions_Ids_State);
  const updateOptionsEntities = useSetRecoilState(Phrases_Semantic_Entities_Options_Entities_State);

  const settersOrUpdaters = [
    [updateConsensusAnnotationsEntities, updateConsensusAnnotationsIds],
    [updatePredictionsEntities, updatePredictionsIds],
    updateOptionsEntities,
  ] as const;

  const { onCompleted: onConfirmCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.confirmed });
  const { onCompleted: onDeclineCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.declined });
  const annotationsOptions = { onConfirmCompleted, onDeclineCompleted };

  return usePhraseHasMeaningAnnotations(annotationConfig, { parentItem, languageId }, annotationsOptions);
}
