import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

type UseSuccessResult = {
  showSuccess: (text?: string) => void;
};

export default function useSuccess(): UseSuccessResult {
  const { enqueueSnackbar } = useSnackbar();
  const showSuccess = useCallback((text?: string) => {
    enqueueSnackbar(text || 'Your changes have been saved!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      autoHideDuration: 1000,
    });
  }, [enqueueSnackbar]);

  return {
    showSuccess,
  };
}
