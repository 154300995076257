import { useAnnotation } from 'hooks/useAnnotation';
import { CONFIRM_TAG_HAS_CLASS_OPTION, DECLINE_TAG_HAS_CLASS_OPTION } from 'methods/tag-has-class/api';
import { AnnotationConfig, DataItem } from 'types';

type useTagHasClassAnnotationsParams = {
  parentItem: DataItem;
}

type useTagHasClassAnnotationsOptions = {
  onConfirmCompleted?: any;
  onDeclineCompleted?: any;
};

export default function useTagHasClassAnnotations(
  annotationConfig: AnnotationConfig,
  params: useTagHasClassAnnotationsParams,
  options?: useTagHasClassAnnotationsOptions
) {
  const { parentItem } = params;

  const confirmOptions = { onCompleted: options?.onConfirmCompleted };
  const confirmAnnotation = useAnnotation(annotationConfig, CONFIRM_TAG_HAS_CLASS_OPTION, parentItem, confirmOptions);

  const declineOptions = { onCompleted: options?.onDeclineCompleted };
  const declineAnnotation = useAnnotation(annotationConfig, DECLINE_TAG_HAS_CLASS_OPTION, parentItem, declineOptions);

  return {
    confirmAnnotation,
    declineAnnotation,
  }
}
