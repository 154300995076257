import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import { CREATE_CLASS } from 'modules/classes/api';
import { Classes_Entities_State, Classes_Ids_State } from 'modules/classes/store';
import { entitiesUpdaterFactory, idsUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Values = {
  name: string
};

type UseClassParams = {
  onCreate?: () => void
};

type UseClassResult = {
  createClass: (values: Values) => void
};

export default function useClass(params: UseClassParams = {}): UseClassResult {
  const { onCreate } = params;

  const updateEntities = useSetRecoilState(Classes_Entities_State);
  const updateIds = useSetRecoilState(Classes_Ids_State);

  const onCompleted = useCallback((result: any) => {
    const ids: string[] = result.create_Class.classes.map((x: DataItem) => x.id);
    const entries = (result.create_Class.classes as DataItem[]).map(x => [x.id, x, x] as const);

    const idsUpdater = idsUpdaterFactory(ids);
    const entitiesUpdater = entitiesUpdaterFactory(entries, true);

    updateEntities(entitiesUpdater);
    updateIds(idsUpdater);
  }, [
    updateEntities,
    updateIds,
  ]);
  const [create] = useMutation(CREATE_CLASS, { onCompleted });

  const createClass = useCallback(
    (values: Values) => {
      create({
        variables: {
          input: {
            name: values.name,
          }
        },
      });

      onCreate?.()
    }, [create, onCreate]
  )

  return {
    createClass,
  };
}
