import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: '10px 26px 10px 24px',
  },
  numericIcon: {
    color: 'white',
    width: 30,
    height: 30,
    borderRadius: 15,
    border: '1px solid white',
    textAlign: 'center',
    lineHeight: 2,
  },
  activeLink: {
    '& .MuiListItemIcon-root div': {
      color: '#253448',
      background: 'white',
    },
  },
}));

export default useStyles;
