import { useCallback, useMemo, useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { EntryParamsType } from 'modules/contacts/constants';

import Form, { FormValues } from './EntryForm';

type Props = {
  value: EntryParamsType;
  onClose: () => void;
  onChange: (value: EntryParamsType) => void;
};

export default function EntryModal(props: Props) {
  const { value, onClose, onChange } = props;
  const formikRef = useRef<any>(null);

  const initialValues = useMemo(() =>
    ({
      name: value.contact?.name || '',
      password: '',
      contact_weight: value.contact?.contact_weight || 1,
    })
  , [value])

  const handleClick = useCallback(() => {
    const { submitForm } = formikRef.current;
    submitForm();
  }, []);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onChange({
        contact: {
          id: value.contact?.id || '',
          name: values.name,
          password: values.password,
          contact_weight: parseInt(`${values.contact_weight}`, 10),
        },
      });
    },
    [value, onChange],
  )

  return (
    <Modal
      title={value.contact ? 'Edit contact' : 'Add contact'}
      open
      onClose={onClose}
      actionsContent={
        <>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Submit
          </Button>
        </>
      }
    >
      <Form formRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} />
    </Modal>
  );
}
