import { memo } from 'react';

import { darkTheme, lightTheme } from 'components/icons/constants';

type Props = {
  light?: boolean;
  fill?: string;
};

function RemoveIcon({ light, fill: inFill }: Props) {
  const theme = light ? lightTheme : darkTheme;
  const fill = inFill || theme.fill.defaults;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1428 0H2.85713C1.27995 0.001875 0.001875 1.27995 0 2.85713V13.1428C0.001875 14.72 1.27995 15.9981 2.85713 16H13.1428C14.72 15.9981 15.9981 14.72 16 13.1428V2.85713C15.9981 1.27995 14.72 0.001875 13.1428 0ZM14.8571 13.1428C14.8571 14.0896 14.0896 14.8571 13.1428 14.8571H2.85713C1.91036 14.8571 1.14285 14.0896 1.14285 13.1428V2.85713C1.14285 1.91036 1.91036 1.14285 2.85713 1.14285H13.1428C14.0896 1.14285 14.8571 1.91036 14.8571 2.85713V13.1428Z"
        fill={fill}
      />
      <path
        d="M 10.8569 7.4282 H 5.1426 C 4.827 7.4282 4.5711 7.6841 4.5711 7.9997 C 4.5711 8.3153 4.827 8.5711 5.1426 8.5711 H 10.8569 C 11.1725 8.5711 11.4283 8.3152 11.4283 7.9996 C 11.4283 7.684 11.1725 7.4282 10.8569 7.4282 Z"
        fill={fill}
      />
    </svg>
  );
}

export default memo(RemoveIcon);
