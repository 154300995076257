import { useCallback, useMemo, useState } from 'react';
import memoizeOne from 'memoize-one';

import DataGroup from 'components/DataGroup';
import DataSection from 'components/DataSection';
import Languages from 'components/Languages';
import config from 'configuration';
import useConsensus from 'hooks/useConsensus';
import useLanguages from 'hooks/useLanguages';
import FilterModal from 'modules/tags/components/FilterModal';
import SortModal from 'modules/tags/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/tags/constants';
import { DataItem } from 'types';

import transformVariablesAdapter from './transformVariablesAdapter';
import useAnnotations from './useAnnotations';
import useContextActions from './useContextActions';
import { useConsensusAnnotationsQuery, useOptionsQuery, usePredictionsQuery } from './useQueries';

type Props = {
  parentItem: DataItem;
};

const menuAcl = {
  Tags_Options: {
    ASSIGN_METHOD_TO_ME: false,
    ASSIGN_METHOD_TO_ANOTHER_CONTACT: false,
  },
};

const memoizeField = memoizeOne((...args) => [...args]);

export default function TagsDataSection({ parentItem }: Props) {
  const [languageId, setLanguageId] = useState<number>(config.defaultLanguage.id);

  const { consensus } = useConsensus();
  const { formatLanguage } = useLanguages();
  const { confirmAnnotation, declineAnnotation } = useAnnotations({ parentItem, languageId });

  const Token_has_Meaning_language = useCallback(
    (item: DataItem) => formatLanguage(item, 'Token_has_Meaning_language'), [formatLanguage],
  );
  const countField = memoizeField(['count_all_positions_in_phrases', Token_has_Meaning_language])

  const actions = useMemo(
    () => [
      {
        content: <Languages value={languageId} onChange={setLanguageId} />,
        handler: () => null,
      },
    ],
    [languageId, setLanguageId],
  );
  const contextActions = useContextActions({ languageId, parentItem });
  const transformVariables = useMemo(() => transformVariablesAdapter({ languageId }), [languageId]);
  const watchParams = useMemo(() => ({ languageId }), [languageId]);

  return (
    <DataGroup
      title="Tags"
      structure={{
        menuId: 'Token_Tags',
        menuAcl,
      }}
      actions={actions}
      contextActions={contextActions}
      consensusAnnotations={
        <DataSection
          parentItem={parentItem}
          useQuery={useConsensusAnnotationsQuery}
          structure={{
            id: 'Tags_Consensus_Annotations',
            title: 'Consensus & Annotations',
            countField,
            readonly: consensus,
          }}
          consensus={consensus}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          watchParams={watchParams}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
      predictions={
        <DataSection
          parentItem={parentItem}
          useQuery={usePredictionsQuery}
          structure={{
            id: 'Tags_Predictions',
            title: 'Predictions',
            countField: 'count_all_positions_in_phrases',
          }}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          watchParams={watchParams}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
      options={
        <DataSection
          parentItem={parentItem}
          useQuery={useOptionsQuery}
          structure={{
            id: 'Tags_Options',
            title: 'Options',
            countField: 'count_all_positions_in_phrases',
            orangeLabel: false,
          }}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          watchParams={watchParams}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
    />
  );
}
