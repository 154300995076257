import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Morphs_Entities_State, Morphs_Ids_State } from './atoms';

export * from "../sections/AssociatedTokensDataSection/selectors"
export * from "../sections/MorphemesDataSection/selectors"

export const Morphs_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/list',
  Morphs_Entities_State,
  Morphs_Ids_State,
);
