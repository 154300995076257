import memoize from 'memoize-one';

export const createItemData = memoize(
  (
    canConfirm,
    canDecline,
    consensus,
    countField,
    horizontal,
    items,
    keyField,
    nameField,
    renderContent,
    readonly,
    se_creation,
    orangeLabel,
    yellowLabel,
    greenLabel,
    selectedItems,
    handleClick,
    handleContext,
    handleConfirm,
    handleDecline,
    handleDoubleClick,
  ) => ({
    canConfirm,
    canDecline,
    consensus,
    countField,
    horizontal,
    items,
    keyField,
    nameField,
    renderContent,
    readonly,
    se_creation,
    orangeLabel,
    yellowLabel,
    greenLabel,
    selectedItems,
    handleClick,
    handleContext,
    handleConfirm,
    handleDecline,
    handleDoubleClick,
  }),
);
