import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const Token_Token_Join_Consensus_Annotations_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/tokens/@atoms/Token_Join_Consensus_Annotations/entities',
  default: ImmutableMap(),
});

export const Token_Token_Join_Consensus_Annotations_Ids_State = atom<string[]>({
  key: 'graph/tokens/@atoms/Token_Join_Consensus_Annotations/ids',
  default: [],
});

export const Token_Token_Join_Predictions_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/tokens/@atoms/Token_Join_Predictions/entities',
  default: ImmutableMap(),
});

export const Token_Token_Join_Predictions_Ids_State = atom<string[]>({
  key: 'graph/tokens/@atoms/Token_Join_Predictions/ids',
  default: [],
});

export const Token_Token_Join_Options_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/tokens/@atoms/Token_Join_Options/entities',
  default: ImmutableMap(),
});

export const Token_Token_Join_Options_Ids_State = atom<string[]>({
  key: 'graph/tokens/@atoms/Token_Join_Options/ids',
  default: [],
});
