import { listSelector } from 'methods/token-has-morph/store';
import { DataItem } from 'types';

import {
  Token_Morphs_Consensus_Annotations_Entities_State,
  Token_Morphs_Consensus_Annotations_Ids_State,
  Token_Morphs_Options_Entities_State,
  Token_Morphs_Options_Ids_State,
  Token_Morphs_Predictions_Entities_State,
  Token_Morphs_Predictions_Ids_State,
} from './atoms';

export const Token_Morphs_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/Morphs_Consensus_Annotations/list',
  Token_Morphs_Consensus_Annotations_Entities_State,
  Token_Morphs_Consensus_Annotations_Ids_State,
);

export const Token_Morphs_Predictions_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/Morphs_Predictions/list',
  Token_Morphs_Predictions_Entities_State,
  Token_Morphs_Predictions_Ids_State,
);

export const Token_Morphs_Options_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/Morphs_Options/list',
  Token_Morphs_Options_Entities_State,
  Token_Morphs_Options_Ids_State,
);
