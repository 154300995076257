import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_TAGS } from 'modules/tags/api';
import { Tags_selector } from 'modules/tags/store';

export default function useTagsQuery(variables: any) {
  const transform = useCallback((data: any) => data.Tags, []);

  return useQuery(QUERY_TAGS, variables, Tags_selector, transform);
}
