import { gql } from '@apollo/client';

export type CreateOrUpdateTagInput = {
  tag_id?: string;
  tag_name: string;
  token_name?: string;
  classes?: Array<{
    id: string
    status?: number
  }>
};

export type CreateOrUpdateTagResult = {
  id: string;
  name: string;
  classes?: Array<{
    id: string
    name: string
    status?: number
  }>
};

export type ItemSpecificaionTag = {
  id: string;
  name: string;
  classes?: Array<{
    id: string;
    name: string;
    status?: number;
  }>
};

export const QUERY_ITEM_SPECIFICATION_TAGS = gql`
  query Item_Specification_Tags($input: Item_Specification_Tags_Input) {
    Item_Specification_Tags(input: $input) {
      id
      name
      classes {
        id
        name
        status
      }
    }
  }
`;

export const QUERY_ITEM_SPECIFICATION_TOKEN_TAGS = gql`
  query Item_Specification_Token_Tags($input: Item_Specification_Token_Tags_Input) {
    Item_Specification_Token_Tags(input: $input) {
      id
      name
      classes {
        id
        name
        status
      }
    }
  }
`;

export const QUERY_ITEM_SPECIFICATION = gql`
  query Item_Specification($id: String!) {
    Item_Specification(id: $id) {
      id
      name
      description
      semanticEntityGroups {
        id
        name
        status
        source
        semanticEntities {
          id
          name
          tags {
            id
            name
            classes {
              id
              name
              status
              source
            }
          }
        }
      }
      phraseTags {
          id
          name
          start
          end
          anchor
          source
          status
          classes {
            id
            name
            status
            source
          }
        }
    }
  }
`;

export const ITEM_SPECIFICATION_ANNOTATION = gql`
  mutation Item_Specification_Annotation($input: Item_Specification_Annotation_Input!) {
    Item_Specification_Annotation(input: $input) {
      id
      name
      description
      semanticEntityGroups {
        id
        name
        status
        source
        semanticEntities {
          id
          name
          tags {
            id
            name
            classes {
              id
              name
              status
              source
            }
          }
        }
      }
      phraseTags {
          id
          name
          start
          end
          anchor
          source
          status
          classes {
            id
            name
            status
            source
          }
        }
    }
  }
`;

export const CREATE_OR_UPDATE_TAG = gql`
  mutation Create_Or_Update_Tag($input: Create_Or_Update_Tag_Input!) {
    Create_Or_Update_Tag(input: $input) {
      id
      name
      classes {
        id
        name
        status
      }
    }
  }
`;

export const CREATE_OR_UPDATE_TAGS = gql`
  mutation Create_Or_Update_Tags($input: [Create_Or_Update_Tag_Input!]!) {
    Create_Or_Update_Tags(input: $input) {
      id
      name
      classes {
        id
        name
        status
      }
    }
  }
`;

export const ASSIGN_ITEM_SPECIFICATION_TO_CONTACT = gql`
  mutation Assign_ItemSpecification_to_Contact($input: Assign_Item_Specification_to_Contact_Input!) {
    assign_ItemSpecification_to_Contact(input: $input) {
      id
    }
  }
`;

export const MARK_ITEM_SPECIFICATION_AS_DONE = gql`
  mutation Mark_ItemSpecification_as_done($ids: [String!]!) {
    mark_ItemSpecification_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_ITEM_SPECIFICATION_AS_NOT_DONE = gql`
  mutation Mark_ItemSpecification_as_not_done($ids: [String!]!) {
    mark_ItemSpecification_as_not_done(ids: $ids) {
      id
    }
  }
`;
