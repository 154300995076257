import { ApolloClient, ApolloQueryResult, from, InMemoryCache, NormalizedCacheObject, OperationVariables, QueryOptions,split } from '@apollo/client';

import { CancellableController } from 'shared/cancellable-controller';

import { appApiLink } from './links/appApiLink';
import { authLink } from './links/authLink';
import { errorLink } from './links/errorLink';
import { graphApiLink } from './links/graphApiLink';

const splitLink = split(
  operation => operation.getContext().apiName === "applicationApi",
  appApiLink,
  graphApiLink,
)

export const apiClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: from([errorLink, authLink, splitLink]),
  resolvers: {},
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  queryDeduplication: false
});

export interface CancellableQueryOptions<TVariables = OperationVariables, TData = any> extends QueryOptions<TVariables, TData> {
  cancellable?: CancellableController
}

export function cancellableQuery(options: CancellableQueryOptions<any, any>): Promise<ApolloQueryResult<any>> {
  const promise = apiClient.query({
    ...options,
    context: {
      fetchOptions: {
        signal: options.cancellable?.signal,
      },
      queryDeduplication: false
    }
  })

  const abortPromise = new Promise((_, reject) => {
    if (options.cancellable) {
      options.cancellable.onAbort = () => {
        reject()
      }
    }
  })

  return Promise.race([promise, abortPromise]) as Promise<ApolloQueryResult<any>>
}



