import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { useContextActions1 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_PHRASE_TO_CONTACT, MARK_PHRASE_AS_DONE, MARK_PHRASE_AS_NOT_DONE } from 'modules/phrases/api';
import useUpdatePhrasesState from 'modules/phrases/hooks/useUpdatePhrasesEntities';

export default function usePhraseContextActions() {
  const onAssignToContactCompleted = useUpdatePhrasesState({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_PHRASE_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdatePhrasesState({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_PHRASE_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdatePhrasesState({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_PHRASE_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  return useContextActions1({ assignToContact, markAsDone, markAsNotDone });
}
