import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Morph_Morphemes_Consensus_Annotations_Entities_State,
  Morph_Morphemes_Consensus_Annotations_Ids_State,
  Morph_Morphemes_Options_Entities_State,
  Morph_Morphemes_Options_Ids_State,
} from './atoms';

export const Morph_Morphemes_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/Morphemes_Consensus_Annotations/list',
  Morph_Morphemes_Consensus_Annotations_Entities_State,
  Morph_Morphemes_Consensus_Annotations_Ids_State,
);

export const Morph_Morphemes_Options_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/Morphemes_Options/list',
  Morph_Morphemes_Options_Entities_State,
  Morph_Morphemes_Options_Ids_State,
);
