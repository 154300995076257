import { gql } from '@apollo/client';

// base asset extension
export const QUERY_SE_TAGS_CONSENSUS_ANNOTATIONS = gql`
  query Semantic_Entity_Tags_Consensus_Annotations(
    $id: String!
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    semanticEntities(where: { id: $id }) {
      Tags_Consensus_Annotations(where: $where, options: $options, consensus: $consensus) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
      }
    }
  }
`;

export const QUERY_SE_TAGS_PREDICTIONS = gql`
  query Semantic_Entity_Tags_Predictions(
    $id: String!
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
  ) {
    semanticEntities(where: { id: $id }) {
      Tags_Predictions(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
      }
    }
  }
`;

export const QUERY_SE_TAGS_OPTIONS = gql`
  query Semantic_Entity_Tags_Options($id: String!, $where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    semanticEntities(where: { id: $id }) {
      Tags_Options(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
      }
    }
  }
`;

// target asset extension
export const QUERY_TAG_SEMANTIC_ENTITIES = gql`
  query Tag_Semantic_Entities(
    $id: BigInt!
    $where: SemanticEntity_Filter_Where_Input
    $options: SemanticEntity_Filter_Options_Input
  ) {
    tags(where: { id: $id }) {
      Semantic_Entities(where: $where, options: $options) {
        id
        name
        active_contact_is_assigned_to
        active_contact_is_done_by
      }
    }
  }
`;

// method node
export const ASSIGN_SE_HAS_TAG_TO_CONTACT = gql`
  mutation Assign_SE_has_Tag_to_Contact($input: Method_SE_has_Tag_Assign_to_Contact_Input!) {
    assign_SE_has_Tag_to_Contact(input: $input) {
      methodSeHasTags {
        id
      }
    }
  }
`;

export const CONFIRM_SE_HAS_TAG_OPTION = gql`
  mutation Confirm_Method_SE_has_Tag_Option($baseAssetId: String!, $targetAssetId: BigInt!) {
    confirm_Method_SE_has_Tag_option(
      input: {
        base_asset_id: $baseAssetId # se id
        target_asset_id: $targetAssetId # tag id
      }
    ) {
      methodSeHasTags {
        id
      }
    }
  }
`;

export const DECLINE_SE_HAS_TAG_OPTION = gql`
  mutation Decline_Method_SE_has_Tag_Option($baseAssetId: String!, $targetAssetId: BigInt!) {
    decline_Method_SE_has_Tag_option(
      input: {
        base_asset_id: $baseAssetId # se id
        target_asset_id: $targetAssetId # tag id
      }
    ) {
      methodSeHasTags {
        id
      }
    }
  }
`;
