import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SearchAutocomplete from 'components/Controls/SearchAutocomplete';
import { useLazyData } from 'hooks/useData';
// import { SEARCH_CLASSES } from 'modules/classes/api';
// import { SEARCH_CLUSTERS } from 'modules/clusters/api';
// import { SEARCH_SEMANTIC_ENTITIES } from 'modules/semantic-entities/api';
import { FilterParamsType } from 'modules/semantic-entities/constants';
import { SEARCH_TAGS } from 'modules/tags/api';

type Props = {
  value: FilterParamsType;
  onChange?: (value: FilterParamsType) => void;
};

export default function SemanticEntitiesFilterForm({ value, onChange }: Props) {
  // const {
  //   data: semanticEntities,
  //   loading: isSemanticEntitiesLoading,
  //   getData: getSemanticEntities,
  // } = useLazyData(SEARCH_SEMANTIC_ENTITIES, 'Semantic_Entities', { nameField: 'name_STARTS_WITH_FULL_TEXT' });
  const {
    data: tags,
    loading: isTagsLoading,
    getData: getTags,
  } = useLazyData(SEARCH_TAGS, 'Tags', { nameField: 'name_STARTS_WITH_FULL_TEXT' });
  // const {
  //   data: classes,
  //   loading: isClassesLoading,
  //   getData: getClasses,
  // } = useLazyData(SEARCH_CLASSES, 'Classes');
  // const {
  //   data: clusters,
  //   loading: isClustersLoading,
  //   getData: getClusters,
  // } = useLazyData(SEARCH_CLUSTERS, 'Clusters');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const newValue = event.target.checked;
    if (onChange) {
      onChange({
        ...value,
        [field]: newValue,
      });
    }
  };

  const handleAutocompleteChange = (event: React.ChangeEvent<HTMLInputElement>, controlValue: any) => {
    if (onChange) {
      const field = event.target.name;
      onChange({
        ...value,
        [field]: controlValue,
      });
    }
  };

  return (
    <div>
      {/* <SearchAutocomplete
        label="Semantic Entities"
        name="semanticEntities"
        multiple
        value={value.semanticEntities}
        options={semanticEntities}
        loading={isSemanticEntitiesLoading}
        onSearchChange={getSemanticEntities}
        onChange={handleAutocompleteChange}
      /> */}
      <SearchAutocomplete
        label="Tags"
        name="tags"
        multiple
        value={value.tags}
        options={tags}
        loading={isTagsLoading}
        onSearchChange={getTags}
        onChange={handleAutocompleteChange}
      />
      {/* <SearchAutocomplete
        label="Classes"
        name="classes"
        multiple
        value={value.classes}
        options={classes}
        loading={isClassesLoading}
        onSearchChange={getClasses}
        onChange={handleAutocompleteChange}
      />
      <SearchAutocomplete
        label="Clusters"
        name="clusters"
        multiple
        value={value.clusters}
        options={clusters}
        loading={isClustersLoading}
        onSearchChange={getClusters}
        onChange={handleAutocompleteChange}
      /> */}
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={value.onlyAssigned}
              onChange={handleCheckboxChange}
              name="onlyAssigned"
              color="primary"
            />
          }
          label="only assigned to me"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox checked={value.onlyDone} onChange={handleCheckboxChange} name="onlyDone" color="primary" />
          }
          label="only done"
        />
      </div>
    </div>
  );
}
