import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 30,
    position: 'relative',
  },
  titleButton: {
    background: '#1565C0',
    color: '#FFFFFF',
    borderRadius: 2,
    padding: 3,
    marginRight: 10,
    '&:hover': {
      backgroundColor: '#1565C0',
      opacity: 0.7,
    },
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    color: '#1565C0',
    fontWeight: 700,
  },
  headerAction: {
    alignSelf: 'end',
  },
  actions: {
    display: 'flex',
    marginRight: 10,
    gap: 5,
  },
  actionButton: {
    borderWidth: 0.5,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    borderRadius: 2,
    padding: 6,
  },
  actionButtonActive: {
    borderColor: '#1565C0',
  },
  content: {
    transition: 'none',
    paddingBottom: 36,
  },
  handle: {
    position: 'absolute',
    cursor: 'pointer',
    bottom: theme.spacing(0.5),
    right: theme.spacing(1),
  },
  handleResize: {
    cursor: 'ns-resize',
  },
}));

export default useStyles;
