import { Fragment, ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import clsx from 'clsx';

import { Collapse } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HandleIcon from 'components/icons/HandleIcon';

import useStyles from './styles';

type ActionType = {
  icon?: ReactNode;
  meta?: Record<string, any>;
  content?: ReactNode;
  handler: () => void;
};

type Props = {
  title?: string;
  defaultWidth?: number;
  defaultHeight?: number;
  actions?: ActionType[];
  children?: any;
};

function renderAction(action: ActionType, classes: any): ReactNode | null {
  if (action.icon) {
    return (
      <IconButton
        className={clsx(classes.actionButton, {
          [classes.actionButtonActive]: action.meta?.dirty,
        })}
        onClick={action.handler}
      >
        {action.icon}
      </IconButton>
    );
  }

  return action?.content || null;
}

export default function DataGrid(props: Props) {
  const {
    title,
    actions,
    defaultWidth = 0,
    defaultHeight = 440,
    children,
  } = props
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [resizing, setResizing] = useState(false);
  const [size, setSize] = useState({ width: defaultWidth, height: defaultHeight });

  const onResize = useCallback((e: SyntheticEvent, { size }: ResizeCallbackData) => setSize(size), [setSize]);
  const onResizeStart = useCallback(() => setResizing(true), [setResizing]);
  const onResizeStop = useCallback(() => setResizing(false), [setResizing]);
  const handleOpen = useCallback(() => setOpen((value) => !value), [setOpen]);

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <>
            <IconButton className={classes.titleButton} onClick={handleOpen}>
              {open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            <span>{title}</span>
          </>
        }
        classes={{
          title: classes.headerTitle,
          action: classes.headerAction,
        }}
        action={
          actions &&
          open && (
            <>
              <div className={classes.actions}>
                {actions.map((action: any, index: any) => (
                  <Fragment key={index}>{renderAction(action, classes)}</Fragment>
                ))}
              </div>
            </>
          )
        }
      />
      <Collapse in={open} timeout="auto">
        <Divider />
        <Resizable
          axis="y"
          minConstraints={[10, 240]}
          resizeHandles={['s']}
          width={size.width}
          height={size.height}
          onResize={onResize}
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
          handle={
            <div
              className={clsx(classes.handle, {
                [classes.handleResize]: resizing,
              })}
            >
              <HandleIcon light />
            </div>
          }
        >
          <CardContent className={classes.content} style={{ height: size.height ? size.height : 'auto' }}>
            {children}
          </CardContent>
        </Resizable>
      </Collapse>
    </Card>
  );
}
