import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Tags_Entities_State, Tags_Ids_State } from './atoms';

export * from "../sections/AssociatedTokensDataSection/selectors"
export * from "../sections/ChildrenTagsDataSection/selectors"
export * from "../sections/ClassesDataSection/selectors"
export * from "../sections/ParentsTagsDataSection/selectors"
export * from "../sections/SemanticEntitiesDataSection/selectors"
export * from "../sections/SynonymsTagsDataSection/selectors"

export const Tags_selector = listSelector<DataItem>(
  'graph/tags/@selectors/list',
  Tags_Entities_State,
  Tags_Ids_State,
);
