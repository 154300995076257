import { useCallback, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { FilterParamsType } from 'modules/classes/constants';

import Form from './FilterForm';

type Props = {
  value: FilterParamsType;
  onChange: (value: FilterParamsType) => void;
  onClose: () => void;
  onReset: () => void;
};

export default function ClassesFilterModal({ value, onClose, onChange, onReset }: Props) {
  const [localValue, setLocalValue] = useState(value);
  const changeAndClose = () => {
    onChange(localValue);
    onClose();
  };

  const onLocalClose = () => {
    setLocalValue(value);
    onClose();
  };

  useEffect(() => setLocalValue(value), [value]);

  const resetLocal = useCallback(() => {
    setLocalValue(value);
    onReset();
  }, [value, onReset]);

  return (
    <Modal
      title="Filter"
      open
      actionsContent={
        <>
          <Button variant="contained" color="primary" onClick={changeAndClose}>
            Filter
          </Button>
          <Button variant="contained" color="default" onClick={resetLocal}>
            Reset
          </Button>
        </>
      }
      onClose={onLocalClose}
    >
      <Form value={localValue} onChange={setLocalValue} />
    </Modal>
  );
}
