import { TransformVariablesConfig } from 'configuration';
import { FilterParamsType, SortParamsType } from 'modules/tokens/constants';
import { transformVariables } from 'modules/tokens/utils';

type Args = {
  languageId: number;
};

export default function transformVariablesAdapter<T extends object>(args: Args): any {
  return (
    filterParams: FilterParamsType,
    sortParams: SortParamsType,
    transformVariablesConfig: TransformVariablesConfig,
  ): T => {
    const variables = transformVariables<T>(filterParams, sortParams, {}, transformVariablesConfig);

    return {
      language: args.languageId,
      ...variables,
    };
  };
}
