import { useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';

import NameForm from './NameForm';

type Value = {
  name: string;
};

type Props = {
  value: Value;
  onChange: (value: any) => void;
  onClose: () => void;
};

export default function ClassNameModal({ value, onClose, onChange }: Props) {
  const formikRef = useRef<any>();

  const handleClick = () => {
    const { submitForm } = formikRef.current;
    submitForm();
  };

  return (
    <Modal
      title="Add / Name"
      open
      actionsContent={
        <Button variant="contained" color="primary" onClick={handleClick}>
          Add
        </Button>
      }
      onClose={onClose}
    >
      <NameForm initialValues={value} formRef={formikRef} onSubmit={onChange} />
    </Modal>
  );
}
