import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Class_Clusters_Consensus_Annotations_Entities_State,
  Class_Clusters_Consensus_Annotations_Ids_State,
  Class_Clusters_Options_Entities_State,
  Class_Clusters_Options_Ids_State,
  Class_Clusters_Predictions_Entities_State,
  Class_Clusters_Predictions_Ids_State,
} from './atoms';

export const Class_Clusters_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Clusters_Consensus_Annotations/list',
  Class_Clusters_Consensus_Annotations_Entities_State,
  Class_Clusters_Consensus_Annotations_Ids_State
);

export const Class_Clusters_Predictions_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Clusters_Predictions/list',
  Class_Clusters_Predictions_Entities_State,
  Class_Clusters_Predictions_Ids_State
);

export const Class_Clusters_Options_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Clusters_Options/list',
  Class_Clusters_Options_Entities_State,
  Class_Clusters_Options_Ids_State
);
