import { useMemo } from 'react';

import { MarkAsDoneParams } from 'hooks/useMarkAsDone';
import { MarkAsNotDoneParams } from 'hooks/useMarkAsNotDone';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useAssignToContactContext } from 'providers/AssignToContactProvider';
import { ContactItem, DataItem } from 'types';

import { ActionAllowedParams, ActionVisibleParams, ContextAction, HandlerParams } from './types';

type Params = {
  assignToContact?: (params: { dataItems: DataItem[]; contacts: ContactItem[] }) => void;
  assignMethodToContact?: (params: { dataItems: DataItem[]; contacts: ContactItem[] }) => void;
  markAsDone?: (params: MarkAsDoneParams) => void;
  markAsNotDone?: (params: MarkAsNotDoneParams) => void;
};

const actionAllowed = (key: string, params?: ActionAllowedParams): boolean => {
  if (typeof params === "undefined") {
    return true;
  }

  if (typeof params === "boolean") {
    return params;
  }

  return params[key] ?? true
};

export default function useContextActions2(path: string, params: Params = {}): ContextAction[] {
  const { assignToContact, assignMethodToContact, markAsDone, markAsNotDone } = params;

  const { user } = useAuth();
  const { openAssignToContactModal } = useAssignToContactContext();

  const contextActions = useMemo(() => {
    const mainContextActions = [
      ...(
        assignToContact ? [
          {
            id: 'ASSIGN_TO_ME',
            title: 'Assign to me',
            meta: {
              actionAllowed: (params?: ActionAllowedParams) => actionAllowed('ASSIGN_TO_ME', params),
              actionVisible: () => true,
            },
            handler: ({ selectedItems, contextItem }: HandlerParams) => {
              const contacts = [{ id: user?.contactId, name: user?.name}] as ContactItem[];
              const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
              assignToContact({ contacts, dataItems });
            },
          },
          {
            id: 'ASSIGN_TO_ANOTHER_CONTACT',
            title: 'Assign to another Contact',
            meta: {
              actionAllowed: (params?: ActionAllowedParams) => actionAllowed('ASSIGN_TO_ANOTHER_CONTACT', params),
              actionVisible: () => true,
            },
            handler: ({ selectedItems, contextItem }: HandlerParams) => {
              const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
              openAssignToContactModal({ dataItems }, ({ contacts }: { contacts: ContactItem[] }) => {
                assignToContact({ contacts, dataItems });
              });
            },
          },
        ] : []
      ),
      ...(
        assignMethodToContact ? [
          {
            id: 'ASSIGN_METHOD_TO_ME',
            title: 'Assign method to me',
            meta: {
              actionAllowed: (params?: ActionAllowedParams) => actionAllowed('ASSIGN_METHOD_TO_ME', params),
              actionVisible: () => true,
            },
            handler: ({ selectedItems, contextItem }: HandlerParams) => {
              const contacts = [{ id: user?.contactId, name: user?.name}] as ContactItem[];
              const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
              assignMethodToContact({ contacts, dataItems });
            },
          },
          {
            id: 'ASSIGN_METHOD_TO_ANOTHER_CONTACT',
            title: 'Assign method to another Contact',
            meta: {
              actionAllowed: (params?: ActionAllowedParams) => actionAllowed('ASSIGN_METHOD_TO_ANOTHER_CONTACT', params),
              actionVisible: () => true,
            },
            handler: ({ selectedItems, contextItem }: HandlerParams) => {
              const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
              openAssignToContactModal({ dataItems }, ({ contacts }: { contacts: ContactItem[] }) => {
                assignMethodToContact({ contacts, dataItems });
              });
            },
          },
        ] : []
      ),
      ...(
        markAsDone ? [
          {
            id: 'MARK_AS_DONE',
            title: 'Done',
            meta: {
              actionAllowed: (params?: ActionAllowedParams) => actionAllowed('MARK_AS_DONE', params),
              actionVisible: ({ selectedItems, contextItem }: ActionVisibleParams) => Boolean(selectedItems.length > 1 || !contextItem?.active_contact_is_done_by),
            },
            handler: ({ selectedItems, contextItem }: HandlerParams) => {
              const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
              markAsDone?.({ dataItems });
            },
          },
        ] : []
      ),
      ...(
        markAsNotDone ? [
          {
            id: 'MARK_AS_NOT_DONE',
            title: 'Not Done',
            meta: {
              actionAllowed: (params?: ActionAllowedParams) => actionAllowed('MARK_AS_NOT_DONE', params),
              actionVisible: ({ selectedItems, contextItem }: ActionVisibleParams) => Boolean(selectedItems.length > 1 || contextItem?.active_contact_is_done_by),
            },
            handler: ({ selectedItems, contextItem }: HandlerParams) => {
              const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
              markAsNotDone?.({ dataItems });
            },
          },
        ] : []
      ),
      {
        id: 'OPEN',
        title: 'Open in a new window',
        meta: {
          actionAllowed: (params?: ActionAllowedParams) => actionAllowed('OPEN', params),
          actionVisible: ({ selectedItems }: ActionVisibleParams) => selectedItems.length < 2,
        },
        handler: ({ contextItem }: HandlerParams) => window.open(`${path}/${contextItem.id}`),
      },
    ];

    return mainContextActions;
  }, [
    path,
    user,
    assignToContact,
    assignMethodToContact,
    markAsDone,
    markAsNotDone,
    openAssignToContactModal,
  ]);

  return contextActions;
}
