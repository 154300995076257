import { PropsWithChildren, useCallback, useContext, useMemo, useRef } from 'react';

import AssignToContactModal, { INITIAL_ASSIGN_TO_CONTACT_PARAMS } from 'components/AssignToContactModal';
import useModalWithValue from 'hooks/useModalWithValue';
import { ContactItem } from 'types';

import Context, { AssignToContactCallback, AssignToContactValue } from './AssignToContactContext';

type Props = PropsWithChildren<{}>;

export const useAssignToContactContext = () => useContext(Context);

const AssignToContactProvider = ({ children }: Props) => {
  const itemsRef = useRef<AssignToContactValue | null>(null);
  const callbackRef = useRef<AssignToContactCallback | null>(null);

  const { open, value, openModal, closeModal } = useModalWithValue({
    initialValue: INITIAL_ASSIGN_TO_CONTACT_PARAMS,
    closeOnChange: false,
  });

  const context = useMemo(
    () => ({
      openAssignToContactModal: (value: AssignToContactValue, callback: AssignToContactCallback) => {
        itemsRef.current = value;
        callbackRef.current = callback;
        openModal();
      },
    }),
    [openModal],
  );

  const handleChange = useCallback(
    async ({ contacts }: { contacts: ContactItem[] }) => {
      if (itemsRef.current && callbackRef.current) {
        const { dataItems } = itemsRef.current;
        callbackRef.current({ contacts, dataItems });
      }

      closeModal();
      itemsRef.current = null;
      callbackRef.current = null;
    },
    [closeModal],
  );

  const handleClose = useCallback(() => {
    closeModal();
    itemsRef.current = null;
    callbackRef.current = null;
  }, [closeModal]);

  return (
    <Context.Provider value={context}>
      {children}
      {open && <AssignToContactModal value={value} onClose={handleClose} onChange={handleChange} />}
    </Context.Provider>
  );
};

export default AssignToContactProvider;
