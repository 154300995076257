import { MouseEvent, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ContextMenu from 'components/ContextMenu';
import Page from 'components/Page';
import TopLevelListItems from 'components/TopLevelListItems';
import { ContextAction } from 'hooks/useContextActions';
import { useSeCreation } from 'hooks/useSeCreation';
import ContextActionsProvider from 'providers/ContextActionsProvider';
import { MAIN_SECTION, useDataSelectionContext } from 'providers/DataSelectionProvider';
import { CountField, DataItem, KeyField, NameField } from 'types';

import useActions from './useActions';

type Action = {
  handler: (e?: MouseEvent) => void;
};

type Structure = {
  keyField?: KeyField | Array<KeyField>;
  nameField?: NameField;
  countField?: CountField | Array<CountField>;
};

type Props = {
  title: string;
  useQuery: any;
  dataVariables?: any;
  structure?: Structure;
  actions?: Record<string, Action>;
  contextActions?: ContextAction[];
  children: (props: DataPageRenderProps) => ReactNode;
};

export type DataPageRenderProps = {
  currentItemId?: string;
  currentItem: DataItem | null;
};

export default function DataPage(props: Props) {
  const { title, dataVariables, structure, actions, contextActions, children, useQuery } = props;

  const params = useParams<{ id: string }>();
  const se_creation = useSeCreation();
  const { loading, data, fetchMore } = useQuery(dataVariables);
  const { currentItem, selectedItems, setCurrentItem, onSelect, onSelectAll, onToggle } = useDataSelectionContext();

  const handleToggle = useCallback((item: DataItem) => onToggle(MAIN_SECTION, item), [onToggle]);
  const handleSelect = useCallback((item: DataItem) => onSelect(MAIN_SECTION, item), [onSelect]);
  const handleSelectAll = useCallback(() => onSelectAll(MAIN_SECTION, data), [data, onSelectAll]);

  const newActions = useMemo(() => ({ pin: { handler: handleSelectAll } }), [handleSelectAll]);
  const mergedActions = useActions(actions, newActions);

  useEffect(() => {
    if (!currentItem && data.length > 0 || currentItem?.id !== params.id) {
      const item = params.id ? data.find((item: DataItem) => item.id === params.id) : data[0];

      if (item) {
        setCurrentItem(item);
      } else if (params.id) {
        setCurrentItem({ id: params.id } as DataItem);
      }
    }
  }, [currentItem, params.id, data, setCurrentItem]);

  return (
    <ContextActionsProvider>
      <Page
        title={title}
        actions={mergedActions}
        data={
          loading ? (
            <h1>Loading</h1>
          ) : (
            <TopLevelListItems
              items={data}
              nameField={structure?.nameField}
              countField={structure?.countField}
              currentItem={currentItem}
              se_creation={se_creation}
              selectedItems={selectedItems.get(MAIN_SECTION)}
              onChange={handleSelect}
              onLoadMoreItems={fetchMore}
              onToggleItem={handleToggle}
            />
          )
        }
      >
        {children({ currentItem, currentItemId: currentItem?.id })}
      </Page>

      <ContextMenu menuId="dataPage" actions={contextActions} />
    </ContextActionsProvider>
  );
}
