import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import { CREATE_CLUSTER } from 'modules/clusters/api';
import { Clusters_Entities_State, Clusters_Ids_State } from 'modules/clusters/store';
import { entitiesUpdaterFactory, idsUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Values = {
  name: string;
};

type UseClusterParams = {
  onCreate?: () => void;
};

type UseClusterResult = {
  createCluster: (values: Values) => void;
};

export default function useCluster(params: UseClusterParams = {}): UseClusterResult {
  const { onCreate } = params;

  const updateEntities = useSetRecoilState(Clusters_Entities_State);
  const updateIds = useSetRecoilState(Clusters_Ids_State);

  const onCompleted = useCallback((result: any) => {
    const ids: string[] = result.create_Cluster.clusters.map((x: DataItem) => x.id);
    const entries = (result.create_Cluster.clusters as DataItem[]).map(x => [x.id, x, x] as const);

    const idsUpdater = idsUpdaterFactory(ids);
    const entitiesUpdater = entitiesUpdaterFactory(entries, true);

    updateEntities(entitiesUpdater);
    updateIds(idsUpdater);
  }, [
    updateEntities,
    updateIds,
  ]);
  const [create] = useMutation(CREATE_CLUSTER, { onCompleted });
  const createCluster = useCallback(
    (values: Values) => {
      create({
        variables: {
          input: {
            name: values.name,
          }
        },
      });

      onCreate?.()
    }, [create, onCreate]
  )

  return {
    createCluster,
  };
}
