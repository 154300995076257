import { useCallback, useEffect, useState } from 'react';
import { DocumentNode, OperationVariables, TypedDocumentNode, useLazyQuery } from '@apollo/client';
import merge from 'lodash/merge';

import { DataItem } from 'types';

type Query = DocumentNode | TypedDocumentNode<any, OperationVariables>;

type GraphApiItem = {
  id: string;
  name: string;
};

export default function useLazyTagsData<T extends GraphApiItem>(query: Query, field: string) {
  const [getRawData, { loading, data: rawData }] = useLazyQuery(query);
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    if (loading) {
      setData([]);
    } else {
      setData(
        (rawData?.[field] || []).map((item: T) => ({
          id: item.id,
          name: item.name,
        })),
      );
    }
  }, [loading, rawData, field, setData]);

  const getData = useCallback(
    (search?: string, args?: object) => {
      if (!!search) {
        const input = merge(args, {
          name: search,
        });

        getRawData({
          variables: {
            // requestId is needed to avoid cache issues
            requestId: new Date().valueOf(),
            input,
          },
        });
      } else {
        setData([]);
      }
    },
    [getRawData],
  );

  return {
    loading,
    data,
    getData,
  };
}
