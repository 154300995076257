import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ContextAction, useContextActions1 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_SE_TO_CONTACT, MARK_SE_AS_DONE, MARK_SE_AS_NOT_DONE } from 'modules/semantic-entities/api';
import useUpdateSemanticEntityEntities from 'modules/semantic-entities/hooks/useUpdateSemanticEntityEntities';

export default function useSemanticEntityContextActions(): ContextAction[] {
  const onAssignToContactCompleted = useUpdateSemanticEntityEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_SE_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateSemanticEntityEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_SE_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateSemanticEntityEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_SE_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  return useContextActions1({ assignToContact, markAsDone, markAsNotDone });
}
