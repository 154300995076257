import { useAnnotation } from 'hooks/useAnnotation';
import { CONFIRM_SE_HAS_TAG_OPTION, DECLINE_SE_HAS_TAG_OPTION } from 'methods/se-has-tag/api';
import { AnnotationConfig, DataItem } from 'types';

type useSeHasTagAnnotationsParams = {
  parentItem: DataItem;
}

type useSeHasTagAnnotationsOptions = {
  onConfirmCompleted?: any;
  onDeclineCompleted?: any;
};

export default function useSeHasTagAnnotations(
  annotationConfig: AnnotationConfig,
  params: useSeHasTagAnnotationsParams,
  options?: useSeHasTagAnnotationsOptions
) {
  const { parentItem } = params;

  const confirmOptions = { onCompleted: options?.onConfirmCompleted };
  const confirmAnnotation = useAnnotation(annotationConfig, CONFIRM_SE_HAS_TAG_OPTION, parentItem, confirmOptions);

  const declineOptions = { onCompleted: options?.onDeclineCompleted };
  const declineAnnotation = useAnnotation(annotationConfig, DECLINE_SE_HAS_TAG_OPTION, parentItem, declineOptions);

  return {
    confirmAnnotation,
    declineAnnotation,
  };
}
