import { useState } from 'react';

import Button from '@material-ui/core/Button';

import SearchAutocomplete from 'components/Controls/SearchAutocomplete';
import Modal from 'components/Modal';
import { useLazyData } from 'hooks/useData';
import { SEARCH_CONTACTS } from 'modules/contacts/api';
import { ContactItem } from 'types';

type Value = {
  contacts: ContactItem[];
};

type Props = {
  onClose: () => void;
  value: Value;
  onChange: (value: any) => void;
};

export const INITIAL_ASSIGN_TO_CONTACT_PARAMS = {
  contacts: [],
};

export default function AssignToContactModal({ onClose, value, onChange }: Props) {
  const [localValue, setLocalValue] = useState<Value>(value);
  const {
    data: contacts,
    loading: isContactsLoading,
    getData: getContacts,
  } = useLazyData(SEARCH_CONTACTS, 'Contacts');

  const handleChange = () => {
    onChange(localValue);
  };

  const handleAutocompleteChange = (event: React.ChangeEvent<HTMLInputElement>, controlValue: any) => {
    const field = event.target.name;
    setLocalValue({
      ...value,
      [field]: controlValue,
    } as Value);
  };

  return (
    <Modal
      title="Assign"
      open
      onClose={onClose}
      actionsContent={
        <Button variant="contained" color="primary" onClick={handleChange}>
          Assign
        </Button>
      }
    >
      <SearchAutocomplete
        label="Contacts"
        name="contacts"
        multiple
        value={localValue.contacts}
        options={contacts}
        loading={isContactsLoading}
        onChange={handleAutocompleteChange}
        onSearchChange={getContacts}
      />
    </Modal>
  );
}
