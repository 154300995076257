import { useCallback, useRef } from 'react';

import { useAnnotation } from 'hooks/useAnnotation';
import { CONFIRM_PHRASE_HAS_MEANING_OPTION, DECLINE_PHRASE_HAS_MEANING_OPTION } from 'methods/phrase-has-meaning/api';
import { AnnotationConfig, DataItem } from 'types';

type UsePhraseHasMeaningAnnotationsParams = {
  parentItem: DataItem;
  languageId: number;
};

type UsePhraseHasMeaningAnnotationsOptions = {
  onConfirmCompleted?: (result: unknown, item: DataItem) => void;
  onDeclineCompleted?: (result: unknown, item: DataItem) => void;
};

type UsePhraseHasMeaningAnnotationsResult = {
  confirmAnnotation: (item: DataItem) => void;
  declineAnnotation: (item: DataItem) => void;
};

export default function usePhraseHasMeaningAnnotations(
  annotationConfig: AnnotationConfig,
  params: UsePhraseHasMeaningAnnotationsParams,
  options: UsePhraseHasMeaningAnnotationsOptions = {},
): UsePhraseHasMeaningAnnotationsResult {
  const { parentItem, languageId } = params;
  const {
    onConfirmCompleted: onConfirmAnnotationCompleted,
    onDeclineCompleted: onDeclineAnnotationCompleted,
  } = options;

  const ref = useRef<DataItem | null>(null);

  const onConfirmCompleted = useCallback((result: unknown) => {
    onConfirmAnnotationCompleted?.(result, ref.current as DataItem);
    ref.current = null;
  }, [onConfirmAnnotationCompleted]);
  const confirm = useAnnotation(annotationConfig, CONFIRM_PHRASE_HAS_MEANING_OPTION, parentItem, { onCompleted: onConfirmCompleted });

  const onDeclineCompleted = useCallback((result: unknown) => {
    onDeclineAnnotationCompleted?.(result, ref.current as DataItem);
    ref.current = null;
  }, [onDeclineAnnotationCompleted]);
  const decline = useAnnotation(annotationConfig, DECLINE_PHRASE_HAS_MEANING_OPTION, parentItem, { onCompleted: onDeclineCompleted });

  const confirmAnnotation = useCallback((item: DataItem) => {
    ref.current = {
      ...item,
      Phrase_has_Meaning_language: ref.current?.Phrase_has_Meaning_language ?? languageId,
    }  as DataItem;
    confirm(item, { language: item.Phrase_has_Meaning_language ?? languageId });
  }, [languageId, confirm]);

  const declineAnnotation = useCallback((item: DataItem) => {
    ref.current = {
      ...item,
      Phrase_has_Meaning_language: ref.current?.Phrase_has_Meaning_language ?? languageId,
    }  as DataItem;
    decline(item, { language: item.Phrase_has_Meaning_language ?? languageId });
  }, [languageId, decline]);

  return {
    confirmAnnotation,
    declineAnnotation,
  };
}
