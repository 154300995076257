import { Field, Form, Formik } from 'formik';

import InputLabel from '@material-ui/core/InputLabel';

import { renderTextField } from 'components/withFormik';

import useStyles from './NameForm.styles';

export type FormValues = {
  name: string;
};

type Props = {
  initialValues: FormValues;
  formRef?: any;
  onSubmit: (values: FormValues) => void;
};

export default function MorphNameForm({ initialValues, formRef, onSubmit }: Props) {
  const classes = useStyles();

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <InputLabel className={classes.label} htmlFor="name">
            Semantic Entity Name
          </InputLabel>
          <Field
            name="name"
            component={renderTextField}
            variant="outlined"
            placeholder="Start typing..."
            required
            fullWidth
          />
        </Form>
      )}
    </Formik>
  );
}
