import { gql } from '@apollo/client';

export enum SemanticEntitySortOption {
  BY_COUNT_OF_RELATED_OFFERS = 'by_count_of_related_Offers',
  BY_COUNT_OF_RELATED_DELIVERABLES = 'by_count_of_related_Deliverables',
  BY_COUNT_OF_PHRASE_HAS_MEANING_EDGES = 'by_count_of_Phrase_has_Meaning_edges',
}

export type SemanticEntitySortOptionsInput = {
  option: SemanticEntitySortOption;
  direction: 'DESC' | 'ASC';
};

export type SemanticEntityFilterWhereInput = {
  name_STARTS_WITH_FULL_TEXT?: string;
  only_assigned?: boolean;
  only_done?: boolean;
};

export type SemanticEntityFilterOptionsInput = {
  sort?: SemanticEntitySortOptionsInput;
  offset?: number;
  limit?: number;
};

export type SemanticEntityFilterInput = {
  where?: SemanticEntityFilterWhereInput;
  options?: SemanticEntityFilterOptionsInput;
};

export const QUERY_SE_CREATION_IN_PROGRESS = gql`
  query se_creation_in_progress {
    se_creation_in_progress
  }
`;

export const QUERY_SEMANTIC_ENTITIES = gql`
  query Semantic_Entities($where: SemanticEntity_Filter_Where_Input, $options: SemanticEntity_Filter_Options_Input) {
    Semantic_Entities(where: $where, options: $options) {
      id
      name
      active_contact_is_assigned_to
      active_contact_is_done_by
    }
  }
`;

export const QUERY_SE_NEXT_TAGS = gql`
  query Semantic_Entity_Next_Tags($id: String!, $where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    semanticEntities(where: { id: $id }) {
      Next_Tags(where: $where, options: $options) {
        id
        name
        active_contact_is_assigned_to
        active_contact_is_done_by
      }
    }
  }
`;

export const QUERY_SE_NEXT_SEMANTIC_ENTITIES = gql`
  query Semantic_Entity_Next_Semantic_Entities(
    $id: String!
    $where: SemanticEntity_Filter_Where_Input
    $options: SemanticEntity_Filter_Options_Input
  ) {
    semanticEntities(where: { id: $id }) {
      Next_Semantic_Entities(where: $where, options: $options) {
        id
        name
        active_contact_is_assigned_to
        active_contact_is_done_by
      }
    }
  }
`;

export const QUERY_SE_NEXT_TOKENS = gql`
  query Semantic_Entity_Tokens($id: String, $where: Token_Filter_Where_Input, $options: Token_Filter_Options_Input) {
    semanticEntities(where: { id: $id }) {
      Associated_Next_Tokens(where: $where, options: $options) {
        id
        name
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
      }
    }
  }
`;

export const SEARCH_SEMANTIC_ENTITIES = gql`
  query Semantic_Entities($where: SemanticEntity_Filter_Where_Input, $options: SemanticEntity_Filter_Options_Input) {
    Semantic_Entities(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const CREATE_SEMANTIC_ENTITY = gql`
  mutation Create_Semantic_Entity($input: Create_SemanticEntity_Input!) {
    create_Semantic_Entity(input: $input) {
      semanticEntities {
        id
        name
      }
    }
  }
`;

export const CREATE_SEMANTIC_ENTITY_IF_NOT_EXISTS = gql`
  mutation Create_Semantic_Entity_If_Not_Exists($input: Create_SemanticEntity_If_Not_Exists_Input!) {
    create_Semantic_Entity_if_not_exists(input: $input) {
      semanticEntities {
        id
        name
        SE_has_Tag {
          id
          name
        }
      }
    }
  }
`;

export const ASSIGN_SE_TO_CONTACT = gql`
  mutation Assign_SE_to_Contact($input: Assign_SE_to_Contact_Input!) {
    assign_SE_to_Contact(input: $input) {
      id
    }
  }
`;

export const MARK_SE_AS_DONE = gql`
  mutation mark_SE_as_done($ids: [String!]!) {
    mark_SE_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_SE_AS_NOT_DONE = gql`
  mutation mark_SE_as_not_done($ids: [String!]!) {
    mark_SE_as_not_done(ids: $ids) {
      id
    }
  }
`;
