import config, { TransformVariablesConfig } from 'configuration';
import { MorphemeFilterOptionsInput, MorphemeFilterWhereInput, MorphemeSortOption } from 'modules/morphemes/api';
import { deleteProperty } from 'shared/utils';
import { PaginationParams } from 'types';

import { FilterParamsType, SortParamsType } from './constants';

export function transformVariables<T extends object>(
  filterParams: FilterParamsType,
  sortParams: SortParamsType,
  paginationParams: PaginationParams,
  transformVariablesConfig?: TransformVariablesConfig,
): T {
  transformVariablesConfig = {
    ...config.transformVariablesConfig,
    ...transformVariablesConfig,
  };

  // where
  const where: MorphemeFilterWhereInput = {};

  if (filterParams) {
    if (filterParams.morpheme?.name) {
      where.name_STARTS_WITH = filterParams.morpheme?.name;
    }

    if (filterParams.countCharactersMin || filterParams.countCharactersMax) {
      where.count_characters_RANGE = {
        min: filterParams.countCharactersMin,
        max: filterParams.countCharactersMax,
      };
    }

    if (filterParams.onlyAssigned) {
      where.only_assigned = true;
    }

    if (filterParams.onlyDone) {
      where.only_done = true;
    }
  }

  // options
  const options: MorphemeFilterOptionsInput = {
    limit: config.dataPage.limit,
    sort: {
      option: sortParams.option as MorphemeSortOption,
      direction: sortParams.direction,
    },
  };

  // filter
  const filter = { where, options } as T;
  deleteProperty<T>(filter, 'where');
  deleteProperty<T>(filter, 'options');

  return filter;
}
