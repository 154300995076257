import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontWeight: 600,
      color: '#000000',
      padding: theme.spacing(1.5, 0),
    },
    inputIcon: {
      color: '#9E9E9E',
    },
  }),
);

export default useStyles;
