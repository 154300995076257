import { TextSpan } from "plugins/react-text-annotate";
import { Operation, Source } from 'shared/constants';

import { Action, Span } from "./types";

export function getColor(span: Span, action: Action) {
  if (span.anchor && action.id === Operation.ATTRIBUTE_ON_BUILDING_MATERIAL) {
    return action.colors.inactive;
  }

  if (span.source === Source.prediction) {
    return action.colors.prediction;
  }

  return span.dirty ? action.colors.dirty : action.colors.default;
}

export class Transformer {
  toSpan(textSpan: TextSpan, action: Action): Span {
    return {
        id: textSpan.id,
        text: textSpan.text,
        seId: textSpan.meta?.seId || null,
        groupId: textSpan.meta?.groupId || null,
        tagId: textSpan.meta?.tagId || null,
        first_position_in_phrase: textSpan.start,
        last_position_in_phrase: textSpan.end,
        anchor: textSpan.meta?.anchor,
        source: textSpan.meta?.source,
        status: textSpan.meta?.status,
        active: textSpan.meta?.active,
        invisible: textSpan.meta?.invisible,
        dirty: textSpan.meta?.color === action.colors.dirty,
        labels: textSpan.tags?.map(it =>
          ({
            id: it.id,
            name: it.name,
            source: it.meta?.source,
            status: it.meta?.status,
            selected: it.meta?.selected,
            dirty: it.meta?.dirty,
          })
        ),
      };
  }

  toSpanArray(textSpans: TextSpan[], action: Action): Span[] {
    return textSpans.map(it => this.toSpan(it, action));
  }

  toTextSpan(span: Span, action: Action): TextSpan {
    return {
      id: span.id,
      text: span.text,
      start: span.first_position_in_phrase,
      end: span.last_position_in_phrase,
      tags: span.labels?.map(it =>
        ({
          id: it.id,
          name: it.name,
          meta: {
            status: it.status,
            source: it.source,
            selected: it.selected,
            dirty: it.dirty,
          },
        })
      ),
      meta: {
        groupId: span.groupId,
        seId: span.seId,
        tagId: span.tagId,
        source: span.source,
        status: span.status,
        anchor: span.anchor,
        active: span.active,
        invisible: span.invisible,
        color: getColor(span, action),
      },
    };
  }

  toTextSpanArray(spans: Span[], action: Action): TextSpan[] {
    return spans.map(it => this.toTextSpan(it, action));
  }
}

export const transformer = new Transformer()
