import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_PHRASES } from 'modules/phrases/api';
import { Phrases_selector } from 'modules/phrases/store';

export default function usePhrasesQuery(variables: any) {
  const transform = useCallback((data: any) => data.Phrases, []);

  return useQuery(QUERY_PHRASES, variables, Phrases_selector, transform);
}
