import { useCallback, useMemo, useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { NameParamsType } from 'modules/semantic-entities/constants';

import NameForm, { FormValues } from './NameForm';

type Props = {
  value: NameParamsType;
  onClose: () => void;
  onChange: (values: NameParamsType) => void;
};

export default function MorphNameModal({ value, onClose, onChange }: Props) {
  const formikRef = useRef<any>();

  const initialValues = useMemo(() =>
    ({
      name: value.name || '',
    }), [value],
  )

  const handleClick = useCallback(() => {
    const { submitForm } = formikRef.current;
    submitForm();
  }, []);

  const handleSubmit = useCallback((values: FormValues) => {
    onChange({
      name: values.name,
    })
  }, [onChange])

  return (
    <Modal
      title="Add / Name"
      open
      onClose={onClose}
      actionsContent={
        <Button variant="contained" color="primary" onClick={handleClick}>
          Add
        </Button>
      }
    >
      <NameForm initialValues={initialValues} formRef={formikRef} onSubmit={handleSubmit} />
    </Modal>
  );
}
