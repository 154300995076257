import { useCallback, useMemo, useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { Span } from 'modules/item-specifications/components/BaseTextAnnotator';
import { Source } from 'shared/constants';

import Form, { FormValues } from './SelectTagForm';

export type SelectTagModalValue = {
  span: Span
};

type Props = {
  value: SelectTagModalValue;
  onDiscard: () => void;
  onSelect: (newValue: SelectTagModalValue) => void;
};

export default function SelectTagModal(props: Props) {
  const { value, onDiscard, onSelect } = props;
  const formikRef = useRef<any>();

  const initialValues: FormValues = useMemo(
    () => ({
      tagId: value.span.tagId,
      tagName: value.span.text,
      classes: value.span.labels?.map(it =>
        ({
          id: it.id,
          name: it.name,
          status: it.status,
        })
      ),
    }),
    [value],
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      value.span.tagId = values.tagId;
      value.span.labels = values.classes?.map(it =>
        ({
          id: it.id,
          name: it.name,
          status: it.status,
          source: Source.consensus,
          dirty: true,
        })
      );
      onSelect(value);
    },
    [value, onSelect],
  );

  const handleConfirm = useCallback(() => {
    const { submitForm } = formikRef.current;
    submitForm();
  }, []);

  return (
    <Modal
      title="Select or Create a new Tag"
      open
      actionsContent={
        <>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </>
      }
      onClose={onDiscard}
    >
      <Form
        initialValues={initialValues}
        formRef={formikRef}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
