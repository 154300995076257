import { useCallback } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { SetterOrUpdater } from 'recoil';

import { Status } from 'shared/constants'
import {
  entitiesMergerFactory,
  entitiesUpdaterFactory,
  extractId as defaultExtractId,
} from 'shared/store'
import { DataItem } from 'types';

type UseAssignMethodToContactOptionsParams<T = ImmutableMap<string, DataItem>, K = SetterOrUpdater<T>> = {
  parentItem?: DataItem;
  bidirectional?: boolean;
  extractId?: ((item: DataItem) => string);
  settersOrUpdaters: Readonly<[K | null, K | null, K | null, K | null]>;
};

export default function useAssignMethodToContactOptions(params: UseAssignMethodToContactOptionsParams) {
  const {
    extractId = defaultExtractId,
    parentItem,
    bidirectional = false,
    settersOrUpdaters,
  } = params;
  const [updateConsensusAnnotations, updatePredictions, updateOptions, updateEntities] = settersOrUpdaters;

  const onCompleted = useCallback((result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [extractId(item), { active_contact_method_annotation_status: Status.assigned } as DataItem] as const)
    const updater = entitiesUpdaterFactory(entries);

    updateConsensusAnnotations?.(updater);
    updatePredictions?.(updater);
    updateOptions?.(updater);

    if (updateEntities) {
      const updatedItems = items.filter(item => item?.active_contact_method_annotation_status !== Status.assigned);
      const entries = [
        [extractId(parentItem as DataItem), { active_contact_methods_count_is_assigned: updatedItems.length } as DataItem] as const,
        ...(
          bidirectional
            ? updatedItems.map(item => [extractId(item), { active_contact_methods_count_is_assigned: 1 } as DataItem] as const)
            : []
        ),
      ];

      const updater = entitiesMergerFactory(
        entries,
        (oldVal, newVal) => ({
          ...oldVal,
          active_contact_methods_count_is_assigned:
            (oldVal?.active_contact_methods_count_is_assigned || 0) +
            (newVal?.active_contact_methods_count_is_assigned || 0),
        }),
      );
      updateEntities(updater);
    }
  }, [
    parentItem,
    bidirectional,
    extractId,
    updateConsensusAnnotations,
    updatePredictions,
    updateOptions,
    updateEntities,
  ]);

  return {
    onCompleted,
  }
}
