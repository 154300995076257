import { useCallback } from 'react';

import { useNestedQuery, useQuery } from 'hooks/useQuery';
import {
  QUERY_TOKEN_JOIN_CONSENSUS_ANNOTATIONS_V2,
  QUERY_TOKEN_JOIN_OPTIONS_V2,
  QUERY_TOKEN_JOIN_PREDICTIONS_V2,
} from 'methods/token-join/api';
import {
  Token_Token_Join_Consensus_Annotations_selector,
  Token_Token_Join_Options_selector,
  Token_Token_Join_Predictions_selector,
} from 'modules/tokens/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Token_Join_Consensus_Annotations_v2 || [], []);

  return useNestedQuery(
    QUERY_TOKEN_JOIN_CONSENSUS_ANNOTATIONS_V2,
    variables,
    Token_Token_Join_Consensus_Annotations_selector,
    transform,
  );
}

export const usePredictionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Token_Join_Predictions_v2 || [], []);

  return useNestedQuery(
    QUERY_TOKEN_JOIN_PREDICTIONS_V2,
    variables,
    Token_Token_Join_Predictions_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Token_Join_Options_v2 || [], []);

  return useNestedQuery(
    QUERY_TOKEN_JOIN_OPTIONS_V2,
    variables,
    Token_Token_Join_Options_selector,
    transform,
  );
}
