import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Morpheme_Morphs_Consensus_Annotations_Entities_State,
  Morpheme_Morphs_Consensus_Annotations_Ids_State,
  Morpheme_Morphs_Options_Entities_State,
  Morpheme_Morphs_Options_Ids_State,
} from './atoms';

export const Morpheme_Morphs_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/morphemes/@selectors/Morphs_Consensus_Annotations/list',
  Morpheme_Morphs_Consensus_Annotations_Entities_State,
  Morpheme_Morphs_Consensus_Annotations_Ids_State,
);

export const Morpheme_Morphs_Options_selector = listSelector<DataItem>(
  'graph/morphemes/@selectors/Morphs_Options/list',
  Morpheme_Morphs_Options_Entities_State,
  Morpheme_Morphs_Options_Ids_State,
);
