import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';

import { AnnotationConfig, DataItem, Mutation } from 'types';

type UseAnnotationOptions = {
  onCompleted?: (result: unknown, item: DataItem) => void;
};

type UseAnnotationResult<T> = (item: DataItem, values?: T) => void;

export default function useAnnotation<T = any>(
  annotationConfig: AnnotationConfig,
  mutation: Mutation,
  parentItem: DataItem,
  options: UseAnnotationOptions = {},
): UseAnnotationResult<T> {
  const ref = useRef<DataItem | null>(null);
  const { onCompleted: onAnnotationCompleted } = options;

  const onCompleted = useCallback((result: unknown) => {
    onAnnotationCompleted?.(result, ref.current as DataItem);
    ref.current = null;
  }, [onAnnotationCompleted]);
  const [mutate] = useMutation(mutation, { onCompleted });

  return useCallback(
    (item: DataItem, values?: T) => {
      ref.current = item;
      mutate({
        variables: {
          [annotationConfig.parentIdField]: parentItem.id,
          [annotationConfig.itemIdField]: item.id,
          ...values,
        },
      })
    },
    [mutate, annotationConfig, parentItem.id],
  );
}
