import { gql } from '@apollo/client';

// base asset extension
export const QUERY_MORPH_MORPHEMES_CONSENSUS_ANNOTATIONS = gql`
  query Morph_Morphemes_Consensus_Annotations(
    $id: BigInt
    $where: Morpheme_Filter_Where_Input
    $options: Morpheme_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    morphs(where: { id: $id }) {
      Morphemes_Consensus_Annotations(where: $where, options: $options, consensus: $consensus) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_inverse__Morph_has_Morpheme
      }
    }
  }
`;

export const QUERY_MORPH_MORPHEMES_OPTIONS = gql`
  query Morph_Morphemes_Options(
    $id: BigInt
    $where: Morpheme_Filter_Where_Input
    $options: Morpheme_Filter_Options_Input
  ) {
    morphs(where: { id: $id }) {
      Morphemes_Options(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_inverse__Morph_has_Morpheme
      }
    }
  }
`;

// target asset extension
export const QUERY_MORPHEME_MORPHS_CONSENSUS_ANNOTATIONS = gql`
  query Morpheme_Morphs_Consensus_Annotations(
    $id: BigInt
    $where: Morph_Filter_Where_Input
    $options: Morph_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    morphemes(where: { id: $id }) {
      Morphs_Consensus_Annotations(where: $where, options: $options, consensus: $consensus) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_Morph_has_Morpheme
      }
    }
  }
`;

export const QUERY_MORPHEME_MORPHS_OPTIONS = gql`
  query Morpheme_Morphs_Options($id: BigInt, $where: Morph_Filter_Where_Input, $options: Morph_Filter_Options_Input) {
    morphemes(where: { id: $id }) {
      Morphs_Options(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_Morph_has_Morpheme
      }
    }
  }
`;

// method node
export const ASSIGN_MORPH_HAS_MORPHEME_TO_CONTACT = gql`
  mutation Assign_Morph_has_Morpheme_to_Contact($input: Method_Assign_to_Contact_Input!) {
    assign_Morph_has_Morpheme_to_Contact(input: $input) {
      methodMorphHasMorphemes {
        id
      }
    }
  }
`;

export const CONFIRM_MORPH_HAS_MORPHEME_OPTION = gql`
  mutation Confirm_Method_Morph_has_Morpheme_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!) {
    confirm_Method_Morph_has_Morpheme_option(input: { base_asset_id: $baseAssetId, target_asset_id: $targetAssetId }) {
      methodMorphHasMorphemes {
        id
      }
    }
  }
`;

export const DECLINE_MORPH_HAS_MORPHEME_OPTION = gql`
  mutation Decline_Method_Morph_has_Morpheme_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!) {
    decline_Method_Morph_has_Morpheme_option(input: { base_asset_id: $baseAssetId, target_asset_id: $targetAssetId }) {
      methodMorphHasMorphemes {
        id
      }
    }
  }
`;
