import jwtDecode, { JwtPayload } from 'jwt-decode';

import { refreshAccessToken } from 'modules/auth/api'

export interface TokenData {
  accessToken: string;
  refreshToken?: string;
  graphApiToken: string;
  tokenExpiresAt: number;
  userUuid?: string;
}

// const tokenStorageBaseName = 'bc.token.';
const tokenStorageContainerName = 'bc.token.container';
/*const accessTokenStorageName = tokenStorageBaseName + 'access';
const refreshTokenStorageName = tokenStorageBaseName + 'refresh';
const tokenExpiresAtStorageName = tokenStorageBaseName + 'expiresAt';*/

const getTokenSubject = (accessToken: string): string => {
  const decoded: any = jwtDecode<JwtPayload>(accessToken);
  return decoded.sub;
};

export const setAuthTokenData = (data: TokenData) => {
  data.userUuid = getTokenSubject(data.accessToken);
  //const a = getTokenSubject(data.graphApiToken);
  localStorage.setItem(tokenStorageContainerName, JSON.stringify(data));
  /*localStorage.setItem(accessTokenStorageName, data.accessToken);
  localStorage.setItem(refreshTokenStorageName, data.refreshToken);
  localStorage.setItem(tokenExpiresAtStorageName, data.tokenExpiresAt.toString());*/
};

export const getAuthTokenData = (): TokenData => {
  return JSON.parse(localStorage.getItem(tokenStorageContainerName) as string);
};

export const resetAuthTokenData = () => {
  localStorage.removeItem(tokenStorageContainerName);
  /*localStorage.removeItem(accessTokenStorageName);
  localStorage.removeItem(refreshTokenStorageName);
  localStorage.removeItem(tokenExpiresAtStorageName);*/
};

async function getRefreshedAccessTokenPromise() {
  const refreshToken = getAuthTokenData().refreshToken;

  if (!refreshToken) return null;

  try {
    const data = await refreshAccessToken(refreshToken);
    if (data && data.refreshToken) setAuthTokenData(data);
    return data.accessToken;
  } catch (error) {
    resetAuthTokenData();
    return error;
  }
}

let pendingAccessTokenPromise: any = null;

export function getAccessTokenPromise() {
  const tokenData = getAuthTokenData();
  if (tokenData == null) {
    return new Promise((resolve) => resolve(null));
  }

  const currentNumericDate = Math.round(Date.now() + 2 * 60 * 1000); // current date + 2 minutes

  if (tokenData.accessToken && tokenData.tokenExpiresAt && currentNumericDate <= Number(tokenData.tokenExpiresAt)) {
    return new Promise((resolve) => resolve(tokenData.accessToken));
  }
  if (!pendingAccessTokenPromise)
    pendingAccessTokenPromise = getRefreshedAccessTokenPromise().finally(() => (pendingAccessTokenPromise = null));

  return pendingAccessTokenPromise;
}

export function getGraphApiAccessTokenPromise() {
  const tokenData = getAuthTokenData();
  if (tokenData == null) {
    return new Promise((resolve) => resolve(null));
  }

  const currentNumericDate = Math.round(Date.now() + 2 * 60 * 1000); // current date + 2 minutes

  if (tokenData.accessToken && tokenData.tokenExpiresAt && currentNumericDate <= Number(tokenData.tokenExpiresAt)) {
    return new Promise((resolve) => resolve(tokenData.graphApiToken));
  }
  if (!pendingAccessTokenPromise)
    pendingAccessTokenPromise = getRefreshedAccessTokenPromise().finally(() => (pendingAccessTokenPromise = null));

  return pendingAccessTokenPromise;
}
