import { useAnnotation } from 'hooks/useAnnotation';
import { CONFIRM_MORPH_HAS_MORPHEME_OPTION, DECLINE_MORPH_HAS_MORPHEME_OPTION } from 'methods/morph-has-morpheme/api';
import { AnnotationConfig, DataItem } from 'types';

type UseMorphHasMorphemeAnnotationsParams = {
  parentItem: DataItem;
};

type UseMorphHasMorphemeAnnotationsOptions = {
  onConfirmCompleted?: (result: unknown, item: DataItem) => void;
  onDeclineCompleted?: (result: unknown, item: DataItem) => void
};

type UseMorphHasMorphemeAnnotationsResult = {
  confirmAnnotation: (item: DataItem) => void;
  declineAnnotation: (item: DataItem) => void;
};

export default function useMorphHasMorphemeAnnotations(
  annotationConfig: AnnotationConfig,
  params: UseMorphHasMorphemeAnnotationsParams,
  options: UseMorphHasMorphemeAnnotationsOptions = {}
): UseMorphHasMorphemeAnnotationsResult {
  const { parentItem } = params;
  const { onConfirmCompleted, onDeclineCompleted } = options;

  const confirmAnnotation = useAnnotation(annotationConfig, CONFIRM_MORPH_HAS_MORPHEME_OPTION, parentItem, { onCompleted: onConfirmCompleted });
  const declineAnnotation = useAnnotation(annotationConfig, DECLINE_MORPH_HAS_MORPHEME_OPTION, parentItem, { onCompleted: onDeclineCompleted });

  return {
    confirmAnnotation,
    declineAnnotation,
  }
}
