import { Action } from 'modules/item-specifications/components/BaseTextAnnotator';
import { Operation } from 'shared/constants';

const actions: Action[] = [
  {
    id: Operation.DEFAULT,
    title: '',
    colors: {
      default: '#8FD14F',
      prediction: '#FA8D10',
      dirty: '#FAC710',
      inactive: '#696969',
    },
  }
];

export default actions;
