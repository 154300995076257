import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { SemanticEntities_Entities_State, SemanticEntities_Ids_State } from './atoms';

export * from "../sections/AssociatedNextTokensDataSection/selectors"
export * from "../sections/NextSemanticEntitiesDataSection/selectors"
export * from "../sections/NextTagsDataSection/selectors"
export * from "../sections/PhrasesDataSection/selectors"
export * from "../sections/TagsDataSection/selectors"

export const SemanticEntities_selector = listSelector<DataItem>(
  'graph/semantic-entities/@selectors/list',
  SemanticEntities_Entities_State,
  SemanticEntities_Ids_State,
);
