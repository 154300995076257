import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 14px rgba(53, 64, 82, 0.05)',
    borderRadius: 5,
    padding: '10px 20px',
  },
}));

export default useStyles;
