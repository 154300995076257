import { ClusterSortOption } from 'modules/clusters/api';
import { DataItem } from 'types';

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: ClusterSortOption;
};

export type FilterParamsType = {
  clusters?: DataItem[];
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export type NameParamsType = {
  name: string;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  clusters: [],
  onlyAssigned: false,
  onlyDone: false,
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: ClusterSortOption.BY_COUNT_OF_CLASS_HAS_CLUSTER_EDGES,
};

export const INITIAL_NAME_PARAMS: NameParamsType = {
  name: '',
};

export const SORT_OPTIONS = [
  {
    value: ClusterSortOption.BY_COUNT_OF_CLASS_HAS_CLUSTER_EDGES,
    label: 'by count of "Class has Cluster" edges',
  },
  {
    value: ClusterSortOption.BY_COUNT_OF_CLASS_HAS_CLUSTER_PREDICTIONS,
    label: 'by count of "Class has Cluster" predictions',
  },
  {
    value: ClusterSortOption.BY_COUNT_OF_CLASS_HAS_CLUSTER_PREDICTIONS_ASSIGNED_TO_USER,
    label: 'by count of "Class has Cluster" predictions assigned to user',
  },
];

export const SEMANTIC_OPTIONS = [
  {
    value: 3,
    label: 'Neutral (Default)',
  },
  {
    value: 1,
    label: 'Include',
  },
  {
    value: 2,
    label: 'Exclude',
  },
];
