import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SearchAutocomplete from 'components/Controls/SearchAutocomplete';
import RangeInput from 'components/RangeInput';
import { useLazyData } from 'hooks/useData';
import { SEARCH_MORPHEMES } from 'modules/morphemes/api';
import { FilterParamsType } from 'modules/morphemes/constants';

type Props = {
  value: FilterParamsType;
  onChange?: (value: FilterParamsType) => void;
};

export default function MorphemesFilterForm({ value, onChange }: Props) {
  const { data: morphemes, getData: getMorphemes } = useLazyData(SEARCH_MORPHEMES, 'Morphemes');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const newValue = event.target.checked;
    if (onChange) {
      onChange({
        ...value,
        [field]: newValue,
      });
    }
  };

  const handleAutocompleteChange = (event: React.ChangeEvent<HTMLInputElement>, controlValue: any) => {
    if (onChange) {
      const field = event.target.name;
      onChange({
        ...value,
        [field]: controlValue,
      });
    }
  };

  return (
    <div>
      <SearchAutocomplete
        label="Morpheme Name"
        name="morpheme"
        freeSolo
        value={value.morpheme}
        options={morphemes}
        onSearchChange={getMorphemes}
        onChange={handleAutocompleteChange}
      />
      <RangeInput
        label="Count of Characters"
        value={{
          min: value.countCharactersMin,
          max: value.countCharactersMax,
        }}
        onChange={(v) => {
          if (onChange) {
            onChange({
              ...value,
              countCharactersMin: v.min,
              countCharactersMax: v.max,
            });
          }
        }}
      />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={value.onlyAssigned}
              onChange={handleCheckboxChange}
              name="onlyAssigned"
              color="primary"
            />
          }
          label="only assigned"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox checked={value.onlyDone} onChange={handleCheckboxChange} name="onlyDone" color="primary" />
          }
          label="only done"
        />
      </div>
    </div>
  );
}
