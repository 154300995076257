import { SyntheticEvent, useCallback } from 'react';
import { contextMenu } from 'react-contexify';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import clsx from 'clsx';
import { Map as ImmutableMap } from 'immutable';

import MuiList from '@material-ui/core/List';

import { useContextActionsContext } from 'providers/ContextActionsProvider';
import { CountField, DataItem, KeyField, NameField } from 'types';

import DataListItem from './DataListItem';
import useStyles from './styles';
import { createItemData } from './utils';

type Props = {
  id: string;
  items?: DataItem[];
  itemSize?: number;
  menuId?: string;
  selectedItems?: ImmutableMap<string, DataItem>;
  keyField?: KeyField | Array<KeyField>;
  nameField?: NameField;
  countField?: CountField | Array<CountField>;
  horizontal?: boolean;
  renderContent?: any;
  readonly?: boolean;
  consensus?: boolean;
  se_creation?: boolean;
  orangeLabel?: boolean;
  yellowLabel?: boolean;
  greenLabel?: boolean;
  onLoadMoreItems: (startIndex: number, stopIndex: number) => void;
  onItemToggle?: (item: DataItem) => void;
  onItemClick?: (e: SyntheticEvent, item: DataItem) => void;
  onItemOpen?: (item: DataItem) => void;
  onItemConfirm?: (item: DataItem) => void;
  onItemDecline?: (item: DataItem) => void;
};

export default function DataList({
  id,
  menuId,
  items = [],
  itemSize = 34,
  selectedItems,
  keyField = ['id'],
  nameField = 'name',
  countField,
  horizontal = false,
  renderContent,
  readonly = false,
  consensus = false,
  se_creation = false,
  orangeLabel = true,
  yellowLabel = true,
  greenLabel = true,
  onLoadMoreItems,
  onItemToggle,
  onItemClick,
  onItemOpen,
  onItemConfirm,
  onItemDecline,
}: Props) {
  const classes = useStyles();
  const { setContextItem, setListId } = useContextActionsContext();

  const totalCount = 9999;
  const isItemLoaded = useCallback((index: number) => index < items.length && items[index] !== null, [items]);

  const handleClick = useCallback(
    (e, item) => {
      e.preventDefault();
      if (e.shiftKey) {
        onItemToggle?.(item);
      } else {
        onItemClick?.(e, item);
      }
    },
    [onItemToggle, onItemClick],
  );

  const handleDoubleClick = useCallback(
    (e, item) => {
      onItemOpen?.(item);
    },
    [onItemOpen],
  );

  const handleContext = useCallback(
    (e: any, contextItem: DataItem) => {
      if (menuId) {
        setListId(id);
        setContextItem(contextItem);
        contextMenu.show({
          id: menuId,
          event: e,
          props: {
            contextItem,
          },
        });
      }
    },
    [id, menuId, setContextItem, setListId],
  );

  const handleConfirm = useCallback((item: DataItem) => onItemConfirm?.(item), [onItemConfirm]);
  const handleDecline = useCallback((item: DataItem) => onItemDecline?.(item), [onItemDecline]);

  const itemData = createItemData(
    !!onItemConfirm,
    !!onItemDecline,
    consensus,
    countField,
    horizontal,
    items,
    keyField,
    nameField,
    renderContent,
    readonly,
    se_creation,
    orangeLabel,
    yellowLabel,
    greenLabel,
    selectedItems,
    handleClick,
    handleContext,
    handleConfirm,
    handleDecline,
    handleDoubleClick,
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={totalCount} loadMoreItems={onLoadMoreItems}>
          {({ onItemsRendered, ref }: any) => (
            <List
              ref={ref}
              innerElementType={MuiList}
              width={width}
              height={height}
              itemCount={items.length}
              itemData={itemData}
              itemSize={itemSize}
              onItemsRendered={onItemsRendered}
              className={clsx({
                [classes.list]: true,
                [classes.horizontalList]: horizontal,
              })}
            >
              {DataListItem}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}
