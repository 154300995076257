import { Map as ImmutableMap } from 'immutable';
import { DefaultValue, RecoilState, selector } from 'recoil';

import { DataItem } from 'types';

type Token_has_Morph_Sequence = {
  id: string;
  Token_has_Morph_sequence?: DataItem[];
};

export const extractId = (item: Token_has_Morph_Sequence) => {
  if (item.Token_has_Morph_sequence) {
    return `${item.id}:${item.Token_has_Morph_sequence.map(x => x.id).join('_')}`
  }

  return item.id
};

export const listSelector = <T extends Token_has_Morph_Sequence>(
  key: string,
  entitiesState: RecoilState<ImmutableMap<string, T>>,
  idsState: RecoilState<string[]>
) => {
  return selector<T[]>({
    key,
    get: ({ get }) => {
      const entities = get(entitiesState);
      const ids = get(idsState);

      return ids.map(x => entities.get(x) as T)
    },
    set: ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(idsState);
        reset(entitiesState);
      } else {
        const ids = newValue.map(x => extractId(x))
        const entities = Object.fromEntries(newValue.map(x => [extractId(x), x]))

        set(idsState, (existing) => [...existing, ...ids])
        set(entitiesState, (existing) => existing.merge(entities))
      }
    },
  });
};
