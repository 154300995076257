import { listSelector } from 'methods/token-has-morph/store';
import { DataItem } from 'types';

import {
  Morph_Associated_Tokens_Consensus_Annotations_Entities_State,
  Morph_Associated_Tokens_Consensus_Annotations_Ids_State,
  Morph_Associated_Tokens_Options_Entities_State,
  Morph_Associated_Tokens_Options_Ids_State,
  Morph_Associated_Tokens_Predictions_Entities_State,
  Morph_Associated_Tokens_Predictions_Ids_State,
} from './atoms';

export const Morph_Associated_Tokens_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/Associated_Tokens_Consensus_Annotations/list',
  Morph_Associated_Tokens_Consensus_Annotations_Entities_State,
  Morph_Associated_Tokens_Consensus_Annotations_Ids_State,
);

export const Morph_Associated_Tokens_Predictions_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/Associated_Tokens_Predictions/list',
  Morph_Associated_Tokens_Predictions_Entities_State,
  Morph_Associated_Tokens_Predictions_Ids_State,
);

export const Morph_Associated_Tokens_Options_selector = listSelector<DataItem>(
  'graph/morphs/@selectors/Associated_Tokens_Options/list',
  Morph_Associated_Tokens_Options_Entities_State,
  Morph_Associated_Tokens_Options_Ids_State,
);
