import { useMemo } from 'react';

import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ActionVisibleParams, HandlerParams, useContextActions1 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_MORPHEME_TO_CONTACT, MARK_MORPHEME_AS_DONE, MARK_MORPHEME_AS_NOT_DONE } from 'modules/morphemes/api';
import useUpdateMorphemeEntities from 'modules/morphemes/hooks/useUpdateMorphemeEntities';
import { DataItem } from 'types';

type  useMorphemeContextActionParams = {
  editMorpheme: (item: DataItem) => void;
};

export default function useMorphemeContextActions(params: useMorphemeContextActionParams) {
  const { editMorpheme } = params;

  const onAssignToContactCompleted = useUpdateMorphemeEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_MORPHEME_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateMorphemeEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_MORPHEME_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateMorphemeEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_MORPHEME_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const contextActions = useContextActions1({ assignToContact, markAsDone, markAsNotDone });

  return useMemo(
    () => {
      return [
        ...contextActions,
        {
          id: 'EDIT',
          title: 'Edit',
          meta: {
            actionAllowed: () => true,
            actionVisible: ({ selectedItems }: ActionVisibleParams) => selectedItems.length < 2,
          },
          handler: ({ contextItem }: HandlerParams) => editMorpheme(contextItem),
        },
      ]
    }, [contextActions, editMorpheme],
  );
}
