import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 0px 14px rgba(53, 64, 82, 0.2)',
  },
  header: {
    color: 'white',
    background: '#253448',
    height: theme.spacing(7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  actions: {
    display: 'flex',
    marginRight: 10,
    gap: 5,
  },
  actionButton: {
    borderWidth: 0.5,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderStyle: 'solid',
    borderRadius: 2,
    padding: 6,
  },
  subheader: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
  },
  expandButton: {
    padding: 0,
    minWidth: 35,
    color: '#253448',
    borderColor: '#253448',
  },
}));

export default useStyles;
