import { useCallback, useMemo, useRef } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Languages from 'components/Languages';
import Modal from 'components/Modal';
import { TokenHasMorphParamsType } from 'methods/token-has-morph';
import { DataItem, LanguageItem } from 'types';

import TokenHasMorphForm from './TokenHasMorphForm';
import useStyles from './TokenHasMorphModal.styles';

type Values = {
  targetAssets: DataItem[];
};

type Props = {
  value: TokenHasMorphParamsType;
  onClose: () => void;
  onChange: (values: TokenHasMorphParamsType) => void;
};

export default function TokenHasMorphModal({ value, onClose, onChange }: Props) {
  const classes = useStyles();
  const formikRef = useRef<any>();

  const initialValues = useMemo(
    () => ({
      targetAssets: value.targetAssets,
    }),
    [value],
  );

  const handleSubmit = useCallback(
    (values: Values) => {
      onChange({
        token: value.token,
        targetAssets: values.targetAssets,
        language: value.language,
        action: value.action,
      });
      onClose();
    },
    [value, onClose, onChange],
  );

  const handleClick = useCallback(() => {
    const { submitForm } = formikRef.current;
    submitForm();
  }, []);

  return (
    <Modal
      title="Token has Morph"
      open
      actionsContent={
        <Button variant="contained" color="primary" size="large" onClick={handleClick}>
          {value.action === 'confirm' && 'Confirm'}
          {value.action === 'decline' && 'Decline'}
        </Button>
      }
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item className={classes.item}>
          <Typography variant="h5">{value.token?.name}</Typography>
          <Typography variant="body2">{value.token?.name?.length} characters</Typography>
        </Grid>
        <Grid item container className={classes.item} justifyContent="flex-end">
          <Languages value={value.language?.id} disabled />
        </Grid>
      </Grid>
      <TokenHasMorphForm
        initialValues={initialValues}
        language={value.language as LanguageItem}
        formRef={formikRef}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
