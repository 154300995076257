import { useRecoilState } from 'recoil';

import { Main_Layout_State } from 'layouts/MainLayout/store';

type UseConsensusResult = {
  consensus: boolean;
}

export default function useConsensus(): UseConsensusResult {
  const [state] = useRecoilState(Main_Layout_State);

  return {
    consensus: state.consensus,
  };
}
