import { listSelector } from 'methods/class-to-class-conversion/store';
import { DataItem } from 'types';

import {
  Class_Conversion_Parents_Classes_Consensus_Annotations_Entities_State,
  Class_Conversion_Parents_Classes_Consensus_Annotations_Ids_State,
  Class_Conversion_Parents_Classes_Options_Entities_State,
  Class_Conversion_Parents_Classes_Options_Ids_State,
} from './atoms';

export const Class_Conversion_Parents_Classes_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Conversion_Parents_Classes_Consensus_Annotations/list',
  Class_Conversion_Parents_Classes_Consensus_Annotations_Entities_State,
  Class_Conversion_Parents_Classes_Consensus_Annotations_Ids_State,
);

export const Class_Conversion_Parents_Classes_Options_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Conversion_Parents_Classes_Options/list',
  Class_Conversion_Parents_Classes_Options_Entities_State,
  Class_Conversion_Parents_Classes_Options_Ids_State,
);
