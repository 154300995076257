import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';

import { Span } from 'modules/item-specifications/components/BaseTextAnnotator';

type AnnotationContextMenuContextProps = {
  contextSpan: Span | null;
  setContextSpan: Dispatch<SetStateAction<Span | null>>;
};

type Props = PropsWithChildren<{}>;

export const AnnotationContextMenuContext = createContext<AnnotationContextMenuContextProps>({
  contextSpan: null,
  setContextSpan: () => null,
});

export function useAnnotationContextMenuContext(): AnnotationContextMenuContextProps {
  return useContext(AnnotationContextMenuContext);
}

export const AnnotationContextMenuContextProvider = ({ children }: Props) => {
  const [contextSpan, setContextSpan] = useState<Span | null>(null);

  const ctx = {
    contextSpan,
    setContextSpan,
  };

  return <AnnotationContextMenuContext.Provider value={ctx}>{children}</AnnotationContextMenuContext.Provider>;
};
