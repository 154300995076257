import { useCallback, useEffect, useState } from 'react';
import merge from 'lodash/merge';
import { RecoilState, useRecoilCallback, useRecoilValue,useResetRecoilState } from 'recoil';

import { apiClient } from 'api/client';
import { DataItem } from 'types';

export default function useQuery<T = DataItem, K = any>(
  query: any,
  variables: any,
  state: RecoilState<T[]>,
  transform?: <T, K>(data: K) => T[],
) {
  const [loading, setLoading] = useState(true);
  const data = useRecoilValue(state);
  const reset = useResetRecoilState(state);

  const getData = useRecoilCallback(({ set }) => async (variables: any, requireLoading?: boolean) => {
    requireLoading && setLoading(true);
    const result = await apiClient.query({ query, variables });
    const data = transform ? transform<T, K>(result.data) : (result.data as T[]);
    set(state, data);
    requireLoading && setLoading(false);
  }, [query, transform, setLoading]);

  const fetchMore = useCallback((startIndex: number, stopIndex: number) => {
    const offset = parseInt(variables.options?.offset || 0, 10);
    const limit = variables.options?.limit;

      // do not fetch more data if stop index inside previous range
      if (stopIndex < offset + limit) return;

      const options: any = {
        offset: `${startIndex}`,
      };

      const newVariables = merge(variables, { options });
      getData(newVariables, false)
  }, [getData, variables]);

  useEffect(() => {
    getData(variables, true)
  }, [getData, variables]);

  useEffect(() => {
    return () => reset()
  }, [reset]);

  return {
    loading,
    data,
    reset,
    fetchMore,
  };
}
