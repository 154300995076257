import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_MORPH_MORPHEMES_CONSENSUS_ANNOTATIONS,
  QUERY_MORPH_MORPHEMES_OPTIONS,
} from 'methods/morph-has-morpheme/api';
import {
  Morph_Morphemes_Consensus_Annotations_selector,
  Morph_Morphemes_Options_selector,
} from 'modules/morphs/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.morphs?.[0]?.Morphemes_Consensus_Annotations || [], []);

  return useNestedQuery(
    QUERY_MORPH_MORPHEMES_CONSENSUS_ANNOTATIONS,
    variables,
    Morph_Morphemes_Consensus_Annotations_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.morphs?.[0]?.Morphemes_Options || [], []);

  return useNestedQuery(
    QUERY_MORPH_MORPHEMES_OPTIONS,
    variables,
    Morph_Morphemes_Options_selector,
    transform,
  );
}
