import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Item_Specifications_Entities_State, Item_Specifications_Ids_State } from './atoms';

export const Item_Specifications_selector = listSelector<DataItem>(
  'graph/item-specifications/@selectors/list',
  Item_Specifications_Entities_State,
  Item_Specifications_Ids_State,
);
