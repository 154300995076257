import { Map as ImmutableMap } from 'immutable';
import { DefaultValue, RecoilState, selector } from 'recoil';

type Class_to_Class_Conversion = {
  id: string;
  Class_to_Class_Conversion_factor?: number;
};

export const extractId = (item: Class_to_Class_Conversion) => {
  if (item.Class_to_Class_Conversion_factor) {
    return `${item.id}:${item.Class_to_Class_Conversion_factor}`
  }

  return item.id
};

export const listSelector = <T extends Class_to_Class_Conversion>(
  key: string,
  entitiesState: RecoilState<ImmutableMap<string, T>>,
  idsState: RecoilState<string[]>
) => {
  return selector<T[]>({
    key,
    get: ({ get }) => {
      const entities = get(entitiesState);
      const ids = get(idsState);

      return ids.map(x => entities.get(x) as T)
    },
    set: ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(idsState);
        reset(entitiesState);
      } else {
        const ids = newValue.map(x => extractId(x))
        const entities = Object.fromEntries(newValue.map(x => [extractId(x), x]))

        set(idsState, (existing) => [...existing, ...ids])
        set(entitiesState, (existing) => existing.merge(entities))
      }
    },
  });
};
