import { Map as ImmutableMap } from 'immutable';
import { DefaultValue, RecoilState, selector } from 'recoil';

type Token_Join_Sequence = {
  id: string;
  Token_Join_sequence?: string[] | null;
};

export const extractId = (item: Token_Join_Sequence) => item.id === '0' ? (item.Token_Join_sequence || []).join('_') : item.id;

export const Token_Join_listSelector = <T extends Token_Join_Sequence>(
  key: string,
  entitiesState: RecoilState<ImmutableMap<string, T>>,
  idsState: RecoilState<string[]>
) => {
  return selector<T[]>({
    key,
    get: ({ get }) => {
      const entities = get(entitiesState);
      const ids = get(idsState);

      return ids.map(x => entities.get(x) as T)
    },
    set: ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(idsState);
        reset(entitiesState);
      } else {
        const ids = newValue.map(x => extractId(x))
        const entities = Object.fromEntries(newValue.map(x => [extractId(x), x]))

        set(idsState, (existing) => [...existing, ...ids])
        set(entitiesState, (existing) => existing.merge(entities))
      }
    },
  });
};
