import { TransformVariablesConfig } from 'configuration';
import { FilterParamsType, SortParamsType } from 'modules/semantic-entities/constants';
import { transformVariables } from 'modules/semantic-entities/utils';
import { PaginationParams } from 'types';

type Args = {
  languageId: number;
};

export default function transformVariablesAdapter<T extends object>(args: Args): any {
  return (
    filterParams: FilterParamsType,
    sortParams: SortParamsType,
    paginationParams: PaginationParams,
    transformVariablesConfig: TransformVariablesConfig,
  ): T => {
    const variables = transformVariables<T>(filterParams, sortParams, paginationParams, transformVariablesConfig);

    return {
      ...variables,
      language: args.languageId,
    };
  };
}
