import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { extractId } from 'methods/token-has-morph/store';
import {
  Morph_Associated_Tokens_Consensus_Annotations_Entities_State,
  Morph_Associated_Tokens_Options_Entities_State,
  Morph_Associated_Tokens_Predictions_Entities_State} from 'modules/morphs/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateTokenEntities(params: Params) {
  const { transform } = params;

  const update_Associated_Tokens_Consensus_Annotations = useSetRecoilState(Morph_Associated_Tokens_Consensus_Annotations_Entities_State);
  const update_Associated_Tokens_Prediction = useSetRecoilState(Morph_Associated_Tokens_Predictions_Entities_State);
  const update_Associated_Tokens_Options = useSetRecoilState(Morph_Associated_Tokens_Options_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [extractId(item), transform(item)] as const);
    const updater = entitiesUpdaterFactory(entries);

    update_Associated_Tokens_Consensus_Annotations(updater);
    update_Associated_Tokens_Prediction(updater);
    update_Associated_Tokens_Options(updater);
  }, [
    transform,
    update_Associated_Tokens_Consensus_Annotations,
    update_Associated_Tokens_Prediction,
    update_Associated_Tokens_Options,
  ]);
}
