import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  SemanticEntity_Next_SemanticEntities_Entities_State,
  SemanticEntity_Next_SemanticEntities_Ids_State,
} from './atoms';

export const SemanticEntity_Next_SemanticEntities_selector = listSelector<DataItem>(
  'graph/semantic-entities/@selectors/Next_SemanticEntities/list',
  SemanticEntity_Next_SemanticEntities_Entities_State,
  SemanticEntity_Next_SemanticEntities_Ids_State,
);
