import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { QUERY_TOKEN_TYPES } from 'api/queries';
import SearchAutocomplete from 'components/Controls/SearchAutocomplete';
import { useLazyData } from 'hooks/useData';
import useMetadata from 'hooks/useMetadata';
import { SEARCH_CLASSES } from 'modules/classes/api';
import { SEARCH_TAGS } from 'modules/tags/api';
import { FilterParamsType } from 'modules/tags/constants';
import { SEARCH_TOKENS } from 'modules/tokens/api';

type TagsFilterFormProps = {
  value: FilterParamsType;
  onChange?: (value: FilterParamsType) => void;
};

const RadioControl = <Radio color="primary" />;

const CHARACTER_TYPES = ['Lating (not)', 'Greek', 'Cyrillic'];
const LANGUAGES = ['German (de)', 'English (en)', 'French (fr)'];
const SEMANTIC_TYPE_TYPES = ['Noun', 'Verb', 'Adjective'];

export default function TagsFilterForm({ value, onChange }: TagsFilterFormProps) {
  const {
    data: tags,
    loading: isTagsLoading,
    getData: getTags,
  } = useLazyData(SEARCH_TAGS, 'Tags', { nameField: 'name_STARTS_WITH_FULL_TEXT' });
  const {
    data: classes,
    loading: isClassesLoading,
    getData: getClasses,
  } = useLazyData(SEARCH_CLASSES, 'Classes');
  const {
    data: tokens,
    loading: isTokensLoading,
    getData: getTokens,
  } = useLazyData(SEARCH_TOKENS, 'Tokens', { nameField: 'name_STARTS_WITH_FULL_TEXT' });
  const { metadata: tokenTypes, getMetadata: getTokenTypes } = useMetadata(QUERY_TOKEN_TYPES, 'token_type');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const newValue = event.target.checked;
    if (onChange) {
      onChange({
        ...value,
        [field]: newValue,
      });
    }
  };

  const handleAutocompleteChange = (event: React.ChangeEvent<HTMLInputElement>, controlValue: any) => {
    if (onChange) {
      const field = event.target.name;
      onChange({
        ...value,
        [field]: controlValue,
      });
    }
  };

  return (
    <div>
      {/* <RadioGroup
        name="filterSwitch"
        value={value.filterSwitch}
        onChange={(event) => {
          if (onChange) {
            onChange({
              ...value,
              [event.target.name]: event.target.value,
            });
          }
        }}
      >
        <FormControlLabel control={RadioControl} value="tags" label="Filter via Tags" />
        <FormControlLabel control={RadioControl} value="tokens" label="Filter via Tokens" />
      </RadioGroup> */}

      {value.filterSwitch === 'tags' && (
        <div>
          <SearchAutocomplete
            label="Tags"
            name="tags"
            multiple
            minLength={1}
            value={value.tags}
            options={tags}
            loading={isTagsLoading}
            onSearchChange={getTags}
            onChange={handleAutocompleteChange}
          />
          <SearchAutocomplete
            label="Classes"
            name="classes"
            multiple
            value={value.classes}
            options={classes}
            loading={isClassesLoading}
            onSearchChange={getClasses}
            onChange={handleAutocompleteChange}
          />
        </div>
      )}

      {value.filterSwitch === 'tokens' && (
        <div>
          <SearchAutocomplete
            label="Token Name"
            name="token"
            freeSolo
            minLength={1}
            value={value.token}
            options={tokens}
            loading={isTokensLoading}
            onSearchChange={getTokens}
            onChange={handleAutocompleteChange}
          />
          <SearchAutocomplete
            label="Token Type"
            name="tokenTypes"
            multiple
            value={value.tokenTypes}
            options={tokenTypes}
            onSearchChange={getTokenTypes}
            onChange={handleAutocompleteChange}
          />
          <SearchAutocomplete
            label="Contains (not) Character"
            name="containsCharacters"
            multiple
            //value={value.containsCharacters}
            options={CHARACTER_TYPES}
            onSearchChange={getTokens}
            onChange={handleAutocompleteChange}
          />
          <SearchAutocomplete
            label="Language"
            name="language"
            multiple
            //value={value.containsCharacters}
            options={LANGUAGES}
            onSearchChange={getTokens}
            onChange={handleAutocompleteChange}
          />
          <SearchAutocomplete
            label="Semantic Type"
            name="semanticType"
            multiple
            //value={value.containsCharacters}
            options={SEMANTIC_TYPE_TYPES}
            onSearchChange={getTokens}
            onChange={handleAutocompleteChange}
          />
        </div>
      )}

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={value.onlyAssigned}
              onChange={handleCheckboxChange}
              name="onlyAssigned"
              color="primary"
            />
          }
          label="only assigned to me"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox checked={value.onlyDone} onChange={handleCheckboxChange} name="onlyDone" color="primary" />
          }
          label="only done"
        />
      </div>
    </div>
  );
}
