import { gql } from '@apollo/client';

export const CONTACT_APP_USER_INFO = gql`
  query {
    currentGetContactAppUser {
      accountContact {
          graphApiContactId
          contact {
            firstName
            lastName
          }
      }
      user {
        email
      }
    }
  }
`
