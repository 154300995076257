import { TokenData } from "modules/auth/utils";

export async function login(username: string, password: string): Promise<TokenData> {
  const authenticationBody = new URLSearchParams();
  authenticationBody.append('grant_type', 'password');
  authenticationBody.append('username', username);
  authenticationBody.append('password', password);
  authenticationBody.append('client_id', window.APPLICATION_API_OAUTH_CLIENT_ID);
  authenticationBody.append('client_secret', window.APPLICATION_API_OAUTH_CLIENT_SECRET);

  return await fetchOauthToken(authenticationBody);
}

export async function refreshAccessToken(refreshToken: string): Promise<TokenData> {
  const authenticationBody = new URLSearchParams();
  authenticationBody.append('grant_type', 'refresh_token');
  authenticationBody.append('client_id', window.APPLICATION_API_OAUTH_CLIENT_ID);
  authenticationBody.append('client_secret', window.APPLICATION_API_OAUTH_CLIENT_SECRET);
  authenticationBody.append('refresh_token', refreshToken);

  return await fetchOauthToken(authenticationBody);
}

export async function fetchPublicClientToken(): Promise<TokenData> {
  const authenticationBody = new URLSearchParams();
  authenticationBody.append('grant_type', 'client_credentials');
  authenticationBody.append('client_id', window.APPLICATION_API_OAUTH_PUBLIC_CLIENT_ID);
  authenticationBody.append('client_secret', window.APPLICATION_API_OAUTH_PUBLIC_CLIENT_SECRET);

  return await fetchOauthToken(authenticationBody);
}

const fetchOauthToken = async function (authenticationBody: URLSearchParams) {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-application': window.APPLICATION_API_APPLICATION_ID,
    },
    body: authenticationBody
  };

  const response = await fetch(window.APPLICATION_API_OAUTH_URL_TOKEN, requestOptions);

  const data = await response.json();

  if (response.ok) {
    return {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      graphApiToken: data.graph_api_token,
      tokenExpiresAt: Date.now() + data.expires_in * 1000 // need to convert to milliseconds for js time
    };
  }

  throw new FetchError({
    message: response.statusText,
    response,
    data
  });
};

export class FetchError extends Error {
  response: Response;
  data: {
    message: string;
  };

  constructor({
    message,
    response,
    data
  }: {
    message: string;
    response: Response;
    data: {
      message: string;
    };
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.response = response;
    this.data = data ?? { message: message };
  }
}
