import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_TAG_CLASSES_CONSENSUS_ANNOTATIONS_V2,
  QUERY_TAG_CLASSES_OPTIONS_V2,
  QUERY_TAG_CLASSES_PREDICTIONS_V2,
} from 'methods/tag-has-class/api';
import {
  Tag_Classes_Consensus_Annotations_selector,
  Tag_Classes_Options_selector,
  Tag_Classes_Predictions_selector,
} from 'modules/tags/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Tag_has_Class_Classes_Consensus_Annotations_v2 || [], []);

  return useNestedQuery(
    QUERY_TAG_CLASSES_CONSENSUS_ANNOTATIONS_V2,
    variables,
    Tag_Classes_Consensus_Annotations_selector,
    transform,
  );
}

export const usePredictionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Tag_has_Class_Classes_Predictions_v2 || [], []);

  return useNestedQuery(
    QUERY_TAG_CLASSES_PREDICTIONS_V2,
    variables,
    Tag_Classes_Predictions_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Tag_has_Class_Classes_Options_v2 || [], []);

  return useNestedQuery(
    QUERY_TAG_CLASSES_OPTIONS_V2,
    variables,
    Tag_Classes_Options_selector,
    transform,
  );
}
