import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SearchAutocomplete from 'components/Controls/SearchAutocomplete';
import { useLazyData } from 'hooks/useData';
import { SEARCH_CLUSTERS } from 'modules/clusters/api';
import { FilterParamsType } from 'modules/clusters/constants';

type Props = {
  value: FilterParamsType;
  onChange?: (value: FilterParamsType) => void;
};

export default function ClustersFilterForm({ value, onChange }: Props) {
  const {
    data: clusters,
    loading: isClustersLoading,
    getData: getClusters,
  } = useLazyData(SEARCH_CLUSTERS, 'Clusters');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const newValue = event.target.checked;
    if (onChange) {
      onChange({
        ...value,
        [field]: newValue,
      });
    }
  };

  const handleAutocompleteChange = (event: React.ChangeEvent<HTMLInputElement>, controlValue: any) => {
    if (onChange) {
      const field = event.target.name;
      onChange({
        ...value,
        [field]: controlValue,
      });
    }
  };

  return (
    <div>
      <SearchAutocomplete
        label="Clusters"
        name="clusters"
        multiple
        value={value.clusters}
        options={clusters}
        loading={isClustersLoading}
        onSearchChange={getClusters}
        onChange={handleAutocompleteChange}
      />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={value.onlyAssigned}
              onChange={handleCheckboxChange}
              name="onlyAssigned"
              color="primary"
            />
          }
          label="only assigned to me"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox checked={value.onlyDone} onChange={handleCheckboxChange} name="onlyDone" color="primary" />
          }
          label="only done"
        />
      </div>
    </div>
  );
}
