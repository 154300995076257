import { TagSortOption } from 'modules/tags/api';
import { DataItem, DictionaryItem } from 'types';

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: TagSortOption;
};

export type FilterParamsType = {
  filterSwitch: 'tags' | 'tokens';
  // tags
  tags?: DataItem[];
  classes?: DataItem[];
  // tokens
  token?: DataItem | null;
  tokenTypes?: DictionaryItem[];
  // common
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  filterSwitch: 'tags',
  // tags
  tags: [],
  classes: [],
  // tokens
  token: null,
  tokenTypes: [],
  // common
  onlyAssigned: false,
  onlyDone: false,
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: TagSortOption.by_count_in_Phrases_all_positions,
};

export const SORT_OPTIONS = [
  {
    value: TagSortOption.by_count_in_Phrases_all_positions,
    label: 'by count in Phrases all positions',
  },
  {
    value: TagSortOption.by_count_in_Phrases_first_position,
    label: 'by count in Phrases first position',
  },
  // Tag_has_Synonym
  {
    value: TagSortOption.by_count__Tag_has_Synonym,
    label: 'by count of "Tag has Synonym" edges',
  },
  {
    value: TagSortOption.by_count__Tag_has_Synonym_predictions,
    label: 'by count of "Tag has Synonym" predictions',
  },
  {
    value: TagSortOption.by_count__Tag_has_Synonym_predictions_assigned,
    label: 'by count of "Tag has Synonym" predictions assigned to user',
  },
  // Tag_has_Parent
  {
    value: TagSortOption.by_count__Tag_has_Parent,
    label: 'by count of "Tag has Parent" edges',
  },
  {
    value: TagSortOption.by_count__Tag_has_Parent_predictions,
    label: 'by count of "Tag has Parent" predictions',
  },
  {
    value: TagSortOption.by_count__Tag_has_Parent_predictions_assigned,
    label: 'by count of "Tag has Parent" predictions assigned to user',
  },
  // Tag_has_Class
  {
    value: TagSortOption.by_count__Tag_has_Class,
    label: 'by count of "Tag has Class" edges',
  },
  {
    value: TagSortOption.by_count__Tag_has_Class_predictions,
    label: 'by count of "Tag has Class" predictions',
  },
  {
    value: TagSortOption.by_count__Tag_has_Class_predictions_assigned,
    label: 'by count of "Tag has Class" predictions assigned to user',
  },
  // Offer_has_Tag
  {
    value: TagSortOption.by_count__Offer_has_Tag,
    label: 'by count of "Offer has Tag" edges',
  },
  {
    value: TagSortOption.by_count__Offer_has_Tag_predictions,
    label: 'by count of "Offer has Tag" predictions',
  },
  {
    value: TagSortOption.by_count__Offer_has_Tag_predictions_assigned,
    label: 'by count of "Offer has Tag" predictions assigned to user',
  },
  // Deliverable_has_Tag
  {
    value: TagSortOption.by_count__Deliverable_has_Tag,
    label: 'by count of "Deliverable has Tag" edges',
  },
  {
    value: TagSortOption.by_count__Deliverable_has_Tag_predictions,
    label: 'by count of "Deliverable has Tag" predictions',
  },
  {
    value: TagSortOption.by_count__Deliverable_has_Tag_predictions_assigned,
    label: 'by count of "Deliverable has Tag" predictions assigned to user',
  },
];
