import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 324;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    //zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: '#1B2430',
    background: 'white',
    paddingRight: theme.spacing(6),
    boxShadow: '0px 0px 14px rgba(53, 64, 82, 0.05)',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonWrapper: {
    marginBottom: 1,
  },
  menuButton: {
    marginLeft: -4,
    marginRight: 12,
    fontSize: 30,
  },
  drawer: {
    position: 'fixed',
    zIndex: 2000,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#253448',
    color: 'white',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#253448',
    color: 'white',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      //width: theme.spacing(9) + 1,
      width: theme.spacing(10) + 1,
    },
    background: '#253448',
    color: 'white',
  },
  drawerLogo: {
    fontSize: 20,
    fontWeight: 700,
  },
  toolbar: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appbarContents: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'center',
  },
  logo: {
    marginLeft: 100,
    fontSize: 20,
    fontWeight: 700,
  },
  toolbarSpacer: {
    flexGrow: 1,
  },
  divider: {
    background: '#F7F7F7',
    opacity: 0.1,
  },
  content: {
    marginLeft: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      //width: theme.spacing(9) + 1,
      marginLeft: theme.spacing(10) + 1,
    },
    flexGrow: 1,
    //padding: theme.spacing(3),
    background: '#E5E5E5',
  },
}));

export default useStyles;
