import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Item_Specifications_Entities_State } from 'modules/item-specifications/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateSpecificationItemsEntities(params: Params) {
  const { transform } = params;

  const update_SpecificationItems = useSetRecoilState(Item_Specifications_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const)
    const updater = entitiesUpdaterFactory(entries);

    update_SpecificationItems(updater);
  }, [
    transform,
    update_SpecificationItems,
  ]);
}
