import { Route } from 'react-router-dom';

function RouteV2(props: any) {
  const { component: Component, pageProps, ...rest } = props;
  const getLayout = Component.getLayout ?? ((page: any) => page);

  return (
    <Route
      render={(props) => getLayout(<Component {...pageProps} />)}
      {...rest}
    />
  );
}

export default RouteV2
