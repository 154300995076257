import { useMemo } from 'react';

import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ActionVisibleParams, HandlerParams, useContextActions1 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_TAG_TO_CONTACT, MARK_TAG_AS_DONE, MARK_TAG_AS_NOT_DONE } from 'modules/tags/api';
import useUpdateTagEntities from 'modules/tags/hooks/useUpdateTagEntities';

export default function useTagContextActions() {
  const onAssignToContactCompleted = useUpdateTagEntities({ transform: assignToContactTransform});
  const assignToContact = useAssignToContact(ASSIGN_TAG_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateTagEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_TAG_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateTagEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_TAG_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const contextActions = useContextActions1({ assignToContact, markAsDone, markAsNotDone });

  return useMemo(() => {
    return [
      ...contextActions,
      {
        id: 'TAG_HAS_PARENT_SYNONYM',
        title: 'Tag has Parent / Synonym',
        meta: {
          actionAllowed: () => true,
          actionVisible: ({ selectedItems }: ActionVisibleParams) => selectedItems.length === 2,
        },
        handler: ({ selectedItems }: HandlerParams) => {
          console.log('selectedItems', Array.from(selectedItems.values()));
        },
      },
    ]
  }, [contextActions]);
}
