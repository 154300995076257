import { ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import TopLevelList from 'components/TopLevelList';

import useStyles from './styles';

type ActionType = {
  handler: () => void;
};

type Props = {
  title: string;
  actions?: Record<string, ActionType>;
  data?: ReactNode;
  children?: ReactNode;
};

const Page = ({ title, actions, data, children }: Props) => {
  const classes = useStyles();

  const [size, setSize] = useState({ width: 240, height: 0 });

  const onResize = useCallback((e: SyntheticEvent, { size }: ResizeCallbackData) => setSize(size), [setSize]);
  const handleExpand = useCallback(
    (e: SyntheticEvent, expanded: boolean) => {
      if (expanded) {
        setSize({ width: 1240, height: 0 });
      } else {
        setSize({ width: 240, height: 0 });
      }
    },
    [setSize],
  );

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`BUILD & CODE | ${title}`}</title>
      </Helmet>
      <Resizable
        axis="x"
        minConstraints={[240, 10]}
        resizeHandles={['e']}
        width={size.width}
        height={size.height}
        onResize={onResize}
        handle={
          <div className={classes.handle}>
            <MoreVertIcon />
          </div>
        }
      >
        <Paper className={classes.paper} style={{ width: size.width }} square>
          <TopLevelList title={title} actions={actions} onExpand={handleExpand}>
            {data}
          </TopLevelList>
        </Paper>
      </Resizable>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Page;
