import { gql } from '@apollo/client';

export const SE_CREATION_STATE_UPDATED = gql`
  subscription SE_Creation_State_Updated {
    se_creation_state_updated {
      SE_creation_started
	    SE_creation_finished
      SE_creation_results {
        data_processing_apis_result {
          cluster_id
          success {
            candidate_phrases
            batch_id
          }
        }
        date_finished
      }
    }
  }
`;
