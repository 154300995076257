import { ReactElement, RefObject, SyntheticEvent, useRef } from 'react';
import useDoubleClick from 'use-double-click';

export type DoubleClickRenderProps<T> = {
  ref: RefObject<T>;
};

type Props = {
  onClick?: (e: SyntheticEvent) => void;
  onDoubleClick?: (e: SyntheticEvent) => void;
  children: (props: any) => ReactElement | null;
};

const DoubleClick = <T extends HTMLElement>({ onClick, onDoubleClick, children }: Props) => {
  const ref = useRef<T>();

  useDoubleClick({
    onSingleClick: onClick,
    onDoubleClick: onDoubleClick,
    ref: ref,
    latency: 250,
  });

  return children({ ref } as DoubleClickRenderProps<T>);
};

export default DoubleClick;
