import { useCallback, useMemo } from 'react';
import { Item, Menu } from 'react-contexify';
import { Map as ImmutableMap } from 'immutable';

import MenuItem from '@material-ui/core/MenuItem';

import { ContextActionAcl } from 'hooks/useContextActions';
import { useContextActionsContext } from 'providers/ContextActionsProvider';
import { useDataSelectionContext } from 'providers/DataSelectionProvider';
import { DataItem } from 'types';

const transform = (selectedItems: ImmutableMap<string, ImmutableMap<string, DataItem>>) => {
  const values = Array.from(selectedItems.values());

  return values.flatMap(value => Array.from(value.values()));
};

type ActionHandlerArgsProps = {
  selectedItems: DataItem[];
  contextItem: DataItem;
};

type Action = {
  id: string;
  title: string;
  handler: (args: ActionHandlerArgsProps) => void;
};

type Props = {
  menuId: string;
  menuAcl?: ContextActionAcl;
  actions?: Action[];
};

const ContextMenu = (props: Props) => {
  const { menuId, menuAcl, actions } = props;

  const { contextItem, listId, setContextItem, setListId } = useContextActionsContext();
  const { selectedItems } = useDataSelectionContext();

  const actionsVisible = useMemo(() => {
    const acl = menuAcl?.[listId as string];

    return (actions || []).filter(
      (action: any) =>
        action.meta.actionAllowed(acl) &&
        action.meta.actionVisible({
          selectedItems: transform(selectedItems),
          contextItem,
        }),
    );
  }, [menuAcl, listId, actions, selectedItems, contextItem]);

  const handleMenuHidden = useCallback(() => {
    setListId(null);
    setContextItem(null);
  }, [setListId, setContextItem]);

  const handleItemClick = useCallback(
    (action, { contextItem }) =>
      action.handler({
        selectedItems: transform(selectedItems),
        contextItem,
      }),
    [selectedItems],
  );

  return actionsVisible.length > 0 ? (
    <Menu id={menuId} onHidden={handleMenuHidden}>
      {actionsVisible.map((action) => (
        <Item key={action.id} onClick={({ props }) => handleItemClick(action, props)}>
          <MenuItem>{action.title}</MenuItem>
        </Item>
      ))}
    </Menu>
  ) : null;
};

export default ContextMenu;
