import { gql } from '@apollo/client';

export enum ClassSortOption {
  BY_COUNT_OF_TAG_HAS_CLASS_EDGES = 'by_count_of_Tag_has_Class_edges',
  BY_COUNT_OF_TAG_HAS_CLASS_PREDICTIONS = 'by_count_of_Tag_has_Class_predictions',
  BY_COUNT_OF_TAG_HAS_CLASS_PREDICTIONS_ASSIGNED_TO_USER = 'by_count_of_Tag_has_Class_predictions_assigned_to_user',
}

export type ClassSortOptionsInput = {
  option: ClassSortOption;
  direction: 'DESC' | 'ASC';
};

export type ClassFilterWhereInput = {
  name_STARTS_WITH?: string;
  name_STARTS_WITH_REGEXP?: string;
  only_assigned?: boolean;
  only_done?: boolean;
};

export type ClassFilterOptionsInput = {
  sort?: ClassSortOptionsInput;
  offset?: number;
  limit?: number;
};

export type ClassFilterInput = {
  where?: ClassFilterWhereInput;
  options?: ClassFilterOptionsInput;
};

export type MethodClassToClassConversionsWhereInput = {
  base_asset_id: string;
  target_asset_id: string;
};


export const QUERY_CLASSES = gql`
  query Classes($where: Class_Filter_Where_Input, $options: Class_Filter_Options_Input) {
    Classes(where: $where, options: $options) {
      id
      name
      active_contact_is_assigned_to
      active_contact_is_done_by
    }
  }
`;

export const SEARCH_CLASSES = gql`
  query Classes($where: Class_Filter_Where_Input, $options: Class_Filter_Options_Input) {
    Classes(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const CREATE_CLASS = gql`
  mutation CreateClass($input: Create_Class_Input!) {
    create_Class(input: $input) {
      classes {
        id
        name
        active_contact_is_assigned_to
        active_contact_is_done_by
      }
    }
  }
`;

export const ASSIGN_CLASS_TO_CONTACT = gql`
  mutation Assign_Class_to_Contact($input: Assign_to_Contact_Input!) {
    assign_Class_to_Contact(input: $input) {
      id
    }
  }
`;

export const MARK_CLASS_AS_DONE = gql`
  mutation mark_Class_as_done($ids: [BigInt!]!) {
    mark_Class_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_CLASS_AS_NOT_DONE = gql`
  mutation mark_Class_as_not_done($ids: [BigInt!]!) {
    mark_Class_as_not_done(ids: $ids) {
      id
    }
  }
`;
