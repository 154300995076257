import { useCallback, useState } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import { isNumber } from 'shared/utils';

import useStyles from './styles';

type Props = {
  label: string;
  name?: string;
  value?: {
    min?: number;
    max?: number;
  };
  onChange?: (a: any) => void;
};

export default function RangeInput({ label, name, value, onChange }: Props) {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const localOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        const { name: localName, value: localValue } = event.target;

        const newValue = {
          ...value,
          [localName]: isNumber(localValue) ? parseInt(localValue, 10) : undefined,
        };
        onChange(newValue);

        if (newValue?.min && newValue?.max) {
          // @ts-ignore
          setError(Number(newValue?.min) > Number(newValue?.max));
        }
      }
    },
    [value, onChange],
  );

  return (
    <div>
      <InputLabel className={classes.label} htmlFor="countLiteralsMin">
        {label}
      </InputLabel>
      <div className={classes.rangeContainer}>
        <TextField
          name="min"
          type="number"
          value={value?.min || ''}
          inputProps={{
            inputMode: 'numeric',
            step: 1,
          }}
          className={classes.rangeInput}
          variant="outlined"
          placeholder="Set minimum..."
          onChange={localOnChange}
          error={error}
        />
        <TextField
          name="max"
          type="number"
          value={value?.max || ''}
          className={classes.rangeInput}
          variant="outlined"
          placeholder="Set maximum..."
          onChange={localOnChange}
          error={error}
        />
      </div>
    </div>
  );
}
