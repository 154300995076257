import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';

import { AssignToContactParams } from 'hooks/useAssignToContact';
import { ASSIGN_TOKEN_HAS_MEANING_TO_CONTACT } from 'methods/token-has-meaning/api';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { Edge_Direction } from 'shared/constants';
import { AssignMethodConfig, ContactItem, DataItem } from 'types';

type useAssignTokenHasMeaningToContactOptions = {
  onCompleted?: (result: unknown, items: DataItem[]) => void;
};

export default function useAssignTokenHasMeaningToContact(
  config: AssignMethodConfig,
  languageId: number,
  parentItem: DataItem,
  options: useAssignTokenHasMeaningToContactOptions = {},
) {
  const { onCompleted: onAssignMethodToContactCompleted } = options;

  const { user } = useAuth();
  const currentRef = useRef<boolean>(false);
  const itemsRef = useRef<DataItem[] | null>(null);

  const onCompleted = useCallback((result: any) => {
    if (!currentRef.current) return;

    onAssignMethodToContactCompleted?.(result, itemsRef.current as DataItem[])
    currentRef.current = false;
    itemsRef.current = null;
  }, [onAssignMethodToContactCompleted]);

  const [mutate] = useMutation(ASSIGN_TOKEN_HAS_MEANING_TO_CONTACT, { onCompleted });
  const assignMethodToContact = useCallback(
    ({ dataItems, contacts }: AssignToContactParams) => {
      itemsRef.current = dataItems;
      currentRef.current = contacts.some(c => c.id === user?.contactId);

      const parentIdField = config.direction === Edge_Direction.DIRECT ? 'base_asset_id' : 'target_asset_id';
      const itemIdField = config.direction === Edge_Direction.DIRECT ? 'target_asset_id' : 'base_asset_id';

      const input = {
        assets: dataItems.map((dataItem: DataItem) => ({
          [parentIdField]: parentItem.id,
          [itemIdField]: dataItem.id,
        })),
        language: languageId,
        contact_ids: contacts.map((contact: ContactItem) => contact.id),
      };

      mutate({
        variables: {
          input,
        },
      });
    },
    [config, languageId, parentItem, user, mutate],
  );

  return assignMethodToContact;
}
