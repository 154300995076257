import { ChangeEvent, useCallback, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import produce from 'immer';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from "@material-ui/core/Checkbox";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { renderTextField } from 'components/withFormik';
import { Action, getColor, Label } from 'modules/item-specifications/components/BaseTextAnnotator';
import { useAnnotationContextMenuContext } from 'modules/item-specifications/contexts/AnnotationContextMenuContext';
import { noop } from 'shared/utils';

import useStyles from './AnnotationContextMenu.styles';

function search(dataItems: Label[], term: string) {
  return term.length > 0
    ? dataItems.filter(it => it.name.toLowerCase().startsWith(term.toLowerCase()))
    : dataItems;
}

type Props = {
  action: Action;
};

export default function AnnotationContextMenu({ action }: Props) {
  const { contextSpan, setContextSpan } = useAnnotationContextMenuContext();
  const color = useMemo(() => contextSpan ? getColor(contextSpan, action) : undefined, [contextSpan, action]);
  const classes = useStyles({ color });

  const initialValues = useMemo(() => ({ name: '' }), []);
  const dataItems = useMemo(() => contextSpan?.labels || [], [contextSpan?.labels]);
  const getData = useCallback((term: string) => search(dataItems, term), [dataItems]);

  const handleSelectionChange = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const targetId = e.currentTarget.getAttribute("aria-labelledby");
    setContextSpan(baseSpan => produce(baseSpan, draftSpan => {
      draftSpan?.labels?.forEach(t => {
        if (t.id === targetId) {
          t.selected = checked;
        }
      });
    }));
  }, [setContextSpan]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" className={classes.heading}>
            {contextSpan?.text}
        </Typography>
        <Divider />
        {dataItems.length > 0 && (
          <Formik initialValues={initialValues} onSubmit={noop}>
            {({ values }) => (
              <Form>
                <Field
                  name="name"
                  component={renderTextField}
                  variant="outlined"
                  placeholder="Search"
                  margin="dense"
                  autoComplete="off"
                  fullWidth
                />
                <List dense disablePadding>
                  {getData(values.name).map(it => (
                    <ListItem key={it.id}>
                      <ListItemIcon className={classes.checkbox}>
                        <Checkbox
                          color="primary"
                          edge="start"
                          checked={it.selected}
                          onChange={handleSelectionChange}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': it.id,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={it.name} />
                    </ListItem>
                  ))}
                </List>
              </Form>
            )}
          </Formik>
        )}
      </CardContent>
    </Card>
  )
}
