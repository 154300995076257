import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  SemanticEntities_Entities_State,
  SemanticEntity_Next_SemanticEntities_Entities_State,
} from 'modules/semantic-entities/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateSemanticEntityEntities(params: Params) {
  const { transform } = params;

  // SemanticEntities
  const update_SemanticEntities = useSetRecoilState(SemanticEntities_Entities_State);
  // Next SemanticEntities
  const update_Next_SemanticEntities = useSetRecoilState(SemanticEntity_Next_SemanticEntities_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const)
    const updater = entitiesUpdaterFactory(entries);

    update_SemanticEntities(updater);
    update_Next_SemanticEntities(updater);
  }, [
    transform,
    update_SemanticEntities,
    update_Next_SemanticEntities,
  ]);
}
