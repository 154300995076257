import { gql } from '@apollo/client';

import { RangeInput } from 'types';

export enum MorphemeSortOption {
  BY_COUNT_OF_INCOMING_MORPH_HAS_MORPHEME = 'by_count_of_incoming_Morph_has_Morpheme',
}

export type MorphemeSortOptionsInput = {
  option: MorphemeSortOption;
  direction: 'DESC' | 'ASC';
};

export type MorphemeFilterWhereInput = {
  name_STARTS_WITH?: String;
  count_characters_RANGE?: RangeInput;
  only_assigned?: boolean;
  only_done?: boolean;
};

export type MorphemeFilterOptionsInput = {
  sort?: MorphemeSortOptionsInput;
  offset?: number;
  limit?: number;
};

export type MorphemeFilterInput = {
  where?: MorphemeFilterWhereInput;
  options?: MorphemeFilterOptionsInput;
};

export const QUERY_MORPHEMES = gql`
  query Morphemes($where: Morpheme_Filter_Where_Input, $options: Morpheme_Filter_Options_Input) {
    Morphemes(where: $where, options: $options) {
      id
      name
      active_contact_is_assigned_to
      active_contact_is_done_by
      language
    }
  }
`;

export const SEARCH_MORPHEMES = gql`
  query Morphemes($where: Morpheme_Filter_Where_Input, $options: Morpheme_Filter_Options_Input) {
    Morphemes(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const CREATE_MORPHEME = gql`
  mutation CreateMorpheme($input: Create_Morpheme_Input!) {
    create_Morpheme(input: $input) {
      morphemes {
        id
        name
        active_contact_is_assigned_to
        active_contact_is_done_by
        language
      }
    }
  }
`;

export const UPDATE_MORPHEME = gql`
  mutation UpdateMorpheme($input: Update_Morpheme_Input!) {
    update_Morpheme(input: $input) {
      morphemes {
        id
        name
        language
      }
    }
  }
`;

export const ASSIGN_MORPHEME_TO_CONTACT = gql`
  mutation Assign_Morpheme_to_Contact($input: Assign_to_Contact_Input!) {
    assign_Morpheme_to_Contact(input: $input) {
      id
    }
  }
`;

export const MARK_MORPHEME_AS_DONE = gql`
  mutation mark_Morpheme_as_done($ids: [BigInt!]!) {
    mark_Morpheme_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_MORPHEME_AS_NOT_DONE = gql`
  mutation mark_Morpheme_as_not_done($ids: [BigInt!]!) {
    mark_Morpheme_as_not_done(ids: $ids) {
      id
    }
  }
`;
