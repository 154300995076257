import { MouseEvent } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';

import { keyCount, keyName } from 'shared/utils';

import useStyles from './styles';

export default function TopLevelListItem({ index, style, data }: any) {
  const classes = useStyles();
  const { path } = useRouteMatch();

  const { items, nameField, countField, se_creation, selectedItems, onContextMenu, onToggleItem, onChange } = data;
  const item = items[index];
  const name = keyName(item, nameField);

  return (
    <ListItem
      className={classes.listItem}
      style={style}
      disableGutters
      onClick={(e) => {
        e.preventDefault();

        if (e.shiftKey) {
          onToggleItem(item);
        } else {
          onChange(item);
        }
      }}
    >
      <Button
        onContextMenu={(e: MouseEvent) => onContextMenu(e, item)}
        component={Link}
        to={`${path.replace(':id?', item.id)}`}
        classes={{
          root: clsx(classes.button, {
            [classes.buttonActive]: selectedItems && selectedItems.has(item.id),
            [classes.buttonSeCreation]: se_creation,
          }),
          label: classes.buttonLabel,
        }}
        variant="outlined"
        title={name}
      >
        {name}
        {keyCount(item, countField)}
        <span className={classes.tagSection}>
          {item?.active_contact_methods_count_is_assigned > 0 && (
            <span className={clsx(classes.tag, classes.orangeTag)}>
              {item?.active_contact_methods_count_is_assigned}
            </span>
          )}
          {item?.active_contact_is_assigned_to && <span className={clsx(classes.tag, classes.yellowTag)}></span>}
          {item?.active_contact_is_done_by && <span className={clsx(classes.tag, classes.greenTag)}></span>}
        </span>
      </Button>
    </ListItem>
  );
}
