import { useCallback, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/SearchRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useStyles from './styles';

type Props = {
  label?: string;
  multiple?: boolean;
  freeSolo?: boolean;
  name: string;
  value?: any;
  options: any[];
  loading?: boolean;
  delay?: number;
  minLength?: number;
  blurOnSelect?: boolean;
  onSearchChange: (search?: string) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  getOptionLabel?: (option: any) => string;
  getOptionSelected?: (option: any, value: any) => boolean;
};

const formatOptionLabel = (option: any) => (typeof option === 'string' ? option : option.name);
const formatOptionSelected = (option: any, value: any) =>
  typeof option === 'string' ? option === value : option.id === value.id;

export default function SearchAutocomplete({
  label,
  multiple = false,
  freeSolo = false,
  name,
  value,
  options,
  loading = false,
  delay = 500,
  minLength,
  blurOnSelect = false,
  onSearchChange,
  onChange,
  getOptionLabel = formatOptionLabel,
  getOptionSelected = formatOptionSelected,
}: Props) {
  const classes = useStyles();

  const fixedOnChange = useCallback(
    (event: any, value: any) => {
      event.target.name = name;
      event.target.value = value;
      onChange(event, value);
    },
    [name, onChange],
  );

  const onInputChange = useDebouncedCallback((event, value, reason) => {
    if (freeSolo) {
      const fakeEvent = {
        target: {
          name,
          value,
        },
      };
      // @ts-ignore
      onChange(fakeEvent, value);
    }

    if (minLength) {
      onSearchChange(value.length > minLength ? value : undefined);
    } else {
      onSearchChange(value);
    }
  }, delay);

  const fixedOptions = useMemo(() => {
    if (!freeSolo || options.length) {
      return options;
    }

    if (!value) {
      return [];
    }

    return [value];
  }, [options, freeSolo, value]);

  const multipleOptions = useMemo(() => {
    if (multiple) {
      return {
        // @ts-ignore
        renderOption: (option, { selected }) => (
          <>
            <Checkbox color="primary" style={{ marginRight: 8 }} checked={selected} />
            {getOptionLabel(option)}
          </>
        ),
      };
    }

    return {};
  }, [multiple, getOptionLabel]);

  useEffect(
    () => () => {
      onInputChange.flush();
    },
    [onInputChange],
  );

  return (
    <div>
      {label && (
        <InputLabel className={classes.label} htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <Autocomplete
        multiple={multiple}
        freeSolo={freeSolo}
        loading={loading}
        blurOnSelect={blurOnSelect}
        value={value}
        options={fixedOptions}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Start typing"
            InputProps={{
              ...params.InputProps,
              name,
              startAdornment: (
                <>
                  <InputAdornment position="start" className={classes.inputIcon}>
                    {loading && <CircularProgress size={24} />}
                    {!loading && <SearchIcon />}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
        onInputChange={onInputChange}
        onChange={fixedOnChange}
        {...multipleOptions}
      />
    </div>
  );
}
