import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Tag_Synonyms_Tags_Consensus_Annotations_Entities_State,
  Tag_Synonyms_Tags_Consensus_Annotations_Ids_State,
  Tag_Synonyms_Tags_Options_Entities_State,
  Tag_Synonyms_Tags_Options_Ids_State,
  Tag_Synonyms_Tags_Predictions_Entities_State,
  Tag_Synonyms_Tags_Predictions_Ids_State,
} from './atoms';

export const Tag_Synonyms_Tags_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Synonyms_Tags_Consensus_Annotations/list',
  Tag_Synonyms_Tags_Consensus_Annotations_Entities_State,
  Tag_Synonyms_Tags_Consensus_Annotations_Ids_State,
);

export const Tag_Synonyms_Tags_Predictions_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Synonyms_Tags_Predictions/list',
  Tag_Synonyms_Tags_Predictions_Entities_State,
  Tag_Synonyms_Tags_Predictions_Ids_State,
);

export const Tag_Synonyms_Tags_Options_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Synonyms_Tags_Options/list',
  Tag_Synonyms_Tags_Options_Entities_State,
  Tag_Synonyms_Tags_Options_Ids_State,
);
