import { ChangeEvent, memo, useCallback } from 'react';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import useLanguages from 'hooks/useLanguages';
import { toLanguageString } from 'shared/utils';
import { LanguageItem } from 'types';

type Props = {
  value: any;
  disabled?: boolean;
  onChange?: (value: number) => void;
};

const useStyles = makeStyles((theme) => ({
  select: {
    paddingTop: 8,
    paddingBottom: 8,
  },
}));

function Languages({ value, disabled = false, onChange }: Props) {
  const { languages } = useLanguages();
  const classes = useStyles();

  const handleChange = useCallback((e: ChangeEvent<any>) => onChange?.(e.target.value), [onChange]);

  return (
    <FormControl variant="outlined" style={{ width: 'auto' }} margin="none">
      <Select
        value={value}
        disabled={disabled}
        classes={{
          outlined: classes.select,
        }}
        onChange={handleChange}
      >
        {languages.map((x: LanguageItem) => (
          <MenuItem key={x.id} value={x.id}>
            {toLanguageString(x)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default memo(Languages);
