import { SetStateAction, useCallback, useMemo, useState } from 'react';
import equal from 'fast-deep-equal/react';

type Props<T> = {
  closeOnChange?: boolean;
  initialValue: T;
  onValueChanged?: () => void;
};

type Meta = {
  pristine: boolean;
  dirty: boolean;
};

type Result<T> = {
  open: boolean;
  value: T;
  meta: Meta;
  openModal: () => void;
  closeModal: (resetOnClose?: boolean) => void;
  setValue: (value: SetStateAction<T>) => void;
  resetValue: () => void;
};

export default function useModalWithValue<T>(props: Props<T>): Result<T> {
  const {
    closeOnChange = true,
    initialValue,
    onValueChanged,
  } = props;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<T>(initialValue);

  const meta = useMemo(() => {
    const pristine = equal(value, initialValue);
    const dirty = !pristine;

    return {
      pristine,
      dirty,
    };
  }, [value, initialValue]);

  const setValueAdapter = useCallback(
    (value: SetStateAction<T>) => {
      setValue(value);
      onValueChanged?.();

      if (closeOnChange) {
        setOpen(false);
      }
    },
    [closeOnChange, onValueChanged],
  );

  const openModal = useCallback(() => setOpen(true), []);

  const closeModal = useCallback(
    (resetOnClose?: boolean) => {
      setOpen(false);
      if (resetOnClose) {
        setValue(initialValue);
      }
    },
    [initialValue],
  );

  const resetValue = useCallback(() => {
    setValue(initialValue);
    onValueChanged?.();
  }, [initialValue, onValueChanged]);

  return {
    open,
    value,
    meta,
    openModal,
    closeModal,
    setValue: setValueAdapter,
    resetValue,
  };
}
