import { BrowserRouter as Router, Switch } from 'react-router-dom';

import Loader from 'components/Loader';
import RouteV2 from 'components/Route';
import { AuthConsumer } from 'modules/auth/contexts/AuthContext';
import Classes from 'modules/classes';
import Clusters from 'modules/clusters';
import Contacts from 'modules/contacts';
import Home from 'modules/home';
import ItemSpecifications from 'modules/item-specifications';
import Morphemes from 'modules/morphemes';
import Morphs from 'modules/morphs';
import Phrases from 'modules/phrases';
import SemanticEntities from 'modules/semantic-entities';
import Tags from 'modules/tags';
import Tokens from 'modules/tokens';
import Login from 'pages/login';

function Routes() {
  return (
    <Router>
      <Switch>
        <RouteV2 path="/auth/login" component={Login} />
        <RouteV2 path="/classes/:id?" component={Classes} />
        <RouteV2 path="/clusters/:id?" component={Clusters} />
        <RouteV2 path="/contacts/:id?" component={Contacts} />
        <RouteV2 path="/morphemes/:id?" component={Morphemes} />
        <RouteV2 path="/morphs/:id?" component={Morphs} />
        <RouteV2 path="/phrases/:id?" component={Phrases} />
        <RouteV2 path="/semantic-entities/:id?" component={SemanticEntities} />
        <RouteV2 path="/item-specifications/:id?" component={ItemSpecifications} />
        <RouteV2 path="/tags/:id?" component={Tags} />
        <RouteV2 path="/tokens/:id?" component={Tokens} />
        <RouteV2 path="/" component={Home} />
      </Switch>
    </Router>
  )
}

function App() {
  return (
    <AuthConsumer>
      {(auth) => (!auth.isInitialized ? <Loader /> : <Routes />)}
    </AuthConsumer>
  );
}

export default App;
