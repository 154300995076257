import { gql } from '@apollo/client';

// base asset extension
export const QUERY_CLASS_CLUSTERS_CONSENSUS_ANNOTATIONS = gql`
  query Class_Clusters_Consensus_Annotations(
    $id: BigInt
    $where: Cluster_Filter_Where_Input
    $options: Cluster_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    classes(where: { id: $id }) {
      Clusters_Consensus_Annotations(where: $where, options: $options, consensus: $consensus) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_inverse__Class_has_Cluster
      }
    }
  }
`;

export const QUERY_CLASS_CLUSTERS_OPTIONS = gql`
  query Class_Clusters_Options(
    $id: BigInt
    $where: Cluster_Filter_Where_Input
    $options: Cluster_Filter_Options_Input
  ) {
    classes(where: { id: $id }) {
      Clusters_Options(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_inverse__Class_has_Cluster
      }
    }
  }
`;

// target asset extension
export const QUERY_CLUSTER_CLASSES_CONSENSUS_ANNOTATIONS = gql`
  query Cluster_Classes_Consensus_Annotations(
    $id: BigInt
    $where: Class_Filter_Where_Input
    $options: Class_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    clusters(where: { id: $id }) {
      Classes_Consensus_Annotations(where: $where, options: $options, consensus: $consensus) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_inverse__Tag_has_Class
      }
    }
  }
`;

export const QUERY_CLUSTER_CLASSES_OPTIONS = gql`
  query Cluster_Classes_Options($id: BigInt, $where: Class_Filter_Where_Input, $options: Class_Filter_Options_Input) {
    clusters(where: { id: $id }) {
      Classes_Options(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_inverse__Tag_has_Class
      }
    }
  }
`;

// method node
export const ASSIGN_CLASS_HAS_CLUSTER_TO_CONTACT = gql`
  mutation Assign_Class_has_Cluster_to_Contact($input: Method_Assign_to_Contact_Input!) {
    assign_Class_has_Cluster_to_Contact(input: $input) {
      methodClassHasClusters {
        id
      }
    }
  }
`;

export const CONFIRM_CLASS_HAS_CLUSTER_OPTION = gql`
  mutation Confirm_Method_Class_has_Cluster_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!) {
    confirm_Method_Class_has_Cluster_option(
      input: {
        base_asset_id: $baseAssetId # class id
        target_asset_id: $targetAssetId # cluster id
      }
    ) {
      methodClassHasClusters {
        id
      }
    }
  }
`;

export const DECLINE_CLASS_HAS_CLUSTER_OPTION = gql`
  mutation Decline_Method_Class_has_Cluster_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!) {
    decline_Method_Class_has_Cluster_option(
      input: {
        base_asset_id: $baseAssetId # class id
        target_asset_id: $targetAssetId # cluster id
      }
    ) {
      methodClassHasClusters {
        id
      }
    }
  }
`;
