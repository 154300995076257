import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_MORPHS } from 'modules/morphs/api';
import { Morphs_selector } from 'modules/morphs/store';

export default function useMorphsQuery(variables: any) {
  const transform = useCallback((data: any) => data.Morphs, []);

  return useQuery(QUERY_MORPHS, variables, Morphs_selector, transform);
}
