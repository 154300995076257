import { SyntheticEvent, useMemo } from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import { TextAnnotator, TextAnnotatorConfig, TextSpan } from 'plugins/react-text-annotate';

import useStyles from './BaseTextAnnotator.styles';
import { transformer } from './transformer';
import type { Action, Span } from './types';

type Props = {
  title: string;
  subtitle?: string;
  config?: TextAnnotatorConfig;
  content?: string;
  actions: Action[];
  selectedAction: Action;
  state: Span[];
  getSpan?: (baseSpan: TextSpan) => (TextSpan | Promise<TextSpan>);
  onActionSelected: (action: Action) => void;
  onStateUpdated?: (values: Span[]) => void;
  onSpanCreated?: (span: Span) => void;
  onSpanClicked?: (e: SyntheticEvent, span: Span) => void;
  disableAnnotatorSelection?: boolean
};

export default function BaseTextAnnotator(props: Props) {
  const {
    title,
    subtitle = '',
    content = '',
    config,
    actions,
    selectedAction,
    state,
    disableAnnotatorSelection,
    getSpan,
    onActionSelected,
    onStateUpdated,
    onSpanCreated,
    onSpanClicked,
  } = props;
  const classes = useStyles();

  const memoizedState = useMemo(
    () => transformer.toTextSpanArray(state, selectedAction),
    [state, selectedAction],
  );

  return (
    <>
      <Card className={classes.section}>
        <CardHeader
          title={
            <Typography variant="h6">{title}</Typography>
          }
          disableTypography
        />
        {actions.length > 1 && (
          <CardContent>
            <ButtonGroup disableElevation>
              {actions.map(it => (
                <Button
                  key={it.id}
                  variant="contained"
                  color={selectedAction.id === it.id ? "primary" : undefined}
                  onClick={() => onActionSelected(it)}
                >
                  {it.title}
                </Button>
              ))}
            </ButtonGroup>
          </CardContent>
        )}
      </Card>
      <Card className={classes.section}>
        <CardHeader
          title={
            <Typography variant="h6">{subtitle}</Typography>
          }
          disableTypography
        />
        <CardContent>
          <TextAnnotator
            style={{
              lineHeight: 1.5,
              fontSize: '1.125rem',
            }}
            config={config}
            content={content}
            value={memoizedState}
            onUpdate={(value: TextSpan[]) => onStateUpdated?.(transformer.toSpanArray(value, selectedAction))}
            onSpanCreated={(value: TextSpan) => onSpanCreated?.(transformer.toSpan(value, selectedAction))}
            onSpanClick={(e: SyntheticEvent, value: TextSpan) => onSpanClicked?.(e, transformer.toSpan(value, selectedAction))}
            getSpan={getSpan}
            disableSelection={disableAnnotatorSelection}
          />
        </CardContent>
      </Card>
    </>
  )
}
