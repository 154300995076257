import { createContext } from 'react';

import { AssignToContactParams } from 'hooks/useAssignToContact';
import {  DataItem } from 'types';

export type AssignToContactValue = { dataItems: DataItem[] };

export type AssignToContactCallback = (params: AssignToContactParams) => void;

type Props = {
  openAssignToContactModal: (value: AssignToContactValue, callback: AssignToContactCallback) => void;
};

const Context = createContext<Props>({} as Props);

export default Context;
