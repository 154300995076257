import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_CLUSTERS } from 'modules/clusters/api';
import { Clusters_selector } from 'modules/clusters/store';

export default function useClustersQuery(variables: any) {
  const transform = useCallback((data: any) => data.Clusters, []);

  return useQuery(QUERY_CLUSTERS, variables, Clusters_selector, transform);
}
