import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  SemanticEntity_Associated_Next_Tokens_Entities_State,
  SemanticEntity_Associated_Next_Tokens_Ids_State,
} from './atoms';

export const SemanticEntity_Associated_Next_Tokens_selector = listSelector<DataItem>(
  'graph/semantic-entities/@selectors/Associated_Next_Tokens/list',
  SemanticEntity_Associated_Next_Tokens_Entities_State,
  SemanticEntity_Associated_Next_Tokens_Ids_State,
);
