import { FastField as Field, Form, Formik } from 'formik';

import InputLabel from '@material-ui/core/InputLabel';

import { renderTextField } from 'components/withFormik';

import useStyles from './EntryForm.styles';

export type FormValues = {
  name: string
  password: string
  contact_weight: number
};

type Props = {
  formRef: any;
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

export default function EntryForm(props: Props) {
  const { formRef, initialValues, onSubmit } = props
  const classes = useStyles()

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <InputLabel className={classes.label} htmlFor="name">Name</InputLabel>
          <Field
            name="name"
            label="Name"
            component={renderTextField}
            variant="outlined"
            placeholder="Start typing..."
            required
            fullWidth
          />
          <InputLabel className={classes.label} htmlFor="password">Password</InputLabel>
          <Field
            name="password"
            label="Password"
            component={renderTextField}
            variant="outlined"
            placeholder="Start typing..."
            required
            fullWidth
          />
          <InputLabel className={classes.label} htmlFor="contact_weight">Weight</InputLabel>
          <Field
            name="contact_weight"
            label="Weight"
            component={renderTextField}
            placeholder="Start typing..."
            variant="outlined"
            required
          />
        </Form>
      )}
    </Formik>
  );
}
