import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';

import { DataItem, Mutation } from 'types';

export type MarkAsDoneParams = {
  dataItems: DataItem[];
};

export const markAsDoneTransform = () => ({ active_contact_is_done_by: true }) as DataItem;

type UseMarkAsDoneOptions = {
  onCompleted?: (result: unknown, items: DataItem[]) => void;
};

export default function useMarkAsDone(
  mutation: Mutation,
  options: UseMarkAsDoneOptions = {},
): (params: MarkAsDoneParams) => void {
  const { onCompleted: onMarkAsDoneCompleted } = options;

  const itemsRef = useRef<DataItem[] | null>(null);

  const onCompleted = useCallback((result: any) => {
    onMarkAsDoneCompleted?.(result, itemsRef.current as DataItem[])
    itemsRef.current = null;
  }, [onMarkAsDoneCompleted]);

  const [mutate] = useMutation(mutation, { onCompleted });
  const markAsDone = useCallback(
    ({ dataItems }: MarkAsDoneParams) => {
      itemsRef.current = dataItems;

      const ids = dataItems.map((dataItem: DataItem) => dataItem.id)

      mutate({
        variables: {
          ids,
        },
      });
    },
    [mutate],
  );

  return markAsDone;
}
