import { createContext, FC, ReactElement, useEffect, useReducer } from 'react'

import { apiClient } from 'api/client'
import { login as apiLogin } from 'modules/auth/api'
import { CONTACT_APP_USER_INFO } from 'modules/auth/api/queries'
import { getAccessTokenPromise, getAuthTokenData, resetAuthTokenData, setAuthTokenData } from 'modules/auth/utils'

interface AuthState {
  isInitialized: boolean
  isAuthenticated: boolean
  user: UserEssentialSet | null
  userIri: string | null
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

interface AuthProviderProps {
  // children: ReactNode
  children: ReactElement
}

interface UserEssentialSet {
  iri: string
  email: string
  contactId: string
  name: string
}

type InitializeAction = {
  type: 'INITIALIZE'
  payload: {
    isAuthenticated: boolean
    user: UserEssentialSet | null
    userIri: string | null
  }
}

type LoginAction = {
  type: 'LOGIN'
  payload: {
    userIri: string
    user: UserEssentialSet | null
  }
}

type LogoutAction = {
  type: 'LOGOUT'
}

type Action = InitializeAction | LoginAction | LogoutAction

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  userIri: null,
  user: null
}

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
  INITIALIZE: (state: AuthState, action: Action): AuthState => {
    const { isAuthenticated, userIri, user } = (action as InitializeAction).payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      userIri
    }
  },
  LOGIN: (state: AuthState, action: Action): AuthState => {
    const { userIri, user } = (action as LoginAction).payload
    return {
      ...state,
      isAuthenticated: true,
      userIri,
      user
    }
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
    userIri: null
  })
}

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state

export const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialAuthState)

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        // get promise one to directly check refreshToken  / expire state
        const accessToken = await getAccessTokenPromise()
        const tokenData = getAuthTokenData()

        if (tokenData !== null && accessToken) {
          const userIri = `/users/${tokenData.userUuid}`
          const userEssentials = await getUserEssentials(userIri)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userEssentials,
              userIri: userIri
            }
          })
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              userIri: null
            }
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            userIri: null
          }
        })
      }
    }

    initialize()
  }, [])

  const login = async (email: string, password: string): Promise<void> => {
    const authData = await apiLogin(email, password);
    setAuthTokenData(authData);
    const userUuid = getAuthTokenData().userUuid;
    const userEssentials = await getUserEssentials(`/users/${userUuid}`);

    dispatch({
      type: 'LOGIN',
      payload: {
        userIri: `/users/${userUuid}`,
        user: userEssentials
      },
    });
  }

  const logout = async (): Promise<void> => {
    resetAuthTokenData();
  };

  const getUserEssentials = async (userIri: string): Promise<UserEssentialSet | null> => {
    try {
      const context = { apiName: 'applicationApi' };
      const result = await apiClient.query({ query: CONTACT_APP_USER_INFO, context });
      const data = result.data.currentGetContactAppUser

      return {
        iri: userIri,
        email: data.user.email,
        contactId: data.accountContact.graphApiContactId,
        name: `${data.accountContact.contact.firstName} ${data.accountContact.contact.lastName}`,
      };
    } catch (error) {
      return null;
    }
  }

  return (
    <AuthContext.Provider value={{
      ...state,
      login,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  // children: PropTypes.node.isRequired
}

export const AuthConsumer = AuthContext.Consumer
