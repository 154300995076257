import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import { CREATE_CONTACT, UPDATE_CONTACT } from 'modules/contacts/api';
import { Contacts_Entities_State, Contacts_Ids_State } from 'modules/contacts/store';
import { entitiesUpdaterFactory, idsUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type UseContactParams = {
  onCreate?: () => void;
  onUpdate?: () => void;
};

type UseContactResult = {
  createContact: (values: any) => void;
  updateContact: (values: any) => void;
};

export default function useContact(params: UseContactParams = {}): UseContactResult {
  const { onCreate, onUpdate } = params;

  const updateEntities = useSetRecoilState(Contacts_Entities_State);
  const updateIds = useSetRecoilState(Contacts_Ids_State);

  const onCreateCompleted = useCallback((result: any) => {
    const ids: string[] = result.create_Contact.contacts.map((x: DataItem) => x.id);
    const entries = (result.create_Contact.contacts as DataItem[]).map(x => [x.id, x, x] as const);

    const idsUpdater = idsUpdaterFactory(ids);
    const entitiesUpdater = entitiesUpdaterFactory(entries, true);

    updateEntities(entitiesUpdater);
    updateIds(idsUpdater);
  }, [
    updateEntities,
    updateIds,
  ]);
  const [create] = useMutation(CREATE_CONTACT, { onCompleted: onCreateCompleted });

  const onUpdateCompleted = useCallback((result: any) => {
    const entries = (result.update_Contact.contacts as DataItem[]).map(x => [x.id, x] as const);
    const entitiesUpdater = entitiesUpdaterFactory(entries);
    updateEntities(entitiesUpdater);

  }, [updateEntities]);
  const [update] = useMutation(UPDATE_CONTACT, { onCompleted: onUpdateCompleted });

  const createContact = useCallback(
    (item: DataItem) => {
      const input = {
        name: item.name,
        password: item.password,
        contact_weight: item.contact_weight,
      };

      create({
        variables: {
          input,
        },
      });
      onCreate?.()
    }, [create, onCreate]
  );

  const updateContact = useCallback(
    (item: DataItem) => {
      const input = {
        id: item.id,
        name: item.name,
        contact_weight: item.contact_weight,
      };

      update({
        variables: {
          input,
        }
      });

      onUpdate?.()
    }, [update, onUpdate]
  );

  return {
    createContact,
    updateContact,
  };
}
