import { Action } from 'modules/item-specifications/components/BaseTextAnnotator';
import { Tag } from 'plugins/react-text-annotate';
import { Operation } from 'shared/constants';

const actions: Action[] = [
  {
    id: Operation.ANCHOR_BUILDING_MATERIAL,
    title: "Anchor Building Material",
    defaultTag: {
      id: '4',
      name: 'Building Material',
    } as Tag,
    colors: {
      default: '#8FD14F',
      prediction: '#FA8D10',
      dirty: '#FAC710',
      inactive: '#696969',
    },
  },
  {
    id: Operation.ATTRIBUTE_ON_BUILDING_MATERIAL,
    title: "Attribute on Building Material",
    defaultTag: {
      id: '5',
      name: 'Attribute on Building Material',
    } as Tag,
    colors: {
      default: '#8FD14F',
      prediction: '#FA8D10',
      dirty: '#FAC710',
      inactive: '#696969',
    },
  },
  {
    id: Operation.SPANCAT,
    title: "SpanCat",
    colors: {
      default: '#8FD14F',
      prediction: '#FA8D10',
      dirty: '#FAC710',
      inactive: '#696969',
    },
  },
];

export default actions;
