import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';

import Popover from '@material-ui/core/Popover';

import useModalWithValue from 'hooks/useModalWithValue';
import { useSuccess } from 'hooks/useSuccess'
import { ATTRIBUTE_ON_BUILDING_MATERIAL_ID, BUILDING_MATERIAL_ID } from 'modules/classes/constants';
import BaseTextAnnotator, { Action, Label, Span } from 'modules/item-specifications/components/BaseTextAnnotator';
import SelectTagModal, { SelectTagModalValue } from 'modules/item-specifications/components/SelectTagModal';
import { useAnnotationContextMenuContext } from 'modules/item-specifications/contexts/AnnotationContextMenuContext';
import { useItemSpecificationContext } from 'modules/item-specifications/contexts/ItemSpecificationContext';
import useCreateOrUpdateTag from 'modules/item-specifications/hooks/useCreateOrUpdateTag';
import useGetItemSpecification from 'modules/item-specifications/hooks/useGetItemSpecification';
import useItemSpecificationAnnotation, { ItemSpecificationAnnotationResult } from 'modules/item-specifications/hooks/useItemSpecificationAnnotation';
import { Tag, TextAnnotatorConfig, TextSpan } from 'plugins/react-text-annotate';
import { Operation, Source, Status } from 'shared/constants';
import { ItemSpecification } from 'types';

import actions from './actions';
import AnnotationContextMenu, { FormValues } from './AnnotationContextMenu';
import { getItemSpecificationInput, getSpans } from './transformations';

function getSelectedTags(tags: Tag[], action: Action): Tag[] {
  const ANCHOR_BUILDING_MATERIAL_ALLOWED = [`${BUILDING_MATERIAL_ID}`]
  const ATTRIBUTE_ON_BUILDING_MATERIAL_ALLOWED = [`${BUILDING_MATERIAL_ID}`, `${ATTRIBUTE_ON_BUILDING_MATERIAL_ID}`]
  const selectedTags = tags.filter(t => t.meta?.status === Status.confirmed || t.meta?.source === Source.prediction);

  if (action.id === Operation.ANCHOR_BUILDING_MATERIAL) {
    return selectedTags.filter(t => ANCHOR_BUILDING_MATERIAL_ALLOWED.includes(t.id));
  }

  if (action.id === Operation.ATTRIBUTE_ON_BUILDING_MATERIAL) {
    return selectedTags.filter(t => ATTRIBUTE_ON_BUILDING_MATERIAL_ALLOWED.includes(t.id));
  }

  return selectedTags;
}

type Props = {
  itemSpecification: ItemSpecification
  onModified: (touched: boolean) => void;
};

export default function PhraseTagClassAnnotator(props: Props) {
  const { itemSpecification, onModified } = props;

  const { showSuccess } = useSuccess();
  const { itemApiRef } = useItemSpecificationContext();
  const { setContextSpan } = useAnnotationContextMenuContext();
  const { getItemSpecification } = useGetItemSpecification();
  const { createOrUpdateTag } = useCreateOrUpdateTag();

  const [state, setState] = useState<Span[]>([]);
  const [action, setAction] = useState<Action>(actions[0]);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const {
    open: tagsModalOpen,
    value: tagsModalValue,
    openModal: openTagsModal,
    closeModal: closeTagsModal,
    setValue: setTagsModalValue,
  } = useModalWithValue({ initialValue: null as unknown as SelectTagModalValue });

  const config: TextAnnotatorConfig = useMemo(() => ({
      getText: (textSpan: TextSpan): string => {
        const selectedTags = getSelectedTags(textSpan.tags || [], action);
        return selectedTags && selectedTags.length > 0
          ? selectedTags.map(it => it.name).join(', ')
          : '';
      }
    }), [action]);

  const getSpan = useCallback(
    (baseSpan: TextSpan) => {
      const span = {
        ...baseSpan,
        meta: {
          seId: null,
          groupId: null,
          tagId: null,
          source: Source.consensus,
          status: Status.confirmed,
          anchor: action.id === Operation.ANCHOR_BUILDING_MATERIAL,
          active: true,
          invisible: false,
          color: action.colors.dirty,
        }
      } as TextSpan;

      if ([
        Operation.ANCHOR_BUILDING_MATERIAL,
        Operation.ATTRIBUTE_ON_BUILDING_MATERIAL,
      ].includes(action.id)) {
        span.tags = [
          {
            ...action.defaultTag,
            meta: {
              source: Source.consensus,
              status: Status.confirmed,
            }
          } as Tag
        ];
      }

      return span;
    },
    [action],
  );

  const onCompleted = useCallback((result: ItemSpecificationAnnotationResult) => {
    const spans = getSpans(result.Item_Specification_Annotation, action.id);
    setState(spans);
    onModified(false);
    showSuccess();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, showSuccess]);
  const { saveAnnotation } = useItemSpecificationAnnotation({ itemId: itemSpecification.id }, { onCompleted })

  const selectAction = useCallback((newAction: Action) => {
    setAction(newAction);
    onModified(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSpan = useCallback((span: Span) => {
    setState(baseState => produce(baseState, draftState => {
      draftState.forEach(draftSpan => {
        if (draftSpan.id === span.id) {
          draftSpan.status = Status.declined;
          draftSpan.invisible = true;
        }
      });
    }));

    onModified(true);
    setAnchorEl(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showContextMenu = useCallback((e: SyntheticEvent, span: Span) => {
    setContextSpan(span);
    setAnchorEl(e.currentTarget);
  }, [setContextSpan]);

  const hideContextMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const showTagsDialog = useCallback((span: Span) => {
    setTagsModalValue({ span });
    openTagsModal();
  }, [setTagsModalValue, openTagsModal]);

  const selectTag = useCallback((newValue: SelectTagModalValue) => {
    const keyId = (span: Span) => `${span.tagId}_${span.first_position_in_phrase}:${span.last_position_in_phrase}`;

    setState(baseState => produce(baseState, draftState => {
      const targetKey = keyId(newValue.span);
      let found = false;

      draftState.forEach((span, index) => {
        if (keyId(span) === targetKey) {
          draftState[index] = newValue.span;
          found = true;
        }
      });

      if (!found) {
        draftState.push(newValue.span);
      }
    }));
    closeTagsModal();
    onModified(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeTagsModal]);

  const updateTag = useCallback(async (values: FormValues) => {
    const resultTag = await createOrUpdateTag({
      tagId: values.tagId,
      tagName: values.tagName,
      classes: values.classes?.filter(it => it.status).map((it) =>
        ({
          id: it.id,
          status: it.status as number,
        })
      ),
    });

    setState(baseState => produce(baseState, draftState => {
      draftState.forEach(span => {
        if (span.tagId === resultTag.id) {
          // create map of result labels
          const resultMap = new Map<string, Label>();

          // create map of existing labels
          const existingMap = new Map<string, Label>();
          span.labels?.forEach(it => {
            existingMap.set(it.id, it);
          });

          // merging
          resultTag.classes?.forEach(it => {
            if (existingMap.has(it.id)) {
              const label = existingMap.get(it.id) as Label;
              resultMap.set(it.id, {
                ...label,
                status: it.status,
                source: Source.consensus,
                dirty: false,
              });
            } else {
              resultMap.set(it.id, {
                id: it.id,
                name: it.name,
                status: it.status,
                source: Source.consensus,
                dirty: false,
              });
            }
          });

          span.labels = [...resultMap.values()];
        }
      });
    }));
    setAnchorEl(null);
  }, [createOrUpdateTag]);

  useEffect(() => {
    getItemSpecification(itemSpecification.id).then(itemSpecification => {
      if (itemSpecification) {
        const spans = getSpans(itemSpecification, action.id);
        setState(spans);
        onModified(false);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSpecification.id, action.id]);

  useEffect(() => {
    itemApiRef.current.saveAnnotations = () => {
      const input = getItemSpecificationInput(state);
      saveAnnotation(input);
    };
  });

  return (
    <>
      <BaseTextAnnotator
        title="Highlighting & Annotating on the text"
        subtitle={itemSpecification.name}
        content={itemSpecification.description}
        config={config}
        actions={actions}
        selectedAction={action}
        state={state}
        getSpan={getSpan}
        onActionSelected={selectAction}
        onSpanCreated={showTagsDialog}
        onSpanClicked={showContextMenu}
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={hideContextMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <AnnotationContextMenu
          action={action}
          onDeleteSpan={deleteSpan}
          onSave={updateTag}
        />
      </Popover>

      {tagsModalOpen && (
        <SelectTagModal
          value={tagsModalValue}
          onSelect={selectTag}
          onDiscard={closeTagsModal}
        />
      )}
    </>
  )
}
