import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Clusters_Entities_State, Clusters_Ids_State } from './atoms';

export * from "../sections/ClassesDataSection/selectors"

export const Clusters_selector = listSelector<DataItem>(
  'graph/clusters/@selectors/list',
  Clusters_Entities_State,
  Clusters_Ids_State,
);
