import { ReactNode, useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import LaunchIcon from '@material-ui/icons/Launch';

import Loader from 'components/Loader';
import { renderRadioGroupField } from 'components/withFormik';
import { ItemSpecificaionTag, QUERY_ITEM_SPECIFICATION_TOKEN_TAGS } from 'modules/item-specifications/api/graphApi';
import useCreateOrUpdateTag from 'modules/item-specifications/hooks/useCreateOrUpdateTag';

export type FormValues = {
  tagId: string | null;
  tagName: string;
  tokenName?: string;
  classes?: Array<{
    id: string;
    name: string;
    status?: number;
  }>;
};

type TagOption = ItemSpecificaionTag & {
  value: string;
  label: ReactNode;
};

type Props = {
  formRef?: any;
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

function getLabel(tag: ItemSpecificaionTag) {
  return (
    <Typography>
      {tag.name}
      <Link color="inherit" target="_blank" href={`/tags/${tag.id}`} style={{ padding: '10px' }}>
        <LaunchIcon color="primary"/>
      </Link>
    </Typography>
  );
}

export default function SelectTagForm(props: Props) {
  const { initialValues, formRef, onSubmit } =  props;

  const variables = useMemo(
    () => ({
      input: {
        name: initialValues?.tagName,
      }
    }),
    [initialValues],
  );
  const { loading, data } = useQuery(QUERY_ITEM_SPECIFICATION_TOKEN_TAGS, { variables });
  const { createOrUpdateTag } = useCreateOrUpdateTag()

  const tagOptions: TagOption[] = useMemo(() => {
    return [
      ...(data?.Item_Specification_Token_Tags || []).map((it: ItemSpecificaionTag) =>
        ({
          ...it,
          value: it.id,
          label: getLabel(it),
        })
      ),
      {
        id: '',
        name: '',
        value: '',
        label: 'Create a new Tag',
      },
    ];
  }, [data]);

  const handleSubmit = useCallback(async (values: FormValues) => {
    if (typeof values.tagId !== 'string') return;

    if (values.tagId) {
      // update the existing tag if there are additional classes
      const uniqueClasses = new Map<string, { id: string, name: string, status?: number }>()

      const existingClasses = tagOptions.find(it => it.value === values.tagId)?.classes || [];
      existingClasses.forEach(it => {
        uniqueClasses.set(it.id, it);
      });

      const additionalClasses = values.classes || [];
      additionalClasses.forEach(it => {
        uniqueClasses.set(it.id, it);
      });

      values.classes = [...uniqueClasses].map(it => ({ id: it[0], name: it[1].name, status: it[1].status }));
    } else if (tagOptions.length > 1) {
      values.tokenName = values.tagName;
      values.tagName = `${values.tagName}_${(tagOptions.length - 1).toString().padStart(4, '0')}`;

    }

    const resultTag = await createOrUpdateTag({
      tagId: values.tagId || undefined,
      tagName: values.tagName,
      tokenName: values.tokenName,
      classes: values.classes?.filter(it => it.status).map(it =>
        ({
          id: it.id,
          status: it.status as number,
        })
      ),
    });

    onSubmit({
      tagId: resultTag.id,
      tagName: resultTag.name,
      classes: values.classes?.map(it =>
        ({
          id: it.id,
          name: it.name,
          status: it.status,
        })
      ),
    });
  }, [tagOptions, createOrUpdateTag, onSubmit]);

  return (
    <Box sx={{ height: '400px' }}>
      {loading
        ? <Loader />
        : (
          <Formik innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values }) => (
              <Form>
                <Box sx={{ p: 1 }}>
                  <Field
                    name="tagId"
                    component={renderRadioGroupField}
                    options={tagOptions}
                    radioProps={{
                      color: 'primary',
                    }}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        )
      }
    </Box>
  );
}
