import { useCallback, useEffect, useState } from 'react';
import merge from 'lodash/merge';
import { RecoilState, Resetter, useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';

import { cancellableQuery } from 'api/client';
import { CancellableController } from 'shared/cancellable-controller';
import { DataItem } from 'types';

export type UseNestedQueryResult<T = DataItem> = {
  loading: boolean;
  data: T[];
  reset: Resetter;
  fetchMore: (startIndex: number, stopIndex: number) => void;
};

export default function useNestedQuery<T = DataItem, K = any>(
  query: any,
  variables: any,
  state: RecoilState<T[]>,
  transform?: <T, K>(data: K) => T[],
): UseNestedQueryResult<T> {
  const [loading, setLoading] = useState(true);

  const getData = useRecoilCallback(({ set }) => async (variables: any, requireLoading: boolean, cancellable?: CancellableController) => {
    try {
      requireLoading && setLoading(true);
      const result = await cancellableQuery({
        query,
        variables,
        cancellable,
      })
      const data = transform ? transform<T, K>(result.data) : (result.data) as T[];
      set(state, data);
    } finally {
      requireLoading && setLoading(false);
    }
  }, [query, transform]);

  const data = useRecoilValue(state);
  const reset = useResetRecoilState(state);

  const fetchMore = useCallback((startIndex: number, stopIndex: number) => {
    const offset = parseInt(variables.options?.offset || 0, 10);
    const limit = variables.options?.limit;

      // do not fetch more data if stop index inside previous range
      if (stopIndex < offset + limit) return;

      const options: any = {
        offset: `${startIndex}`,
      };

      const newVariables = merge(variables, { options });
      getData(newVariables, false)
  }, [getData, variables]);

  useEffect(() => {
    const cancellable = new CancellableController();
    getData(variables, true, cancellable);

    return () => {
      cancellable.abort();
    };
  }, [getData, variables]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return {
    loading,
    data,
    reset,
    fetchMore,
  };
}
