import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_CLASS_TAGS_CONSENSUS_ANNOTATIONS,
  QUERY_CLASS_TAGS_OPTIONS,
  QUERY_CLASS_TAGS_PREDICTIONS,
} from 'methods/tag-has-class/api';
import {
  Class_Tags_Consensus_Annotations_selector,
  Class_Tags_Options_selector,
  Class_Tags_Predictions_selector,
} from 'modules/classes/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.classes?.[0]?.Tags_Consensus_Annotations || [], []);

  return useNestedQuery(
    QUERY_CLASS_TAGS_CONSENSUS_ANNOTATIONS,
    variables,
    Class_Tags_Consensus_Annotations_selector,
    transform,
  );
}

export const usePredictionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.classes?.[0]?.Tags_Predictions || [], []);

  return useNestedQuery(
    QUERY_CLASS_TAGS_PREDICTIONS,
    variables,
    Class_Tags_Predictions_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.classes?.[0]?.Tags_Options || [], []);

  return useNestedQuery(
    QUERY_CLASS_TAGS_OPTIONS,
    variables,
    Class_Tags_Options_selector,
    transform,
  );
}
