export const navItems = [
  {
    label: "Phrases",
    path: "/phrases",
    active: false,
  },
  {
    label: "Tokens",
    path: "/tokens",
    active: true,
  },
  {
    label: "Morphs",
    path: "/morphs",
    active: false,
  },
  {
    label: "Morphemes",
    path: "/morphemes",
    active: false,
  },
  {
    label: "Tags",
    path: "/tags",
    active: true,
  },
  {
    label: "Classes",
    path: "/classes",
    active: true,
  },
  {
    label: "Clusters",
    path: "/clusters",
    active: true,
  },
  {
    label: "Semantic Entities",
    path: "/semantic-entities",
    active: true,
  },
  {
    label: "Catalog-Items",
    path: "/catalog-items",
    active: false,
  },
  {
    label: "Product-Items",
    path: "/product-items",
    active: false,
  },
  {
    label: "Specification-Items",
    path: "/item-specifications",
    active: true,
  },
  {
    label: "Accounts",
    path: "/accounts",
    active: false,
  },
  {
    label: "Contacts",
    path: "/contacts",
    active: false,
  },
];
