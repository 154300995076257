import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import merge from 'lodash/merge';

import { DataItem } from 'types';

import { ApplicationApiItem, Options, Query } from './types';

export default function useApplicationApiLazyData<T extends ApplicationApiItem>(
  query: Query,
  field: string,
  { nameField = 'name' }: Options = {},
) {
  const [getRawData, { loading, data: rawData }] = useLazyQuery(query, { context: { apiName: 'applicationApi' } });
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    if (loading) {
      setData([]);
    } else {
      setData(
        (rawData?.[field]?.edges || []).map((item: T) => ({
          id: item.node._id,
          name: item.node.name,
        })),
      );
    }
  }, [loading, rawData, field, setData]);

  const getData = useCallback(
    (search?: string, args?: any) => {
      if (!!search) {
        // requestId is needed to avoid cache issues
        const variables = merge(args, {
          [nameField]: search,
          requestId: new Date().valueOf(),
        });

        getRawData({ variables });
      } else {
        setData([]);
      }
    },
    [nameField, getRawData, setData],
  );

  return {
    loading,
    data,
    getData,
  };
}
