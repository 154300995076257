import { useAnnotation } from 'hooks/useAnnotation';
import { CONFIRM_CLASS_HAS_CLUSTER_OPTION, DECLINE_CLASS_HAS_CLUSTER_OPTION } from 'methods/class-has-cluster/api';
import { AnnotationConfig, DataItem } from 'types';

type UseClassHasClusterAnnotationsParams = {
  parentItem: DataItem;
}

type UseClassHasClusterAnnotationsOptions = {
  onConfirmCompleted?: (result: unknown, item: DataItem) => void;
  onDeclineCompleted?: (result: unknown, item: DataItem) => void;
};

type UseClassHasClusterAnnotationsResult = {
  confirmAnnotation: (item: DataItem) => void;
  declineAnnotation: (item: DataItem) => void;
};

export default function useClassHasClusterAnnotations(
  annotationConfig: AnnotationConfig,
  params: UseClassHasClusterAnnotationsParams,
  options: UseClassHasClusterAnnotationsOptions = {},
): UseClassHasClusterAnnotationsResult {
  const { parentItem } = params;
  const { onConfirmCompleted, onDeclineCompleted } = options;

  const confirmAnnotation = useAnnotation(annotationConfig, CONFIRM_CLASS_HAS_CLUSTER_OPTION, parentItem, { onCompleted: onConfirmCompleted });
  const declineAnnotation = useAnnotation(annotationConfig, DECLINE_CLASS_HAS_CLUSTER_OPTION, parentItem, { onCompleted: onDeclineCompleted });

  return {
    confirmAnnotation,
    declineAnnotation,
  }
}
