import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';

import { AssignToContactParams } from 'hooks/useAssignToContact';
import { ASSIGN_TOKEN_HAS_MORPH_TO_CONTACT } from 'methods/token-has-morph/api';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { AssignMethodConfig, ContactItem, DataItem } from 'types';

type useAssignTokenHasMorphToContactOptions = {
  onCompleted?: (result: unknown, items: DataItem[]) => void;
};

export default function useAssignTokenHasMorphToContact(
  config: AssignMethodConfig,
  languageId: number,
  parentItem: DataItem,
  options: useAssignTokenHasMorphToContactOptions = {},
) {
  const { onCompleted: onAssignMethodToContactCompleted } = options;

  const { user } = useAuth();
  const currentRef = useRef<boolean>(false);
  const itemsRef = useRef<DataItem[] | null>(null);

  const onCompleted = useCallback((result: any) => {
    if (!currentRef.current) return;

    onAssignMethodToContactCompleted?.(result, itemsRef.current as DataItem[])
    currentRef.current = false;
    itemsRef.current = null;
  }, [onAssignMethodToContactCompleted]);

  const [mutate] = useMutation(ASSIGN_TOKEN_HAS_MORPH_TO_CONTACT, { onCompleted });
  const assignMethodToContact = useCallback(
    ({ dataItems, contacts }: AssignToContactParams) => {
      itemsRef.current = dataItems;
      currentRef.current = contacts.some(c => c.id === user?.contactId);

      const input = {
        assets: dataItems.map((dataItem: DataItem) => ({
          base_asset_id: parentItem.id,
          target_asset_ids: dataItem.Token_has_Morph_sequence?.map(x => x.id),
        })),
        language: languageId,
        contact_ids: contacts.map((contact: ContactItem) => contact.id),
      };
      const sort = [
        {
          edge: {
            morph_position_in_token: 'ASC'
          }
        }
      ]

      mutate({
        variables: {
          input,
          sort,
        },
      });
    },
    [languageId, parentItem, user, mutate],
  );

  return assignMethodToContact;
}
