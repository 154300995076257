import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { useAuth } from 'modules/auth/hooks/useAuth';

interface AuthenticatedProps {
  children: ReactNode;
}

export const Authenticated: FC<AuthenticatedProps> = (props) => {
  const {children} = props;
  const auth = useAuth();
  let history = useHistory();
  const [verified, setVerified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

    if (!auth.isAuthenticated) {
      // TODO: query: {backTo: router.asPath}
      history.push('/auth/login')
    } else {
      if (auth.user === null) {
        auth.logout();
        return;
      }

      setVerified(true);

      enqueueSnackbar('You are successfully authenticated!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        autoHideDuration: 2000,
      });
    }
  }, [auth, history, enqueueSnackbar]);

  if (!verified) {
    return <></>;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};
