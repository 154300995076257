import config from 'configuration';
import { MorphSortOption } from 'modules/morphs/api';
import { DataItem, LanguageItem } from 'types';

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: MorphSortOption;
};

export type FilterParamsType = {
  morph?: DataItem | null;
  language?: LanguageItem | null;
  countCharactersMin?: number;
  countCharactersMax?: number;
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export type NameParamsType = {
  id?: string
  name: string;
  language: LanguageItem | null;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  morph: null,
  countCharactersMin: 0,
  countCharactersMax: 0,
  onlyAssigned: false,
  onlyDone: false,
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: MorphSortOption.BY_COUNT_OF_INCOMING_TOKEN_HAS_MORPH,
};

export const INITIAL_NAME_PARAMS: NameParamsType = {
  name: '',
  language: config.defaultLanguage,
};

export const SORT_OPTIONS = [
  {
    value: MorphSortOption.BY_COUNT_OF_INCOMING_TOKEN_HAS_MORPH,
    label: 'by count of incoming "Token has Morph"',
  },
  {
    value: MorphSortOption.BY_COUNT_OF_CHARACTERS,
    label: 'by count of characters',
  },
];
