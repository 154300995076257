import { useCallback, useRef } from 'react'
import { useMutation } from '@apollo/client';

import { CONFIRM_TOKEN_JOIN_OPTION_V2, DECLINE_TOKEN_JOIN_OPTION_V2 } from 'methods/token-join/api';
import { DataItem } from 'types';

type UseTokenJoinAnnotationsOptions = {
  onConfirmCompleted?: (result: unknown, item: DataItem) => void;
  onDeclineCompleted?: (result: unknown, item: DataItem) => void;
};

type UseTokenJoinAnnotationsResult = {
  confirmAnnotation?: (item: DataItem) => void;
  declineAnnotation?: (item: DataItem) => void;
};

export default function useTokenJoinAnnotations(
  options: UseTokenJoinAnnotationsOptions = {},
): UseTokenJoinAnnotationsResult {
  const ref = useRef<DataItem | null>(null);

  const {
    onConfirmCompleted: onConfirmAnnotationCompleted,
    onDeclineCompleted: onDeclineAnnotationCompleted,
  } = options;

  const onConfirmCompleted = useCallback((result: unknown) => {
    onConfirmAnnotationCompleted?.(result, ref.current as DataItem);
    ref.current = null;
  }, [onConfirmAnnotationCompleted]);
  const [confirm] = useMutation(CONFIRM_TOKEN_JOIN_OPTION_V2, { onCompleted: onConfirmCompleted });

  const onDeclineCompleted = useCallback((result: unknown) => {
    onDeclineAnnotationCompleted?.(result, ref.current as DataItem);
    ref.current = null;
  }, [onDeclineAnnotationCompleted]);
  const [decline] = useMutation(DECLINE_TOKEN_JOIN_OPTION_V2, { onCompleted: onDeclineCompleted });

  const confirmAnnotation = useCallback(
    (item: DataItem) => {
      ref.current = item;
      confirm({
        variables: {
          input: {
            id: item.id,
            name: item.name,
          },
        },
      })
    },
    [confirm],
  );

  const declineAnnotation = useCallback(
    (item: DataItem) => {
      ref.current = item;
      decline({
        variables: {
          input: {
            id: item.id,
            name: item.name,
          },
        },
      })
    },
    [decline],
  );

  return {
    confirmAnnotation,
    declineAnnotation,
  };
}
