import { ReactNode, useCallback, useMemo, useState } from "react";

import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import { AnnotationMode } from "modules/item-specifications/constants";
import { useItemSpecificationContext } from 'modules/item-specifications/contexts/ItemSpecificationContext';

import AnnotationModeGroup from './AnnotationModeGroup'
import useStyles from './Header.styles';
import useArrowNavigate from './useArrowNavigate.hook';

type Props = {
  children: (props: HeaderRenderProps) => ReactNode;
};

type HeaderRenderProps = {
  annotationMode: AnnotationMode;
  helpers: HeaderRenderHelpers;
};

type HeaderRenderHelpers = {
  setDirty: (newValue: boolean) => void;
}

export default function Header({ children }: Props) {
  const [dirty, setDirty] = useState(false);
  const classes = useStyles({ dirty });

  const [annotationMode, setAnnotationMode] = useState(AnnotationMode.Method_Phrase_Tag);
  const { itemApiRef } = useItemSpecificationContext();
  const { hasNext, hasPrevious, arrowNavigate } = useArrowNavigate();

  const save = useCallback(() => {
    itemApiRef.current.saveAnnotations?.();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const helpers = useMemo(() =>
    ({
      setDirty,
    }),
    [],
  );

  return (
    <>
      <Card className={classes.container}>
        <CardHeader
          title={
            <AnnotationModeGroup
              mode={annotationMode}
              onSelect={setAnnotationMode}
            />
          }
          action={
            <>
              <Button
                size="small"
                className={classes.buttonArrow}
                disabled={!hasPrevious}
                onClick={() => arrowNavigate('previous')}
              >
                <ArrowLeft />
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.buttonSave}
                onClick={save}>
                Save
              </Button>

              <Button
                size="small"
                className={classes.buttonArrow}
                disabled={!hasNext}
                onClick={() => arrowNavigate('next')}
              >
                <ArrowRight />
              </Button>
            </>
          }
          classes={{
            action: classes.actionsContainer,
          }}
        />
      </Card>
      {children({ annotationMode, helpers })}
    </>
  )
}
