import { memo } from 'react';

import { darkTheme, lightTheme } from 'components/icons/constants';

type Props = {
  light?: boolean;
};

function RepeatIcon({ light }: Props) {
  const theme = light ? lightTheme : darkTheme;
  const opacity = theme.opacity.defaults;
  const fill = theme.fill.defaults;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16">
      <g clipRule="evenodd" fill={fill} fillRule="evenodd" opacity={opacity}>
        <path d="M6 16a1 1 0 0 0 1-1V7h16.6l-3.26 3.3c-.4.39-.4 1.03 0 1.42a1 1 0 0 0 1.42 0l4.94-5c.4-.4.4-1.03 0-1.42l-4.94-5a1 1 0 0 0-1.42 0c-.4.39-.4 1.03 0 1.42L23.57 5H6a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1zM26 16a1 1 0 0 0-1 1v8.02H8.37l3.29-3.33c.39-.4.39-1.03 0-1.43a1 1 0 0 0-1.43 0l-4.91 4.99c-.42.4-.42 1.07 0 1.47l4.92 4.98a1 1 0 0 0 1.42 0c.4-.4.4-1.03 0-1.43l-3.2-3.25H26a1 1 0 0 0 1-1V17a1 1 0 0 0-1-1z" />
      </g>
    </svg>
  );
}

export default memo(RepeatIcon);
