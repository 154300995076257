import { useSetRecoilState } from 'recoil';

import { useAssignMethodToContactOptions }  from 'hooks/useAssignMethodToContact';
import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ContextAction, useContextActions2 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import useAssignTokenHasMorphToContact from 'methods/token-has-morph/hooks/useAssignTokenHasMorphToContact';
import useUpdateTokenEntities from 'modules/morphs/hooks/useUpdateTokenEntities';
import {
  Morph_Associated_Tokens_Consensus_Annotations_Entities_State,
  Morph_Associated_Tokens_Predictions_Entities_State,
} from 'modules/morphs/store';
import { ASSIGN_TOKEN_TO_CONTACT, MARK_TOKEN_AS_DONE, MARK_TOKEN_AS_NOT_DONE } from 'modules/tokens/api';
import { Edge_Direction, Methods } from 'shared/constants';
import { AssignMethodConfig, DataItem } from 'types';

const assignMethodConfig: AssignMethodConfig = {
  method: Methods.Token_has_Morph,
  direction: Edge_Direction.INVERSE,
};

type useContextActionsParams = {
  languageId: number;
  parentItem: DataItem;
};

export default function useContextActions(params: useContextActionsParams): ContextAction[] {
  const { languageId, parentItem } = params;

  const update_Associated_Tokens_Consensus_Annotations = useSetRecoilState(Morph_Associated_Tokens_Consensus_Annotations_Entities_State);
  const update_Associated_Tokens_Predictions = useSetRecoilState(Morph_Associated_Tokens_Predictions_Entities_State);

  const onAssignToContactCompleted = useUpdateTokenEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_TOKEN_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateTokenEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_TOKEN_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateTokenEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_TOKEN_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const settersOrUpdaters = [
    update_Associated_Tokens_Consensus_Annotations,
    update_Associated_Tokens_Predictions,
    null,
    null,
  ] as const;
  const assignMethodToContactOptions = useAssignMethodToContactOptions({ settersOrUpdaters });
  const assignMethodToContact = useAssignTokenHasMorphToContact(assignMethodConfig, languageId, parentItem, assignMethodToContactOptions);

  return useContextActions2('/tokens', { assignToContact, assignMethodToContact, markAsDone, markAsNotDone });
}
