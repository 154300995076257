import { useCallback, useEffect, useState } from 'react';
import merge from 'lodash/merge';
import { RecoilState, useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';

import { apiClient } from 'api/client';
import { DataItem } from 'types';

export default function useApplicationApiQuery<T = DataItem, K = any>(
  query: any,
  variables: any,
  state: RecoilState<T[]>,
  transform?: <T, K>(data: K) => T[],
  queryName?: string
) {
  const [ loading, setLoading ] = useState(true);
  const data = useRecoilValue(state);
  const reset = useResetRecoilState(state);
  const [ nextCursor, setNextCursor ] = useState<string | null>(null);

  const getData = useRecoilCallback(({set}) => async (variables: any, requireLoading?: boolean) => {
    requireLoading && setLoading(true);

    const result = await apiClient.query({query, variables, context: { apiName: 'applicationApi' }});
    const data = transform ? transform<T, K>(result.data) : (result.data as T[]);

    if (queryName && result.data[queryName].pageInfo.hasNextPage) {
      setNextCursor(result.data[queryName].pageInfo.endCursor);
    } else {
      setNextCursor(null)
    }
    set(state, data);

    requireLoading && setLoading(false);
  }, [ query, transform, setLoading ]);

  const fetchMore = useCallback(() => {
    if (nextCursor && variables.after !== nextCursor) {
      getData(merge(variables, {after: nextCursor}), false)
    }
  }, [ getData, variables, nextCursor ]);

  useEffect(() => {
    getData(variables, true)
  }, [ getData, variables ]);

  useEffect(() => {
    return () => reset()
  }, [ reset ]);

  return {
    loading,
    data,
    reset,
    fetchMore,
  };
}
