import { gql } from '@apollo/client';

export enum ContactSortOption {
  BY_COUNT_IN_PHRASES_ALL_POSITIONS = 'by_count_in_Phrases_all_positions',
}

export type ContactSortOptionsInput = {
  option: ContactSortOption;
  direction: 'DESC' | 'ASC';
};

export type ContactFilterWhereInput = {
  name_STARTS_WITH?: string;
};

export type ContactFilterOptionsInput = {
  sort?: ContactSortOptionsInput;
  offset?: number;
  limit?: number;
};

export type ContactFilterInput = {
  where?: ContactFilterWhereInput;
  options?: ContactFilterOptionsInput;
};

export const QUERY_CONTACTS = gql`
  query Contacts($where: Contact_Filter_Where_Input, $options: Contact_Filter_Options_Input) {
    Contacts(where: $where, options: $options) {
      id
      name
      contact_weight
    }
  }
`;

export const SEARCH_CONTACTS = gql`
  query Contacts($where: Contact_Filter_Where_Input, $options: Contact_Filter_Options_Input) {
    Contacts(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: Create_Contact_Input!) {
    create_Contact(input: $input) {
      contacts {
        id
        name
        contact_weight
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($input: Update_Contact_Input!) {
    update_Contact(input: $input) {
      contacts {
        id
        name
        contact_weight
      }
    }
  }
`;
