import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Tag_Associated_Tokens_Consensus_Annotations_Entities_State,
  Tag_Associated_Tokens_Consensus_Annotations_Ids_State,
  Tag_Associated_Tokens_Options_Entities_State,
  Tag_Associated_Tokens_Options_Ids_State,
  Tag_Associated_Tokens_Predictions_Entities_State,
  Tag_Associated_Tokens_Predictions_Ids_State,
} from './atoms';

export const Tag_Associated_Tokens_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Associated_Tokens_Consensus_Annotations/list',
  Tag_Associated_Tokens_Consensus_Annotations_Entities_State,
  Tag_Associated_Tokens_Consensus_Annotations_Ids_State,
);

export const Tag_Associated_Tokens_Predictions_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Associated_Tokens_Predictions/list',
  Tag_Associated_Tokens_Predictions_Entities_State,
  Tag_Associated_Tokens_Predictions_Ids_State,
);

export const Tag_Associated_Tokens_Options_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Associated_Tokens_Options/list',
  Tag_Associated_Tokens_Options_Entities_State,
  Tag_Associated_Tokens_Options_Ids_State,
);
