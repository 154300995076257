import { useSetRecoilState } from 'recoil';

import { useAssignMethodToContactOptions }  from 'hooks/useAssignMethodToContact';
import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { useContextActions2 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import useAssignPhraseHasMeaningToContact from 'methods/phrase-has-meaning/hooks/useAssignPhraseHasMeaningToContact';
import useUpdateSemanticEntitiesEntities from 'modules/phrases/hooks/useUpdateSemanticEnttitiesEntities';
import {
  Phrases_Semantic_Entities_Consensus_Annotations_Entities_State,
  Phrases_Semantic_Entities_Predictions_Entities_State,
} from 'modules/phrases/store';
import { ASSIGN_SE_TO_CONTACT, MARK_SE_AS_DONE, MARK_SE_AS_NOT_DONE } from 'modules/semantic-entities/api';
import { Edge_Direction, Methods } from 'shared/constants';
import { AssignMethodConfig, DataItem } from 'types';

const assignMethodConfig: AssignMethodConfig = {
  method: Methods.Phrase_has_Meaning,
  direction: Edge_Direction.DIRECT,
};

type useContextActionsParams = {
  languageId: number;
  parentItem: DataItem;
};

export default function useContextActions(params: useContextActionsParams) {
  const { languageId, parentItem } = params;

  const update_Semantic_Entities_Consensus_Annotations = useSetRecoilState(Phrases_Semantic_Entities_Consensus_Annotations_Entities_State);
  const update_Semantic_Entities_Predictions = useSetRecoilState(Phrases_Semantic_Entities_Predictions_Entities_State);

  const onAssignToContactCompleted = useUpdateSemanticEntitiesEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_SE_TO_CONTACT, { onCompleted: onAssignToContactCompleted});

  const onMarkAsDoneCompleted = useUpdateSemanticEntitiesEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_SE_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateSemanticEntitiesEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_SE_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const settersOrUpdaters = [
    update_Semantic_Entities_Consensus_Annotations,
    update_Semantic_Entities_Predictions,
    null,
    null,
  ] as const;
  const { onCompleted: onAssignMethodToContactCompleted } = useAssignMethodToContactOptions({ settersOrUpdaters });
  const assignMethodToContact = useAssignPhraseHasMeaningToContact(assignMethodConfig, languageId, parentItem, { onCompleted: onAssignMethodToContactCompleted });

  return useContextActions2('/semantic-entities', {
    assignToContact,
    assignMethodToContact,
    markAsDone,
    markAsNotDone,
  });
}

