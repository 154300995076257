import { memo } from 'react';

import { darkTheme, lightTheme } from 'components/icons/constants';

type Props = {
  light?: boolean;
  fill?: string;
};

function AddIcon({ light, fill: inFill }: Props) {
  const theme = light ? lightTheme : darkTheme;
  const fill = inFill || theme.fill.defaults;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1428 0H2.85713C1.27995 0.001875 0.001875 1.27995 0 2.85713V13.1428C0.001875 14.72 1.27995 15.9981 2.85713 16H13.1428C14.72 15.9981 15.9981 14.72 16 13.1428V2.85713C15.9981 1.27995 14.72 0.001875 13.1428 0ZM14.8571 13.1428C14.8571 14.0896 14.0896 14.8571 13.1428 14.8571H2.85713C1.91036 14.8571 1.14285 14.0896 1.14285 13.1428V2.85713C1.14285 1.91036 1.91036 1.14285 2.85713 1.14285H13.1428C14.0896 1.14285 14.8571 1.91036 14.8571 2.85713V13.1428Z"
        fill={fill}
      />
      <path
        d="M10.8569 7.42821H8.57115V5.14249C8.57115 4.82688 8.31532 4.57104 7.99971 4.57104C7.68411 4.57104 7.42827 4.82688 7.42827 5.14249V7.42821H5.14258C4.82697 7.42821 4.57114 7.68405 4.57114 7.99966C4.57114 8.31526 4.82697 8.57106 5.14258 8.57106H7.42831V10.8568C7.42831 11.1724 7.68414 11.4282 7.99975 11.4282C8.31535 11.4282 8.57115 11.1724 8.57115 10.8568V8.57106H10.8569C11.1725 8.57106 11.4283 8.31522 11.4283 7.99962C11.4283 7.68402 11.1725 7.42821 10.8569 7.42821Z"
        fill={fill}
      />
    </svg>
  );
}

export default memo(AddIcon);
