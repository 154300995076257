import { gql } from '@apollo/client';

export enum TokenSortOption {
  BY_COUNT_IN_PHRASES_ALL_POSITIONS = 'by_count_in_Phrases_all_positions',
  BY_COUNT_IN_PHRASES_FIRST_POSITION = 'by_count_in_Phrases_first_position',
  BY_COUNT_OF_METHOD_TOKEN_JOIN = 'by_count_of_Method_Token_Join',
  BY_COUNT_OF_METHOD_TOKEN_JOIN_ASSIGNED_TO_USER = 'by_count_of_Method_Token_Join_assigned_to_User',
  BY_COUNT_OF_METHOD_TOKEN_HAS_MEANING = 'by_count_of_Method_Token_has_Meaning',
  BY_COUNT_OF_METHOD_TOKEN_HAS_MEANING_ASSIGNED_TO_USER = 'by_count_of_Method_Token_has_Meaning_assigned_to_User',
}

export type TokenSortOptionsInput = {
  option: TokenSortOption;
  direction: 'DESC' | 'ASC';
};

export type TokenFilterWhereInput = {
  name_STARTS_WITH?: string;
  name_STARTS_WITH_FULL_TEXT?: string;
  name_STARTS_WITH_REGEXP?: string;
  name_STARTS_WITH_Token_Join?: string;
  token_type_WITHIN?: number[];
  is_part_of_Clusters?: string[];
  only_assigned?: boolean;
  only_done?: boolean;
};

export type TokenFilterOptionsInput = {
  sort?: TokenSortOptionsInput;
  offset?: number;
  session?: string;
  limit?: number;
};

export type TokenFilterInput = {
  where?: TokenFilterWhereInput;
  options?: TokenFilterOptionsInput;
};

export const QUERY_TOKENS = gql`
  query Tokens($where: Token_Filter_Where_Input, $options: Token_Filter_Options_Input) {
    Tokens(where: $where, options: $options) {
      id
      name
      active_contact_is_assigned_to
      active_contact_is_done_by
      active_contact_methods_count_is_assigned
      count_all_positions_in_phrases
      count_first_position_in_phrases
    }
  }
`;

export const QUERY_CLASS_TOKENS = gql`
  query Class_Associated_Tokens($id: BigInt, $where: Token_Filter_Where_Input, $options: Token_Filter_Options_Input) {
    classes(where: { id: $id }) {
      Associated_Tokens(where: $where, options: $options) {
        id
        name
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
      }
    }
  }
`;

export const SEARCH_TOKENS = gql`
  query Tokens($where: Token_Filter_Where_Input, $options: Token_Filter_Options_Input) {
    Tokens(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const ASSIGN_TOKEN_TO_CONTACT = gql`
  mutation Assign_Token_to_Contact($input: Assign_to_Contact_Input!) {
    assign_Token_to_Contact(input: $input) {
      id
    }
  }
`;

export const MARK_TOKEN_AS_DONE = gql`
  mutation mark_Token_as_done($ids: [BigInt!]!) {
    mark_Token_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_TOKEN_AS_NOT_DONE = gql`
  mutation mark_Token_as_not_done($ids: [BigInt!]!) {
    mark_Token_as_not_done(ids: $ids) {
      id
    }
  }
`;
