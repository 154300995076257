import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Phrases_selector } from 'modules/phrases/store';

export default function useResetPhrases() {
  const reset = useResetRecoilState(Phrases_selector);

  return useCallback(() => {
    reset();
  }, [reset]);
}
