import { PropsWithChildren, useContext } from 'react';

import DataGroupContext from './DataGroupContext';

type Structure = {
  menuId: string;
};

type Props = PropsWithChildren<{
  structure: Structure;
}>;

export function useDataGroupContext() {
  return useContext(DataGroupContext);
}

const DataGroupProvider = ({ children, structure }: Props) => {
  return <DataGroupContext.Provider value={structure}>{children}</DataGroupContext.Provider>;
};

export default DataGroupProvider;
