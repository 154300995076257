import { useMemo } from 'react';

import DataPage, { DataPageRenderProps } from 'components/DataPage';
import useModalWithValue from 'hooks/useModalWithValue';
import MainLayout from 'layouts/MainLayout';
import { Authenticated } from 'modules/auth/components/Authenticated';
import FilterModal from 'modules/semantic-entities/components/FilterModal';
import NameModal from 'modules/semantic-entities/components/NameModal';
import SortModal from 'modules/semantic-entities/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_NAME_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/semantic-entities/constants';
import useCreateSemanticEntity from 'modules/semantic-entities/hooks/useCreateSemanticEntity';
import useResetSemanticEntities from 'modules/semantic-entities/hooks/useResetSemanticEntities';
import useSemanticEntitiesQuery from 'modules/semantic-entities/hooks/useSemanticEntitiesQuery';
import useSemanticEntityContextActions from 'modules/semantic-entities/hooks/useSemanticEntityContextActions';
// import AssociatedNextTokensDataSection from 'modules/semantic-entities/sections/AssociatedNextTokensDataSection';
// import NextSemanticEntitiesDataSection from 'modules/semantic-entities/sections/NextSemanticEntitiesDataSection';
// import NextTagsDataSection from 'modules/semantic-entities/sections/NextTagsDataSection';
// import PhrasesDataSection from 'modules/semantic-entities/sections/PhrasesDataSection';
import TagsDataSection from 'modules/semantic-entities/sections/TagsDataSection';
import { transformVariables } from 'modules/semantic-entities/utils';
import DataPageProvider from 'providers/DataPageProvider';

export default function SemanticEntities() {
  const resetSemanticEntities = useResetSemanticEntities();

  const {
    // open: sortModalOpen,
    value: sortModalValue,
    openModal: openSortModal,
    closeModal: closeSortModal,
    setValue: setSortModalValue,
  } = useModalWithValue({ initialValue: INITIAL_SORT_PARAMS, onValueChanged: resetSemanticEntities });

  const {
    open: filterModalOpen,
    value: filterModalValue,
    meta: filterMeta,
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    setValue: setFilterModalValue,
    resetValue: resetFilter,
  } = useModalWithValue({ initialValue: INITIAL_FILTER_PARAMS, onValueChanged: resetSemanticEntities });

  const {
    open: nameModalOpen,
    value: nameModalValue,
    openModal: openNameModal,
    closeModal: closeNameModal,
  } = useModalWithValue({ initialValue: INITIAL_NAME_PARAMS });

  const { createSemanticEntity } = useCreateSemanticEntity({
    onCreate() {
      closeNameModal();
    },
  })

  const actions = useMemo(
    () => ({
      add: {
        handler: openNameModal,
      },
      sort: {
        handler: openSortModal,
      },
      filter: {
        meta: filterMeta,
        handler: openFilterModal,
      },
    }),
    [openNameModal, openSortModal, openFilterModal, filterMeta],
  );
  const contextActions = useSemanticEntityContextActions()
  const dataVariables = useMemo(() => transformVariables(filterModalValue, sortModalValue, {}), [filterModalValue, sortModalValue]);

  return (
    <DataPageProvider>
      <DataPage
        title="Semantic Entities"
        useQuery={useSemanticEntitiesQuery}
        dataVariables={dataVariables}
        actions={actions}
        contextActions={contextActions}
      >
        {({ currentItem }: DataPageRenderProps) => (
          <>
            {currentItem && (
              <>
                {/* <PhrasesDataSection parentItem={currentItem} /> */}
                <TagsDataSection parentItem={currentItem} />
                {/* <NextSemanticEntitiesDataSection parentItem={currentItem} />
                <NextTagsDataSection parentItem={currentItem} />
                <AssociatedNextTokensDataSection parentItem={currentItem} /> */}
              </>
            )}

            {false && (
              <SortModal
                value={sortModalValue}
                options={SORT_OPTIONS}
                onChange={setSortModalValue}
                onClose={closeSortModal}
              />
            )}

            {filterModalOpen && (
              <FilterModal
                value={filterModalValue}
                onChange={setFilterModalValue}
                onClose={closeFilterModal}
                onReset={resetFilter}
              />
            )}

            {nameModalOpen && (
              <NameModal
                value={nameModalValue}
                onChange={createSemanticEntity}
                onClose={closeNameModal}
              />
            )}
          </>
        )}
      </DataPage>
    </DataPageProvider>
  );
}

SemanticEntities.getLayout = (page: any) => (
  <Authenticated>
    <MainLayout>
      {page}
    </MainLayout>
  </Authenticated>
);
