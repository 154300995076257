import useStyles from './styles';

type Props = {
  children: React.ReactNode;
};

export default function OutlinedWrapper({ children }: Props) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}
