import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_PHRASE_SES_CONSENSUS_ANNOTATIONS,
  QUERY_PHRASE_SES_OPTIONS,
  QUERY_PHRASE_SES_PREDICTIONS,
} from 'methods/phrase-has-meaning/api';
import {
  Phrases_Semantic_Entities_Consensus_Annotations_selector,
  Phrases_Semantic_Entities_Options_selector,
  Phrases_Semantic_Entities_Predictions_selector,
} from 'modules/phrases/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.phrases?.[0]?.Semantic_Entities_Consensus_Annotations || [], []);

  return useNestedQuery(
    QUERY_PHRASE_SES_CONSENSUS_ANNOTATIONS,
    variables,
    Phrases_Semantic_Entities_Consensus_Annotations_selector,
    transform,
  );
}

export const usePredictionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.phrases?.[0]?.Semantic_Entities_Predictions || [], []);

  return useNestedQuery(
    QUERY_PHRASE_SES_PREDICTIONS,
    variables,
    Phrases_Semantic_Entities_Predictions_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.phrases?.[0]?.Semantic_Entities_Options || [], []);

  return useNestedQuery(
    QUERY_PHRASE_SES_OPTIONS,
    variables,
    Phrases_Semantic_Entities_Options_selector,
    transform,
  );
}
