import { gql } from '@apollo/client';

// base asset extension
export const QUERY_TOKEN_MORPHS_CONSENSUS_ANNOTATIONS = gql`
  query Token_Morphs_Consensus_Annotations(
    $id: BigInt
    $where: Morph_Filter_Where_Input
    $options: Morph_Filter_Options_Input
    $consensus: Boolean = true
    $language: Int!
  ) {
    tokens(where: { id: $id }) {
      Morphs_Consensus_Annotations(where: $where, options: $options, consensus: $consensus, language: $language) {
        id
        name
        active_contact_method_annotation_status
        Token_has_Morph_sequence {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_TOKEN_MORPHS_PREDICTIONS = gql`
  query Token_Morphs_Predictions(
    $id: BigInt
    $where: Morph_Filter_Where_Input
    $options: Morph_Filter_Options_Input
    $language: Int!
  ) {
    tokens(where: { id: $id }) {
      Morphs_Predictions(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        Token_has_Morph_sequence {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_TOKEN_MORPHS_OPTIONS = gql`
  query Token_Morphs_Options(
    $id: BigInt
    $where: Morph_Filter_Where_Input
    $options: Morph_Filter_Options_Input
    $language: Int!
  ) {
    tokens(where: { id: $id }) {
      Morphs_Options(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        Token_has_Morph_sequence {
          id
          name
        }
      }
    }
  }
`;

// target asset extension
export const QUERY_MORPH_TOKENS_CONSENSUS_ANNOTATIONS = gql`
  query Morph_Associated_Tokens_Consensus_Annotations(
    $id: BigInt
    $where: Token_Filter_Where_Input
    $options: Token_Filter_Options_Input
    $consensus: Boolean = true
    $language: Int!
  ) {
    morphs(where: { id: $id }) {
      Associated_Tokens_Consensus_Annotations(
        where: $where
        options: $options
        consensus: $consensus
        language: $language
      ) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        consensus_status
        count_all_positions_in_phrases
        Token_has_Morph_sequence {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_MORPH_TOKENS_PREDICTIONS = gql`
  query Morph_Associated_Tokens_Predictions(
    $id: BigInt
    $where: Token_Filter_Where_Input
    $options: Token_Filter_Options_Input
    $language: Int!
  ) {
    morphs(where: { id: $id }) {
      Associated_Tokens_Predictions(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_has_Morph_sequence {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_MORPH_TOKENS_OPTIONS = gql`
  query Morph_Associated_Tokens_Options(
    $id: BigInt
    $where: Token_Filter_Where_Input
    $options: Token_Filter_Options_Input
    $language: Int!
  ) {
    morphs(where: { id: $id }) {
      Associated_Tokens_Options(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_has_Morph_sequence {
          id
          name
        }
      }
    }
  }
`;

// method node
export const ASSIGN_TOKEN_HAS_MORPH_TO_CONTACT = gql`
  mutation Assign_Token_has_Morph_to_Contact($input: Method_Token_has_Morph_Assign_to_Contact_Input!) {
    assign_Token_has_Morph_to_Contact(input: $input) {
      methodTokenHasMorphs {
        id
      }
    }
  }
`;

export const CONFIRM_TOKEN_HAS_MORPH_OPTION = gql`
  mutation Confirm_Method_Token_has_Morph_Option($input: Method_Token_has_Morph_Option_Eval_Input!) {
    confirm_Method_Token_has_Morph_option(input: $input) {
      methodTokenHasMorphs {
        id
        label_Token_has_Morph_Target_AssetConnection {
          edges {
            node {
              id
              name
            }
            morph_position_in_token
          }
        }
      }
    }
  }
`;

export const DECLINE_TOKEN_HAS_MORPH_OPTION = gql`
  mutation Decline_Method_Token_has_Morph_Option($input: Method_Token_has_Morph_Option_Eval_Input!) {
    decline_Method_Token_has_Morph_option(input: $input) {
      methodTokenHasMorphs {
        id
        label_Token_has_Morph_Target_AssetConnection {
          edges {
            node {
              id
              name
            }
            morph_position_in_token
          }
        }
      }
    }
  }
`;
