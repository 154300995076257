import { createContext, MutableRefObject, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

import useGetItemSpecification from 'modules/item-specifications/hooks/useGetItemSpecification';
import { ItemSpecification } from 'types';

type ItemSpecificationContextProps = {
  itemApiRef: MutableRefObject<ItemApiRef>;
  setItemSpecification: (item: ItemSpecification) => void;
};

type ItemApiRef = {
  saveAnnotations?: () => void;
};

type Props = {
  itemId?: string;
  children: (props: ItemSpecificationProviderRenderProps) => ReactNode;
};

type ItemSpecificationProviderRenderProps = {
  currentItem: ItemSpecification;
};

export const ItemSpecificationContext = createContext<ItemSpecificationContextProps>({
  itemApiRef: {
    current: {} as ItemApiRef,
  },
  setItemSpecification: () => null,
});

export function useItemSpecificationContext(): ItemSpecificationContextProps {
  return useContext(ItemSpecificationContext);
}

export const ItemSpecificationProvider = ({ itemId, children }: Props) => {
  const [currentItem, setCurrentItem] = useState<ItemSpecification | null>(null);
  const itemApiRef = useRef<ItemApiRef>({} as ItemApiRef);

  const { getItemSpecification } = useGetItemSpecification();
  const setItemSpecification = useCallback((item: ItemSpecification) => setCurrentItem(item), []);

  const ctx = {
    itemApiRef,
    setItemSpecification,
  };

  useEffect(() => {
    if (itemId) {
      getItemSpecification(itemId).then((item) => setCurrentItem(item));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return (
    <ItemSpecificationContext.Provider value={ctx}>
      {currentItem ? children({ currentItem }) : null}
    </ItemSpecificationContext.Provider>
  );
};
