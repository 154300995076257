import { useCallback } from 'react';

import { apiClient } from 'api/client'
import { CREATE_OR_UPDATE_TAGS, CreateOrUpdateTagInput, CreateOrUpdateTagResult } from 'modules/item-specifications/api/graphApi';

type UseCreateOrUpdateTagsResult = {
  createOrUpdateTags: (values: Array<Values>) => Promise<Array<CreateOrUpdateTagResult>>;
};

type Values = {
  tagId?: string;
  tagName: string;
  classes?: Array<{
    id: string;
    status: number;
  }>;
};

async function createOrUpdateTagsExecutor(input: Array<CreateOrUpdateTagInput>): Promise<Array<CreateOrUpdateTagResult>> {
  const result = await apiClient.mutate({
    mutation: CREATE_OR_UPDATE_TAGS,
    variables: {
      input,
    },
  });

  return result.data.Create_Or_Update_Tags;
}

export default function useCreateOrUpdateTags(): UseCreateOrUpdateTagsResult {
  const createOrUpdateTags = useCallback(
    async (values: Array<Values>) => {
      const input = values.map(value =>
        ({
          tag_id: value.tagId,
          tag_name: value.tagName,
          classes: value.classes?.map((it) =>
            ({
              id: it.id,
              status: it.status,
            })
          ),
        })
      )
      const result = await createOrUpdateTagsExecutor(input);

      return result;
    }, []);

  return {
    createOrUpdateTags,
  };
}
