import { Guest } from 'modules/auth/components/Guest';
import SignIn from 'modules/auth/components/SignIn';
import { useAuth } from 'modules/auth/hooks/useAuth';

export default function Login() {
  const { login } = useAuth();

  return (
    <SignIn login={login} />
  );
}

Login.getLayout = (page: any) => (
  <Guest>
    {page}
  </Guest>
);
