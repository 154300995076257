import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  headerRoot: {
    display: 'flex',
    padding: '10px 20px',
  },
  headerTitle: {
    fontWeight: 600,
  },
  headerAction: {
    marginTop: 0,
    marginBottom: 0,
  },
  content: {
    overflow: 'auto',
    height: 'calc(100% - 52px)',
  },
  actions: {
    display: 'flex',
    gap: 5,
  },
  actionButton: {
    borderWidth: 0.5,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    borderRadius: 2,
    padding: theme.spacing(1),
  },
}));

export default useStyles;
