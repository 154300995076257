import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import { CREATE_MORPH, UPDATE_MORPH } from 'modules/morphs/api';
import { Morphs_Entities_State, Morphs_Ids_State } from 'modules/morphs/store';
import { entitiesUpdaterFactory, idsUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type UseMorphParams = {
  onEdit?: (item: DataItem) => void;
  onCreateOrUpdate?: () => void;
};

type UseMorphResult = {
  editMorph: (item: DataItem) => void;
  createOrUpdateMorph: (values: any) => void;
};

export default function useMorph(params: UseMorphParams = {}): UseMorphResult {
  const { onEdit, onCreateOrUpdate } = params;

  const itemRef = useRef<DataItem | null>(null);
  const updateEntities = useSetRecoilState(Morphs_Entities_State);
  const updateIds = useSetRecoilState(Morphs_Ids_State);

  const onCreateCompleted = useCallback((result: any) => {
    const ids: string[] = result.create_Morph.morphs.map((x: DataItem) => x.id);
    const entries = (result.create_Morph.morphs as DataItem[]).map(x => [x.id, x, x] as const);

    const idsUpdater = idsUpdaterFactory(ids);
    const entitiesUpdater = entitiesUpdaterFactory(entries, true);

    updateEntities(entitiesUpdater);
    updateIds(idsUpdater);
  }, [
    updateEntities,
    updateIds,
  ]);
  const [create] = useMutation(CREATE_MORPH, { onCompleted: onCreateCompleted });

  const onUpdateCompleted = useCallback((result: any) => {
    const entries = (result.update_Morph.morphs as DataItem[]).map(x => [x.id, x] as const);
    const entitiesUpdater = entitiesUpdaterFactory(entries);
    updateEntities(entitiesUpdater);

  }, [updateEntities]);
  const [update] = useMutation(UPDATE_MORPH, { onCompleted: onUpdateCompleted });

  const editMorph = useCallback(
    (contextItem: DataItem) => {
      itemRef.current = contextItem;
      onEdit?.(itemRef.current);
    }, [onEdit]
  );

  const createOrUpdateMorph = useCallback(
    (values: any) => {
      if (itemRef.current) {
        update({
          variables: {
            input: {
              id: itemRef.current.id,
              name: values.name,
              language: values.language.id,
            }
          },
        });
      } else {
        create({
          variables: {
            input: {
              name: values.name,
              language: values.language.id,
            }
          },
        });
      }

      onCreateOrUpdate?.()
    }, [update, create, onCreateOrUpdate]
  );

  return {
    editMorph,
    createOrUpdateMorph,
  };
}
