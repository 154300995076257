import { ClassSortOption } from 'modules/classes/api';
import { DataItem } from 'types';

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: ClassSortOption;
};

export type FilterParamsType = {
  classes?: DataItem[];
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export type NameParamsType = {
  id?: string;
  name: string;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  classes: [],
  onlyAssigned: false,
  onlyDone: false,
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: ClassSortOption.BY_COUNT_OF_TAG_HAS_CLASS_EDGES,
};

export const INITIAL_NAME_PARAMS: NameParamsType = {
  name: '',
};

export const SORT_OPTIONS = [
  {
    value: ClassSortOption.BY_COUNT_OF_TAG_HAS_CLASS_EDGES,
    label: 'by count of "Tag has Class" edges',
  },
  {
    value: ClassSortOption.BY_COUNT_OF_TAG_HAS_CLASS_PREDICTIONS,
    label: 'by count of "Tag has Class" predictions',
  },
  {
    value: ClassSortOption.BY_COUNT_OF_TAG_HAS_CLASS_PREDICTIONS_ASSIGNED_TO_USER,
    label: 'by count of "Tag has Class" predictions assigned to user',
  },
];

export const BUILDING_MATERIAL_ID = 4;
export const BUILDING_MATERIAL = "Building Material";
export const ATTRIBUTE_ON_BUILDING_MATERIAL_ID = 5;
export const ATTRIBUTE_ON_BUILDING_MATERIAL = "Attribute on Building Material";
export const TRADES = "Trades (Leistungsbereiche)";
export const DIN_276_COST_GROUPS = "DIN 276 Cost Groups (DIN 276 Kostengruppen)";
export const GROUPS_OF_SIMILAR_WORK = "Groups of similar work items (Teilleistungsbereiche)";
