import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const Tag_SemanticEntities_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/tags/@atoms/SemanticEntities/entities',
  default: ImmutableMap(),
});

export const Tag_SemanticEntities_Ids_State = atom<string[]>({
  key: 'graph/tags/@atoms/SemanticEntities/ids',
  default: [],
});
