import { SemanticEntitySortOption } from 'modules/semantic-entities/api';
import { DataItem } from 'types';

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: SemanticEntitySortOption;
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: SemanticEntitySortOption.BY_COUNT_OF_RELATED_OFFERS,
};

export type FilterParamsType = {
  semanticEntities?: DataItem[];
  tags?: DataItem[];
  classes?: DataItem[];
  clusters?: DataItem[];
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  semanticEntities: [],
  tags: [],
  onlyAssigned: false,
  onlyDone: false,
};

export type SearchParamsType = {
  semanticEntity: DataItem | null;
};

export const INITIAL_SEARCH_PARAMS: SearchParamsType = {
  semanticEntity: null,
};

export type NameParamsType = {
  name: string;
};

export const INITIAL_NAME_PARAMS: NameParamsType = {
  name: '',
};

export const SORT_OPTIONS = [
  {
    value: SemanticEntitySortOption.BY_COUNT_OF_RELATED_OFFERS,
    label: 'by count of related Offers',
  },
  {
    value: SemanticEntitySortOption.BY_COUNT_OF_RELATED_DELIVERABLES,
    label: 'by count of related Deliverables',
  },
  {
    value: SemanticEntitySortOption.BY_COUNT_OF_PHRASE_HAS_MEANING_EDGES,
    label: 'by count of "Phrase has Meaning" edges',
  },
];
