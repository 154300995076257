import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    flexGrow: 1,
    flexBasis: 0,
    width: 'calc(100%/2)',
  },
}));

export default useStyles;
