import { PropsWithChildren, useCallback, useContext, useState } from 'react';
import { Map as ImmutableMap } from 'immutable';

import { keyId as defaultKeyId } from 'shared/utils';
import { DataItem, KeyField } from 'types';

import DataSelectionContext, { DataSelectionContextType } from './DataSelectionContext';

export const MAIN_SECTION = 'Main_Page';

export function useDataSelectionContext(): DataSelectionContextType {
  return useContext(DataSelectionContext) as DataSelectionContextType;
}

type Props = PropsWithChildren<{
  keyField?: KeyField | Array<KeyField>;
}>;

const DataSelectionProvider = (props: Props) => {
  const { keyField, children } = props;

  const [currentItem, _setCurrentItem] = useState<DataItem | null>(null);
  const [selectedItems, setSelectedItems] = useState<ImmutableMap<string, ImmutableMap<string, DataItem>>>(ImmutableMap<string, ImmutableMap<string, DataItem>>());

  const keyId = useCallback((item) => defaultKeyId(item, keyField), [keyField]);
  const keyEquals = useCallback((left, right) => keyId(left) === keyId(right), [keyId]);
  const keyNotEquals = useCallback((left, right) => keyId(left) !== keyId(right), [keyId]);

  const setCurrentItem = useCallback((item: DataItem) => {
    _setCurrentItem(item);
    setSelectedItems((_selectedItems) => _selectedItems.set(MAIN_SECTION, ImmutableMap<string, DataItem>({ [keyId(item)]: item })))
  }, [_setCurrentItem, keyId, setSelectedItems]);

  const onSelectAll = useCallback(
    (section: string, data: DataItem[]) => {
      setSelectedItems((_selectedItems) => {
        if (_selectedItems.has(section)) {
          const itemMap = _selectedItems.get(section);

          if (data.length === itemMap?.size && currentItem) {
            return _selectedItems.set(section, ImmutableMap({ [keyId(currentItem)]: currentItem }));
          }
          if (data.length === itemMap?.size) {
            return _selectedItems.set(section, ImmutableMap());
          }

          return _selectedItems.set(section, ImmutableMap(data.map((item: DataItem) => [keyId(item), item])));
        }

        return _selectedItems.set(section, ImmutableMap(data.map((item: DataItem) => [keyId(item), item])));
      });
    },
    [currentItem, keyId, setSelectedItems],
  );

  const onSelect = useCallback(
    (section: string, item: DataItem) => {
      _setCurrentItem(item);
      setSelectedItems((_selectedItems) => {
        const itemMap = ImmutableMap<string, DataItem>({ [keyId(item)]: item });
        return _selectedItems.set(section, itemMap);
      });
    },
    [_setCurrentItem, setSelectedItems, keyId],
  );

  const onReset = useCallback(
    (section: string) => {
      setSelectedItems((_selectedItems) => _selectedItems.set(section, ImmutableMap()));
    },
    [setSelectedItems]
  );

  const onToggle = useCallback(
    (section: string, item: DataItem) => {
      setSelectedItems((_selectedItems) => {
        if (_selectedItems.has(section)) {
          const itemMap = _selectedItems.get(section);
          if (itemMap?.has(keyId(item)) && currentItem) {
            const newItemMap = itemMap.filter((x: DataItem) => keyNotEquals(x, item) || keyEquals(x, currentItem));
            return _selectedItems.set(section, newItemMap);
          }

          if (itemMap?.has(keyId(item))) {
            const newItemMap = itemMap.filter((x: DataItem) => keyNotEquals(x, item));
            return _selectedItems.set(section, newItemMap);
          }

          if (itemMap) {
            const newItemMap = itemMap.set(keyId(item), item);
            return _selectedItems.set(section, newItemMap);
          }

          return _selectedItems;
        } else {
          const itemMap = ImmutableMap<string, DataItem>({ [keyId(item)]: item });
          return _selectedItems.set(section, itemMap);
        }
      });
    },
    [currentItem, keyId, keyNotEquals, keyEquals, setSelectedItems],
  );

  const ctx = {
    currentItem,
    selectedItems,
    setCurrentItem,
    onSelect,
    onSelectAll,
    onReset,
    onToggle,
  };

  return <DataSelectionContext.Provider value={ctx}>{children}</DataSelectionContext.Provider>;
};

export default DataSelectionProvider;
