import { useSetRecoilState } from 'recoil';

import { useAnnotationOptions } from 'hooks/useAnnotation';
import useTokenHasMorphAnnotations from 'methods/token-has-morph/hooks/useTokenHasMorphAnnotations';
import { extractId } from 'methods/token-has-morph/store';
import {
  Morph_Associated_Tokens_Consensus_Annotations_Entities_State,
  Morph_Associated_Tokens_Consensus_Annotations_Ids_State,
  Morph_Associated_Tokens_Options_Entities_State,
  Morph_Associated_Tokens_Predictions_Entities_State,
  Morph_Associated_Tokens_Predictions_Ids_State,
} from 'modules/morphs/store';
import { Status } from 'shared/constants';;

type useAnnotationsParams = {
  languageId: number;
}

export default function useAnnotations(params: useAnnotationsParams) {
  const { languageId } = params;

  const updateConsensusAnnotationsEntities = useSetRecoilState(Morph_Associated_Tokens_Consensus_Annotations_Entities_State);
  const updateConsensusAnnotationsIds = useSetRecoilState(Morph_Associated_Tokens_Consensus_Annotations_Ids_State);
  const updatePredictionsEntities = useSetRecoilState(Morph_Associated_Tokens_Predictions_Entities_State);
  const updatePredictionsIds = useSetRecoilState(Morph_Associated_Tokens_Predictions_Ids_State);
  const updateOptionsEntities = useSetRecoilState(Morph_Associated_Tokens_Options_Entities_State);

  const settersOrUpdaters = [
    [updateConsensusAnnotationsEntities, updateConsensusAnnotationsIds],
    [updatePredictionsEntities, updatePredictionsIds],
    updateOptionsEntities,
  ] as const;

  const { onCompleted: onConfirmCompleted } = useAnnotationOptions({ extractId, settersOrUpdaters, status: Status.confirmed });
  const { onCompleted: onDeclineCompleted } = useAnnotationOptions({ extractId, settersOrUpdaters, status: Status.declined });
  const annotationsOptions = { onConfirmCompleted, onDeclineCompleted };

  return useTokenHasMorphAnnotations({ parentItem: null , languageId }, annotationsOptions);
}
