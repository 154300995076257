import { atom } from 'recoil';

export type MainLayoutState = {
  consensus: boolean;
  isSidebarOpen: boolean
}

export const Main_Layout_State = atom<MainLayoutState>({
  key: 'graph/layouts/@atoms/main',
  default: {
    consensus: true,
    isSidebarOpen: false,
  },
});
