import { useSetRecoilState } from 'recoil';

import { useAssignMethodToContact, useAssignMethodToContactOptions }  from 'hooks/useAssignMethodToContact';
import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ContextAction, useContextActions2 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_MORPH_HAS_MORPHEME_TO_CONTACT } from 'methods/morph-has-morpheme/api';
import { ASSIGN_MORPHEME_TO_CONTACT, MARK_MORPHEME_AS_DONE, MARK_MORPHEME_AS_NOT_DONE } from 'modules/morphemes/api';
import useUpdateMorphemeEntities from 'modules/morphs/hooks/useUpdateMorphemeEntities';
import { Morph_Morphemes_Consensus_Annotations_Entities_State } from 'modules/morphs/store';
import { Edge_Direction, Methods } from 'shared/constants';
import { AssignMethodConfig, DataItem } from 'types';

const assignMethodConfig: AssignMethodConfig = {
  method: Methods.Morph_has_Morpheme,
  direction: Edge_Direction.DIRECT,
};

type useContextActionsParams = {
  parentItem: DataItem;
};

export default function useContextActions(params: useContextActionsParams): ContextAction[] {
  const { parentItem } = params;

  const update_Morphemes_Consensus_Annotations = useSetRecoilState(Morph_Morphemes_Consensus_Annotations_Entities_State);

  const onAssignToContactCompleted = useUpdateMorphemeEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_MORPHEME_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateMorphemeEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_MORPHEME_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateMorphemeEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_MORPHEME_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const settersOrUpdaters = [
    update_Morphemes_Consensus_Annotations,
    null,
    null,
    null,
  ] as const;
  const assignMethodToContactOptions = useAssignMethodToContactOptions({ settersOrUpdaters });
  const assignMethodToContact = useAssignMethodToContact(assignMethodConfig, ASSIGN_MORPH_HAS_MORPHEME_TO_CONTACT, parentItem, assignMethodToContactOptions)

  return useContextActions2('/morphemes', { assignToContact, assignMethodToContact, markAsDone, markAsNotDone });
}
