import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    flexGrow: 1,
    flexBasis: 0,
    width: '60%',
  },
  controls: {
    display: 'flex',
  },
}));

export default useStyles;
