import { useMemo } from 'react';

import DataPage, { DataPageRenderProps } from 'components/DataPage';
import useModalWithValue from 'hooks/useModalWithValue';
import MainLayout from 'layouts/MainLayout';
import { Authenticated } from 'modules/auth/components/Authenticated';
import FilterModal from 'modules/tags/components/FilterModal';
import SortModal from 'modules/tags/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/tags/constants';
import useResetTags from 'modules/tags/hooks/useResetTags';
import useTagContextActions from 'modules/tags/hooks/useTagContextActions';
import useTagsQuery from 'modules/tags/hooks/useTagsQuery';
// import AssociatedTokensDataSection from 'modules/tags/sections/AssociatedTokensDataSection';
// import ChildrenTagsDataSection from 'modules/tags/sections/ChildrenTagsDataSection';
import ClassesDataSection from 'modules/tags/sections/ClassesDataSection';
// import ParentsTagsDataSection from 'modules/tags/sections/ParentsTagsDataSection';
// import SemanticEntitiesDataSection from 'modules/tags/sections/SemanticEntitiesDataSection';
// import SynonymsTagsDataSection from 'modules/tags/sections/SynonymsTagsDataSection';
import { transformVariables } from 'modules/tags/utils';
import DataPageProvider from 'providers/DataPageProvider';

export default function Tags() {
  const resetTags = useResetTags();

  const {
    // open: sortModalOpen,
    value: sortModalValue,
    openModal: openSortModal,
    closeModal: closeSortModal,
    setValue: setSortModalValue,
  } = useModalWithValue({ initialValue: INITIAL_SORT_PARAMS, onValueChanged: resetTags });

  const {
    open: filterModalOpen,
    value: filterModalValue,
    meta: filterMeta,
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    setValue: setFilterModalValue,
    resetValue: resetFilterModalValue,
  } = useModalWithValue({ initialValue: INITIAL_FILTER_PARAMS, onValueChanged: resetTags });

  const actions = useMemo(
    () => ({
      sort: {
        handler: openSortModal,
      },
      filter: {
        meta: filterMeta,
        handler: openFilterModal,
      },
    }),
    [openSortModal, openFilterModal, filterMeta],
  );
  const contextActions = useTagContextActions();
  const dataVariables = useMemo(() => transformVariables(filterModalValue, sortModalValue, {}), [filterModalValue, sortModalValue]);

  return (
    <DataPageProvider>
      <DataPage
        title="Tags"
        useQuery={useTagsQuery}
        dataVariables={dataVariables}
        structure={{
          countField: 'count_all_positions_in_phrases',
        }}
        actions={actions}
        contextActions={contextActions}
      >
        {({ currentItem }: DataPageRenderProps) => (
          <>
            {currentItem && (
              <>
                {/* <AssociatedTokensDataSection parentItem={currentItem} />
                <ParentsTagsDataSection parentItem={currentItem} />
                <ChildrenTagsDataSection parentItem={currentItem} />
                <SynonymsTagsDataSection parentItem={currentItem} /> */}
                <ClassesDataSection parentItem={currentItem} />
                {/* <SemanticEntitiesDataSection parentItem={currentItem} /> */}
              </>
            )}

            {false && (
              <SortModal
                value={sortModalValue}
                options={SORT_OPTIONS}
                onChange={setSortModalValue}
                onClose={closeSortModal}
              />
            )}

            {filterModalOpen && (
              <FilterModal
                value={filterModalValue}
                onChange={setFilterModalValue}
                onClose={closeFilterModal}
                onReset={resetFilterModalValue}
              />
            )}
          </>
        )}
      </DataPage>
    </DataPageProvider>
  );
}

Tags.getLayout = (page: any) => (
  <Authenticated>
    <MainLayout>
      {page}
    </MainLayout>
  </Authenticated>
);
