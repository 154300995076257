import { useSetRecoilState } from 'recoil';

import { useAnnotationOptions } from 'hooks/useAnnotation';
import useSeHasTagAnnotations from 'methods/se-has-tag/hooks/useSeHasTagAnnotations';
import {
  SemanticEntity_Tags_Consensus_Annotations_Entities_State,
  SemanticEntity_Tags_Consensus_Annotations_Ids_State,
  SemanticEntity_Tags_Options_Entities_State,
  SemanticEntity_Tags_Predictions_Entities_State,
  SemanticEntity_Tags_Predictions_Ids_State,
} from 'modules/semantic-entities/store';
import { Status } from 'shared/constants';
import { AnnotationConfig, DataItem } from 'types';

type useAnnotationsParams = {
  parentItem: DataItem;
};

const annotationConfig: AnnotationConfig = {
  parentIdField: 'baseAssetId',
  itemIdField: 'targetAssetId',
};

export default function useAnnotations(params: useAnnotationsParams) {
  const { parentItem } = params;

  const updateConsensusAnnotationsEntities = useSetRecoilState(SemanticEntity_Tags_Consensus_Annotations_Entities_State);
  const updateConsensusAnnotationsIds = useSetRecoilState(SemanticEntity_Tags_Consensus_Annotations_Ids_State);
  const updatePredictionsEntities = useSetRecoilState(SemanticEntity_Tags_Predictions_Entities_State);
  const updatePredictionsIds = useSetRecoilState(SemanticEntity_Tags_Predictions_Ids_State);
  const updateOptionsEntities = useSetRecoilState(SemanticEntity_Tags_Options_Entities_State);

  const settersOrUpdaters = [
    [updateConsensusAnnotationsEntities, updateConsensusAnnotationsIds],
    [updatePredictionsEntities, updatePredictionsIds],
    updateOptionsEntities,
  ] as const;

  const { onCompleted: onConfirmCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.confirmed });
  const { onCompleted: onDeclineCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.declined });
  const annotationsOptions = { onConfirmCompleted, onDeclineCompleted };

  return useSeHasTagAnnotations(annotationConfig, { parentItem }, annotationsOptions);
}
