import { Field, Form, Formik } from 'formik';

// import { QUERY_TOKEN_TYPES } from 'api/queries';
import { renderAutoCompleteField, renderCheckboxField } from 'components/withFormik';
import { useLazyData } from 'hooks/useData';
// import useMetadata from 'hooks/useMetadata';
// import { SEARCH_CLUSTERS } from 'modules/clusters/api';
import { SEARCH_TOKENS } from 'modules/tokens/api';
import { DataItem, DictionaryItem } from 'types';

export type FormValues = {
  token?: DataItem | string | null;
  initialTokens: DataItem[];
  tokenTypes?: DictionaryItem[];
  containsCharacters?: string[];
  partOfClusters?: DataItem[];
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

type Props = {
  initialValues: FormValues;
  formRef?: any;
  onSubmit: (values: FormValues) => void;
};

// const CHARACTER_TYPES = ['Lating (not)', 'Greek', 'Cyrillic'];

export default function TokensFilterForm({ initialValues, formRef, onSubmit }: Props) {
  const {
    data: tokens,
    loading: isTokensLoading,
    getData: getTokens,
  } = useLazyData(SEARCH_TOKENS, 'Tokens', { nameField: 'name_STARTS_WITH_FULL_TEXT' });
  // const {
  //   data: initialTokens,
  //   loading: isInitialTokensLoading,
  //   getData: getInitialTokens,
  // } = useLazyData(SEARCH_TOKENS, 'Tokens', { nameField: 'name_STARTS_WITH_FULL_TEXT' });
  // const {
  //   data: clusters,
  //   loading: isClustersLoading,
  //   getData: getClusters,
  // } = useLazyData(SEARCH_CLUSTERS, 'Clusters');
  // const {
  //   metadata: tokenTypes,
  //   getMetadata: getTokenTypes,
  // } = useMetadata(QUERY_TOKEN_TYPES, 'token_type');

  return (
    <Formik innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Field
            name="token"
            label="Token Name"
            component={renderAutoCompleteField}
            freeSolo
            minLength={1}
            options={tokens}
            loading={isTokensLoading}
            onSearchChange={getTokens}
          />
         {/* <Field
            name="initialTokens"
            label="Token Name of Initial Token (for “join” paths)"
            component={renderAutoCompleteField}
            minLength={1}
            multiple
            options={initialTokens}
            loading={isInitialTokensLoading}
            onSearchChange={getInitialTokens}
          />
          <Field
            name="tokenTypes"
            label="Token Type"
            component={renderAutoCompleteField}
            multiple
            options={tokenTypes}
            onSearchChange={getTokenTypes}
          />
          <Field
            name="containsCharacters"
            label="Contains (not) Character"
            component={renderAutoCompleteField}
            options={CHARACTER_TYPES}
            multiple
            onSearchChange={getTokens}
          />
          <Field
            name="partOfClusters"
            label="is part of Cluster"
            component={renderAutoCompleteField}
            minLength={1}
            multiple
            options={clusters}
            loading={isClustersLoading}
            onSearchChange={getClusters}
          /> */}

          <div>
            <Field name="onlyAssigned" component={renderCheckboxField} label="only assigned" color="primary" />
          </div>

          <div>
            <Field name="onlyDone" component={renderCheckboxField} label="only done" color="primary" />
          </div>
        </Form>
      )}
    </Formik>
  );
}
