import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from 'modules/auth/hooks/useAuth';

interface GuestProps {
  children: ReactNode;
}

export const Guest: FC<GuestProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/');
    }
  }, [auth, history]);

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node
};
