import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const Morpheme_Morphs_Consensus_Annotations_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/morphemes/@atoms/Morphs_Consensus_Annotations/entities',
  default: ImmutableMap(),
});

export const Morpheme_Morphs_Consensus_Annotations_Ids_State = atom<string[]>({
  key: 'graph/morphemes/@atoms/Morphs_Consensus_Annotations/ids',
  default: [],
});

export const Morpheme_Morphs_Predictions_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/morphemes/@atoms/Morphs_Predictions/entities',
  default: ImmutableMap(),
});

export const Morpheme_Morphs_Predictions_Ids_State = atom<string[]>({
  key: 'graph/morphemes/@atoms/Morphs_Predictions/ids',
  default: [],
});

export const Morpheme_Morphs_Options_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/morphemes/@atoms/Morphs_Options/entities',
  default: ImmutableMap(),
});

export const Morpheme_Morphs_Options_Ids_State = atom<string[]>({
  key: 'graph/morphemes/@atoms/Morphs_Options/ids',
  default: [],
});
