import { Source } from 'shared/constants';
import { DataItem, Nullable } from 'types';

export enum Item_Specification_Sort_Option {
  BY_DATE_LAST_UPDATED = 'by_date_last_updated',
}

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: Item_Specification_Sort_Option;
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: Item_Specification_Sort_Option.BY_DATE_LAST_UPDATED,
};

export const SORT_OPTIONS = [
  {
    value:  Item_Specification_Sort_Option.BY_DATE_LAST_UPDATED,
    label: 'by last updated',
  },
];

export type FilterParamsType = {
  trades?: DataItem[];
  groupsOfWorkItems?: DataItem[];
  din276CostGroups?: DataItem[];
  source: number[];
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  trades: [],
  groupsOfWorkItems: [],
  din276CostGroups: [],
  source: [
    Source.STLB_Bau_Base,
    Source.STLB_Bau_Not_Base,
    Source.Specifications,
  ],
  onlyAssigned: undefined,
  onlyDone: undefined,
};

export type AddProductParamsType = {
  product: Nullable<DataItem>;
  attributes: DataItem[];
};

export const INITIAL_ADD_PRODUCT_PARAMS: AddProductParamsType = {
  product: null,
  attributes: [],
}

export enum AnnotationMode {
  Method_Specification = "Specification",
  Method_Phrase_Tag = "Phrase_to_Tag",
  Method_Phrase_SE = "Phrase_to_SE",
}

export type ButtonDirections = 'previous' | 'next';
