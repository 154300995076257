import { LanguageItem } from 'types';

export type TransformVariablesConfig = {
  nameProp?:
    | 'name_STARTS_WITH'
    | 'name_STARTS_WITH_FULL_TEXT'
    | 'name_STARTS_WITH_REGEXP'
    | 'name_STARTS_WITH_Token_Join';
};

export type Configuration = {
  dataPage: {
    limit: number;
  };
  defaultLanguage: LanguageItem;
  transformVariablesConfig: TransformVariablesConfig;
};

const config: Configuration = {
  dataPage: {
    limit: 100,
  },
  defaultLanguage: {
    id: 0,
    iso_language_name: 'Universal',
    code: '',
  },
  transformVariablesConfig: {
    nameProp: 'name_STARTS_WITH',
  },
};

export default config;
