import { useCallback, useEffect, useState } from 'react';
import produce from 'immer'

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useSuccess } from 'hooks/useSuccess'
import { useItemSpecificationContext } from 'modules/item-specifications/contexts/ItemSpecificationContext';
import useCreateOrUpdateTags from 'modules/item-specifications/hooks/useCreateOrUpdateTags';
import useGetItemSpecification from 'modules/item-specifications/hooks/useGetItemSpecification';
import useItemSpecificationAnnotation, { ItemSpecificationAnnotationResult } from 'modules/item-specifications/hooks/useItemSpecificationAnnotation';
import { Source, Status } from 'shared/constants';
import { uid } from 'shared/utils';
import { ItemSpecification, PhraseTag, SemanticEntityGroup } from 'types';

import Description from './Description';
import NewSemanticEntityGroup from './NewSemanticEntityGroup';
import SemanticEntityGroups from './SemanticEntityGroups';
import { getSemanticEntityGroups, getSemanticEntityGroupsInput } from './transformation';

function getNewSemanticEntityGroup(): SemanticEntityGroup {
  const id = uid();

  return {
    id,
    name: `SE-${id}`,
    semanticEntities: [],
  };
}

function transformToCreateOrUpdateTagInputArray(group: SemanticEntityGroup, status: Status): Array<any> {
  const results: any[] = []

  group.semanticEntities.forEach(se => {
    se.tags.forEach(spanTag => {
      results.push({
        tagId: spanTag.id,
        tagName: spanTag.name,
        classes: spanTag.classes?.map((it) =>
          ({
            id: it.id,
            status,
          })
        ),
      });
    });
  });

  return results
}

type Props = {
  itemSpecification: ItemSpecification;
  onModified: (touched: boolean) => void;
};

export default function SpecificationAnnotator({ itemSpecification, onModified }: Props) {
  const [open, setOpen] = useState(false);
  const [newGroup, setNewGroup] = useState(getNewSemanticEntityGroup());
  const [groups, setGroups] = useState<SemanticEntityGroup[]>([]);
  const [phraseTags, setPhraseTags] = useState<PhraseTag[]>([]);

  const { showSuccess } = useSuccess();
  const { getItemSpecification } = useGetItemSpecification()
  const { itemApiRef } = useItemSpecificationContext();

  const onCompleted = useCallback((result: ItemSpecificationAnnotationResult) => {
    setNewGroup(getNewSemanticEntityGroup());
    setGroups(getSemanticEntityGroups(result.Item_Specification_Annotation));
    setPhraseTags(result.Item_Specification_Annotation.phraseTags);

    setOpen(false);
    onModified(false);
    showSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuccess]);
  const { saveAnnotation } = useItemSpecificationAnnotation({ itemId: itemSpecification.id }, { onCompleted });
  const { createOrUpdateTags } = useCreateOrUpdateTags();

  const confirmGroup = useCallback(async (group: SemanticEntityGroup) => {
    const foundIndex = groups.findIndex(g => g.id === group.id);
    if (foundIndex > -1) {
      if (group.source === Source.prediction) {
        const createOrUpdateTagInputArray = transformToCreateOrUpdateTagInputArray(group, Status.confirmed);
        await createOrUpdateTags(createOrUpdateTagInputArray);
      }

      groups[foundIndex].status = Status.confirmed;
      groups[foundIndex].source = Source.consensus;
      const input = getSemanticEntityGroupsInput(phraseTags, groups);
      saveAnnotation(input);
    }
  }, [groups, phraseTags, createOrUpdateTags, saveAnnotation]);

  const declineGroup = useCallback(async (group: SemanticEntityGroup) => {
    const foundIndex = groups.findIndex(g => g.id === group.id);
    if (foundIndex > -1) {
      if (group.source === Source.prediction) {
        const createOrUpdateTagInputArray = transformToCreateOrUpdateTagInputArray(group, Status.declined);
        await createOrUpdateTags(createOrUpdateTagInputArray);
      }

      groups[foundIndex].status = Status.declined;
      groups[foundIndex].source = Source.consensus;
      const input = getSemanticEntityGroupsInput(phraseTags, groups);
      saveAnnotation(input);
    }
  }, [groups, phraseTags, createOrUpdateTags, saveAnnotation]);

  const newAnnotation = useCallback(() => {
    setNewGroup(baseGroup => produce(baseGroup, draftGroup => {
      draftGroup.semanticEntities = [];
    }));
    setOpen(true);
    onModified(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNewGroup = useCallback((group: SemanticEntityGroup) => {
    setNewGroup(group);
    onModified(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getItemSpecification(itemSpecification.id).then(itemSpecification => {
      if (itemSpecification) {
        setNewGroup(getNewSemanticEntityGroup());
        setGroups(getSemanticEntityGroups(itemSpecification));
        setPhraseTags(itemSpecification.phraseTags);
        onModified(false);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSpecification.id]);

  useEffect(() => {
    itemApiRef.current.saveAnnotations = () => {
      const input = getSemanticEntityGroupsInput(phraseTags, groups, newGroup);
      saveAnnotation(input);
    };
  });

  return (
    <>
      <Description
        name={itemSpecification.name}
        description={itemSpecification.description}
      />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: open ? 0 : 16 }}
            onClick={newAnnotation}
          >
            New Annotation
          </Button>
        </Grid>
        {open && (
          <NewSemanticEntityGroup
            group={newGroup}
            onUpdated={updateNewGroup}
          />
        )}
      </Grid>
      <SemanticEntityGroups
        semanticEntityGroups={groups.filter(g => g.id)}
        onConfirmAnnotation={confirmGroup}
        onDeclineAnnotation={declineGroup}
      />
    </>
  )
}
