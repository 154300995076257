import { gql } from '@apollo/client';

// base asset extension
export const QUERY_PHRASE_SES_CONSENSUS_ANNOTATIONS = gql`
  query Phrase_Semantic_Entities_Consensus_Annotations(
    $id: BigInt
    $where: SemanticEntity_Filter_Where_Input
    $options: SemanticEntity_Filter_Options_Input
    $language: Int
    $consensus: Boolean = true
  ) {
    phrases(where: { id: $id }) {
      Semantic_Entities_Consensus_Annotations(
        where: $where
        options: $options
        language: $language
        consensus: $consensus
      ) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        Phrase_has_Meaning_language
      }
    }
  }
`;

export const QUERY_PHRASE_SES_PREDICTIONS = gql`
  query Phrase_Semantic_Entities_Predictions(
    $id: BigInt
    $where: SemanticEntity_Filter_Where_Input
    $options: SemanticEntity_Filter_Options_Input
    $language: Int
  ) {
    phrases(where: { id: $id }) {
      Semantic_Entities_Predictions(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        Phrase_has_Meaning_language
      }
    }
  }
`;

export const QUERY_PHRASE_SES_OPTIONS = gql`
  query Phrase_Semantic_Entities_Options(
    $id: BigInt
    $where: SemanticEntity_Filter_Where_Input
    $options: SemanticEntity_Filter_Options_Input
    $language: Int
  ) {
    phrases(where: { id: $id }) {
      Semantic_Entities_Options(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        Phrase_has_Meaning_language
      }
    }
  }
`;

// target asset extension
export const QUERY_SE_PHRASES_CONSENSUS_ANNOTATIONS = gql`
  query Semantic_Entity_Phrases_Consensus_Annotations(
    $id: String!
    $where: Phrase_Filter_Where_Input
    $options: Phrase_Filter_Options_Input
    $language: Int
    $consensus: Boolean = true
  ) {
    semanticEntities(where: { id: $id }) {
      Phrases_Consensus_Annotations(where: $where, options: $options, language: $language, consensus: $consensus) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        Phrase_has_Meaning_language
      }
    }
  }
`;

export const QUERY_SE_PHRASES_PREDICTIONS = gql`
  query Semantic_Entity_Phrases_Predictions(
    $id: String!
    $where: Phrase_Filter_Where_Input
    $options: Phrase_Filter_Options_Input
    $language: Int
  ) {
    semanticEntities(where: { id: $id }) {
      Phrases_Predictions(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        Phrase_has_Meaning_language
      }
    }
  }
`;

export const QUERY_SE_PHRASES_OPTIONS = gql`
  query Semantic_Entity_Phrases_Options(
    $id: String!
    $where: Phrase_Filter_Where_Input
    $options: Phrase_Filter_Options_Input
    $language: Int
  ) {
    semanticEntities(where: { id: $id }) {
      Phrases_Options(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        Phrase_has_Meaning_language
      }
    }
  }
`;

// method node
export const ASSIGN_PHRASE_HAS_MEANING_TO_CONTACT = gql`
  mutation Assign_Phrase_has_Meaning_to_Contact($input: Method_Phrase_has_Meaning_Assign_to_Contact_Input!) {
    assign_Phrase_has_Meaning_to_Contact(input: $input) {
      methodPhraseHasMeanings {
        id
      }
    }
  }
`;

export const CONFIRM_PHRASE_HAS_MEANING_OPTION = gql`
  mutation Confirm_Method_Phrase_has_Meaning_Option($baseAssetId: BigInt!, $targetAssetId: String!, $language: Int!) {
    confirm_Method_Phrase_has_Meaning_option(
      input: { base_asset_id: $baseAssetId, target_asset_id: $targetAssetId }
      values: { language: $language }
    ) {
      methodPhraseHasMeanings {
        id
      }
    }
  }
`;

export const DECLINE_PHRASE_HAS_MEANING_OPTION = gql`
  mutation Decline_Method_Phrase_has_Meaning_Option($baseAssetId: BigInt!, $targetAssetId: String!, $language: Int!) {
    decline_Method_Phrase_has_Meaning_option(
      input: { base_asset_id: $baseAssetId, target_asset_id: $targetAssetId }
      values: { language: $language }
    ) {
      methodPhraseHasMeanings {
        id
      }
    }
  }
`;
