import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_SE_TAGS_CONSENSUS_ANNOTATIONS,
  QUERY_SE_TAGS_OPTIONS,
  QUERY_SE_TAGS_PREDICTIONS,
} from 'methods/se-has-tag/api';
import {
  SemanticEntity_Tags_Consensus_Annotations_selector,
  SemanticEntity_Tags_Options_selector,
  SemanticEntity_Tags_Predictions_selector,
} from 'modules/semantic-entities/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.semanticEntities?.[0]?.Tags_Consensus_Annotations || [], []);

  return useNestedQuery(
    QUERY_SE_TAGS_CONSENSUS_ANNOTATIONS,
    variables,
    SemanticEntity_Tags_Consensus_Annotations_selector,
    transform,
  );
}

export const usePredictionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.semanticEntities?.[0]?.Tags_Predictions || [], []);

  return useNestedQuery(
    QUERY_SE_TAGS_PREDICTIONS,
    variables,
    SemanticEntity_Tags_Predictions_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.semanticEntities?.[0]?.Tags_Options || [], []);

  return useNestedQuery(
    QUERY_SE_TAGS_OPTIONS,
    variables,
    SemanticEntity_Tags_Options_selector,
    transform,
  );
}
