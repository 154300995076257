import sortBy from 'lodash/sortBy';

import { ATTRIBUTE_ON_BUILDING_MATERIAL_ID, BUILDING_MATERIAL_ID } from 'modules/classes/constants';
import { Span } from 'modules/item-specifications/components/BaseTextAnnotator';
import { Source, Status } from "shared/constants";
import { debug, uid } from 'shared/utils';
import { ItemSpecification, ItemSpecificationInput, PhraseTag, PhraseTagInput, SemanticEntityGroupInput, SemanticEntityInput, SemanticEntityTag, SemanticEntityTagInput } from 'types';

const ALLOWED = [`${BUILDING_MATERIAL_ID}`, `${ATTRIBUTE_ON_BUILDING_MATERIAL_ID}`]

const transformSemanticEntityGroups = (itemSpecification: ItemSpecification): Span[] => {
  const spans: Span[] = [];

  itemSpecification.semanticEntityGroups.filter(group => group.source === Source.consensus).forEach(group => {
    group.semanticEntities.forEach(se => {
      se.tags.forEach(spanTag => {
        spans.push({
          id: uid(),
          text: '',
          groupId: group.id as string,
          seId: se.id as string,
          tagId: spanTag.id,
          first_position_in_phrase: spanTag.start,
          last_position_in_phrase: spanTag.end,
          anchor: spanTag.anchor,
          source: group.source as number,
          status: group.status as number,
          dirty: false,
          active: false,
          invisible: true,
        });
      });
    });
  });

  return spans;
};

const transformPhraseTags = (itemSpecification: ItemSpecification): Span[] => {
  const spans: Span[] = [];
  const phraseTags: PhraseTag[] = [];

  const description = itemSpecification.description || '';

  // 1. collect all phrase tags
  itemSpecification.phraseTags.forEach(spanTag => {
    phraseTags.push(spanTag);
  });

  // 2. extract anchors from phrase tags
  const semanticEntities: Array<{ id: string, name: string }> = []
  const suggestions = sortBy(phraseTags, it => it.start);
  suggestions.forEach((phraseTag: PhraseTag) => {
    if (phraseTag.anchor && phraseTag.status === Status.confirmed) {
      semanticEntities.push({ id: uid(), name: phraseTag.name });
    }
  });

  let currentSeIndex = 0;
  let currentSeId: string | undefined;

  // 3. transform to spans
  suggestions.forEach((spanTag: SemanticEntityTag, index: number) => {
    if (spanTag.anchor && spanTag.status === Status.confirmed) {
      currentSeId = semanticEntities[currentSeIndex++]?.id;
    }

    spans.push({
      id: uid(),
      text: description.slice(spanTag.start, spanTag.end),
      groupId: null,
      seId: `${currentSeId}`,
      tagId: spanTag.id,
      first_position_in_phrase: spanTag.start,
      last_position_in_phrase: spanTag.end,
      anchor: spanTag.anchor,
      source: spanTag.source as number,
      status: spanTag.status as number,
      dirty: true,
      active: spanTag.status === Status.confirmed && Boolean(spanTag.classes?.some(it => ALLOWED.includes(it.id))),
      invisible: spanTag.status === Status.declined || !spanTag.classes?.some(it => ALLOWED.includes(it.id)),
      labels: spanTag.anchor
        ? undefined
        : semanticEntities.map(se =>
          ({
            id: se.id,
            name: se.name,
            selected: se.id === currentSeId,
            dirty: false,
          })
        ),
      });
  });

  return spans;
};

export const getSpans = (itemSpecification: ItemSpecification): Span[] => {
  debug("getSpans:semanticEntityGroups", itemSpecification.semanticEntityGroups);
  debug("getSpans:phraseTags", itemSpecification.phraseTags);

  const groups = transformSemanticEntityGroups(itemSpecification);
  const nonGroups = transformPhraseTags(itemSpecification);

  debug("getSpans:groups", groups);
  debug("getSpans:nonGroups", nonGroups);

  const spans = [...groups, ...nonGroups];

  debug("getSpans:spans", spans);

  return spans;
};

export const getItemSpecificationInput = (spans: Span[], description: string): ItemSpecificationInput => {
  const semanticEntityGroups: SemanticEntityGroupInput[] = [];
  const phraseTags: PhraseTagInput[] = [];

  debug("getItemSpecificationInput:spans", spans);

  // semanticEntityGroups
  const spanMap = new Map<string, Span>();
  const groupSesMap = new Map<string, Set<string>>();
  const groupStatusMap = new Map<string, number>();
  const seSpansMap = new Map<string, Set<string>>();
  // phraseTags
  const phraseTagSpansSet = new Set<string>();

  // 1. collect spans
  spans.forEach(span => {
    spanMap.set(span.id, span);
  });

  // 2. collect semanticEntityGroups
  spans.filter(span => span.groupId).forEach(span => {
    if (span.source === Source.consensus) {
      const groupId = span.groupId as string;
      const seId = span.seId as string;

      // track group status
      groupStatusMap.set(groupId, span.status);

      // add SE to SEG
      const ses = groupSesMap.get(groupId) || new Set<string>();
      ses.add(seId);
      groupSesMap.set(groupId, ses);

      // add span to SE
      const spans = seSpansMap.get(seId) || new Set<string>();
      spans.add(span.id);
      seSpansMap.set(seId, spans);
    }
  });

  // 3. collect phraseTags
  spans.filter(span => !span.groupId).forEach(span => {
    if (span.source === Source.consensus || span.source === Source.prediction && !span.invisible) {
      phraseTagSpansSet.add(span.id);
    }
  });

  // 4. restore semanticEntityGroups from spans
  groupSesMap.forEach((ses, groupId) => {
    semanticEntityGroups.push({
      id: groupId,
      name: `SEG-${groupId}`,
      source: Source.consensus,
      status: groupStatusMap.get(groupId),
      semanticEntities: [...ses].map(seId => {
        const seSpans = Array.from(seSpansMap.get(seId) || []).map(it => spanMap.get(it) as Span)

        return {
          id: seId,
          name: `SE-${seId}`,
          tags: seSpans.map(span =>
            ({
              id: span.tagId as string,
            })
          )
        };
      }),
    });
  });

  // 5. restore phraseTags from spans
  phraseTagSpansSet.forEach((spanId) => {
    const span = spanMap.get(spanId) as Span;

    phraseTags.push({
      id: span.tagId as string,
      start: span.first_position_in_phrase,
      end: span.last_position_in_phrase,
      anchor: span.anchor,
      status: span.status,
    })
  });

  // 6. New Semantic Entity
  const seMap = new Map<string, { id: string, name: string }>();

  // 6.1 Collect anchors
  spans.filter(span => span.active).forEach(span => {
    if (span.anchor) {
      seMap.set(span.id, {
        id: span.id,
        name: description.slice(span.first_position_in_phrase, span.last_position_in_phrase).toLowerCase(),
      });
    }
  });

  let iterator = 1;
  const semanticEntities: SemanticEntityInput[] = [];

  // 6.2 Collect semantic entities
  seMap.forEach(se => {
    const seSpans = spans
      .filter(span => span.active)
      .filter(span => {
        // anchor tag
        if (span.anchor && span.id === se.id) {
          return true;
        }

        // attribute tag
        if (span.labels?.filter(it => it.selected).find(it => it.name === se.name)) {
          return true;
        }

        return false;
      });

    const tags: SemanticEntityTagInput[] = seSpans.map(span => ({ id: span.tagId as string }));

    semanticEntities.push({
      name: `SE-${iterator++}`,
      tags,
    });
  });

  semanticEntityGroups.push({
    id: `SEG-New`,
    status: Status.confirmed,
    source: Source.consensus,
    semanticEntities,
  });

  debug("getItemSpecificationInput:semanticEntityGroups", semanticEntityGroups);
  debug("getItemSpecificationInput:phraseTags", phraseTags);

  return {
    id: '',
    semanticEntityGroups,
    phraseTags,
  };
};
