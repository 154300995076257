import { useCallback } from 'react';

import DataGroup from 'components/DataGroup';
import DataSection from 'components/DataSection';
import { TransformVariablesConfig } from 'configuration'
import useConsensus from 'hooks/useConsensus';
import useModalWithValue from 'hooks/useModalWithValue';
import { INITIAL_PHRASES_PARAMS, PhrasesParamsType } from 'methods/token-join';
import PhrasesModal from 'methods/token-join/components/PhrasesModal';
import { Token_Join_frequency, Token_Join_sequence } from 'methods/token-join/helpers';
import FilterModal from 'modules/tokens/components/FilterModal';
import SortModal from 'modules/tokens/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/tokens/constants';
import { transformVariables } from 'modules/tokens/utils';
import { DataItem } from 'types';

import useAnnotations from './useAnnotations';
import useContextActions from './useContextActions';
import { useConsensusAnnotationsQuery, useOptionsQuery, usePredictionsQuery } from './useQueries';

const menuAcl = {
  Token_Join_Options: false,
};

const transformVariablesConfig: TransformVariablesConfig = {
  nameProp: 'name_STARTS_WITH_Token_Join',
};

type Props = {
  parentItem: DataItem;
};

export default function TokenJoinDataSection({ parentItem }: Props) {
  const { consensus } = useConsensus();
  const { confirmAnnotation, declineAnnotation } = useAnnotations()
  const contextActions = useContextActions();

  // Phrases Modal
  const {
    open: phrasesModalOpen,
    value: phrasesModalValue,
    openModal: openPhrasesModal,
    closeModal: closePhrasesModal,
    setValue: setPhrasesModalValue,
  } = useModalWithValue<PhrasesParamsType>({ initialValue: INITIAL_PHRASES_PARAMS });

  const openPhrasesModalAdapter = useCallback(
    (e: any, item: DataItem) => {
      setPhrasesModalValue({ token: item });
      openPhrasesModal();
    },
    [openPhrasesModal, setPhrasesModalValue],
  );

  return (
    <>
      <DataGroup
        title="Token Join"
        structure={{
          menuId: 'Token_Token_Join',
          menuAcl,
          keyField: Token_Join_sequence,
        }}
        contextActions={contextActions}
        consensusAnnotations={
          <DataSection
            parentItem={parentItem}
            useQuery={useConsensusAnnotationsQuery}
            structure={{
              id: 'Token_Join_Consensus_Annotations',
              title: 'Consensus & Annotations',
              readonly: consensus,
            }}
            consensus={consensus}
            transformVariables={transformVariables}
            transformVariablesConfig={transformVariablesConfig}
            filterComponent={FilterModal}
            filterParams={INITIAL_FILTER_PARAMS}
            sortComponent={SortModal}
            sortParams={INITIAL_SORT_PARAMS}
            sortOptions={SORT_OPTIONS}
            onConfirm={confirmAnnotation}
            onDecline={declineAnnotation}
            onClick={openPhrasesModalAdapter}
          />
        }
        predictions={
          <DataSection
            parentItem={parentItem}
            useQuery={usePredictionsQuery}
            selectable={false}
            structure={{
              id: 'Token_Join_Predictions',
              title: 'Predictions',
            }}
            transformVariables={transformVariables}
            transformVariablesConfig={transformVariablesConfig}
            onConfirm={confirmAnnotation}
            onDecline={declineAnnotation}
            onClick={openPhrasesModalAdapter}
          />
        }
        options={
          <DataSection
            parentItem={parentItem}
            useQuery={useOptionsQuery}
            selectable={false}
            structure={{
              id: 'Token_Join_Options',
              title: 'Options',
              keyField: Token_Join_sequence,
              countField: Token_Join_frequency,
              orangeLabel: false,
            }}
            transformVariables={transformVariables}
            // paginationParams={paginationParams}
            onConfirm={confirmAnnotation}
            onDecline={declineAnnotation}
            onClick={openPhrasesModalAdapter}
          />
        }
      />

      {phrasesModalOpen && (
        <PhrasesModal
          value={phrasesModalValue}
          onClose={closePhrasesModal}
        />
      )}
    </>
  );
}
