import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';

import { DataItem, Mutation } from 'types';

export type MarkAsNotDoneParams = {
  dataItems: DataItem[];
};

export const markAsNotDoneTransform = () => ({ active_contact_is_done_by: false }) as DataItem;

type UseMarkAsNotDoneOptions = {
  onCompleted?: (result: unknown, items: DataItem[]) => void;
};

export default function useMarkAsNotDone(
  mutation: Mutation,
  options: UseMarkAsNotDoneOptions = {},
): (params: MarkAsNotDoneParams) => void {
  const { onCompleted: onMarkAsNotDoneCompleted } = options;

  const itemsRef = useRef<DataItem[] | null>(null);

  const onCompleted = useCallback((result: any) => {
    onMarkAsNotDoneCompleted?.(result, itemsRef.current as DataItem[])
    itemsRef.current = null;
  }, [onMarkAsNotDoneCompleted]);

  const [mutate] = useMutation(mutation, { onCompleted });
  const markAsNotDone = useCallback(
    ({ dataItems }: MarkAsNotDoneParams) => {
      itemsRef.current = dataItems;

      const ids = dataItems.map((dataItem: DataItem) => dataItem.id)

      mutate({
        variables: {
          ids,
        },
      });
    },
    [mutate],
  );

  return markAsNotDone;
}
