import { Source, Status } from 'shared/constants';
import { debug } from 'shared/utils';
import { ItemSpecification, ItemSpecificationInput, PhraseTag, PhraseTagInput, SemanticEntityGroup, SemanticEntityGroupInput } from 'types';

export const getSemanticEntityGroups = (itemSpecification: ItemSpecification): SemanticEntityGroup[] => {
  const groupMap = new Map<string | null, SemanticEntityGroup>()

  const semanticEntityGroups = [
    ...itemSpecification.semanticEntityGroups.filter(group => group.source === Source.prediction),
    ...itemSpecification.semanticEntityGroups.filter(group => group.source === Source.consensus),
  ];
  debug("semanticEntityGroups", semanticEntityGroups);

  semanticEntityGroups.forEach(group => {
    groupMap.set(group.id ?? null, group);
  })

  const groups = [...groupMap.values()]
  debug("groups", groups);

  return groups;
};

export const getSemanticEntityGroupsInput = (
  phraseTags: PhraseTag[],
  semanticEntityGroups: SemanticEntityGroup[],
  newGroup?: SemanticEntityGroup,
  ): ItemSpecificationInput => {
  semanticEntityGroups = [
    ...semanticEntityGroups.filter(group => group.id),
    ...semanticEntityGroups.filter(group => !group.id),
  ];

  debug("semanticEntityGroups", semanticEntityGroups);
  debug("newGroup", newGroup);

  const newSemanticEntityGroups: SemanticEntityGroupInput[] = semanticEntityGroups.map(seg =>
    ({
      id: seg.id,
      name: seg.name,
      source: seg.id ? seg.source: undefined,
      status: seg.id ? seg.status: undefined,
      semanticEntities: seg.semanticEntities.map(se =>
        ({
          id: se.id,
          name: se.name,
          tags: se.tags.map(spanTag =>
            ({
              id: spanTag.id,
            })
          ),
        })
      ),
    })
  );

  if (newGroup && newGroup.semanticEntities.length > 0) {
    newSemanticEntityGroups.push(
      {
        id: newGroup.id,
        name: newGroup.name,
        source: Source.consensus,
        status: Status.confirmed,
        semanticEntities: newGroup.semanticEntities.map(se =>
          ({
            id: se.id,
            name: se.name,
            tags: se.tags.map(spanTag =>
              ({
                id: spanTag.id,
              })
            ),
          })
        ),
      }
    );
  }

  const newPhraseTags: PhraseTagInput[] = phraseTags.map(phraseTag =>
    ({
      id: phraseTag.id,
      start: phraseTag.start,
      end: phraseTag.end,
      anchor: phraseTag.anchor,
      status: phraseTag.status,
    })
  )

  debug("getSemanticEntityGroupsInput:newSemanticEntityGroups", newSemanticEntityGroups);
  debug("getSemanticEntityGroupsInput:newPhraseTags", newPhraseTags);

  return {
    id: '',
    semanticEntityGroups: newSemanticEntityGroups,
    phraseTags: newPhraseTags,
  };
};
