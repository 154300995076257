import { useCallback, useMemo, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { ButtonDirections } from "modules/item-specifications/constants";
import { Item_Specifications_Entities_State, Item_Specifications_Ids_State } from 'modules/item-specifications/store';
import { MAIN_SECTION, useDataSelectionContext } from "providers/DataSelectionProvider";
import { DataItem } from "types";

type UseArrowNavigateResult = {
  hasPrevious: boolean;
  hasNext: boolean;
  arrowNavigate: (direction: ButtonDirections) => void;
}

export default function useArrowNavigate(): UseArrowNavigateResult {
  const history = useHistory();
  const historyRef = useRef(history);

  const [itemEntities] = useRecoilState(Item_Specifications_Entities_State);
  const [itemIds] = useRecoilState(Item_Specifications_Ids_State);

  const { currentItem, onSelect } = useDataSelectionContext();
  const handleSelect = useCallback((item: DataItem) => onSelect(MAIN_SECTION, item), [ onSelect ]);

  const hasPrevious = useMemo(() => {
    if (currentItem === null || itemIds === null) return false;

    const currentIndex = itemIds.indexOf(currentItem.id);
    if (typeof currentIndex === 'undefined') return false;

    if (currentIndex > 0 && currentIndex < itemIds.length) {
      return Boolean(itemIds[currentIndex - 1]);
    }

    return false;
  },[currentItem, itemIds]);

  const hasNext = useMemo(() => {
    if (currentItem === null || itemIds === null) return false;

    const currentIndex = itemIds.indexOf(currentItem.id);
    if (typeof currentIndex === 'undefined') return false;

    if (currentIndex >= 0 && currentIndex < itemIds.length - 1) {
      return Boolean(itemIds[currentIndex + 1]);
    }

    return false;
  },[currentItem, itemIds]);

  const arrowNavigate = useCallback((direction: ButtonDirections) => {
    if (currentItem === null || itemIds === null) return;

    const currentIndex = itemIds.indexOf(currentItem.id);
    if (typeof currentIndex === 'undefined') return;

    let nextItemToDisplay: DataItem | undefined;

    if (direction === 'previous') {
      if (currentIndex > 0 && currentIndex < itemIds.length) {
        const nextId = itemIds[currentIndex - 1];
        nextItemToDisplay = itemEntities.get(nextId)

      }
    } else {
      if (currentIndex >= 0 && currentIndex < itemIds.length - 1) {
        const nextId = itemIds[currentIndex + 1];
        nextItemToDisplay = itemEntities.get(nextId)
      }
    }

    if (nextItemToDisplay) {
      handleSelect(nextItemToDisplay);
      historyRef.current.push(`/item-specifications/${nextItemToDisplay.id}`);
    }
  }, [currentItem, itemEntities, itemIds, handleSelect]);



  return {
    hasPrevious,
    hasNext,
    arrowNavigate,
  }
}
