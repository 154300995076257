import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_TOKENS } from 'modules/tokens/api';
import { Tokens_selector } from 'modules/tokens/store';

export default function useTagsQuery(variables: any) {
  const transform = useCallback((data: any) => data.Tokens, []);

  return useQuery(QUERY_TOKENS, variables, Tokens_selector, transform);
}
