import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflow: 'auto',
  },
  item: {
    flexGrow: 1,
    flexBasis: 0,
    height: '100%',
    width: 'calc(100%/3)',
  },
}));

export default useStyles;
