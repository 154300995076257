import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const Class_Clusters_Consensus_Annotations_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/classes/@atoms/Clusters_Consensus_Annotations/entities',
  default: ImmutableMap(),
});

export const Class_Clusters_Consensus_Annotations_Ids_State = atom<string[]>({
  key: 'graph/classes/@atoms/Clusters_Consensus_Annotations/ids',
  default: [],
});

export const Class_Clusters_Predictions_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/classes/@atoms/Clusters_Predictions/entities',
  default: ImmutableMap(),
});

export const Class_Clusters_Predictions_Ids_State = atom<string[]>({
  key: 'graph/classes/@atoms/Clusters_Predictions/ids',
  default: [],
});

export const Class_Clusters_Options_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/classes/@atoms/Clusters_Options/entities',
  default: ImmutableMap(),
});

export const Class_Clusters_Options_Ids_State = atom<string[]>({
  key: 'graph/classes/@atoms/Clusters_Options/ids',
  default: [],
});
