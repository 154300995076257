import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Clusters_selector } from 'modules/clusters/store';

export default function useResetClusters() {
  const reset = useResetRecoilState(Clusters_selector);

  return useCallback(() => {
    reset();
  }, [reset]);
}
