import { useSetRecoilState } from 'recoil';

import { useAnnotationOptions } from 'hooks/useAnnotation';
import useClassHasClusterAnnotations from 'methods/class-has-cluster/hooks/useClassHasClusterAnnotations';
import {
  Class_Clusters_Consensus_Annotations_Entities_State,
  Class_Clusters_Consensus_Annotations_Ids_State,
  Class_Clusters_Options_Entities_State,
  Class_Clusters_Predictions_Entities_State,
  Class_Clusters_Predictions_Ids_State,
} from 'modules/classes/store';
import { Status } from 'shared/constants';
import { AnnotationConfig, DataItem } from 'types';

type useAnnotationsParams = {
  parentItem: DataItem;
}

const annotationConfig: AnnotationConfig = {
  parentIdField: 'baseAssetId',
  itemIdField: 'targetAssetId',
};

export default function useAnnotations(params: useAnnotationsParams) {
  const { parentItem } = params;

  const updateConsensusAnnotationsEntities = useSetRecoilState(Class_Clusters_Consensus_Annotations_Entities_State);
  const updateConsensusAnnotationsIds = useSetRecoilState(Class_Clusters_Consensus_Annotations_Ids_State);
  const updatePredictionsEntities = useSetRecoilState(Class_Clusters_Predictions_Entities_State);
  const updatePredictionsIds = useSetRecoilState(Class_Clusters_Predictions_Ids_State);
  const updateOptionsEntities = useSetRecoilState(Class_Clusters_Options_Entities_State);

  const settersOrUpdaters = [
    [updateConsensusAnnotationsEntities, updateConsensusAnnotationsIds],
    [updatePredictionsEntities, updatePredictionsIds],
    updateOptionsEntities,
  ] as const;

  const { onCompleted: onConfirmCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.confirmed });
  const { onCompleted: onDeclineCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.declined });
  const annotationsOptions = { onConfirmCompleted, onDeclineCompleted };

  return useClassHasClusterAnnotations(annotationConfig, { parentItem }, annotationsOptions);
}
