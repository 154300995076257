import { DataItem } from 'types';

export const Token_has_Morph_name = (item: DataItem) => {
  if (Array.isArray(item.Token_has_Morph_sequence) && item.Token_has_Morph_sequence.length > 0) {
    return item.Token_has_Morph_sequence.map((x) => `{${x.name}}`).join(' + ');
  }

  return item.name;
};

export const Token_has_Morph_sequence = (item: DataItem) => {
  return (item?.Token_has_Morph_sequence || []).map((x: DataItem) => x.id).join('_') || '';
};
