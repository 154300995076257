import { useCallback, useMemo, useState } from 'react';

import DataGroup from 'components/DataGroup';
import DataSection from 'components/DataSection';
import Languages from 'components/Languages';
import config, { TransformVariablesConfig } from 'configuration';
import useConsensus from 'hooks/useConsensus';
import useLanguages from 'hooks/useLanguages';
import useModalWithValue from 'hooks/useModalWithValue';
import { INITIAL_TOKEN_HAS_MOPRH_PARAMS, TokenHasMorphParamsType } from 'methods/token-has-morph';
import TokenHasMorphModal from 'methods/token-has-morph/components/TokenHasMorphModal';
import { Token_has_Morph_name, Token_has_Morph_sequence } from 'methods/token-has-morph/helpers';
import FilterModal from 'modules/tokens/components/FilterModal';
import SortModal from 'modules/tokens/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/tokens/constants';
import { DataItem } from 'types';

import transformVariablesAdapter from './transformVariablesAdapter';
import useAnnotations from './useAnnotations'
import useContextActions from './useContextActions'
import { useConsensusAnnotationsQuery, useOptionsQuery, usePredictionsQuery } from './useQueries'

type Props = {
  parentItem: DataItem;
};

const transformVariablesConfig: TransformVariablesConfig = {
  nameProp: 'name_STARTS_WITH_REGEXP',
}

const menuAcl = {
  Associated_Tokens_Options: {
    ASSIGN_METHOD_TO_ME: false,
    ASSIGN_METHOD_TO_ANOTHER_CONTACT: false,
  }
}

export default function AssociatedTokensDataSection({ parentItem }: Props) {
  const [languageId, setLanguageId] = useState<number>(config.defaultLanguage.id);
  const { getLanguage } = useLanguages();

  const { consensus } = useConsensus();
  const { confirmAnnotation, declineAnnotation } = useAnnotations({ languageId });

  // Token Has Morph Modal
  const {
    open: tokenHasMorphModalOpen,
    value: tokenHasMorphModalValue,
    openModal: openTokenHasMorphModal,
    closeModal: closeTokenHasMorphModal,
    setValue: setTokenHasMorphModalValue,
  } = useModalWithValue<TokenHasMorphParamsType>({ initialValue: INITIAL_TOKEN_HAS_MOPRH_PARAMS });

  const actions = useMemo(
    () => [
      {
        content: <Languages value={languageId} onChange={setLanguageId} />,
        handler: () => null,
      },
    ],
    [languageId, setLanguageId],
  );
  const contextActions = useContextActions({ languageId, parentItem });
  const transformVariables = useMemo(() => transformVariablesAdapter({ languageId }), [languageId]);
  const watchParams = useMemo(() => ({ languageId }), [languageId]);

  // Options
  const confirmOption = useCallback(
    (item: DataItem) => {
      if (item.Token_has_Morph_sequence) {
        confirmAnnotation(item);
      } else {
        const language = getLanguage(languageId);
        setTokenHasMorphModalValue({
          token: item,
          language,
          targetAssets: [],
          action: 'confirm',
        });
        openTokenHasMorphModal();
      }
    },
    [languageId, getLanguage, confirmAnnotation, openTokenHasMorphModal, setTokenHasMorphModalValue],
  );

  const declineOption = useCallback(
    (item: DataItem) => {
      if (item.Token_has_Morph_sequence) {
        declineAnnotation(item);
      } else {
        const language = getLanguage(languageId);
        setTokenHasMorphModalValue({
          token: item,
          language,
          targetAssets: [],
          action: 'decline',
        });
        openTokenHasMorphModal();
      }
    },
    [languageId, getLanguage, declineAnnotation, openTokenHasMorphModal, setTokenHasMorphModalValue],
  );

  const handleTokenHasMorphModalChange = useCallback(
    (values: TokenHasMorphParamsType) => {
      if (values.action === 'confirm') {
        confirmAnnotation({
          id: values.token?.id,
          Token_has_Morph_sequence: values.targetAssets,
        } as DataItem);
      }

      if (values.action === 'decline') {
        declineAnnotation({
          id: values.token?.id,
          Token_has_Morph_sequence: values.targetAssets,
        } as DataItem);
      }
    },
    [confirmAnnotation, declineAnnotation],
  );

  return (
    <>
      <DataGroup
        title="Associated Tokens"
        structure={{
          menuId: 'Morph_Associated_Tokens',
          menuAcl,
          keyField: ['id', Token_has_Morph_sequence],
        }}
        actions={actions}
        contextActions={contextActions}
        consensusAnnotations={
          <DataSection
            parentItem={parentItem}
            useQuery={useConsensusAnnotationsQuery}
            consensus={consensus}
            structure={{
              id: 'Associated_Tokens_Consensus_Annotations',
              title: 'Consensus & Annotations',
              keyField: ['id', Token_has_Morph_sequence],
              nameField: Token_has_Morph_name,
              countField: 'count_all_positions_in_phrases',
              readonly: consensus,
            }}
            transformVariables={transformVariables}
            transformVariablesConfig={transformVariablesConfig}
            filterComponent={FilterModal}
            filterParams={INITIAL_FILTER_PARAMS}
            sortComponent={SortModal}
            sortParams={INITIAL_SORT_PARAMS}
            sortOptions={SORT_OPTIONS}
            watchParams={watchParams}
            onConfirm={confirmAnnotation}
            onDecline={declineAnnotation}
          />
        }
        predictions={
          <DataSection
            parentItem={parentItem}
            useQuery={usePredictionsQuery}
            structure={{
              id: 'Associated_Tokens_Predictions',
              title: 'Predictions',
              keyField: ['id', Token_has_Morph_sequence],
              nameField: Token_has_Morph_name,
              countField: 'count_all_positions_in_phrases',
            }}
            transformVariables={transformVariables}
            transformVariablesConfig={transformVariablesConfig}
            filterComponent={FilterModal}
            filterParams={INITIAL_FILTER_PARAMS}
            sortComponent={SortModal}
            sortParams={INITIAL_SORT_PARAMS}
            sortOptions={SORT_OPTIONS}
            watchParams={watchParams}
            onConfirm={confirmAnnotation}
            onDecline={declineAnnotation}
          />
        }
        options={
          <DataSection
            parentItem={parentItem}
            useQuery={useOptionsQuery}
            structure={{
              id: 'Associated_Tokens_Options',
              title: 'Options',
              keyField: ['id', Token_has_Morph_sequence],
              nameField: Token_has_Morph_name,
              countField: 'count_all_positions_in_phrases',
              orangeLabel: false,
            }}
            transformVariables={transformVariables}
            transformVariablesConfig={transformVariablesConfig}
            filterComponent={FilterModal}
            filterParams={INITIAL_FILTER_PARAMS}
            sortComponent={SortModal}
            sortParams={INITIAL_SORT_PARAMS}
            sortOptions={SORT_OPTIONS}
            watchParams={watchParams}
            onConfirm={confirmOption}
            onDecline={declineOption}
          />
        }
      />
      {tokenHasMorphModalOpen && (
        <TokenHasMorphModal
          value={tokenHasMorphModalValue}
          onClose={closeTokenHasMorphModal}
          onChange={handleTokenHasMorphModalChange}
        />
      )}
    </>
  );
}
