import { memo, ReactNode, SyntheticEvent, useCallback, useState } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import AddIcon from 'components/icons/AddIcon';
import FilterIcon from 'components/icons/FilterIcon';
import PinIcon from 'components/icons/PinIcon';
import RepeatIcon from 'components/icons/RepeatIcon';
import SortIcon from 'components/icons/SortIcon';

import useStyles from './styles';

type ActionType = {
  meta?: {
    dirty?: boolean;
  };
  handler: () => void;
};

type Props = {
  title: string;
  actions?: Record<string, ActionType>;
  onExpand: (e: SyntheticEvent, expanded: boolean) => void;
  onAddClick?: () => void;
  onPinClick?: () => void;
  onSortClick?: () => void;
  onFilterClick?: () => void;
  children?: ReactNode;
};

const TopLevelList = ({ title, actions, onExpand, children }: Props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = useCallback(
    (e: SyntheticEvent) => {
      setExpanded(!expanded);
      onExpand?.(e, !expanded);
    },
    [expanded, setExpanded, onExpand],
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.actions}>
          {actions && actions.process && (
            <IconButton className={classes.actionButton} onClick={actions.process.handler}>
              <RepeatIcon />
            </IconButton>
          )}
          {actions && actions.add && (
            <IconButton className={classes.actionButton} onClick={actions.add.handler}>
              <AddIcon />
            </IconButton>
          )}
          {actions && actions.pin && (
            <IconButton className={classes.actionButton} onClick={actions.pin.handler}>
              <PinIcon />
            </IconButton>
          )}
          {actions && actions.filter && (
            <IconButton className={classes.actionButton} onClick={actions.filter.handler}>
              <FilterIcon selected={Boolean(actions.filter?.meta?.dirty)} />
            </IconButton>
          )}
          {actions && actions.sort && (
            <IconButton className={classes.actionButton} onClick={actions.sort.handler}>
              <SortIcon selected={Boolean(actions.sort?.meta?.dirty)} />
            </IconButton>
          )}
        </div>
      </div>

      <div className={classes.subheader}>
        <Button className={classes.expandButton} variant="outlined" onClick={handleExpandClick}>
          {expanded ? <FirstPageIcon fontSize="small" /> : <LastPageIcon fontSize="small" />}
        </Button>
      </div>

      {children}
    </div>
  );
};

export default memo(TopLevelList);
