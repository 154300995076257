import { useSetRecoilState } from 'recoil';

import { useAssignMethodToContact, useAssignMethodToContactOptions }  from 'hooks/useAssignMethodToContact';
import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { useContextActions2 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_MORPH_HAS_MORPHEME_TO_CONTACT } from 'methods/morph-has-morpheme/api';
import useUpdateMorphEntities from 'modules/morphemes/hooks/useUpdateMorphEntities';
import {
  Morpheme_Morphs_Consensus_Annotations_Entities_State,
  Morpheme_Morphs_Predictions_Entities_State,
} from 'modules/morphemes/store';
import { ASSIGN_MORPH_TO_CONTACT, MARK_MORPH_AS_DONE, MARK_MORPH_AS_NOT_DONE } from 'modules/morphs/api';
import { Edge_Direction, Methods } from 'shared/constants';
import { AssignMethodConfig, DataItem } from 'types';

type useContextActionsParams = {
  parentItem: DataItem;
}

const assignMethodConfig: AssignMethodConfig = {
  method: Methods.Morph_has_Morpheme,
  direction: Edge_Direction.INVERSE,
}

export default function useContextActions(params: useContextActionsParams) {
  const { parentItem } = params;

  const update_Morphs_Consensus_Annotations = useSetRecoilState(Morpheme_Morphs_Consensus_Annotations_Entities_State);
  const update_Morphs_Predictions = useSetRecoilState(Morpheme_Morphs_Predictions_Entities_State);

  const onAssignToContactCompleted = useUpdateMorphEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_MORPH_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateMorphEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_MORPH_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateMorphEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_MORPH_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const settersOrUpdaters = [
    update_Morphs_Consensus_Annotations,
    update_Morphs_Predictions,
    null,
    null,
  ] as const;
  const assignMethodToContactOptions = useAssignMethodToContactOptions({ settersOrUpdaters });
  const assignMethodToContact = useAssignMethodToContact(assignMethodConfig, ASSIGN_MORPH_HAS_MORPHEME_TO_CONTACT, parentItem, assignMethodToContactOptions)
  const contextActions = useContextActions2('/morphs', { assignToContact, assignMethodToContact, markAsDone, markAsNotDone });

  return contextActions;
}
