import MainLayout from 'layouts/MainLayout';
import { Authenticated } from 'modules/auth/components/Authenticated';

export default function Home() {
  return (
    <>
      <h1>Home</h1>
      <p>This is home page</p>
    </>
  );
}

Home.getLayout = (page: any) => (
  <Authenticated>
    <MainLayout>
      {page}
    </MainLayout>
  </Authenticated>
);
