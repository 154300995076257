/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, memo, SyntheticEvent } from 'react';

import { Mark, TextSpan } from './types';

import styles from './Mark.module.css';

type MarkProps = Mark & {
  text?: string;
  onClick?: (e: SyntheticEvent, span: TextSpan) => void;
}

const MarkWrapper: FC<MarkProps> = (props: MarkProps) => {
  const {
    anchor,
    text,
    textSpan,
    onClick,
  } = props;

  return (
    <div className={styles.root}>
      <div className={styles.mark} style={{ borderTopColor: textSpan.meta?.color }}></div>
      {anchor && (
        <div className={styles.anchor}>
          {textSpan.meta?.active
            ? (
              <a
                href="#"
                className={styles.text}
                title={text}
                style={{
                  color: textSpan.meta?.color,
                }}
                onClick={(e: SyntheticEvent) => onClick?.(e, textSpan)}
              >
                {text || <b>...</b>}
              </a>
            )
            : (
              <span
                className={styles.text}
                title={text}
                style={{
                  color: textSpan.meta?.color,
                }}
              >
                {text || <b>...</b>}
              </span>
            )
          }
        </div>
      )}
    </div>
  )
};

export default memo(MarkWrapper);
