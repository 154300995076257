import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { DataItem } from 'types';

import { GraphApiItem, Query } from './types';

const filterFactory = (term: string) => ({ name }: { name: string }) => name.toLowerCase().startsWith(term);

export default function useData<T extends GraphApiItem>(query: Query, field: string) {
  const { loading, data: rawData } = useQuery(query);
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    if (loading) {
      setData([]);
    } else {
      setData(
        (rawData?.[field] || []).map((item: T) => ({
          id: item.id,
          name: item.name,
        })),
      );
    }
  }, [loading, rawData, field]);

  const getData = useCallback(
    (search?: string) => {
      const items = rawData?.[field] || [];

      if (search) {
        const callback = filterFactory(search.toLowerCase());
        setData(items.filter(callback));
      } else {
        setData(items);
      }
    },
    [rawData, field],
  );

  return {
    loading,
    data,
    getData,
  };
}
