import { PropsWithChildren } from 'react';

import DataSelectionProvider from 'providers/DataSelectionProvider';

import DataPageContext from './DataPageContext';

type Props = PropsWithChildren<{}>;

const DataPageProvider = ({ children }: Props) => {
  return (
    <DataPageContext.Provider value={null}>
      <DataSelectionProvider>{children}</DataSelectionProvider>
    </DataPageContext.Provider>
  );
};

export default DataPageProvider;
