import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    color: '#000000',
    padding: theme.spacing(1.5, 0),
  },
  inputIcon: {
    color: '#9E9E9E',
  },
  rangeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
  },
  rangeInput: {
    flexGrow: 1,
  },
}));

export default useStyles;
