import { useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';

import NameForm from './NameForm';

type Props = {
  editable?: boolean;
  value: any;
  onClose: () => void;
  onChange: (value: any) => void;
};

export default function MorphemeNameModal({ editable = false, value, onClose, onChange }: Props) {
  const formikRef = useRef<any>();

  const handleClick = () => {
    const { submitForm } = formikRef.current;
    submitForm();
  };

  return (
    <Modal
      title={editable ? 'Edit / Name' : 'Add / Name'}
      open
      onClose={onClose}
      actionsContent={
        <Button variant="contained" color="primary" onClick={handleClick}>
          {editable ? 'Update' : 'Add'}
        </Button>
      }
    >
      <NameForm initialValues={value} formRef={formikRef} onSubmit={onChange} />
    </Modal>
  );
}
