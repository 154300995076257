import { useCallback, useMemo, useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { FilterParamsType } from 'modules/morphs/constants';

import Form from './FilterForm';

type Props = {
  value: FilterParamsType;
  onClose: () => void;
  onChange: (value: FilterParamsType) => void;
  onReset: () => void;
};

export default function MorphsFilterModal({ value, onClose, onChange, onReset }: Props) {
  const formikRef = useRef<any>();
  const initialValues = useMemo(
    () => ({
      morph: value.morph,
      language: value.language,
      countCharacters: {
        min: value.countCharactersMin,
        max: value.countCharactersMax,
      },
      onlyAssigned: value.onlyAssigned,
      onlyDone: value.onlyDone,
    }),
    [value],
  );

  const handleSubmit = useCallback(
    (values: any) => {
      onChange({
        morph: values.morph,
        language: values.language,
        countCharactersMin: values.countCharacters?.min,
        countCharactersMax: values.countCharacters?.max,
        onlyAssigned: values.onlyAssigned,
        onlyDone: values.onlyDone,
      });
      onClose();
    },
    [onClose, onChange],
  );

  const handleFilter = useCallback(() => {
    const { submitForm } = formikRef.current;
    submitForm();
  }, []);

  return (
    <Modal
      title="Filter"
      open
      actionsContent={
        <>
          <Button variant="contained" color="primary" onClick={handleFilter}>
            Filter
          </Button>
          <Button variant="contained" color="default" onClick={onReset}>
            Reset
          </Button>
        </>
      }
      onClose={onClose}
    >
      <Form initialValues={initialValues} formRef={formikRef} onSubmit={handleSubmit} />
    </Modal>
  );
}
