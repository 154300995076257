import { memo, ReactNode, useCallback } from 'react';
import { FieldInputProps, getIn, useFormik } from 'formik';

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

import SearchAutocomplete from 'components/Controls/SearchAutocomplete';
import RangeInput from 'components/RangeInput';

// TODO: move to utils
export const FORM_ERROR = '@FORM_ERROR';
export const getSubmissionError = ({ errors }: any) => errors[FORM_ERROR];

export const renderTextField = (props: any) => {
  const {
    field,
    form: { errors, submitCount, touched },
    label,
    margin,
    ...others
  } = props;

  const touchedOrSubmitted = getIn(touched, field.name) || submitCount > 0;
  const error = errors[field.name];
  const submitError = getSubmissionError({ errors });

  return (
    <TextField
      label={label}
      margin={margin || 'none'}
      error={(!!error || !!submitError) && touchedOrSubmitted}
      helperText={touchedOrSubmitted && (error || submitError)}
      {...field}
      {...others}
    />
  );
};

export const renderAutoCompleteField = (props: any) => {
  const {
    field,
    form: { getFieldHelpers },
    onChange,
    parse,
    ...others
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleChange = useCallback(
    (e: any, value: any) => {
      const valueOrParsedValue = parse ? parse(value) : value;
      const helpers = getFieldHelpers(field.name);

      helpers.setValue(valueOrParsedValue);
      onChange?.(e, valueOrParsedValue);
    },
    [field.name, parse, getFieldHelpers, onChange],
  );

  return <SearchAutocomplete {...others} {...field} onChange={handleChange} />;
};

export const renderRadioField = (props: any) => {
  const { field, label, classes, labelClasses, inputProps, ...others } = props;

  return (
    <FormControlLabel
      classes={labelClasses}
      control={
        <Radio
          classes={classes}
          inputProps={inputProps}
          checked={inputProps.value === field.value}
          {...field}
          {...others}
        />
      }
      label={label}
    />
  );
};

export type RadioGroupOption = {
  label: ReactNode
  value: string
};

export type RadioGroupFieldProps<T extends RadioGroupOption> = {
  field: FieldInputProps<any>
  form: ReturnType<typeof useFormik>
  label?: string
  orientation?: 'horizontal' | 'vertical'
  options: Array<T>
  radioProps?: RadioProps
};

export const renderRadioGroupField = <T extends RadioGroupOption>(props: RadioGroupFieldProps<T>) => {
  const {
    field,
    form,
    label,
    options,
    orientation = 'vertical',
    radioProps,
  } = props;
  const { getFieldHelpers } = form;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleChange = useCallback(
    (event: any) => {
      const helpers = getFieldHelpers(field.name);
      helpers.setValue(event.target.value);
    },
    [field.name, getFieldHelpers],
  );

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        {...field}
        style={{ flexDirection: orientation === 'vertical' ? 'column' : 'row' }}
        onChange={handleChange}
      >
        {options.map((option: RadioGroupOption) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            control={
              <Radio {...radioProps} />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export type CheckboxFieldProps = {
  field: FieldInputProps<any>;
  form: ReturnType<typeof useFormik>;
  label?: string;
  checkboxProps?: CheckboxProps;
};

export const renderCheckboxField = memo((props: CheckboxFieldProps) => {
  const { field, label, checkboxProps } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          {...checkboxProps}
          checked={Array.isArray(field.value)
            ? field.value.includes(checkboxProps?.value)
            : field.value === checkboxProps?.value
          }
        />
      }
      label={label}
    />
  );
});

export const renderRangeInputField = (props: any) => {
  const { field, form, label } = props;
  const { getFieldHelpers } = form;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleChange = useCallback(
    (value: any) => {
      const helpers = getFieldHelpers(field.name);
      helpers.setValue(value);
    },
    [field.name, getFieldHelpers],
  );

  return <RangeInput label={label} name={field.name} value={field.value} onChange={handleChange} />;
};
