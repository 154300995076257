import { useMemo } from 'react';

export default function useActions(actions: any, actions1: any): any {
  return useMemo(() => {
    return {
      ...actions,
      ...actions1,
    };
  }, [actions1, actions]);
}
