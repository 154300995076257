import { FieldArray, Form, Formik } from 'formik';

import { DataItem, LanguageItem } from 'types';

import TokenHasMorphTargetAssetEntry from './TokenHasMorphTargetAssetEntry';

type Values = {
  targetAssets: DataItem[];
};

type Props = {
  initialValues: Values;
  language: LanguageItem;
  formRef?: any;
  onSubmit: (values: Values) => void;
};

export default function TokenHasMorphForm({ initialValues, language, formRef, onSubmit }: Props) {
  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <FieldArray name="targetAssets">
            {(arrayHelpers) => (
              <>
                {values.targetAssets.map((targetAssets: any, index: number) => (
                  <TokenHasMorphTargetAssetEntry
                    key={index}
                    name={`targetAssets.${index}`}
                    language={language}
                    onRemove={() => arrayHelpers.remove(index)}
                  />
                ))}
                <TokenHasMorphTargetAssetEntry onAdd={() => arrayHelpers.push({ name: '' })} />
              </>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
}
