import { useMemo } from 'react';

import DataPage, { DataPageRenderProps } from 'components/DataPage';
import useModalWithValue from 'hooks/useModalWithValue';
import MainLayout from 'layouts/MainLayout';
import { Authenticated } from 'modules/auth/components/Authenticated';
import FilterModal from 'modules/classes/components/FilterModal';
import NameModal from 'modules/classes/components/NameModal';
import SortModal from 'modules/classes/components/SortModal';
import {
  INITIAL_FILTER_PARAMS,
  INITIAL_NAME_PARAMS,
  INITIAL_SORT_PARAMS,
  SORT_OPTIONS,
} from 'modules/classes/constants';
import useClass from 'modules/classes/hooks/useClass';
import useClassContextActions from 'modules/classes/hooks/useClassContextActions';
import useClassesQuery from 'modules/classes/hooks/useClassesQuery';
import useResetClasses from 'modules/classes/hooks/useResetClasses';
import AssociatedTokensDataSection from 'modules/classes/sections/AssociatedTokensDataSection';
import ChildrenClassesDataSection from 'modules/classes/sections/ChildrenClassesDataSection';
import ClustersDataSection from 'modules/classes/sections/ClustersDataSection';
import ConversionChildrenClassesDataSection from 'modules/classes/sections/ConversionChildrenClassesDataSection';
import ConversionParentsClassesDataSection from 'modules/classes/sections/ConversionParentsClassesDataSection';
import ParentsClassesDataSection from 'modules/classes/sections/ParentsClassesDataSection';
import TagsDataSection from 'modules/classes/sections/TagsDataSection';
import { transformVariables } from 'modules/classes/utils';
import DataPageProvider from 'providers/DataPageProvider';

export default function Classes() {
  const resetClasses = useResetClasses();

  const {
    // open: sortModalOpen,
    value: sortModalValue,
    meta: sortMeta,
    openModal: openSortModal,
    closeModal: closeSortModal,
    setValue: setSortModalValue,
  } = useModalWithValue({ initialValue: INITIAL_SORT_PARAMS, onValueChanged: resetClasses });

  const {
    // open: filterModalOpen,
    value: filterModalValue,
    meta: filterMeta,
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    setValue: setFilterModalValue,
    resetValue: resetFilter,
  } = useModalWithValue({ initialValue: INITIAL_FILTER_PARAMS, onValueChanged: resetClasses });

  const {
    open: nameModalOpen,
    value: nameModalValue,
    openModal: openNameModal,
    closeModal: closeNameModal,
  } = useModalWithValue({ initialValue: INITIAL_NAME_PARAMS });

  const { createClass } = useClass({
    onCreate() {
      const resetOnClose = true;
      closeNameModal(resetOnClose);
    }
  })

  const actions = useMemo(
    () => ({
      add: {
        handler: openNameModal,
      },
      sort: {
        meta: sortMeta,
        handler: openSortModal,
      },
      filter: {
        meta: filterMeta,
        handler: openFilterModal,
      },
    }),
    [openNameModal, openSortModal, openFilterModal, sortMeta, filterMeta],
  );
  const contextActions = useClassContextActions();
  const dataVariables = useMemo(() => transformVariables(filterModalValue, sortModalValue, {}), [filterModalValue, sortModalValue]);

  return (
    <DataPageProvider>
      <DataPage
        title="Classes"
        useQuery={useClassesQuery}
        dataVariables={dataVariables}
        actions={actions}
        contextActions={contextActions}
      >
        {({ currentItem }: DataPageRenderProps) => (
          <>
            {currentItem && (
              <>
                <TagsDataSection parentItem={currentItem} />
                {/* <AssociatedTokensDataSection parentItem={currentItem} /> */}
                <ClustersDataSection parentItem={currentItem} />
                {/* <ParentsClassesDataSection parentItem={currentItem} />
                <ChildrenClassesDataSection parentItem={currentItem} />
                <ConversionParentsClassesDataSection parentItem={currentItem} />
                <ConversionChildrenClassesDataSection parentItem={currentItem} /> */}
              </>
            )}

            {false && (
              <SortModal
                value={sortModalValue}
                options={SORT_OPTIONS}
                onChange={setSortModalValue}
                onClose={closeSortModal}
              />
            )}

            {false && (
              <FilterModal
                value={filterModalValue}
                onChange={setFilterModalValue}
                onClose={closeFilterModal}
                onReset={resetFilter}
              />
            )}

            {nameModalOpen && (
              <NameModal
                value={nameModalValue}
                onChange={createClass}
                onClose={closeNameModal}
              />
            )}
          </>
        )}
      </DataPage>
    </DataPageProvider>
  );
}

Classes.getLayout = (page: any) => (
  <Authenticated>
    <MainLayout>
      {page}
    </MainLayout>
  </Authenticated>
);
