import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Phrases_Entities_State, Phrases_Ids_State } from './atoms';

export * from "../sections/SemanticEntitiesDataSection/selectors"

export const Phrases_selector = listSelector<DataItem>(
  'graph/phrases/@selectors/list',
  Phrases_Entities_State,
  Phrases_Ids_State,
);
