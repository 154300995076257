import { useSetRecoilState } from 'recoil';

import { useAssignMethodToContactOptions }  from 'hooks/useAssignMethodToContact';
import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { useContextActions2 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import useAssignTokenHasMeaningToContact from 'methods/token-has-meaning/hooks/useAssignTokenHasMeaningToContact';
import { ASSIGN_TAG_TO_CONTACT, MARK_TAG_AS_DONE, MARK_TAG_AS_NOT_DONE } from 'modules/tags/api';
import useUpdateTagEntities from 'modules/tokens/hooks/useUpdateTagEntities';
import {
  Token_Tags_Consensus_Annotations_Entities_State,
  Token_Tags_Predictions_Entities_State,
  Tokens_Entities_State,
} from 'modules/tokens/store';
import { Edge_Direction, Methods } from 'shared/constants';
import { AssignMethodConfig, DataItem } from 'types';

const assignMethodConfig: AssignMethodConfig = {
  method: Methods.Token_has_Meaning,
  direction: Edge_Direction.DIRECT,
};

type UseContextActionsParams = {
  languageId: number;
  parentItem: DataItem;
};

export default function useContextActions(params: UseContextActionsParams) {
  const { languageId, parentItem } = params;

  const update_Tokens = useSetRecoilState(Tokens_Entities_State);
  const update_Tags_Consensus_Annotations = useSetRecoilState(Token_Tags_Consensus_Annotations_Entities_State);
  const update_Tags_Predictions = useSetRecoilState(Token_Tags_Predictions_Entities_State);

  const onAssignToContactCompleted = useUpdateTagEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_TAG_TO_CONTACT, { onCompleted: onAssignToContactCompleted});

  const onMarkAsDoneCompleted = useUpdateTagEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_TAG_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateTagEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_TAG_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const settersOrUpdaters = [
    update_Tags_Consensus_Annotations,
    update_Tags_Predictions,
    null,
    update_Tokens,
  ] as const;
  const assignMethodToContactOptions = useAssignMethodToContactOptions({ parentItem, settersOrUpdaters });
  const assignMethodToContact = useAssignTokenHasMeaningToContact(assignMethodConfig, languageId, parentItem, assignMethodToContactOptions);

  return useContextActions2('/tags', { assignToContact, assignMethodToContact, markAsDone, markAsNotDone });
}

