import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ContextAction, useContextActions1 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import {
  ASSIGN_ITEM_SPECIFICATION_TO_CONTACT,
  MARK_ITEM_SPECIFICATION_AS_DONE,
  MARK_ITEM_SPECIFICATION_AS_NOT_DONE,
} from 'modules/item-specifications/api/graphApi';
import useUpdateSpecificationItemsEntities from 'modules/item-specifications/hooks/useUpdateSpecificationItemsEntities';

export default function useSpecificationItemContextActions(): ContextAction[] {
  const onAssignToContactCompleted = useUpdateSpecificationItemsEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_ITEM_SPECIFICATION_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateSpecificationItemsEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_ITEM_SPECIFICATION_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateSpecificationItemsEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_ITEM_SPECIFICATION_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  return useContextActions1({ assignToContact, markAsDone, markAsNotDone });
}
