import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  Tag_Classes_Consensus_Annotations_Entities_State,
  Tag_Classes_Options_Entities_State,
  Tag_Classes_Predictions_Entities_State,
} from 'modules/tags/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

export type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateClassEntities(params: Params) {
  const { transform } = params;

  const update_Classes_Consensus_Annotations = useSetRecoilState(Tag_Classes_Consensus_Annotations_Entities_State);
  const update_Classes_Predictions = useSetRecoilState(Tag_Classes_Predictions_Entities_State);
  const update_Classes_Options = useSetRecoilState(Tag_Classes_Options_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const);
    const updater = entitiesUpdaterFactory(entries);

    update_Classes_Consensus_Annotations(updater);
    update_Classes_Predictions(updater);
    update_Classes_Options(updater);
  }, [
    transform,
    update_Classes_Consensus_Annotations,
    update_Classes_Predictions,
    update_Classes_Options,
  ])
}
