import { useSetRecoilState } from 'recoil';

import { useAnnotationOptions } from 'hooks/useAnnotation';
import useTokenJoinAnnotations from 'methods/token-join/hooks/useTokenJoinAnnotations';
import { extractId } from 'methods/token-join/store';
import {
  Token_Token_Join_Consensus_Annotations_Entities_State,
  Token_Token_Join_Consensus_Annotations_Ids_State,
  Token_Token_Join_Options_Entities_State,
  Token_Token_Join_Options_Ids_State,
  Token_Token_Join_Predictions_Entities_State,
  Token_Token_Join_Predictions_Ids_State,
} from 'modules/tokens/store';
import { Status } from 'shared/constants';

export default function useAnnotations() {
  const updateConsensusAnnotationsEntities = useSetRecoilState(Token_Token_Join_Consensus_Annotations_Entities_State);
  const updateConsensusAnnotationsIds = useSetRecoilState(Token_Token_Join_Consensus_Annotations_Ids_State);
  const updatePredictionsEntities = useSetRecoilState(Token_Token_Join_Predictions_Entities_State);
  const updatePredictionsIds = useSetRecoilState(Token_Token_Join_Predictions_Ids_State);
  const updateOptionsEntities = useSetRecoilState(Token_Token_Join_Options_Entities_State);
  const updateOptionsIds = useSetRecoilState(Token_Token_Join_Options_Ids_State);

  const settersOrUpdaters = [
    [updateConsensusAnnotationsEntities, updateConsensusAnnotationsIds] as const,
    [updatePredictionsEntities, updatePredictionsIds],
    [updateOptionsEntities, updateOptionsIds],
  ] as const;

  const { onCompleted: onConfirmCompleted } = useAnnotationOptions({ extractId, settersOrUpdaters, status: Status.confirmed });
  const { onCompleted: onDeclineCompleted } = useAnnotationOptions({ extractId, settersOrUpdaters, status: Status.declined });
  const annotationsOptions = { onConfirmCompleted, onDeclineCompleted };

  return useTokenJoinAnnotations(annotationsOptions);
}
