import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { useContextActions2 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import useAssignTokenJoinToContact from 'methods/token-join/hooks/useAssignTokenJoinToContact';
import { ASSIGN_TOKEN_TO_CONTACT, MARK_TOKEN_AS_DONE, MARK_TOKEN_AS_NOT_DONE } from 'modules/tokens/api';
import useUpdateTokenEntities, { UseUpdateTokenEntitiesConfig } from 'modules/tokens/hooks/useUpdateTokenEntities';

import useAssignMethodToContactOptions from './useAssignMethodToContactOptions'

const config: UseUpdateTokenEntitiesConfig = {
  Token_Join_Predictions: false,
  Token_Join_Options: false,
};

export default function useContextActions() {
  const onAssignToContactCompleted = useUpdateTokenEntities({ config, transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_TOKEN_TO_CONTACT, { onCompleted: onAssignToContactCompleted});

  const onMarkAsDoneCompleted = useUpdateTokenEntities({ config, transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_TOKEN_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateTokenEntities({ config, transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_TOKEN_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const assignMethodToContactOptions = useAssignMethodToContactOptions();
  const assignMethodToContact = useAssignTokenJoinToContact(assignMethodToContactOptions);

  return useContextActions2('/tokens', { assignToContact, assignMethodToContact, markAsDone, markAsNotDone });
}

