import { useSetRecoilState } from 'recoil';

import { useAssignMethodToContact, useAssignMethodToContactOptions } from 'hooks/useAssignMethodToContact';
import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { useContextActions2 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_CLASS_HAS_CLUSTER_TO_CONTACT } from 'methods/class-has-cluster/api';
import useUpdateClusterEntities from 'modules/classes/hooks/useUpdateClusterEntities';
import { ASSIGN_CLUSTER_TO_CONTACT, MARK_CLUSTER_AS_DONE, MARK_CLUSTER_AS_NOT_DONE } from 'modules/clusters/api';
import {
  Cluster_Classes_Consensus_Annotations_Entities_State,
} from 'modules/clusters/store';
import { Edge_Direction, Methods } from 'shared/constants';
import { AssignMethodConfig, DataItem } from 'types';

type useContextActionsParams = {
  parentItem: DataItem;
}

const assignMethodConfig: AssignMethodConfig = {
  method: Methods.Class_has_Cluster,
  direction: Edge_Direction.DIRECT,
}

export default function useContextActions(params: useContextActionsParams) {
  const { parentItem } = params;

  const update_Classes_Consensus_Annotations = useSetRecoilState(Cluster_Classes_Consensus_Annotations_Entities_State);

  const onAssignToContactCompleted = useUpdateClusterEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_CLUSTER_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateClusterEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_CLUSTER_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateClusterEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_CLUSTER_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const settersOrUpdaters = [
    update_Classes_Consensus_Annotations,
    null,
    null,
    null,
  ] as const;
  const assignMethodToContactOptions = useAssignMethodToContactOptions({ settersOrUpdaters });
  const assignMethodToContact = useAssignMethodToContact(assignMethodConfig, ASSIGN_CLASS_HAS_CLUSTER_TO_CONTACT, parentItem, assignMethodToContactOptions);

  return useContextActions2('/clusters', { assignToContact, assignMethodToContact, markAsDone, markAsNotDone });
}
