import { useMemo } from 'react';

import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ActionVisibleParams, ContextAction, HandlerParams, useContextActions1 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_CLUSTER_TO_CONTACT, MARK_CLUSTER_AS_DONE, MARK_CLUSTER_AS_NOT_DONE } from 'modules/clusters/api';
import useUpdateClusterEntities from 'modules/clusters/hooks/useUpdateClusterEntities';
import { DataItem } from 'types';

type useClusterContextActionsParams = {
  editSeCreation: (item: DataItem) => void;
};

export default function useClusterContextActions(params: useClusterContextActionsParams): ContextAction[] {
  const { editSeCreation } = params;

  const onAssignToContactCompleted = useUpdateClusterEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_CLUSTER_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateClusterEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_CLUSTER_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateClusterEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_CLUSTER_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const contextActions = useContextActions1({ assignToContact, markAsDone, markAsNotDone });

  return useMemo(
    () => {
      return [
        ...contextActions,
        {
          id: 'SET_SE_CREATION',
          title: 'Set SE-Creation',
          meta: {
            actionAllowed: () => true,
            actionVisible: ({ selectedItems }: ActionVisibleParams) => selectedItems.length < 2,
          },
          handler: ({ contextItem }: HandlerParams) => editSeCreation(contextItem),
        },
      ]
    }, [contextActions, editSeCreation]
  )
}
