import { useCallback, useMemo, useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { FilterParamsType } from 'modules/item-specifications/constants';

import Form, { FormValues } from './FilterForm';
import { AssignedValueTransformer, DoneValueTransformer } from './transformers';

const assignedValueTransformer = new AssignedValueTransformer();
const doneValueTransformer = new DoneValueTransformer();

type Props = {
  value: FilterParamsType;
  onClose: () => void;
  onChange: (value: FilterParamsType) => void;
  onReset: () => void;
};

export default function ItemSpecificationsFilterModal({ value, onClose, onChange, onReset }: Props) {
  const formikRef = useRef<any>();

  const initialValues: FormValues = useMemo(
    () => ({
      trades: value.trades,
      groupsOfWorkItems: value.groupsOfWorkItems,
      din276CostGroups: value.din276CostGroups,
      source: value.source.map(it => it.toString()),
      assigned: assignedValueTransformer.toValue(value.onlyAssigned),
      done: doneValueTransformer.toValue(value.onlyDone),
    }),
    [value],
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onChange({
        trades: values.trades,
        groupsOfWorkItems: values.groupsOfWorkItems,
        din276CostGroups: values.din276CostGroups,
        source: values.source.map(it => parseInt(it, 10)),
        onlyAssigned: assignedValueTransformer.toBoolean(values.assigned),
        onlyDone: doneValueTransformer.toBoolean(values.done),
      });
      onClose();
    },
    [onClose, onChange],
  );

  const handleFilter = useCallback(() => {
    const { submitForm } = formikRef.current;
    submitForm();
  }, []);

  const handleReset = useCallback(() => {
    const { resetForm } = formikRef.current;
    resetForm();
    onReset();
  }, [onReset]);

  return (
    <Modal
      title="Filter"
      open
      actionsContent={
        <>
          <Button variant="contained" color="primary" onClick={handleFilter}>
            Filter
          </Button>
          <Button variant="contained" color="default" onClick={handleReset}>
            Reset
          </Button>
        </>
      }
      onClose={onClose}
    >
      <Form initialValues={initialValues} formRef={formikRef} onSubmit={handleSubmit} />
    </Modal>
  );
}
