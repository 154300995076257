import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import { UPDATE_CLUSTER_SE_CREATION } from 'modules/clusters/api';
import { Clusters_Entities_State } from 'modules/clusters/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type UseClusterSeCreationParams = {
  onEdit?: (item: DataItem) => void;
  onUpdate?: (value: number) => void;
};

type UseClusterSeCreationResult = {
  editSeCreation: (item: DataItem) => void;
  updateSeCreation: (value: number) => void;
};

export default function useClusterSeCreation(
  params: UseClusterSeCreationParams = {},
): UseClusterSeCreationResult {
  const { onEdit, onUpdate } = params;

  const itemRef = useRef<DataItem | null>(null);
  const valueRef = useRef<number | null>(null);
  const updateEntities = useSetRecoilState(Clusters_Entities_State);

  const onCompleted = useCallback(() => {
    const entries = [
      [itemRef.current?.id as string, { se_creation: valueRef.current as number } as DataItem] as const
    ]
    const entitiesUpdater = entitiesUpdaterFactory(entries);
    updateEntities(entitiesUpdater);
  }, [
    updateEntities,
  ]);
  const [updateSECreation] = useMutation(UPDATE_CLUSTER_SE_CREATION, { onCompleted });

  const editSeCreation = useCallback((item: DataItem) => {
    itemRef.current = item;
    onEdit?.(itemRef.current);
  }, [onEdit]);

  const updateSeCreation = useCallback((value: number) => {
    valueRef.current = value
    updateSECreation({
      variables: {
        input: {
          id: itemRef.current?.id as string,
          se_creation: value,
        }
      },
    });
    onUpdate?.(value);
  }, [updateSECreation, onUpdate]);

  return {
    editSeCreation,
    updateSeCreation,
  };
}
