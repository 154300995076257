import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const SemanticEntity_Next_SemanticEntities_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/semantic-entities/@atoms/Next_SemanticEntities/entities',
  default: ImmutableMap(),
});

export const SemanticEntity_Next_SemanticEntities_Ids_State = atom<string[]>({
  key: 'graph/semantic-entities/@atoms/Next_SemanticEntities/ids',
  default: [],
});
