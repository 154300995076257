import { setContext } from '@apollo/client/link/context';

import { getAccessTokenPromise, getGraphApiAccessTokenPromise } from 'modules/auth/utils';

async function getAccessTokenPromiseWrapper(name: string) {
  if (name === 'applicationApi') {
    return await getAccessTokenPromise();
  }

  return await getGraphApiAccessTokenPromise();
}

function getHeadersWrapper(name: string) {
  if (name === 'applicationApi') {
    return {
      'x-application': window.APPLICATION_API_APPLICATION_ID,
    };
  }

  return {};
}

export const authLink = setContext(async (_, { headers, apiName = 'graphApi' }) => {
  const accessToken = await getAccessTokenPromiseWrapper(apiName);

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      ...getHeadersWrapper(apiName)
    }
  };
});
