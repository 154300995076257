import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export * from "../sections/ClassesDataSection/atoms"

export const Clusters_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/clusters/@atoms/entities',
  default: ImmutableMap(),
});

export const Clusters_Ids_State = atom<string[]>({
  key: 'graph/clusters/@atoms/ids',
  default: [],
});
