import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { extractId } from 'methods/token-join/store';
import {
  Token_Token_Join_Consensus_Annotations_Entities_State,
  Token_Token_Join_Predictions_Entities_State,
  Tokens_Entities_State,
} from 'modules/tokens/store';
import { Status } from 'shared/constants'
import { entitiesMergerFactory, entitiesUpdaterFactory } from 'shared/store'
import { DataItem } from 'types';

export default function useAssignMethodToContactOptions() {
  const updateEntities = useSetRecoilState(Tokens_Entities_State);
  const updateConsensusAnnotations = useSetRecoilState(Token_Token_Join_Consensus_Annotations_Entities_State);
  const updatePredictions = useSetRecoilState(Token_Token_Join_Predictions_Entities_State);

  const onCompleted = useCallback((result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [extractId(item), { active_contact_method_annotation_status: Status.assigned } as DataItem] as const)
    const updater = entitiesUpdaterFactory(entries);
    updateConsensusAnnotations(updater);
    updatePredictions(updater);

    const itemMap = new Map<string, number>()
    const updatedItems = items.filter(item => item?.active_contact_method_annotation_status !== Status.assigned);
    updatedItems.forEach(item => {
      item?.Token_Join_sequence?.forEach(tokenId => {
        const value = itemMap.get(tokenId) || 0;
        itemMap.set(tokenId, value + 1);
      });
    });

    const entries2 = Array.from(itemMap.entries())
      .map(entry => [entry[0], { active_contact_methods_count_is_assigned: entry[1] } as DataItem] as const)
    const updater2 = entitiesMergerFactory(
      entries2,
      (oldVal, newVal) => ({
        ...oldVal,
        active_contact_methods_count_is_assigned:
          (oldVal?.active_contact_methods_count_is_assigned || 0) +
          (newVal?.active_contact_methods_count_is_assigned || 0),
      }),
    );
    updateEntities(updater2);
  }, [
    updateConsensusAnnotations,
    updatePredictions,
    updateEntities,
  ]);

  return {
    onCompleted,
  }
}
