import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_MORPH_TOKENS_CONSENSUS_ANNOTATIONS,
  QUERY_MORPH_TOKENS_OPTIONS,
  QUERY_MORPH_TOKENS_PREDICTIONS,
} from 'methods/token-has-morph/api';
import {
  Morph_Associated_Tokens_Consensus_Annotations_selector,
  Morph_Associated_Tokens_Options_selector,
  Morph_Associated_Tokens_Predictions_selector,
} from 'modules/morphs/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.morphs?.[0]?.Associated_Tokens_Consensus_Annotations || [], []);

  return useNestedQuery(
    QUERY_MORPH_TOKENS_CONSENSUS_ANNOTATIONS,
    variables,
    Morph_Associated_Tokens_Consensus_Annotations_selector,
    transform,
  );
}

export const usePredictionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.morphs?.[0]?.Associated_Tokens_Predictions || [], []);

  return useNestedQuery(
    QUERY_MORPH_TOKENS_PREDICTIONS,
    variables,
    Morph_Associated_Tokens_Predictions_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.morphs?.[0]?.Associated_Tokens_Options || [], []);

  return useNestedQuery(
    QUERY_MORPH_TOKENS_OPTIONS,
    variables,
    Morph_Associated_Tokens_Options_selector,
    transform,
  );
}
