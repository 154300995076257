import { useSetRecoilState } from 'recoil';

import { useAnnotationOptions } from 'hooks/useAnnotation';
import useTagHasClassAnnotations from 'methods/tag-has-class/hooks/useTagHasClassAnnotations';
import {
  Tag_Classes_Consensus_Annotations_Entities_State,
  Tag_Classes_Consensus_Annotations_Ids_State,
  Tag_Classes_Options_Entities_State,
  Tag_Classes_Predictions_Entities_State,
  Tag_Classes_Predictions_Ids_State,
} from 'modules/tags/store';
import { Status } from 'shared/constants';
import { AnnotationConfig, DataItem } from 'types';

const annotationConfig: AnnotationConfig = {
  parentIdField: 'baseAssetId',
  itemIdField: 'targetAssetId',
};

type useAnnotationsParams = {
  parentItem: DataItem;
};

type useAnnotationsResult = {
  confirmAnnotation: (item: DataItem, values?: any) => void;
  declineAnnotation: (item: DataItem, values?: any) => void;
};

export default function useAnnotations(params: useAnnotationsParams): useAnnotationsResult {
  const { parentItem } = params;

  const updateConsensusAnnotationsEntities = useSetRecoilState(Tag_Classes_Consensus_Annotations_Entities_State);
  const updateConsensusAnnotationsIds = useSetRecoilState(Tag_Classes_Consensus_Annotations_Ids_State);
  const updatePredictionsEntities = useSetRecoilState(Tag_Classes_Predictions_Entities_State);
  const updatePredictionsIds = useSetRecoilState(Tag_Classes_Predictions_Ids_State);
  const updateOptionsEntities = useSetRecoilState(Tag_Classes_Options_Entities_State);

  const settersOrUpdaters = [
    [updateConsensusAnnotationsEntities, updateConsensusAnnotationsIds],
    [updatePredictionsEntities, updatePredictionsIds],
    updateOptionsEntities,
  ] as const;

  const { onCompleted: onConfirmCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.confirmed });
  const { onCompleted: onDeclineCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.declined });
  const annotationsOptions = { onConfirmCompleted, onDeclineCompleted };

  return useTagHasClassAnnotations(annotationConfig, { parentItem }, annotationsOptions);
}
