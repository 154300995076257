import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Tokens_selector } from 'modules/tokens/store';

export default function useResetTokens() {
  const reset = useResetRecoilState(Tokens_selector);

  return useCallback(() => {
    reset();
  }, [reset]);
}
