import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import { CREATE_MORPHEME, UPDATE_MORPHEME } from 'modules/morphemes/api';
import { Morphemes_Entities_State, Morphemes_Ids_State } from 'modules/morphemes/store';
import { entitiesUpdaterFactory, idsUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type UseMorphemeParams = {
  onEdit?: (item: DataItem) => void;
  onCreateOrUpdate?: () => void;
};

type UseMorphemeResult = {
  editMorpheme: (item: DataItem) => void;
  createOrUpdateMorpheme: (values: any) => void;
};

export default function useMorpheme(params: UseMorphemeParams = {}): UseMorphemeResult {
  const { onEdit, onCreateOrUpdate } = params;

  const itemRef = useRef<DataItem | null>(null);
  const updateEntities = useSetRecoilState(Morphemes_Entities_State);
  const updateIds = useSetRecoilState(Morphemes_Ids_State);

  const onCreateCompleted = useCallback((result: any) => {
    const ids: string[] = result.create_Morpheme.morphemes.map((x: DataItem) => x.id);
    const entries = (result.create_Morpheme.morphemes as DataItem[]).map(x => [x.id, x, x] as const);

    const idsUpdater = idsUpdaterFactory(ids);
    const entitiesUpdater = entitiesUpdaterFactory(entries, true);

    updateEntities(entitiesUpdater);
    updateIds(idsUpdater);
  }, [
    updateEntities,
    updateIds,
  ]);
  const [create] = useMutation(CREATE_MORPHEME, { onCompleted: onCreateCompleted });

  const onUpdateCompleted = useCallback((result: any) => {
    const entries = (result.update_Morpheme.morphemes as DataItem[]).map(x => [x.id, x] as const);
    const entitiesUpdater = entitiesUpdaterFactory(entries);
    updateEntities(entitiesUpdater);

  }, [updateEntities]);
  const [update] = useMutation(UPDATE_MORPHEME, { onCompleted: onUpdateCompleted });

  const editMorpheme = useCallback(
    (contextItem: DataItem) => {
      itemRef.current = contextItem;
      onEdit?.(itemRef.current);
    }, [onEdit]
  );

  const createOrUpdateMorpheme = useCallback(
    (values: any) => {
      if (itemRef.current) {
        update({
          variables: {
            input: {
              id: itemRef.current.id,
              name: values.name,
              language: values.language.id,
            }
          },
        });
      } else {
        create({
          variables: {
            input: {
              name: values.name,
              language: values.language.id,
            }
          },
        });
      }

      onCreateOrUpdate?.()
    }, [update, create, onCreateOrUpdate]
  );

  return {
    editMorpheme,
    createOrUpdateMorpheme,
  };
}
