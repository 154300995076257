import { AssignedValue, DoneValue } from './FilterForm';

export class AssignedValueTransformer {
  toBoolean(value: AssignedValue): boolean | undefined {
    switch (value) {
      case AssignedValue.ASSIGNED:
        return true;

      case AssignedValue.NOT_ASSIGNED:
        return false;

      default:
        return undefined;
    }
  }

  toValue(value?: boolean): AssignedValue {
    if (typeof value === "undefined") return AssignedValue.ALL;

    return value ? AssignedValue.ASSIGNED : AssignedValue.NOT_ASSIGNED;
  }
}

export class DoneValueTransformer {
  toBoolean(value: DoneValue): boolean | undefined {
    switch (value) {
      case DoneValue.DONE:
        return true;

      case DoneValue.UNDONE:
        return false;

      default:
        return undefined;
    }
  }

  toValue(value?: boolean): DoneValue {
    if (typeof value === "undefined") return DoneValue.ALL;

    return value ? DoneValue.DONE : DoneValue.UNDONE;
  }
}
