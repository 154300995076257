import { gql } from '@apollo/client';

// base asset extension
export const QUERY_TOKEN_JOIN_CONSENSUS_ANNOTATIONS = gql`
  query Token_Join_Consensus_Annotations(
    $id: BigInt
    $where: Token_Filter_Where_Input
    $options: Token_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    tokens(where: { id: $id }) {
      Token_Join_Consensus_Annotations(where: $where, options: $options, consensus: $consensus) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        consensus_status
        count_all_positions_in_phrases
        Token_Join_sequence
      }
    }
  }
`;

export const QUERY_TOKEN_JOIN_CONSENSUS_ANNOTATIONS_V2 = gql`
  query Token_Join_Consensus_Annotations_v2(
    $id: BigInt!
    $where: Token_Filter_Where_Input
    $options: Token_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    Token_Join_Consensus_Annotations_v2(id: $id, where: $where, options: $options, consensus: $consensus) {
      id
      name
      count_all_positions_in_phrases
      active_contact_method_annotation_status
      consensus_status
    }
  }
`;

export const QUERY_TOKEN_JOIN_PREDICTIONS = gql`
  query Token_Join_Predictions($id: BigInt, $where: Token_Filter_Where_Input, $options: Token_Filter_Options_Input) {
    tokens(where: { id: $id }) {
      Token_Join_Predictions(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_Join_sequence
      }
    }
  }
`;

export const QUERY_TOKEN_JOIN_PREDICTIONS_V2 = gql`
  query Token_Join_Predictions_v2($id: BigInt!, $where: Token_Filter_Where_Input, $options: Token_Filter_Options_Input) {
    Token_Join_Predictions_v2(id: $id, where: $where, options: $options) {
      id
      name
      count_all_positions_in_phrases
      active_contact_method_annotation_status
    }
  }
`;

export const QUERY_TOKEN_JOIN_OPTIONS = gql`
  query Token_Join_Options($id: BigInt, $where: Token_Filter_Where_Input, $options: Token_Filter_Options_Input) {
    tokens(where: { id: $id }) {
      Token_Join_Options(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        count_all_positions_in_phrases
        Token_Join_sequence
        Token_Join_frequency
      }
    }
  }
`;

export const QUERY_TOKEN_JOIN_OPTIONS_V2 = gql`
  query Token_Join_Options_v2($id: BigInt!, $where: Token_Filter_Where_Input, $options: Token_Filter_Options_Input) {
    Token_Join_Options_v2(id: $id, where: $where, options: $options) {
      id
      name
      Token_Join_sequence
      Token_Join_frequency
    }
  }
`;

export const QUERY_TOKEN_JOIN_PHRASES_V2 = gql`
  query Token_Join_Phrases_v2($where: Token_Join_Phrases_Where!) {
    Token_Join_Phrases_v2(where: $where) {
      id
      name
    }
  }
`;

// method node
export const ASSIGN_TOKEN_JOIN_TO_CONTACT = gql`
  mutation Assign_Token_Join_to_Contact($input: Method_Token_Join_Assign_to_Contact_Input!) {
    assign_Token_Join_to_Contact(input: $input) {
      methodTokenJoins {
        id
      }
    }
  }
`;

export const CONFIRM_TOKEN_JOIN_OPTION = gql`
  mutation Confirm_Method_Token_Join_Option($baseAssetIds: [BigInt!]) {
    confirm_Method_Token_Join_option(input: { base_asset_ids: $baseAssetIds }) {
      methodTokenJoins {
        id
      }
    }
  }
`;

export const CONFIRM_TOKEN_JOIN_OPTION_V2 = gql`
  mutation Confirm_Method_Token_Join_Option_v2($input: Token_Join_Annotation_Input!) {
    confirm_Method_Token_Join_option_v2(input: $input) {
      id
      name
      count_all_positions_in_phrases
      active_contact_method_annotation_status
      consensus_status
    }
  }
`;

export const DECLINE_TOKEN_JOIN_OPTION = gql`
  mutation Decline_Method_Token_Join_Option($baseAssetIds: [BigInt!]) {
    decline_Method_Token_Join_option(input: { base_asset_ids: $baseAssetIds }) {
      methodTokenJoins {
        id
      }
    }
  }
`;

export const DECLINE_TOKEN_JOIN_OPTION_V2 = gql`
  mutation Decline_Method_Token_Join_Option_v2($input: Token_Join_Annotation_Input!) {
    decline_Method_Token_Join_option_v2(input: $input) {
      id
      name
      count_all_positions_in_phrases
      active_contact_method_annotation_status
      consensus_status
    }
  }
`;
