import {
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import DataCard from 'components/DataCard';
import { useDataGroupContext } from 'components/DataGroupProvider';
import DataList from 'components/DataList';
import FilterIcon from 'components/icons/FilterIcon';
import PinIcon from 'components/icons/PinIcon';
import SortIcon from 'components/icons/SortIcon';
import { TransformVariablesConfig } from 'configuration';
import useModalWithValue from 'hooks/useModalWithValue';
import { UseNestedQueryResult } from 'hooks/useQuery';
import { useSeCreation } from 'hooks/useSeCreation';
import { useDataSelectionContext } from 'providers/DataSelectionProvider';
import { CountField, DataItem, KeyField, NameField } from 'types';

type Action = {
  icon?: (meta?: Record<string, any>) => ReactNode;
  meta?: Record<string, any>;
  handler: () => void;
};

type Structure = {
  id: string;
  title?: string;
  keyField?: KeyField | Array<KeyField>;
  nameField?: NameField;
  countField?: CountField | Array<CountField>;
  readonly?: boolean;
  orangeLabel?: boolean;
  yellowLabel?: boolean;
  greenLabel?: boolean;
};

type UseQuery = (variables: any) => UseNestedQueryResult;

type Props = {
  parentItem: DataItem;
  transformVariables: any;
  transformVariablesConfig?: TransformVariablesConfig;
  actions?: Action[];
  consensus?: boolean;
  selectable?: boolean;
  structure: Structure;
  filterComponent?: FunctionComponent<any>;
  filterParams?: any;
  sortComponent?: FunctionComponent<any>;
  sortParams?: any;
  sortOptions?: any;
  paginationParams?: any;
  watchParams?: any;
  onClick?: (e: SyntheticEvent, item: DataItem) => void;
  onConfirm?: (item: DataItem) => void;
  onDecline?: (item: DataItem) => void;
  useQuery: UseQuery;
};

export default function DataSection(props: Props) {
  const {
    parentItem,
    transformVariables,
    transformVariablesConfig,
    actions: initialActions = [],
    consensus,
    selectable = true,
    structure,
    filterComponent: FilterModal = () => null,
    filterParams: initialFilterValue = null,
    sortComponent: SortModal = () => null,
    sortParams: initialSortValue = null,
    sortOptions = [],
    paginationParams: initialPaginationParams = {},
    watchParams,
    onClick,
    onConfirm,
    onDecline,
    useQuery,
  } = props;
  const itemRef = useRef<DataItem>(parentItem);
  const consensusRef = useRef<boolean | undefined>(consensus);
  const watchRef = useRef<any>(watchParams);

  const [paginationParams] = useState(initialPaginationParams);
  const se_creation = useSeCreation();
  const { path, menuId } = useDataGroupContext();

  const onItemOpen = useCallback((item: DataItem) => window.open(`${path}/${item.id}`), [path]);

  const {
    open: filterModalOpen,
    value: filterModalValue,
    meta: filterMeta,
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    setValue: setFilterModalValue,
    resetValue: resetFilterModalValue,
  } = useModalWithValue({ initialValue: initialFilterValue });

  const {
    open: sortModalOpen,
    value: sortModalValue,
    meta: sortMeta,
    openModal: openSortModal,
    closeModal: closeSortModal,
    setValue: setSortModalValue,
  } = useModalWithValue({ initialValue: initialSortValue });

  const filterValueRef = useRef<any>(filterModalValue);
  const sortValueRef = useRef<any>(sortModalValue);

  const variables = useMemo(
    () => ({
      id: parentItem.id,
      consensus,
      ...transformVariables(filterModalValue, sortModalValue, paginationParams, transformVariablesConfig),
    }),
    [
      parentItem.id,
      consensus,
      filterModalValue,
      sortModalValue,
      paginationParams,
      transformVariables,
      transformVariablesConfig,
    ]
  )
  const { loading, data, reset, fetchMore } = useQuery(variables);
  const { selectedItems, onSelectAll, onReset, onToggle } = useDataSelectionContext();

  const actions = useMemo(
    () => [
      ...initialActions,
      ...(
        selectable ? [
          {
            icon: () => <PinIcon light />,
            handler: () => onSelectAll(structure.id, data),
          },
        ] : []
      ),
      ...(
        initialFilterValue ? [
          {
            icon: ({ dirty }: any) => <FilterIcon light selected={dirty} />,
            meta: filterMeta,
            handler: openFilterModal,
          },
        ] : []
      ),
      ...(
        initialSortValue ? [
          {
            icon: () => <SortIcon light />,
            meta: sortMeta,
            handler: openSortModal,
          },
        ] : []
      ),
    ],
    [
      structure.id,
      data,
      initialActions,
      initialFilterValue,
      initialSortValue,
      selectable,
      filterMeta,
      openFilterModal,
      sortMeta,
      openSortModal,
      onSelectAll,
    ],
  );

  const content = useMemo(() => {
    if (loading) {
      return <h1>Loading...</h1>;
    }

    return (
      <DataCard title={structure.title} actions={actions}>
        <DataList
          id={structure.id}
          menuId={menuId}
          items={data}
          selectedItems={selectedItems.get(structure.id)}
          keyField={structure.keyField}
          nameField={structure.nameField}
          countField={structure.countField}
          readonly={structure.readonly}
          consensus={consensus}
          se_creation={se_creation}
          orangeLabel={structure.orangeLabel ?? true}
          yellowLabel={structure.yellowLabel ?? true}
          greenLabel={structure.greenLabel ?? true}
          onLoadMoreItems={fetchMore}
          onItemToggle={(item: DataItem) => onToggle(structure.id || '', item)}
          onItemOpen={onItemOpen}
          onItemClick={onClick}
          onItemConfirm={onConfirm}
          onItemDecline={onDecline}
        />
      </DataCard>
    );
  }, [
    loading,
    consensus,
    se_creation,
    data,
    actions,
    structure.id,
    structure.title,
    structure.keyField,
    structure.nameField,
    structure.countField,
    structure.readonly,
    structure.orangeLabel,
    structure.yellowLabel,
    structure.greenLabel,
    selectedItems,
    onToggle,
    onItemOpen,
    onClick,
    onConfirm,
    onDecline,
    fetchMore,
    menuId,
  ]);

  useEffect(() => {
    if (itemRef.current !== parentItem) {
      reset();
      onReset(structure.id);
      itemRef.current = parentItem;
    }

    if (consensusRef.current !== consensus) {
      reset();
      onReset(structure.id);
      consensusRef.current = consensus;
    }

    if (filterValueRef.current !== filterModalValue) {
      reset();
      onReset(structure.id);
      filterValueRef.current = filterModalValue;
    }

    if (sortValueRef.current !== sortModalValue) {
      reset();
      onReset(structure.id);
      sortValueRef.current = sortModalValue;
    }

    if (watchRef.current !== watchParams) {
      reset();
      onReset(structure.id);
      watchRef.current = watchParams;
    }
  }, [
    structure.id,
    parentItem,
    consensus,
    filterModalValue,
    sortModalValue,
    watchParams,
    reset,
    onReset
  ]);

  return (
    <>
      {content}
      {filterModalOpen && (
        <FilterModal
          value={filterModalValue}
          onChange={setFilterModalValue}
          onClose={closeFilterModal}
          onReset={resetFilterModalValue}
        />
      )}
      {sortModalOpen && (
        <SortModal
          value={sortModalValue}
          options={sortOptions}
          onChange={setSortModalValue}
          onClose={closeSortModal}
        />
      )}
    </>
  );
}
