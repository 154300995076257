import config, { TransformVariablesConfig } from 'configuration';
import {
  ItemSpecification_Filter_Order,
  ItemSpecification_has_Tags_Filter_Input,
  ItemSpecification_Query_Input,
} from "modules/item-specifications/api/applicationApi";
import { FilterParamsType, SortParamsType } from 'modules/item-specifications/constants';
import { PaginationParams } from 'types';

export function transformVariables<T extends object>(
  filterParams: FilterParamsType,
  sortParams: SortParamsType,
  paginationParams: PaginationParams,
  transformVariablesConfig?: TransformVariablesConfig,
): T {

  transformVariablesConfig = {
    ...config.transformVariablesConfig,
    ...transformVariablesConfig,
  };

  // where
  const queryVariables: ItemSpecification_Query_Input = {
    first: config.dataPage.limit
  };

  if (filterParams) {
    const itemHasTagFilterInput: ItemSpecification_has_Tags_Filter_Input = {};

    if (Array.isArray(filterParams.trades) && filterParams.trades.length > 0) {
      itemHasTagFilterInput.trades = filterParams.trades.map(it => it.id);
    }

    if (Array.isArray(filterParams.groupsOfWorkItems) && filterParams.groupsOfWorkItems.length > 0) {
      itemHasTagFilterInput.groupsOfWorkItems = filterParams.groupsOfWorkItems.map(it => it.id);
    }

    if (Array.isArray(filterParams.din276CostGroups) && filterParams.din276CostGroups.length > 0) {
      itemHasTagFilterInput.din276CostGroups = filterParams.din276CostGroups.map(it => it.id);
    }
    if (itemHasTagFilterInput.trades || itemHasTagFilterInput.groupsOfWorkItems || itemHasTagFilterInput.din276CostGroups) {
      queryVariables.itemHasTags = itemHasTagFilterInput;
    }

    if (filterParams.source && filterParams.source.length < 3) {
      queryVariables.source = {
        includeSource: filterParams.source,
      };
    }

    if (typeof filterParams.onlyAssigned === 'boolean') {
      queryVariables.assigned = filterParams.onlyAssigned;
    }

    if (typeof filterParams.onlyDone === 'boolean') {
      queryVariables.done = filterParams.onlyDone;
    }
  }

  if (sortParams) {
    queryVariables.order = [
      {
        updatedAt: sortParams.direction,
      } as ItemSpecification_Filter_Order,
    ];
  }

  return queryVariables as T;
}
