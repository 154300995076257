import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Classes_Entities_State, Classes_Ids_State } from './atoms';

export * from "../sections/AssociatedTokensDataSection/selectors";
export * from "../sections/ChildrenClassesDataSection/selectors";
export * from "../sections/ClustersDataSection/selectors";
export * from "../sections/ConversionChildrenClassesDataSection/selectors";
export * from "../sections/ConversionParentsClassesDataSection/selectors";
export * from "../sections/ParentsClassesDataSection/selectors";
export * from "../sections/TagsDataSection/selectors";

export const Classes_selector = listSelector<DataItem>(
  'graph/classes/@selectors/list',
  Classes_Entities_State,
  Classes_Ids_State,
);
