import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Tokens_Entities_State, Tokens_Ids_State } from './atoms';

export * from "../sections/MorphsDataSection/selectors"
export * from "../sections/TagsDataSection/selectors"
export * from "../sections/TokenJoinDataSection/selectors"

export const Tokens_selector = listSelector<DataItem>(
  'graph/tokens/@selectors/list',
  Tokens_Entities_State,
  Tokens_Ids_State,
);
