import { useMemo } from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { UseNestedQueryResult } from 'hooks/useQuery';
import { DataItem } from 'types';

import useStyles from './styles';

type Props = {
  useQuery: (variables: any) => UseNestedQueryResult;
  onAdd: () => void;
  onEdit: (item: any) => void;
};

export default function DataTable(props: Props) {
  const { useQuery, onAdd, onEdit } = props;
  const classes = useStyles();

  const variables = useMemo(() => null, []);
  const { data } = useQuery(variables)

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell variant="head" style={{ width: '10%' }}>
              ID
            </TableCell>
            <TableCell variant="head" style={{ width: '70%' }}>
              Name
            </TableCell>
            <TableCell variant="head" style={{ width: '10%' }}>
              Weight
            </TableCell>
            <TableCell variant="head" align="right">
              <Button variant="contained" color="primary" onClick={onAdd}>
                Add
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: DataItem) => (
            <TableRow key={row.name}>
              <TableCell component="td" scope="row">
                {row.id}
              </TableCell>
              <TableCell component="td" scope="row">
                {row.name}
              </TableCell>
              <TableCell component="td" scope="row">
                {row.contact_weight}
              </TableCell>
              <TableCell component="td" scope="row" align="right" style={{ display: 'flex', gridGap: '5px' }}>
                <Button variant="outlined" color="primary" onClick={() => onEdit(row)}>
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
