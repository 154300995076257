import { createContext } from 'react';
import { Map as ImmutableMap } from 'immutable';

import { DataItem } from 'types';

export type DataSelectionContextType<T = DataItem> = {
  currentItem: T | null;
  setCurrentItem: (item: T) => void;
  selectedItems: ImmutableMap<string, ImmutableMap<string, DataItem>>;
  onSelect: (section: string, item: T) => void;
  onSelectAll: (section: string, items: T[]) => void;
  onReset: (section: string) => void;
  onToggle: (section: string, item: T) => void;
};

const DataSelectionContext = createContext<DataSelectionContextType | null>(null);

export default DataSelectionContext;
