import { memo } from 'react';

import { darkTheme, lightTheme } from 'components/icons/constants';

type Props = {
  light?: boolean;
  selected?: boolean;
};

function FilterIcon({ light, selected }: Props) {
  const theme = light ? lightTheme : darkTheme;
  const opacity = selected ? theme.opacity.selected : theme.opacity.defaults;
  const fill = selected ? theme.fill.selected : theme.fill.defaults;

  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity} clipPath="url(#clip0)">
        <path
          d="M10.9375 0H1.09375C0.490625 0 0 0.490625 0 1.09375V2.2975C0 2.75688 0.17875 3.18875 0.50375 3.51312L4.14625 7.15312C4.29125 7.29812 4.375 7.5 4.375 7.70563V12.6562C4.375 12.8394 4.48125 13.0056 4.64812 13.0819C4.71062 13.1112 4.7775 13.125 4.84375 13.125C4.95375 13.125 5.0625 13.0863 5.14875 13.0119L6.89937 11.5112C7.28125 11.185 7.5 10.7087 7.5 10.2063V7.71875C7.5 7.50813 7.58687 7.3025 7.73875 7.15625L11.5056 3.52188C11.8394 3.2 12.0306 2.74875 12.0306 2.285V1.09375C12.0312 0.490625 11.5406 0 10.9375 0V0Z"
          fill={fill}
        />
        <path
          d="M13.9062 7.5H9.84375C9.24062 7.5 8.75 7.99063 8.75 8.59375V13.9062C8.75 14.5094 9.24062 15 9.84375 15H13.9062C14.5094 15 15 14.5094 15 13.9062V8.59375C15 7.99063 14.5094 7.5 13.9062 7.5ZM12.6562 12.8125H11.0937C10.835 12.8125 10.625 12.6025 10.625 12.3438C10.625 12.085 10.835 11.875 11.0937 11.875H12.6562C12.915 11.875 13.125 12.085 13.125 12.3438C13.125 12.6025 12.915 12.8125 12.6562 12.8125ZM12.6562 10.9375H11.0937C10.835 10.9375 10.625 10.7275 10.625 10.4688C10.625 10.21 10.835 10 11.0937 10H12.6562C12.915 10 13.125 10.21 13.125 10.4688C13.125 10.7275 12.915 10.9375 12.6562 10.9375Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="15" height="15" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(FilterIcon);
