import { memo } from 'react';

import { darkTheme, lightTheme } from 'components/icons/constants';

type Props = {
  light?: boolean;
};

function PinIcon({ light }: Props) {
  const theme = light ? lightTheme : darkTheme;
  const opacity = theme.opacity.defaults;
  const fill = theme.fill.defaults;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity} clipPath="url(#clip0)">
        <path
          d="M15.6425 3.52582L12.4751 0.358499C11.9984 -0.118198 11.2259 -0.118198 10.7487 0.358499C10.5198 0.587315 10.3913 0.897207 10.3913 1.22092C10.3913 1.45784 10.4615 1.68775 10.5885 1.88392L6.35656 5.52414C5.92078 5.32253 5.44408 5.21139 4.95433 5.21139C4.069 5.21139 3.21966 5.5628 2.59264 6.18986C2.1355 6.64746 2.1355 7.38845 2.59264 7.84604L4.49614 9.75004L0 16.0009L6.2509 11.5048L8.1544 13.4083C8.61143 13.8654 9.35345 13.8654 9.81111 13.4083C10.4371 12.7818 10.7889 11.9325 10.7889 11.0467C10.7889 10.5575 10.6784 10.0808 10.4768 9.64548L14.1176 5.41251C14.3132 5.53939 14.5426 5.60974 14.7796 5.60974C15.1032 5.60974 15.4138 5.48116 15.6425 5.25178C16.1192 4.77505 16.1192 4.00251 15.6425 3.52582Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(PinIcon);
