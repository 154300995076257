import { NavLink } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import useMainLayout from '../../hooks/useMainLayout';

import useStyles from './styles';

type Item = {
  label: string;
  path: string;
}

type Props = {
  items: Item[];
};

export default function MainNav(props: Props) {
  const { items } = props;

  const classes = useStyles();
  const { closeSidebar } = useMainLayout();

  return (
    <List>
      {items.map((item, index) => (
        <ListItem
          key={item.label}
          button
          disableGutters
          className={classes.listItem}
          component={NavLink}
          to={item.path}
          activeClassName={classes.activeLink}
          onClick={closeSidebar}
        >
          <ListItemIcon>
            <div className={classes.numericIcon}>{index + 1}</div>
          </ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
    </List>
  );
}
