import { Token_Join_listSelector } from 'methods/token-join/store';
import { DataItem } from 'types';

import {
  Token_Token_Join_Consensus_Annotations_Entities_State,
  Token_Token_Join_Consensus_Annotations_Ids_State,
  Token_Token_Join_Options_Entities_State,
  Token_Token_Join_Options_Ids_State,
  Token_Token_Join_Predictions_Entities_State,
  Token_Token_Join_Predictions_Ids_State,
} from './atoms';

export const Token_Token_Join_Consensus_Annotations_selector = Token_Join_listSelector<DataItem>(
  'graph/tokens/@selectors/Token_Join_Consensus_Annotations/list',
  Token_Token_Join_Consensus_Annotations_Entities_State,
  Token_Token_Join_Consensus_Annotations_Ids_State,
);

export const Token_Token_Join_Predictions_selector = Token_Join_listSelector<DataItem>(
  'graph/tokens/@selectors/Token_Join_Predictions/list',
  Token_Token_Join_Predictions_Entities_State,
  Token_Token_Join_Predictions_Ids_State,
);

export const Token_Token_Join_Options_selector = Token_Join_listSelector<DataItem>(
  'graph/tokens/@selectors/Token_Join_Options/list',
  Token_Token_Join_Options_Entities_State,
  Token_Token_Join_Options_Ids_State,
);
