import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  Class_Tags_Consensus_Annotations_Entities_State,
  Class_Tags_Options_Entities_State,
  Class_Tags_Predictions_Entities_State,
} from 'modules/classes/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateTagEntities(params: Params) {
  const { transform } = params;

  const update_Tags_Consensus_Annotations = useSetRecoilState(Class_Tags_Consensus_Annotations_Entities_State);
  const update_Tags_Predictions = useSetRecoilState(Class_Tags_Predictions_Entities_State);
  const update_Tags_Options = useSetRecoilState(Class_Tags_Options_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const);
    const updater = entitiesUpdaterFactory(entries);

    update_Tags_Consensus_Annotations(updater);
    update_Tags_Predictions(updater);
    update_Tags_Options(updater);
  }, [
    transform,
    update_Tags_Consensus_Annotations,
    update_Tags_Predictions,
    update_Tags_Options,
  ]);
}
