import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    '& > ul': {
      padding: 0,
    },
  },
  horizontalList: {
    '& > ul': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(0, 2),
      height: '100%',
      overflow: 'auto',
      alignContent: 'flex-start',
    },
  },
  horizontalItem: {
    width: 'calc(25% - 15px)',
  },
  tagListHeader: {
    color: 'white',
    background: '#253448',
    height: theme.spacing(7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    maxWidth: 130,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  actions: {
    display: 'flex',
    marginRight: 10,
    gap: theme.spacing(0.5),
  },
  actionButton: {
    borderWidth: 0.5,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderStyle: 'solid',
    borderRadius: 2,
    padding: theme.spacing(0.5),
  },
  listItem: {
    padding: 0,
  },
  itemButton: {
    width: '100%',
    justifyContent: 'flex-start',
    textTransform: 'none',
    background: '#F7F9FC',
    padding: theme.spacing(0.25, 1),
    borderColor: '#E0E0E0',
    position: 'relative',
  },
  itemButtonActive: {
    color: 'white',
    background: '#1565C0',
    borderColor: '#1565C0',
    '&:hover': {
      backgroundColor: '#1565C0',
    },
  },
  itemButtonSeCreation: {
    color: 'red',
  },
  itemButtonHorizontal: {
    marginBottom: theme.spacing(),
  },
  itemButtonText: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  itemButtonWithActions: {
    marginRight: 12,
  },
  tagSection: {
    color: '#FFFFFF',
    position: 'absolute',
    top: -1,
    right: 5,
    fontSize: 9,
    display: 'flex',
  },
  tag: {
    height: 20,
    borderRadius: '0px 0px 2px 2px',
    minWidth: 10,
    padding: '6px 3px',
    marginLeft: 5,
    lineHeight: 1,
  },
  yellowTag: {
    background: '#FFD704',
  },
  greenTag: {
    background: '#98D4A5',
  },
  orangeTag: {
    background: '#FFAB48',
  },
}));

export default useStyles;
