import { Map as ImmutableMap } from 'immutable';
import { DefaultValue, RecoilState, selector } from 'recoil';

export const extractId = (item: { id: string }): string => item.id;

export const listSelector = <T extends { id: string }>(
  key: string,
  entitiesState: RecoilState<ImmutableMap<string, T>>,
  idsState: RecoilState<string[]>
) => {
  return selector<T[]>({
    key,
    get: ({ get }) => {
      const entities = get(entitiesState);
      const ids = get(idsState);

      return ids.map(x => entities.get(x) as T)
    },
    set: ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(idsState);
        reset(entitiesState);
      } else {
        const ids = newValue.map(x => x.id)
        const entities = Object.fromEntries(newValue.map(x => [x.id, x]))
        set(idsState, (existing) => [...existing, ...ids])
        set(entitiesState, (existing) => existing.merge(entities))
      }
    },
  });
};

export const entitiesUpdaterFactory = <T>(
  entries: Array<Readonly<[string, T, T?]>>,
  insertOrUpdate = false,
) => (entities: ImmutableMap<string, T>) => {
  const updatedEntries = Object.fromEntries(
    entries
      .filter(entry => insertOrUpdate || entities.has(entry[0]))
      .map(entry => [
        entry[0], insertOrUpdate ? { ...entry[2], ...entry[1] } as T : entry[1],
      ])
  );

  return entities.mergeWith((oldVal, newVal) => ({ ...oldVal, ...newVal }) as T, updatedEntries)
};

export const entitiesMergerFactory = <T>(
  entries: Array<Readonly<[string, T, T?]>>,
  merger: (oldVal: T, newVal: T) => T,
) => (entities: ImmutableMap<string, T>) => {
  const updatedEntries = Object.fromEntries(
    entries
      .filter(entry => entities.has(entry[0]))
      .map(entry => [entry[0], entry[1]])
  );

  return entities.mergeWith(merger, updatedEntries)
};

export const idsUpdaterFactory = <T>(
  newIds: T | T[],
) => (ids: T[]) => {
  if (Array.isArray(newIds)) {
    return [...newIds, ...ids];
  }

  if (ids.indexOf(newIds) > -1) {
    return ids;
  }

  return [newIds, ...ids];
};

export const idsRemoverFactory = <T>(
  targetId: T,
) => (ids: T[]) => {
  const idx = ids.indexOf(targetId);

  if (idx > -1) {
    return [
      ...ids.slice(0, idx),
      ...ids.slice(idx + 1),
    ];
  }

  return ids;
};
