import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_CLASSES } from 'modules/classes/api';
import { Classes_selector } from 'modules/classes/store';

export default function useClassesQuery(variables: any) {
  const transform = useCallback((data: any) => data.Classes, []);

  return useQuery(QUERY_CLASSES, variables, Classes_selector, transform);
}
