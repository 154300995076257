import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export * from "../sections/AssociatedTokensDataSection/atoms";
export * from "../sections/ChildrenClassesDataSection/atoms";
export * from "../sections/ClustersDataSection/atoms";
export * from "../sections/ConversionChildrenClassesDataSection/atoms";
export * from "../sections/ConversionParentsClassesDataSection/atoms";
export * from "../sections/ParentsClassesDataSection/atoms";
export * from "../sections/TagsDataSection/atoms";

export const Classes_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/classes/@atoms/entities',
  default: ImmutableMap(),
});

export const Classes_Ids_State = atom<string[]>({
  key: 'graph/classes/@atoms/ids',
  default: [],
});
