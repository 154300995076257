import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';

import { useAuth } from 'modules/auth/hooks/useAuth';
import { ContactItem, DataItem, Mutation } from 'types';

export type AssignToContactParams = {
  dataItems: DataItem[];
  contacts: ContactItem[];
};

export const assignToContactTransform = () => ({ active_contact_is_assigned_to: true }) as DataItem;

type UseAssignToContactOptions = {
  onCompleted?: (result: unknown, items: DataItem[]) => void;
};

export default function useAssignToContact(
  mutation: Mutation,
  options: UseAssignToContactOptions = {},
): (params: AssignToContactParams) => void {
  const { onCompleted: onAssignToContactCompleted } = options;

  const currentRef = useRef<boolean>(false);
  const itemsRef = useRef<DataItem[] | null>(null);
  const { user } = useAuth();

  const onCompleted = useCallback((result: any) => {
    if (!currentRef.current) return;

    onAssignToContactCompleted?.(result, itemsRef.current as DataItem[])
    currentRef.current = false;
    itemsRef.current = null;
  }, [onAssignToContactCompleted]);

  const [mutate] = useMutation(mutation, { onCompleted });
  const assignToContact = useCallback(
    ({ dataItems, contacts }: AssignToContactParams) => {
      itemsRef.current = dataItems;
      currentRef.current = contacts.some(c => c.id === user?.contactId);

      const input = {
        ids: dataItems.map((dataItem: DataItem) => dataItem.id),
        contact_ids: contacts.map((contact: ContactItem) => contact.id),
      };

      mutate({
        variables: {
          input,
        },
      });
    },
    [user, mutate],
  );

  return assignToContact;
}
