import { useCallback } from 'react';
import { useQuery, useSubscription } from '@apollo/client';

import { SE_CREATION_STATE_UPDATED } from 'api/subscriptions';
import { QUERY_SE_CREATION_IN_PROGRESS } from 'modules/semantic-entities/api';

export default function useSeCreationStateUpdated(): void {
  const { refetch } = useQuery(QUERY_SE_CREATION_IN_PROGRESS);

  const onSubscriptionData = useCallback(({ subscriptionData }) => {
    const state = subscriptionData.data?.se_creation_state_updated
      if (state?.SE_creation_started || state?.SE_creation_finished) {
        refetch();
      }

      if (state?.SE_creation_results) {
        alert(JSON.stringify(state.SE_creation_results, null, 2))
      }
  }, [refetch])

  useSubscription(SE_CREATION_STATE_UPDATED, { onSubscriptionData });
}
