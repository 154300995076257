import DataGroup from 'components/DataGroup';
import DataSection from 'components/DataSection';
import useConsensus from 'hooks/useConsensus';
import FilterModal from 'modules/tags/components/FilterModal';
import SortModal from 'modules/tags/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/tags/constants';
import { transformVariables } from 'modules/tags/utils';
import { DataItem } from 'types';

import useAnnotations from './useAnnotations'
import useContextActions from './useContextActions'
import { useConsensusAnnotationsQuery, useOptionsQuery, usePredictionsQuery } from './useQueries';

type Props = {
  parentItem: DataItem;
};

export default function TagsDataSection({ parentItem }: Props) {
  const { consensus } = useConsensus();
  const { confirmAnnotation, declineAnnotation } = useAnnotations({ parentItem });
  const contextActions = useContextActions({ parentItem });

  return (
    <DataGroup
      title="Tags"
      structure={{
        menuId: 'Class_Tags',
      }}
      contextActions={contextActions}
      consensusAnnotations={
        <DataSection
          parentItem={parentItem}
          useQuery={useConsensusAnnotationsQuery}
          structure={{
            id: 'Tags_Consensus_Annotations',
            title: 'Consensus & Annotations',
            countField: 'count_all_positions_in_phrases',
            readonly: consensus,
          }}
          consensus={consensus}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
      predictions={
        <DataSection
          parentItem={parentItem}
          useQuery={usePredictionsQuery}
          structure={{
            id: 'Tags_Predictions',
            title: 'Predictions',
            countField: 'count_all_positions_in_phrases',
          }}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
      options={
        <DataSection
          parentItem={parentItem}
          useQuery={useOptionsQuery}
          structure={{
            id: 'Tags_Options',
            title: 'Options',
            countField: 'count_all_positions_in_phrases',
            orangeLabel: false,
          }}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
    />
  );
}
