import config, { TransformVariablesConfig } from 'configuration';
import { SemanticEntityFilterOptionsInput, SemanticEntityFilterWhereInput } from 'modules/semantic-entities/api';
import { deleteProperty } from 'shared/utils';
import { PaginationParams } from 'types';

import { FilterParamsType, SortParamsType } from './constants';

export function transformVariables<T extends object>(
  filterParams: FilterParamsType,
  sortValue: SortParamsType,
  paginationParams: PaginationParams,
  transformVariablesConfig?: TransformVariablesConfig,
): T {
  transformVariablesConfig = {
    ...config.transformVariablesConfig,
    ...transformVariablesConfig,
  };

  // where
  const where: SemanticEntityFilterWhereInput = {};

  if (filterParams) {
    if ((filterParams.semanticEntities || []).length > 0) {
      // NOTE: we support only 1 item for now
      const [name] = (filterParams.semanticEntities || []).map((x) => x!.name);
      where.name_STARTS_WITH_FULL_TEXT = name;
    }

    if (filterParams.onlyAssigned) {
      where.only_assigned = true;
    }

    if (filterParams.onlyDone) {
      where.only_done = true;
    }
  }

  // options
  const options: SemanticEntityFilterOptionsInput = {
    limit: config.dataPage.limit,
  };

  // filter
  const filter = { where, options } as T;
  deleteProperty<T>(filter, 'where');
  deleteProperty<T>(filter, 'options');

  return filter;
}
