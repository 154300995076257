import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  SemanticEntity_Next_Tags_Entities_State,
  SemanticEntity_Next_Tags_Ids_State,
} from './atoms';

export const SemanticEntity_Next_Tags_selector = listSelector<DataItem>(
  'graph/semantic-entities/@selectors/Associated_Next_Tags/list',
  SemanticEntity_Next_Tags_Entities_State,
  SemanticEntity_Next_Tags_Ids_State,
);
