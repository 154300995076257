import { useCallback } from 'react';
import { Field } from 'formik';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';

import AddIcon from 'components/icons/AddIcon';
import RemoveIcon from 'components/icons/RemoveIcon';
import { renderAutoCompleteField } from 'components/withFormik';
import { useLazyData } from 'hooks/useData';
import { SEARCH_MORPHS } from 'modules/morphs/api';
import { LanguageItem } from 'types';

import useStyles from './TokenHasMorphTargetAssetEntry.styles';

type Props = {
  name?: string;
  language?: LanguageItem;
  onAdd?: () => void;
  onRemove?: () => void;
};

type Value = string | null | { id: string; name: string };

function parseValue(value: Value) {
  if (!value) return value;

  return typeof value === 'object' ? value.name : value;
}

export default function TokenHasMorphTargetAssetEntry({
  name,
  language,
  onAdd = () => null,
  onRemove = () => null,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    data: morphs,
    loading: isMorphsLoading,
    getData: getMorphs,
  } = useLazyData(SEARCH_MORPHS, 'Morphs');
  const handleSearchChange = useCallback(
    (s?: string) => getMorphs(s, { where: { language_EQUALS: language?.id } }),
    [language, getMorphs],
  );

  return (
    <Grid container spacing={2}>
      {name && (
        <>
          <Grid item className={classes.item}>
            <Field
              name={`${name}.name`}
              component={renderAutoCompleteField}
              freeSolo
              parse={parseValue}
              options={morphs}
              loading={isMorphsLoading}
              onSearchChange={handleSearchChange}
            />
          </Grid>
          <Grid item className={classes.controls}>
            <IconButton color="primary" onClick={onRemove}>
              <RemoveIcon fill={theme.palette.primary.main} />
            </IconButton>
          </Grid>
        </>
      )}
      {!name && (
        <Grid item>
          <Button
            startIcon={<AddIcon fill={theme.palette.primary.main} />}
            onClick={onAdd}
          >
            Add new Morph
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
