import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Morphemes_Entities_State, Morphemes_Ids_State } from './atoms';

export * from "../sections/MorphsDataSection/selectors"

export const Morphemes_selector = listSelector<DataItem>(
  'graph/morphemes/@selectors/list',
  Morphemes_Entities_State,
  Morphemes_Ids_State,
);
