import { useCallback, useMemo, useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { FilterParamsType } from 'modules/tokens/constants';

import Form, { FormValues } from './FilterForm';

type Props = {
  value: FilterParamsType;
  onClose: () => void;
  onChange: (value: FilterParamsType) => void;
  onReset: () => void;
};

export default function TokensFilterModal({ value, onClose, onChange, onReset }: Props) {
  const formikRef = useRef<any>();

  const initialValues = useMemo(
    () => ({
      token: value.token,
      initialTokens: value.initialTokens,
      tokenTypes: value.tokenTypes,
      containsCharacters: value.containsCharacters,
      partOfClusters: value.partOfClusters,
      onlyAssigned: value.onlyAssigned,
      onlyDone: value.onlyDone,
    }),
    [value],
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onChange({
        token: values.token,
        initialTokens: values.initialTokens,
        tokenTypes: values.tokenTypes,
        containsCharacters: values.containsCharacters,
        partOfClusters: values.partOfClusters,
        onlyAssigned: values.onlyAssigned,
        onlyDone: values.onlyDone,
      });
      onClose();
    },
    [onClose, onChange],
  );

  const handleFilter = useCallback(() => {
    const { submitForm } = formikRef.current;
    submitForm();
  }, []);

  const handleReset = useCallback(() => {
    const { resetForm } = formikRef.current;
    resetForm();
    onReset();
  }, [onReset]);

  return (
    <Modal
      title="Filter"
      open
      actionsContent={
        <>
          <Button variant="contained" color="primary" onClick={handleFilter}>
            Filter
          </Button>
          <Button variant="contained" color="default" onClick={handleReset}>
            Reset
          </Button>
        </>
      }
      onClose={onClose}
    >
      <Form initialValues={initialValues} formRef={formikRef} onSubmit={handleSubmit} />
    </Modal>
  );
}
