import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { RecoilRoot } from 'recoil';
import { RecoilDevTools } from 'recoil-gear'

import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { apiClient } from './api/client';
import { AuthProvider } from './modules/auth/contexts/AuthContext';
import App from './App';

import './index.css';
import 'react-contexify/dist/ReactContexify.css';

const theme = createTheme({
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#1565C0',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

const anchorOrigin: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BUILD & CODE</title>
      </Helmet>
      <ApolloProvider client={apiClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <SnackbarProvider maxSnack={3} anchorOrigin={anchorOrigin}>
              <RecoilRoot>
                <RecoilDevTools />
                <App />
              </RecoilRoot>
            </SnackbarProvider>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
