import Dialog from '@material-ui/core/Dialog';

import DialogActions from './Actions';
import DialogContent from './Content';
import useStyles from './styles';
import DialogTitle from './Title';

type Props = {
  actionsContent?: React.ReactNode;
  classes?: any;
  title: string;
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

export default function Modal({ actionsContent, classes: outerClasses, open, title, children, onClose }: Props) {
  const classes = useStyles({ classes: outerClasses });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle id="x" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actionsContent && <DialogActions>{actionsContent}</DialogActions>}
    </Dialog>
  );
}
