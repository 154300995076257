import { useCallback } from 'react';

import { apiClient } from 'api/client'
import { CREATE_OR_UPDATE_TAG, CreateOrUpdateTagInput, CreateOrUpdateTagResult } from 'modules/item-specifications/api/graphApi';

type UseCreateOrUpdateTagResult = {
  createOrUpdateTag: (values: Values) => Promise<CreateOrUpdateTagResult>;
};

type Values = {
  tagId?: string;
  tagName: string;
  tokenName?: string;
  classes?: Array<{
    id: string;
    status: number;
  }>;
};

async function createOrUpdateTagExecutor(input: CreateOrUpdateTagInput): Promise<CreateOrUpdateTagResult> {
  const result = await apiClient.mutate({
    mutation: CREATE_OR_UPDATE_TAG,
    variables: {
      input,
    },
  });

  return result.data.Create_Or_Update_Tag;
}

export default function useCreateOrUpdateTag(): UseCreateOrUpdateTagResult {
  const createOrUpdateTag = useCallback(
    async (values: Values) => {
      const result = await createOrUpdateTagExecutor({
        tag_id: values.tagId,
        tag_name: values.tagName,
        token_name: values.tokenName,
        classes: values.classes?.map((it) =>
          ({
            id: it.id,
            status: it.status,
          })
        ),
      });

      return result;
    }, []);

  return {
    createOrUpdateTag,
  };
}
