import { memo } from 'react';

import { darkTheme, lightTheme } from 'components/icons/constants';

type Props = {
  light?: boolean;
  currentColor?: boolean
};

function SchemaIcon({light, currentColor}: Props) {
  const theme = light ? lightTheme : darkTheme;
  const fill = currentColor ? 'currentColor' : theme.fill.defaults;

  return (
    <svg width="25" height="16" viewBox="0 0 25 16" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(.4)" fill="none" fillRule="evenodd">
        <path stroke={fill} d="M12.0569 3.3662v6.6433" />
        <rect fill={fill} x="8.4171" width="7.2796" height="5.4597" rx="1" />
        <rect fill={fill} x="8.4171" y="10.2825" width="7.2796" height="5.4597" rx="1" />
        <rect fill={fill} x="16.7204" y="10.2825" width="7.2796" height="5.4597" rx="1" />
        <rect fill={fill} y="10.2825" width="7.2796" height="5.4597" rx="1" />
        <path stroke={fill} strokeLinejoin="round" d="M12.7885 7.7346H3.689v2.2749" />
        <path stroke={fill} strokeLinejoin="round" d="M11.3116 7.7346h9.0995v2.2749" />
      </g>
    </svg>
  )
    ;
}

export default memo(SchemaIcon);
