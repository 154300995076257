import { useCallback, useEffect, useState } from 'react';
import { DocumentNode, OperationVariables, TypedDocumentNode, useQuery } from '@apollo/client';

import { DictionaryItem } from 'types';

const filterFactory = (term: string) => ({ name }: { name: string }) => name.toLowerCase().startsWith(term);

type QueryType = DocumentNode | TypedDocumentNode<any, OperationVariables>;

export default function useMetadata(query: QueryType, field: string) {
  const { data } = useQuery(query);
  const [metadata, setMetadata] = useState([]);

  useEffect(() => {
    if (data?.metadata?.[field]) {
      setMetadata(data?.metadata?.[field] || []);
    }
  }, [data, field]);

  const getMetadataItemById = useCallback(
    (id: number) => (data?.metadata?.[field] || []).find((x: DictionaryItem) => x.id === id),
    [data, field],
  );

  const getMetadata = useCallback(
    (search?: string) => {
      const items = data?.metadata?.[field] || [];

      if (search) {
        const callback = filterFactory(search.toLowerCase());
        setMetadata(items.filter(callback));
      } else {
        setMetadata(items);
      }
    },
    [data, field],
  );

  return {
    metadata,
    getMetadata,
    getMetadataItemById,
  };
}
