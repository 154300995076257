import config, { TransformVariablesConfig } from 'configuration';
import { MorphFilterOptionsInput, MorphFilterWhereInput, MorphSortOption } from 'modules/morphs/api';
import { deleteProperty } from 'shared/utils';
import { PaginationParams } from 'types';

import { FilterParamsType, SortParamsType } from './constants';

export function transformVariables<T extends object>(
  filterParams: FilterParamsType,
  sortParams: SortParamsType,
  paginationParams: PaginationParams,
  transformVariablesConfig?: TransformVariablesConfig,
): T {
  transformVariablesConfig = {
    ...config.transformVariablesConfig,
    ...transformVariablesConfig,
  };

  // where
  const where: MorphFilterWhereInput = {};

  if (filterParams) {
    if (filterParams.morph?.name) {
      where.name_STARTS_WITH = filterParams.morph?.name;
    }

    if (filterParams.language?.id) {
      where.language_EQUALS = filterParams.language?.id;
    }

    if (filterParams.countCharactersMin || filterParams.countCharactersMax) {
      where.count_characters_RANGE = {
        min: filterParams.countCharactersMin,
        max: filterParams.countCharactersMax,
      };
    }

    if (filterParams.onlyAssigned) {
      where.only_assigned = true;
    }

    if (filterParams.onlyDone) {
      where.only_done = true;
    }
  }

  // options
  const options: MorphFilterOptionsInput = {
    limit: config.dataPage.limit,
    sort: {
      option: sortParams.option as MorphSortOption,
      direction: sortParams.direction,
    },
  };

  // filter
  const filter = { where, options } as T;
  deleteProperty<T>(filter, 'where');
  deleteProperty<T>(filter, 'options');

  return filter;
}
