import { PhraseSortOption } from 'modules/phrases/api';
import { DataItem } from 'types';

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: PhraseSortOption;
};

export type FilterParamsType = {
  phrase?: DataItem | string | null;
  countLiteralsMin?: number;
  countLiteralsMax?: number;
  countTokensMin?: number;
  countTokensMax?: number;
  classes?: DataItem[];
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  phrase: null,
  countLiteralsMin: 0,
  countLiteralsMax: 0,
  countTokensMin: 0,
  countTokensMax: 0,
  classes: [],
  onlyAssigned: false,
  onlyDone: false,
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: PhraseSortOption.BY_COUNT_OF_INCOMING_OFFER_HAS_PHRASE,
};

export const SORT_OPTIONS = [
  {
    value: PhraseSortOption.BY_COUNT_OF_INCOMING_OFFER_HAS_PHRASE,
    label: 'by count of incoming "Offer has Phrase"',
  },
  {
    value: PhraseSortOption.BY_COUNT_OF_INCOMING_DELIVERABLE_HAS_PHRASE,
    label: 'by count of incoming "Deliverable has Phrase"',
  },
];
