import { useCallback, useRef } from 'react'
import { useMutation } from '@apollo/client';

import { CONFIRM_TOKEN_HAS_MORPH_OPTION, DECLINE_TOKEN_HAS_MORPH_OPTION } from 'methods/token-has-morph/api';
import { DataItem } from 'types';

function transform(parentItem: DataItem | null, item: DataItem, languageId: number) {
  const input = {
    base_asset: {
      id: parentItem?.id || item.id,
    },
    target_assets: (item.Token_has_Morph_sequence || []).map((asset: DataItem) => ({
      id: asset?.id,
      name: asset?.name,
    })),
    properties: {
      language: languageId,
    },
  };

  return input
}

type UseTokenHasMorphAnnotationsParams = {
  parentItem: DataItem | null;
  languageId: number;
};

type UseTokenHasMorphAnnotationsOptions = {
  onConfirmCompleted?: (result: unknown, item: DataItem) => void;
  onDeclineCompleted?: (result: unknown, item: DataItem) => void;
};

type UseTokenHasMorphAnnotationsResult = {
  confirmAnnotation: (item: DataItem) => void;
  declineAnnotation: (item: DataItem) => void;
};

export default function useTokenHasMorphAnnotations(
  params: UseTokenHasMorphAnnotationsParams,
  options: UseTokenHasMorphAnnotationsOptions = {},
): UseTokenHasMorphAnnotationsResult {
  const { parentItem, languageId } = params;
  const {
    onConfirmCompleted: onConfirmAnnotationCompleted,
    onDeclineCompleted: onDeclineAnnotationCompleted,
  } = options;

  const ref = useRef<DataItem | null>(null);

  const onConfirmCompleted = useCallback((result: unknown) => {
    onConfirmAnnotationCompleted?.(result, ref.current as DataItem);
    ref.current = null;
  }, [onConfirmAnnotationCompleted]);
  const [confirm] = useMutation(CONFIRM_TOKEN_HAS_MORPH_OPTION, { onCompleted: onConfirmCompleted });

  const onDeclineCompleted = useCallback((result: unknown) => {
    onDeclineAnnotationCompleted?.(result, ref.current as DataItem);
    ref.current = null;
  }, [onDeclineAnnotationCompleted]);
  const [decline] = useMutation(DECLINE_TOKEN_HAS_MORPH_OPTION, { onCompleted: onDeclineCompleted });

  const confirmAnnotation = useCallback((item: DataItem) => {
    ref.current = item;

    const input = transform(parentItem, item, languageId)
    confirm({
      variables: {
        input,
      },
    });
  }, [languageId, parentItem, confirm]);

  const declineAnnotation = useCallback((item: DataItem) => {
    ref.current = item;

    const input = transform(parentItem, item, languageId)
    decline({
      variables: {
        input,
      },
    });
  }, [languageId, parentItem, decline]);

  return {
    confirmAnnotation,
    declineAnnotation,
  };
}
