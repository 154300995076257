import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import produce from 'immer';

import Popover from '@material-ui/core/Popover';

import { useSuccess } from 'hooks/useSuccess'
import BaseTextAnnotator, { Action, Span } from 'modules/item-specifications/components/BaseTextAnnotator';
import { useAnnotationContextMenuContext } from 'modules/item-specifications/contexts/AnnotationContextMenuContext';
import { useItemSpecificationContext } from 'modules/item-specifications/contexts/ItemSpecificationContext';
import useGetItemSpecification from 'modules/item-specifications/hooks/useGetItemSpecification';
import useItemSpecificationAnnotation, { ItemSpecificationAnnotationResult } from 'modules/item-specifications/hooks/useItemSpecificationAnnotation';
import { TextAnnotatorConfig, TextSpan } from 'plugins/react-text-annotate';
import { noop } from 'shared/utils';
import { ItemSpecification } from 'types';

import actions from './actions';
import AnnotationContextMenu from './AnnotationContextMenu';
import { getItemSpecificationInput, getSpans } from './transformations';

const config: TextAnnotatorConfig = {
  getText: (textSpan: TextSpan): string => {
    const selectedTags = textSpan.tags?.filter(t => t.meta?.selected);

    return selectedTags && selectedTags.length > 0
      ? selectedTags.map(it => it.name).join(', ')
      : '';
  }
};

type Props = {
  itemSpecification: ItemSpecification;
  onModified: (touched: boolean) => void;
};

export default function PhraseSeAnnotator(props: Props) {
  const { itemSpecification, onModified } = props;

  const { showSuccess } = useSuccess();
  const { itemApiRef, setItemSpecification } = useItemSpecificationContext();
  const { contextSpan, setContextSpan} = useAnnotationContextMenuContext();
  const { getItemSpecification } = useGetItemSpecification();

  const [state, setState] = useState<Span[]>([]);
  const [action, setAction] = useState<Action>(actions[0]);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onCompleted = useCallback((result: ItemSpecificationAnnotationResult) => {
    setItemSpecification(result.Item_Specification_Annotation);
    showSuccess();
    onModified(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setItemSpecification, showSuccess]);
  const { saveAnnotation } = useItemSpecificationAnnotation({  itemId: itemSpecification.id }, { onCompleted })

  const selectAction = useCallback((newAction: Action) => {
    setAction(newAction);
    onModified(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showContextMenu = useCallback((e: SyntheticEvent, span: Span) => {
    setContextSpan(span);
    setAnchorEl(e.currentTarget);
  }, [setContextSpan]);

  const hideContextMenu = useCallback((e: SyntheticEvent) => {
    setState(baseState => produce(baseState, draftState => {
      draftState.forEach(span => {
        if (span.id === contextSpan?.id) {
          span.labels = contextSpan?.labels;
        }
      })
    }));
    onModified(true);
    setAnchorEl(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSpan]);

  useEffect(() => {
    getItemSpecification(itemSpecification.id).then(itemSpecification => {
      if (itemSpecification) {
        const spans = getSpans(itemSpecification);
        setState(spans);
        onModified(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSpecification.id]);

  useEffect(() => {
    itemApiRef.current.saveAnnotations = () => {
      const input = getItemSpecificationInput(state, itemSpecification.description || '');
      saveAnnotation(input);
    };
  });

  return (
    <>
      <BaseTextAnnotator
        title="Anchor Building Material and its Attributes"
        subtitle={itemSpecification.name}
        content={itemSpecification.description}
        config={config}
        actions={actions}
        selectedAction={action}
        state={state}
        disableAnnotatorSelection
        onActionSelected={selectAction}
        onStateUpdated={noop}
        onSpanClicked={showContextMenu}
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={hideContextMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <AnnotationContextMenu action={action} />
      </Popover>
    </>
  )
}
