import { useCallback, useEffect, useRef } from 'react';
import { contextMenu } from 'react-contexify';
import mergeRefs from 'react-merge-refs';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Map as ImmutableMap } from 'immutable';

import Paper from '@material-ui/core/Paper';

import { useContextActionsContext } from 'providers/ContextActionsProvider';
import { CountField, DataItem, NameField } from 'types';

import useStyles from './styles';
import TopLevelListItem from './TopLevelListItem';
import { createItemData } from './utils';

type Props = {
  items: DataItem[];
  nameField?: NameField;
  countField?: CountField | Array<CountField>;
  currentItem: DataItem | null;
  se_creation: boolean;
  selectedItems?: ImmutableMap<string, DataItem>;
  onChange: (item: DataItem) => void;
  onLoadMoreItems: (offset: number) => void;
  onToggleItem: (item: DataItem) => void;
};

export default function TopLevelListItems({
  items,
  nameField = 'name',
  countField,
  currentItem,
  se_creation,
  selectedItems,
  onChange,
  onLoadMoreItems,
  onToggleItem,
}: Props) {
  const classes = useStyles();
  const { setContextItem } = useContextActionsContext();
  const autoScrolled = useRef(false);
  const listRef = useRef<any>();
  const totalCount = 9999;

  // @ts-ignore
  const handleContext = useCallback(
    (e, contextItem: DataItem) => {
      setContextItem(contextItem);
      contextMenu.show({
        id: 'dataPage',
        event: e,
        props: {
          contextItem,
        },
      });
    },
    [setContextItem],
  );

  useEffect(() => {
    if (items && currentItem && listRef.current && !autoScrolled.current) {
      const index = items.findIndex((x: DataItem) => x.id === currentItem.id);
      listRef.current.scrollToItem(index);
      autoScrolled.current = true;
    }
  }, [items, currentItem]);

  const itemData = createItemData(
    items,
    nameField,
    countField,
    se_creation,
    selectedItems,
    handleContext,
    onToggleItem,
    onChange,
  );
  const isItemLoaded = useCallback((index: number) => index < items.length && items[index] !== null, [items]);

  return (
    <Paper square elevation={0} className={classes.root}>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={totalCount} loadMoreItems={onLoadMoreItems}>
            {({ onItemsRendered, ref }: any) => (
              <List
                ref={mergeRefs([ref, listRef])}
                height={height}
                itemCount={items.length}
                itemData={itemData}
                itemSize={34}
                width={width}
                onItemsRendered={onItemsRendered}
              >
                {TopLevelListItem}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </Paper>
  );
}
