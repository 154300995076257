import config, { TransformVariablesConfig } from 'configuration';
import { TokenFilterOptionsInput, TokenFilterWhereInput, TokenSortOption } from 'modules/tokens/api';
import { deleteProperty } from 'shared/utils';
import { DataItem, DictionaryItem, PaginationParams } from 'types';

import { FilterParamsType, SortParamsType } from './constants';

export function transformVariables<T extends object>(
  filterParams: FilterParamsType | null,
  sortParams: SortParamsType | null,
  paginationParams: PaginationParams,
  transformVariablesConfig?: TransformVariablesConfig,
): T {
  const { nameProp = 'name_STARTS_WITH' } = {
    ...config.transformVariablesConfig,
    ...transformVariablesConfig,
  };

  // where
  const where: TokenFilterWhereInput = {};

  if (filterParams) {
    if (filterParams.token) {
      if (typeof filterParams.token === 'string') {
        where[nameProp as Extract<typeof nameProp, keyof TokenFilterWhereInput>] = filterParams.token;
      } else if (filterParams.token?.name) {
        where[nameProp as Extract<typeof nameProp, keyof TokenFilterWhereInput>] = filterParams.token?.name;
      }
    }

    if (Array.isArray(filterParams.tokenTypes) && filterParams.tokenTypes.length > 0) {
      where.token_type_WITHIN = filterParams.tokenTypes.map((x: DictionaryItem) => x.id);
    }

    if (Array.isArray(filterParams.partOfClusters) && filterParams.partOfClusters.length > 0) {
      where.is_part_of_Clusters = filterParams.partOfClusters.map((x: DataItem) => x.id);
    }

    if (filterParams.onlyAssigned) {
      where.only_assigned = true;
    }

    if (filterParams.onlyDone) {
      where.only_done = true;
    }
  }

  // options
  const options: TokenFilterOptionsInput = {
    limit: paginationParams.limit || config.dataPage.limit,
  };

  if (sortParams) {
    options.sort = {
      option: sortParams.option as TokenSortOption,
      direction: sortParams.direction,
    };
  }

  // filter
  const filter = { where, options } as T;
  deleteProperty<T>(filter, 'where');
  deleteProperty<T>(filter, 'options');

  return filter;
}
