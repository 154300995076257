import { useCallback } from 'react';

import Grid from '@material-ui/core/Grid';

import useModalWithValue from 'hooks/useModalWithValue';
import SpecificationEntry from 'methods/specification/components/SpecificationEntry';
import AddProductModal from 'modules/item-specifications/components/AddProductModal';
import { AddProductParamsType, INITIAL_ADD_PRODUCT_PARAMS } from 'modules/item-specifications/constants';
import { uid } from 'shared/utils';
import { DataItem, SemanticEntityGroup } from 'types';

type Props = {
  group:  SemanticEntityGroup;
  onUpdated: (group: SemanticEntityGroup) => void;
};

export default function NewSemanticEntityGroup(props: Props) {
  const { group, onUpdated } = props;

  const {
    open: addProductModalOpen,
    value: addProductModalValue,
    openModal: openSearchModal,
    closeModal: closeAddProductModal,
  } = useModalWithValue({ initialValue: INITIAL_ADD_PRODUCT_PARAMS });

  const addSemanticEntity = useCallback((value: AddProductParamsType) => {
    onUpdated({
      ...group,
      semanticEntities: [
        ...group.semanticEntities,
        {
          id: uid(),
          tags: [
            {
              id: value.product?.id as string,
              name: value.product?.name as string,
              start: -1,
              end: -1,
              anchor: true,
            },
            ...value.attributes.map(attribute =>
                ({
                  id: attribute.id as string,
                  name: attribute.name as string,
                  start: -1,
                  end: -1,
                  anchor: false,
                })
            )
          ]
        }
      ],
    });
  }, [group, onUpdated]);

  const removeSemanticEntity = useCallback((item: DataItem) => {
    onUpdated({
      ...group,
      semanticEntities: group.semanticEntities.filter(se => se.id !== item.id),
    });
  }, [group, onUpdated]);

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <SpecificationEntry
            title="New Annotation"
            group={group}
            isNew
            structure={{
              id: 'New_Annotation',
            }}
            onItemAdd={openSearchModal}
            onItemRemove={removeSemanticEntity}
          />
        </Grid>
      </Grid>
      {addProductModalOpen && (
        <AddProductModal
          value={addProductModalValue}
          onChange={addSemanticEntity}
          onClose={closeAddProductModal}
        />
      )}
    </>
  )
}
