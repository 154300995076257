import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_CLASS_CLUSTERS_CONSENSUS_ANNOTATIONS,
  QUERY_CLASS_CLUSTERS_OPTIONS,
} from 'methods/class-has-cluster/api';
import {
  Class_Clusters_Consensus_Annotations_selector,
  Class_Clusters_Options_selector,
} from 'modules/classes/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.classes?.[0]?.Clusters_Consensus_Annotations || [], []);

  return useNestedQuery(
    QUERY_CLASS_CLUSTERS_CONSENSUS_ANNOTATIONS,
    variables,
    Class_Clusters_Consensus_Annotations_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.classes?.[0]?.Clusters_Options || [], []);

  return useNestedQuery(
    QUERY_CLASS_CLUSTERS_OPTIONS,
    variables,
    Class_Clusters_Options_selector,
    transform,
  );
}
