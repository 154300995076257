import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Class_Children_Classes_Consensus_Annotations_Entities_State,
  Class_Children_Classes_Consensus_Annotations_Ids_State,
  Class_Children_Classes_Options_Entities_State,
  Class_Children_Classes_Options_Ids_State,
  Class_Children_Classes_Predictions_Entities_State,
  Class_Children_Classes_Predictions_Ids_State,
} from './atoms';

export const Class_Children_Classes_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Children_Classes_Consensus_Annotations/list',
  Class_Children_Classes_Consensus_Annotations_Entities_State,
  Class_Children_Classes_Consensus_Annotations_Ids_State
);

export const Class_Children_Classes_Predictions_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Children_Classes_Predictions/list',
  Class_Children_Classes_Predictions_Entities_State,
  Class_Children_Classes_Predictions_Ids_State
);

export const Class_Children_Classes_Options_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Children_Classes_Options/list',
  Class_Children_Classes_Options_Entities_State,
  Class_Children_Classes_Options_Ids_State
);
