import { useCallback, useRef } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';

import SearchAutocomplete from 'components/Controls/SearchAutocomplete';
import { QUERY_ITEM_SPECIFICATION_TAGS } from 'modules/item-specifications/api/graphApi';
import useLazyTagsData from 'modules/tags/hooks/useLazyTagsData';
import { DataItem, Nullable } from 'types';

import useStyles from './AddProductForm.styles';

export type FormValues = {
  product: Nullable<DataItem>;
  attributes: DataItem[];
};

type Props = {
  initialValues: FormValues;
  formRef?: any;
  onSubmit: (values: FormValues) => void;
};

export default function AddProductForm({ initialValues, formRef, onSubmit }: Props) {
  const classes = useStyles();
  const arrayHelpersRef = useRef<Nullable<FieldArrayRenderProps>>(null)

  const {
    data: products,
    loading: isProductsLoading,
    getData: getProducts,
  } = useLazyTagsData(QUERY_ITEM_SPECIFICATION_TAGS, 'Item_Specification_Tags');

  const {
    data: attributes,
    loading: isAttributesLoading,
    getData: getAttibutes,
  } = useLazyTagsData(QUERY_ITEM_SPECIFICATION_TAGS, 'Item_Specification_Tags');

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <Grid container item className={classes.section}>
            <Grid item xs={6}>
              <SearchAutocomplete
                label="Building Material"
                name="product"
                value={null}
                options={products}
                loading={isProductsLoading}
                blurOnSelect
                onSearchChange={getProducts}
                onChange={(_, newValue) => {
                  setFieldValue("product", newValue)
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.section}>
              <InputLabel className={classes.label}>
                Selected
              </InputLabel>
              <Field name="product">
                {() => (
                  <FormGroup style={{ minHeight: 200 }}>
                    {values.product ? (
                      <FormControlLabel
                        label={values.product.name}
                        control={
                          <Checkbox
                            value={values.product.id}
                            color="primary"
                            checked
                            onChange={() => setFieldValue("product", null)}
                          />
                        }
                      />
                    ) : null}
                  </FormGroup>
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid container item className={classes.section}>
            <Grid item xs={6}>
            <SearchAutocomplete
                label="Attributes on Building Material"
                name="attributes"
                value={null}
                options={attributes}
                loading={isAttributesLoading}
                blurOnSelect
                onSearchChange={getAttibutes}
                onChange={(_, newValue) => {
                  arrayHelpersRef.current?.push(newValue)
                }}
              />
            </Grid>
            <Grid item xs={6}>
            <InputLabel className={classes.label}>
              Selected
            </InputLabel>
            <FieldArray name="attributes">
              {(arrayHelpers) => {
                arrayHelpersRef.current = arrayHelpers

                return (
                    <FormGroup style={{ minHeight: 200 }}>
                      {values.attributes.map((attr, index) => (
                        <FormControlLabel
                          key={attr.id}
                          label={attr.name}
                          control={
                            <Checkbox
                              value={attr.id}
                              color="primary"
                              checked
                              onChange={() => arrayHelpersRef.current?.remove(index)}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                )
              }}
            </FieldArray>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
