import { memo, useCallback } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';

import DataGrid from 'components/DataGrid';
import DataList from 'components/DataList';
import AddIcon from 'components/icons/AddIcon';
import ConfirmedIcon from 'components/icons/ConfirmedIcon';
import DeclinedIcon from 'components/icons/DeclinedIcon';
import { useSeCreation } from 'hooks/useSeCreation';
import { Source, Status } from 'shared/constants';
import { noop } from 'shared/utils';
import { DataItem, SemanticEntity, SemanticEntityGroup } from 'types';

const defaultGroup: SemanticEntityGroup = {
  id: '',
  name: '',
  semanticEntities: [],
};

const isItemConfirmed = (item: SemanticEntityGroup) => item.source === Source.consensus && item.status === Status.confirmed;
const isItemDeclined = (item: SemanticEntityGroup) => item.source === Source.consensus && item.status === Status.declined;

type Props = {
  title: string;
  group?: SemanticEntityGroup;
  isNew?: boolean;
  structure: {
    id: string;
    menuId?: string;
  }
  onItemAdd?: () => void;
  onItemRemove?: (item: any) => void;
  onConfirm?: (item: any) => void;
  onDecline?: (item: any) => void;
};

function SpecificationEntry(props: Props) {
  const {
    title,
    group = defaultGroup,
    isNew = false,
    structure,
    onItemAdd,
    onItemRemove,
    onConfirm,
    onDecline,
  } = props;

  const theme = useTheme();
  const se_creation = useSeCreation();

  const handleConfirm = useCallback(() => onConfirm?.(group), [group, onConfirm]);
  const handleDecline = useCallback(() => onDecline?.(group), [group, onDecline]);

  const renderContent = useCallback((se: SemanticEntity, index: number) => {
    return (
      <Grid container wrap="nowrap" spacing={1}>
        <Grid item style={{ width: 40 }}>
          {onItemRemove
            ? (
              <IconButton size="small" onClick={() => onItemRemove?.(se)}>
              <DeclinedIcon />
            </IconButton>
            )
            : null
          }
        </Grid>
        <Grid item>
          <Avatar style={{ width: 30, height: 30 }}>{index + 1}</Avatar>
        </Grid>
        <Grid item>
          {se.tags?.map(spanTag => (
            <Chip
              key={spanTag.id}
              label={spanTag.name}
              style={{
                marginRight: 8,
                background: 'transparent',
                borderStyle: 'solid',
                borderColor: spanTag.anchor ? 'red' : 'green',
                borderWidth: 2,
              }}
            />
          ))}
        </Grid>
      </Grid>
    );
  }, [onItemRemove]);

  return (
    <DataGrid
      title={title}
      defaultHeight={240}
    >
      <Grid container direction="column" style={{ height: '100%' }}>
        <Grid container spacing={2} item style={{ height: '90%' }}>
          <Grid item style={{ width: '60%', height: '100%' }}>
            <DataList
              id={structure.id}
              menuId={structure.menuId}
              items={(group?.semanticEntities || []) as unknown as DataItem[]}
              itemSize={48}
              renderContent={renderContent}
              onLoadMoreItems={noop}
            />
          </Grid>
          <Grid item style={{ width: '40%', height: '100%', textAlign: 'right' }}>
            {false === isNew && (
              <>
                {(isItemConfirmed(group) ? (
                  <IconButton size="small" disabled>
                    <ConfirmedIcon active />
                  </IconButton>
                ) : (
                  <IconButton size="small" disabled={se_creation} onClick={handleConfirm}>
                    <ConfirmedIcon />
                  </IconButton>
                ))}
                {(isItemDeclined(group) ? (
                  <IconButton size="small" disabled>
                    <DeclinedIcon active />
                  </IconButton>
                ) : (
                  <IconButton size="small" disabled={se_creation} onClick={handleDecline}>
                    <DeclinedIcon />
                  </IconButton>
                ))}
              </>
            )}
          </Grid>
        </Grid>
        {isNew && (
          <Grid container item style={{ height: '10%' }}>
            <Grid item>
              <Button
                startIcon={<AddIcon fill={theme.palette.primary.main} />}
                onClick={onItemAdd}
              >
                Add Product Type
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </DataGrid>
  )
}

export default memo(SpecificationEntry);
