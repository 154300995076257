import { memo } from 'react';

type Props = {
  active?: boolean;
};

function DeclinedIcon({ active }: Props) {
  if (active) {
    return (
      <svg
        className="MuiSvgIcon-root"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0745 2.92624C13.1727 -0.974764 6.82718 -0.974764 2.92536 2.92624C-0.974793 6.82725 -0.974793 13.1744 2.92536 17.0754C4.87627 19.0255 7.43829 20.0001 10.0004 20.0001C12.5624 20.0001 15.1236 19.0254 17.0745 17.0754C20.9755 13.1744 20.9755 6.82725 17.0745 2.92624ZM14.1264 12.9484C14.4524 13.2744 14.4524 13.8013 14.1264 14.1273C13.9639 14.2899 13.7504 14.3716 13.537 14.3716C13.3235 14.3716 13.1101 14.2899 12.9475 14.1273L10.0003 11.1793L7.05393 14.1265C6.89053 14.289 6.67707 14.3707 6.46447 14.3707C6.25105 14.3707 6.0376 14.289 5.87502 14.1265C5.54905 13.8005 5.54905 13.2727 5.87502 12.9476L8.8214 10.0004L5.8742 7.05318C5.54822 6.72721 5.54822 6.19946 5.8742 5.87431C6.19935 5.54833 6.7271 5.54833 7.05307 5.87431L10.0003 8.82151L12.9475 5.87431C13.2734 5.54833 13.8004 5.54833 14.1263 5.87431C14.4523 6.19946 14.4523 6.72721 14.1263 7.05318L11.1791 10.0004L14.1264 12.9484Z"
          fill="#D32F2F"
        />
      </svg>
    );
  }

  return (
    <svg
      className="MuiSvgIcon-root"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0709 2.92906C13.1655 -0.976367 6.83364 -0.976368 2.92902 2.92914C-0.976367 6.83453 -0.976368 13.1664 2.92918 17.071C6.83364 20.9764 13.1655 20.9764 17.0709 17.0709C20.9764 13.1664 20.9764 6.83453 17.0709 2.92906ZM15.8925 15.8924C12.6379 19.147 7.36141 19.147 4.1077 15.8925C0.853086 12.6386 0.853086 7.36215 4.10762 4.10762C7.36141 0.853047 12.6379 0.853047 15.8924 4.10762C19.147 7.36219 19.147 12.6386 15.8925 15.8924Z"
        fill="#E0E0E0"
      />
      <path
        d="M14.1245 5.87542C13.7991 5.54999 13.2715 5.54999 12.946 5.87542L9.99974 8.82171L7.05345 5.87542C6.72802 5.54999 6.20036 5.54999 5.87493 5.87542C5.5495 6.20085 5.5495 6.72851 5.87493 7.05394L8.82122 10.0002L5.87603 12.9454C5.5506 13.2708 5.5506 13.7985 5.87603 14.1239C6.20146 14.4494 6.72911 14.4494 7.05454 14.1239L9.99974 11.1787L12.9458 14.1248C13.2712 14.4502 13.7989 14.4502 14.1243 14.1248C14.4497 13.7994 14.4497 13.2717 14.1243 12.9463L11.1783 10.0002L14.1245 7.05394C14.45 6.72851 14.45 6.20089 14.1245 5.87542Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}

export default memo(DeclinedIcon);
