import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_MORPHEMES } from 'modules/morphemes/api';
import { Morphemes_selector } from 'modules/morphemes/store';

export default function useMorphemesQuery(variables: any) {
  const transform = useCallback((data: any) => data.Morphemes, []);

  return useQuery(QUERY_MORPHEMES, variables, Morphemes_selector, transform);
}
