import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  Phrases_Semantic_Entities_Consensus_Annotations_Entities_State,
  Phrases_Semantic_Entities_Options_Entities_State,
  Phrases_Semantic_Entities_Predictions_Entities_State,
} from 'modules/phrases/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateSemanticEntitiesEntities(params: Params) {
  const { transform } = params;

  const update_Semantic_Entities_Consensus_Annotations = useSetRecoilState(Phrases_Semantic_Entities_Consensus_Annotations_Entities_State);
  const update_Semantic_Entities_Predictions = useSetRecoilState(Phrases_Semantic_Entities_Predictions_Entities_State);
  const update_Semantic_Entities_Options = useSetRecoilState(Phrases_Semantic_Entities_Options_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const)
    const updater = entitiesUpdaterFactory(entries);

    update_Semantic_Entities_Consensus_Annotations(updater);
    update_Semantic_Entities_Predictions(updater);
    update_Semantic_Entities_Options(updater);
  }, [
    transform,
    update_Semantic_Entities_Consensus_Annotations,
    update_Semantic_Entities_Predictions,
    update_Semantic_Entities_Options,
  ]);
}
