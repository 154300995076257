import { gql } from '@apollo/client';

import { RangeInput } from 'types';

export enum PhraseSortOption {
  BY_COUNT_OF_INCOMING_OFFER_HAS_PHRASE = 'by_count_of_incoming_Offer_has_Phrase',
  BY_COUNT_OF_INCOMING_DELIVERABLE_HAS_PHRASE = 'by_count_of_incoming_Deliverable_has_Phrase',
}

export type PhaseSortOptionsInput = {
  option: PhraseSortOption;
  direction: 'DESC' | 'ASC';
};

export type PhraseFilterWhereInput = {
  name_STARTS_WITH?: string;
  name_STARTS_WITH_FULL_TEXT?: string;
  name_STARTS_WITH_REGEXP?: string;
  count_literals_RANGE?: RangeInput;
  count_tokens_RANGE?: RangeInput;
  only_assigned?: boolean;
  only_done?: boolean;
};

export type PhraseFilterOptionsInput = {
  sort?: PhaseSortOptionsInput;
  offset?: number;
  limit?: number;
};

export type PhraseFilterInput = {
  where?: PhraseFilterWhereInput;
  options?: PhraseFilterOptionsInput;
};

export const QUERY_PHRASES = gql`
  query Phrases($where: Phrase_Filter_Where_Input, $options: Phrase_Filter_Options_Input) {
    Phrases(where: $where, options: $options) {
      id
      name
      active_contact_is_assigned_to
      active_contact_is_done_by
    }
  }
`;

export const SEARCH_PHRASES = gql`
  query Phrases($where: Phrase_Filter_Where_Input, $options: Phrase_Filter_Options_Input) {
    Phrases(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const ASSIGN_PHRASE_TO_CONTACT = gql`
  mutation Assign_Phrase_to_Contact($input: Assign_to_Contact_Input!) {
    assign_Phrase_to_Contact(input: $input) {
      id
    }
  }
`;

export const MARK_PHRASE_AS_DONE = gql`
  mutation mark_Phrase_as_done($ids: [BigInt!]!) {
    mark_Phrase_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_PHRASE_AS_NOT_DONE = gql`
  mutation mark_Phrase_as_not_done($ids: [BigInt!]!) {
    mark_Phrase_as_not_done(ids: $ids) {
      id
    }
  }
`;
