import { gql } from '@apollo/client';

import { RangeInput } from 'types';

export enum MorphSortOption {
  BY_COUNT_OF_INCOMING_TOKEN_HAS_MORPH = 'by_count_of_incoming_Token_has_Morph',
  BY_COUNT_OF_CHARACTERS = 'by_count_of_characters',
}

export type MorphSortOptionsInput = {
  option: MorphSortOption;
  direction: 'DESC' | 'ASC';
};

export type MorphFilterWhereInput = {
  name_STARTS_WITH?: string;
  language_EQUALS?: number;
  count_characters_RANGE?: RangeInput;
  only_assigned?: boolean;
  only_done?: boolean;
};

export type MorphFilterOptionsInput = {
  sort?: MorphSortOptionsInput;
  offset?: number;
  limit?: number;
};

export type MorphFilterInput = {
  where?: MorphFilterWhereInput;
  options?: MorphFilterOptionsInput;
};

export const QUERY_MORPHS = gql`
  query Morphs($where: Morph_Filter_Where_Input, $options: Morph_Filter_Options_Input) {
    Morphs(where: $where, options: $options) {
      id
      name
      language
      active_contact_is_assigned_to
      active_contact_is_done_by
      count_inverse__Token_has_Morph
    }
  }
`;

export const SEARCH_MORPHS = gql`
  query Morphs($where: Morph_Filter_Where_Input, $options: Morph_Filter_Options_Input) {
    Morphs(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const CREATE_MORPH = gql`
  mutation CreateMorph($input: Create_Morph_Input!) {
    create_Morph(input: $input) {
      morphs {
        id
        name
        language
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_inverse__Token_has_Morph
      }
    }
  }
`;

export const UPDATE_MORPH = gql`
  mutation UpdateMorph($input: Update_Morph_Input!) {
    update_Morph(input: $input) {
      morphs {
        id
        name
        language
      }
    }
  }
`;

export const ASSIGN_MORPH_TO_CONTACT = gql`
  mutation Assign_Morph_to_Contact($input: Assign_to_Contact_Input!) {
    assign_Morph_to_Contact(input: $input) {
      id
    }
  }
`;

export const MARK_MORPH_AS_DONE = gql`
  mutation mark_Morph_as_done($ids: [BigInt!]!) {
    mark_Morph_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_MORPH_AS_NOT_DONE = gql`
  mutation mark_Morph_as_not_done($ids: [BigInt!]!) {
    mark_Morph_as_not_done(ids: $ids) {
      id
    }
  }
`;
