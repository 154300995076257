import SpecificationEntry from 'methods/specification/components/SpecificationEntry';
import ContextActionsProvider from 'providers/ContextActionsProvider';
import { Source } from 'shared/constants';
import { SemanticEntityGroup } from 'types';

type Props = {
  semanticEntityGroups: SemanticEntityGroup[];
  onConfirmAnnotation: (group: SemanticEntityGroup) => void;
  onDeclineAnnotation: (group: SemanticEntityGroup) => void;
};

type Counts = {
  consensus: number;
  predictions: number;
}

function getTitle(group: SemanticEntityGroup, counts: Counts) {
  if (group.source === Source.prediction) {
    return `Prediction #${counts.predictions++}`;
  } else {
    return `Consensus #${counts.consensus++}`;
  }
}

const keyId = (group: SemanticEntityGroup) => `${group.id}:${group.source}`

export default function SemanticEntityGroups(props: Props) {
  const {
    semanticEntityGroups,
    onConfirmAnnotation,
    onDeclineAnnotation,
  } = props;

  const counts: Counts = {
    predictions: 1,
    consensus: 1,
  };

  return (
    <ContextActionsProvider>
      {semanticEntityGroups.map((group) => (
        <SpecificationEntry
          key={keyId(group)}
          title={getTitle(group, counts)}
          group={group}
          structure={{
            id: `SemanticEntityGroup_${keyId(group)}`,
          }}
          onConfirm={onConfirmAnnotation}
          onDecline={onDeclineAnnotation}
        />
      ))}
    </ContextActionsProvider>
  );
}
