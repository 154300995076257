import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import { CREATE_SEMANTIC_ENTITY } from 'modules/semantic-entities/api';
import { SemanticEntities_Entities_State, SemanticEntities_Ids_State } from 'modules/semantic-entities/store';
import { entitiesUpdaterFactory, idsUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type UseSemanticEntityParams = {
  onCreate?: () => void;
};

type UseSemanticEntityResult = {
  createSemanticEntity: (values: any) => void;
};

type Values = {
  name: string;
};

export default function useSemanticEntity(
  params: UseSemanticEntityParams = {}
): UseSemanticEntityResult {
  const { onCreate } = params;

  const updateEntities = useSetRecoilState(SemanticEntities_Entities_State);
  const updateIds = useSetRecoilState(SemanticEntities_Ids_State);

  const onCompleted = useCallback((result: any) => {
    const ids: string[] = result.create_Semantic_Entity_if_not_exists.semanticEntities.map((x: DataItem) => x.id);
    const entries = (result.create_Semantic_Entity_if_not_exists.semanticEntities as DataItem[]).map(x => [x.id, x, x] as const);

    const idsUpdater = idsUpdaterFactory(ids);
    const entitiesUpdater = entitiesUpdaterFactory(entries, true);

    updateEntities(entitiesUpdater);
    updateIds(idsUpdater);
  }, [
    updateEntities,
    updateIds,
  ]);
  const [create] = useMutation(CREATE_SEMANTIC_ENTITY, { onCompleted });

  const createSemanticEntity = useCallback(
    (values: Values) => {
      create({
        variables: {
          input: {
            name: values.name,
          },
        },
      })
      onCreate?.()
    }, [create, onCreate]
  );

  return {
    createSemanticEntity,
  };
}
