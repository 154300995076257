import { memo } from 'react';

type Props = {
  active?: boolean;
};

function ConfirmedIcon({ active }: Props) {
  if (active) {
    return (
      <svg
        className="MuiSvgIcon-root"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.48578 0 0 4.48578 0 10C0 15.5142 4.48578 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48578 15.5142 0 10 0ZM15.0684 7.88086L9.65164 13.2974C9.48914 13.4599 9.27582 13.5417 9.0625 13.5417C8.84918 13.5417 8.63586 13.4599 8.47336 13.2974L5.76508 10.5891C5.43915 10.2634 5.43915 9.73663 5.76508 9.41086C6.09085 9.08493 6.61743 9.08493 6.94336 9.41086L9.0625 11.53L13.8901 6.70258C14.2159 6.37665 14.7424 6.37665 15.0684 6.70258C15.3941 7.02835 15.3941 7.55493 15.0684 7.88086Z"
          fill="#49B460"
        />
      </svg>
    );
  }

  return (
    <svg
      className="MuiSvgIcon-root"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9935 6.71237C14.6856 6.38776 14.1724 6.37346 13.8473 6.6821L8.67105 11.591L6.20215 9.05616C5.88945 8.73534 5.37676 8.72831 5.05566 9.04104C4.73484 9.35346 4.72808 9.8667 5.04051 10.1875L8.06754 13.2954C8.22617 13.4583 8.43699 13.5405 8.64836 13.5405C8.8489 13.5405 9.04945 13.4664 9.20648 13.3181L14.9632 7.85862C15.2881 7.55049 15.3018 7.03725 14.9935 6.71237Z"
        fill="#E0E0E0"
      />
      <path
        d="M10 0C4.48594 0 0 4.48594 0 10C0 15.5141 4.48594 20 10 20C15.5141 20 20 15.5141 20 10C20 4.48594 15.5141 0 10 0ZM10 18.3784C5.38027 18.3784 1.6216 14.62 1.6216 10C1.6216 5.38027 5.38023 1.6216 10 1.6216C14.62 1.6216 18.3784 5.38023 18.3784 10C18.3784 14.62 14.62 18.3784 10 18.3784Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}

export default memo(ConfirmedIcon);
