import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import useMainLayout from '../../hooks/useMainLayout';

import useStyles from './styles';

export default function Consensus() {
  const { consensus, toggleConsensus } = useMainLayout();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography noWrap>
        Consensus / All Data
        <Switch
          checked={consensus}
          onChange={toggleConsensus}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Typography>
    </div>
  );
}
