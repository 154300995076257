import { useMemo } from 'react';

import DataPage, { DataPageRenderProps } from 'components/DataPage';
import { TransformVariablesConfig } from 'configuration';
import useModalWithValue from 'hooks/useModalWithValue';
import MainLayout from 'layouts/MainLayout';
import { Authenticated } from 'modules/auth/components/Authenticated';
import FilterModal from 'modules/phrases/components/FilterModal';
import SortModal from 'modules/phrases/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/phrases/constants';
import usePhraseContextActions from 'modules/phrases/hooks/usePhraseContextActions';
import usePhrasesQuery from 'modules/phrases/hooks/usePhrasesQuery';
import useResetPhrases from 'modules/phrases/hooks/useResetPhrases';
import SemanticEntitiesDataSection from 'modules/phrases/sections/SemanticEntitiesDataSection';
import { transformVariables } from 'modules/phrases/utils';
import DataPageProvider from 'providers/DataPageProvider';

const transformVariablesConfig: TransformVariablesConfig = {
  nameProp: 'name_STARTS_WITH_FULL_TEXT',
};

export default function Phrases() {
  const resetPhrases = useResetPhrases();

  const {
    open: sortModalOpen,
    value: sortModalValue,
    openModal: openSortModal,
    closeModal: closeSortModal,
    setValue: setSortModalValue,
  } = useModalWithValue({ initialValue: INITIAL_SORT_PARAMS, onValueChanged: resetPhrases });

  const {
    open: filterModalOpen,
    value: filterModalValue,
    meta: filterMeta,
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    setValue: setFilterModalValue,
    resetValue: resetFilter,
  } = useModalWithValue({ initialValue: INITIAL_FILTER_PARAMS, onValueChanged: resetPhrases });

  const actions = useMemo(
    () => ({
      sort: {
        handler: openSortModal,
      },
      filter: {
        meta: filterMeta,
        handler: openFilterModal,
      },
    }),
    [openSortModal, openFilterModal, filterMeta],
  );
  const contextActions = usePhraseContextActions();
  const dataVariables = useMemo(
    () => transformVariables(filterModalValue, sortModalValue, {}, transformVariablesConfig), [filterModalValue, sortModalValue]
  );

  return (
    <DataPageProvider>
      <DataPage
        title="Phrases"
        useQuery={usePhrasesQuery}
        dataVariables={dataVariables}
        actions={actions}
        contextActions={contextActions}
      >
        {({ currentItem }: DataPageRenderProps) => (
          <>
            {currentItem && (
              <>
                <SemanticEntitiesDataSection parentItem={currentItem} />
              </>
            )}

            {sortModalOpen && (
              <SortModal
                value={sortModalValue}
                options={SORT_OPTIONS}
                onClose={closeSortModal}
                onChange={setSortModalValue}
              />
            )}

            {filterModalOpen && (
              <FilterModal
                value={filterModalValue}
                onClose={closeFilterModal}
                onChange={setFilterModalValue}
                onReset={resetFilter}
              />
            )}
          </>
        )}
      </DataPage>
    </DataPageProvider>
  );
}

Phrases.getLayout = (page: any) => (
  <Authenticated>
    <MainLayout>
      {page}
    </MainLayout>
  </Authenticated>
);
