import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const Tag_Associated_Tokens_Consensus_Annotations_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/tags/@atoms/Associated_Tokens_Consensus_Annotations/entities',
  default: ImmutableMap(),
});

export const Tag_Associated_Tokens_Consensus_Annotations_Ids_State = atom<string[]>({
  key: 'graph/tags/@atoms/Associated_Tokens_Consensus_Annotations/ids',
  default: [],
});

export const Tag_Associated_Tokens_Predictions_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/tags/@atoms/Associated_Tokens_Predictions/entities',
  default: ImmutableMap(),
});

export const Tag_Associated_Tokens_Predictions_Ids_State = atom<string[]>({
  key: 'graph/tags/@atoms/Associated_Tokens_Predictions/ids',
  default: [],
});

export const Tag_Associated_Tokens_Options_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/tags/@atoms/Associated_Tokens_Options/entities',
  default: ImmutableMap(),
});

export const Tag_Associated_Tokens_Options_Ids_State = atom<string[]>({
  key: 'graph/tags/@atoms/Associated_Tokens_Options/ids',
  default: [],
});
