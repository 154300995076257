import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Tag_Classes_Consensus_Annotations_Entities_State,
  Tag_Classes_Consensus_Annotations_Ids_State,
  Tag_Classes_Options_Entities_State,
  Tag_Classes_Options_Ids_State,
  Tag_Classes_Predictions_Entities_State,
  Tag_Classes_Predictions_Ids_State,
} from './atoms';

export const Tag_Classes_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Classes_Consensus_Annotations/list',
  Tag_Classes_Consensus_Annotations_Entities_State,
  Tag_Classes_Consensus_Annotations_Ids_State,
);

export const Tag_Classes_Predictions_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Classes_Predictions/list',
  Tag_Classes_Predictions_Entities_State,
  Tag_Classes_Predictions_Ids_State,
);

export const Tag_Classes_Options_selector = listSelector<DataItem>(
  'graph/tags/@selectors/Classes_Options/list',
  Tag_Classes_Options_Entities_State,
  Tag_Classes_Options_Ids_State,
);
