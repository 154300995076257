import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Morphs_selector } from 'modules/morphs/store';

export default function useResetMorphs() {
  const reset = useResetRecoilState(Morphs_selector);

  return useCallback(() => {
    reset();
  }, [reset]);
}
