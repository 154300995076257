import { gql } from '@apollo/client';

export enum ClusterSortOption {
  BY_COUNT_OF_CLASS_HAS_CLUSTER_EDGES = 'by_count_of_Class_has_Cluster_edges',
  BY_COUNT_OF_CLASS_HAS_CLUSTER_PREDICTIONS = 'by_count_of_Class_has_Cluster_predictions',
  BY_COUNT_OF_CLASS_HAS_CLUSTER_PREDICTIONS_ASSIGNED_TO_USER = 'by_count_of_Class_has_Cluster_predictions_assigned_to_user',
}

export type ClusterSortOptionsInput = {
  option: ClusterSortOption;
  direction: 'DESC' | 'ASC';
};

export type ClusterFilterWhereInput = {
  name_STARTS_WITH?: string;
  name_STARTS_WITH_FULL_TEXT?: string;
  only_assigned?: boolean;
  only_done?: boolean;
};

export type ClusterFilterOptionsInput = {
  sort?: ClusterSortOptionsInput;
  offset?: number;
  limit?: number;
};

export type ClusterFilterInput = {
  where?: ClusterFilterWhereInput;
  options?: ClusterFilterOptionsInput;
};

export const QUERY_CLUSTERS = gql`
  query Clusters($where: Cluster_Filter_Where_Input, $options: Cluster_Filter_Options_Input) {
    Clusters(where: $where, options: $options) {
      id
      name
      active_contact_is_assigned_to
      active_contact_is_done_by
      se_creation
    }
  }
`;

export const SEARCH_CLUSTERS = gql`
  query Clusters($where: Cluster_Filter_Where_Input, $options: Cluster_Filter_Options_Input) {
    Clusters(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const CREATE_CLUSTER = gql`
  mutation CreateCluster($input: Create_Cluster_Input!) {
    create_Cluster(input: $input) {
      clusters {
        id
        name
        active_contact_is_assigned_to
        active_contact_is_done_by
        se_creation
      }
    }
  }
`;

export const ASSIGN_CLUSTER_TO_CONTACT = gql`
  mutation Assign_Cluster_to_Contact($input: Assign_to_Contact_Input!) {
    assign_Cluster_to_Contact(input: $input) {
      id
    }
  }
`;

export const UPDATE_CLUSTER_SE_CREATION = gql`
  mutation Update_SE_Creation($input: Update_Cluster_SE_creation_Input!) {
    update_Cluster_se_creation(input: $input)
  }
`;

export const START_CLUSTER_SE_CREATION = gql`
  mutation start_Cluster_se_creation {
    start_Cluster_se_creation
  }
`;

export const MARK_CLUSTER_AS_DONE = gql`
  mutation mark_Cluster_as_done($ids: [BigInt!]!) {
    mark_Cluster_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_CLUSTER_AS_NOT_DONE = gql`
  mutation mark_Cluster_as_not_done($ids: [BigInt!]!) {
    mark_Cluster_as_not_done(ids: $ids) {
      id
    }
  }
`;
