import { useCallback, useEffect, useState } from 'react';
import {  useLazyQuery } from '@apollo/client';
import merge from 'lodash/merge';

import { DataItem } from 'types';

import { GraphApiItem, Options, Query } from './types';

export default function useLazyData<T extends GraphApiItem>(
  query: Query,
  field: string,
  { nameField = 'name_STARTS_WITH', limit = 50 }: Options = {},
) {
  const [getRawData, { loading, data: rawData }] = useLazyQuery(query);
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    if (loading) {
      setData([]);
    } else {
      setData(
        (rawData?.[field] || []).map((item: T) => ({
          id: item.id,
          name: item.name,
        })),
      );
    }
  }, [loading, rawData, field, setData]);

  const getData = useCallback(
    (search?: string, args?: any) => {
      if (!!search) {
        // requestId is needed to avoid cache issues
        const variables = merge(args, {
          requestId: new Date().valueOf(),
          where: {
            [nameField]: search,
          },
          options: {
            limit,
          },
        });

        getRawData({ variables });
      } else {
        setData([]);
      }
    },
    [nameField, limit, getRawData, setData],
  );

  return {
    loading,
    data,
    getData,
  };
}
