import config from 'configuration';
import { MorphemeSortOption } from 'modules/morphemes/api';
import { DataItem, LanguageItem } from 'types';

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: MorphemeSortOption;
};

export type FilterParamsType = {
  morpheme?: DataItem | null;
  countCharactersMin?: number;
  countCharactersMax?: number;
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export type NameParamsType = {
  id?: string;
  name: string;
  language: LanguageItem | null;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  morpheme: null,
  countCharactersMin: undefined,
  countCharactersMax: undefined,
  onlyAssigned: false,
  onlyDone: false,
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: MorphemeSortOption.BY_COUNT_OF_INCOMING_MORPH_HAS_MORPHEME,
};

export const INITIAL_NAME_PARAMS: NameParamsType = {
  name: '',
  language: config.defaultLanguage,
};

export const SORT_OPTIONS = [
  {
    value: MorphemeSortOption.BY_COUNT_OF_INCOMING_MORPH_HAS_MORPHEME,
    label: 'by count of incoming "Morph has Morpheme"',
  },
];
