import { gql } from '@apollo/client';

// base asset extension
export const QUERY_TOKEN_TAGS_CONSENSUS_ANNOTATIONS = gql`
  query Token_Tags_Consensus_Annotations(
    $id: BigInt
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
    $consensus: Boolean = true
    $language: Int!
  ) {
    tokens(where: { id: $id }) {
      Tags_Consensus_Annotations(where: $where, options: $options, language: $language, consensus: $consensus) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_has_Meaning_language
      }
    }
  }
`;

export const QUERY_TOKEN_TAGS_CONSENSUS_ANNOTATIONS_V2 = gql`
  query Token_Tags_Consensus_Annotations_V2(
    $id: BigInt!
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
    $consensus: Boolean = true
    $language: Int!
  ) {
    Token_has_Meaning_Tags_Consensus_Annotations_v2(id: $id, where: $where, options: $options, language: $language, consensus: $consensus) {
      id
      name
      count_all_positions_in_phrases
      active_contact_method_annotation_status
      consensus_status
      Token_has_Meaning_language
    }
  }
`;

export const QUERY_TOKEN_TAGS_PREDICTIONS = gql`
  query Token_Tags_Predictions(
    $id: BigInt
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
    $language: Int!
  ) {
    tokens(where: { id: $id }) {
      Tags_Predictions(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_has_Meaning_language
      }
    }
  }
`;

export const QUERY_TOKEN_TAGS_PREDICTIONS_V2 = gql`
  query Token_Tags_Predictions_V2(
    $id: BigInt!
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
    $language: Int!
  ) {
    Token_has_Meaning_Tags_Predictions_v2(id: $id, where: $where, options: $options, language: $language) {
      id
      name
      count_all_positions_in_phrases
      active_contact_method_annotation_status
      Token_has_Meaning_language
    }
  }
`;

export const QUERY_TOKEN_TAGS_OPTIONS = gql`
  query Token_Tags_Options(
    $id: BigInt
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
    $language: Int!
  ) {
    tokens(where: { id: $id }) {
      Tags_Options(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_has_Meaning_language
      }
    }
  }
`;

export const QUERY_TOKEN_TAGS_OPTIONS_V2 = gql`
  query Token_Tags_Options_V2(
    $id: BigInt!
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
    $language: Int!
  ) {
    Token_has_Meaning_Tags_Options_v2(id: $id, where: $where, options: $options, language: $language) {
      id
      name
      count_all_positions_in_phrases
      active_contact_method_annotation_status
      Token_has_Meaning_language
    }
  }
`;

// target asset extension
export const QUERY_TAG_TOKENS_CONSENSUS_ANNOTATIONS = gql`
  query Tag_Tokens_Consensus_Annotations(
    $id: BigInt
    $where: Token_Filter_Where_Input
    $options: Token_Filter_Options_Input
    $consensus: Boolean = true
    $language: Int!
  ) {
    tags(where: { id: $id }) {
      Associated_Tokens_Consensus_Annotations(
        where: $where
        options: $options
        consensus: $consensus
        language: $language
      ) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_has_Meaning_language
      }
    }
  }
`;

export const QUERY_TAG_TOKENS_PREDICTIONS = gql`
  query Tag_Tokens_Prediction(
    $id: BigInt
    $where: Token_Filter_Where_Input
    $options: Token_Filter_Options_Input
    $language: Int!
  ) {
    tags(where: { id: $id }) {
      Associated_Tokens_Predictions(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_has_Meaning_language
      }
    }
  }
`;

export const QUERY_TAG_TOKENS_OPTIONS = gql`
  query Tag_Tokens_Options(
    $id: BigInt
    $where: Token_Filter_Where_Input
    $options: Token_Filter_Options_Input
    $language: Int!
  ) {
    tags(where: { id: $id }) {
      Associated_Tokens_Options(where: $where, options: $options, language: $language) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
        Token_has_Meaning_language
      }
    }
  }
`;

// method node
export const ASSIGN_TOKEN_HAS_MEANING_TO_CONTACT = gql`
  mutation Assign_Token_has_Meaning_to_Contact($input: Method_Token_has_Meaning_Assign_to_Contact_Input!) {
    assign_Token_has_Meaning_to_Contact(input: $input) {
      methodTokenHasMeanings {
        id
      }
    }
  }
`;

export const CONFIRM_TOKEN_HAS_MEANING_OPTION = gql`
  mutation Confirm_Method_Token_has_Meaning_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!, $language: Int!) {
    confirm_Method_Token_has_Meaning_option(
      input: { base_asset_id: $baseAssetId, target_asset_id: $targetAssetId }
      values: { language: $language }
    ) {
      methodTokenHasMeanings {
        id
      }
    }
  }
`;

export const CONFIRM_TOKEN_HAS_MEANING_OPTION_V2 = gql`
  mutation Confirm_Method_Token_has_Meaning_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!, $language: Int!) {
    confirm_Method_Token_has_Meaning_option(
      input: { base_asset_id: $baseAssetId, target_asset_id: $targetAssetId }
      values: { language: $language }
    ) {
      methodTokenHasMeanings {
        id
      }
    }
  }
`;

export const DECLINE_TOKEN_HAS_MEANING_OPTION = gql`
  mutation Decline_Method_Token_has_Meaning_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!, $language: Int!) {
    decline_Method_Token_has_Meaning_option(
      input: {
        base_asset_id: $baseAssetId # token id
        target_asset_id: $targetAssetId # tag id
      }
      values: { language: $language }
    ) {
      methodTokenHasMeanings {
        id
      }
    }
  }
`;

export const DECLINE_TOKEN_HAS_MEANING_OPTION_V2 = gql`
  mutation Decline_Method_Token_has_Meaning_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!, $language: Int!) {
    decline_Method_Token_has_Meaning_option(
      input: {
        base_asset_id: $baseAssetId # token id
        target_asset_id: $targetAssetId # tag id
      }
      values: { language: $language }
    ) {
      methodTokenHasMeanings {
        id
      }
    }
  }
`;
