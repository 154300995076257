import type { CharacterSpan, Mark, TextSpan } from "./types";

// https://www.joshwcomeau.com/snippets/javascript/range/
const range = (start: number, end: number, step = 1): number[] => {
  let output = [];

  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }

  for (let i = start; i < end; i += step) {
    output.push(i);
  }

  return output;
};

export const splitWithOffsets = <T extends TextSpan>(text: string, textSpans: T[]) => {
  const splits: CharacterSpan[] = [];
  const indexMap = new Map<number, Mark[]>();

  // TODO: fix a bug with correct overlapping
  textSpans.filter(textSpan => !textSpan.meta?.invisible).forEach((textSpan: T) => {
    const indexes =range(textSpan.start, textSpan.end);

    indexes.forEach((index: number, j: number) => {
      const marks = indexMap.get(index) || [] as Mark[];
      marks.push({
        anchor: j === 0,
        textSpan,
      });
      indexMap.set(index, marks);
    });
  });

  text.split('').forEach((t: string, index: number) => {
    splits.push({
      id: index.toString(),
      start: index,
      end: index + 1,
      text: t === ' ' ? '.' : t,
      transparent: text.slice(index, index + 1) === ' ',
      marks: indexMap.get(index),
    });
  });

  return splits;
}

export const isSelectionEmpty = (selection: Selection) => {
  const position = selection.anchorNode?.parentNode?.compareDocumentPosition(selection?.focusNode?.parentNode as Node);

  return position === 0 && selection.focusOffset === selection.anchorOffset;
};

export const getStartEnd = (node: any) => {
  const start = parseInt(node.getAttribute('data-start'), 10);
  const end = parseInt(node.getAttribute('data-end'), 10);

  return [start, end];
};

export const getText = (textSpan: TextSpan): string => {
  const selectedTags = textSpan.tags;

  return selectedTags && selectedTags.length > 0
        ? selectedTags.map(it => it.name).join(', ')
        : '';
}

export const uid = () => {
  return String(
    Date.now().toString(32) +
      Math.random().toString(16)
  ).replace(/\./g, '');
};
