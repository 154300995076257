import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Class_Associated_Tokens_Entities_State,
Class_Associated_Tokens_Ids_State,
} from './atoms';

export const Class_Associated_Tokens_selector = listSelector<DataItem>(
  'graph/classes/@selectors/Associated_Tokens/list',
  Class_Associated_Tokens_Entities_State,
  Class_Associated_Tokens_Ids_State
);
