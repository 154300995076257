import { useCallback } from 'react';

import { apiClient } from 'api/client'
import { QUERY_ITEM_SPECIFICATION } from 'modules/item-specifications/api/graphApi';
import { ItemSpecification } from 'types';

type UseGetItemSpecificationResult = {
  getItemSpecification: (itemId: string) => Promise<ItemSpecification | null>;
};

async function execute(itemId: string): Promise<ItemSpecification | null> {
  const result = await apiClient.query({
    query: QUERY_ITEM_SPECIFICATION,
    variables: {
      id: itemId,
    },
  });

  return result.data.Item_Specification;
}

export default function useGetItemSpecification(): UseGetItemSpecificationResult {
  const getItemSpecification = useCallback(
    async (itemId: string) => {
      const result = await execute(itemId);

      return result;
    }, []);

  return {
    getItemSpecification,
  };
}
