import { useMemo } from 'react';

import DataPage, { DataPageRenderProps } from 'components/DataPage';
import useModalWithValue from 'hooks/useModalWithValue';
import MainLayout from 'layouts/MainLayout';
import { Authenticated } from 'modules/auth/components/Authenticated';
import FilterModal from 'modules/item-specifications/components/FilterModal';
import SortModal from 'modules/item-specifications/components/SortModal';
import { AnnotationMode, INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/item-specifications/constants';
import { AnnotationContextMenuContextProvider } from 'modules/item-specifications/contexts/AnnotationContextMenuContext';
import { ItemSpecificationProvider } from 'modules/item-specifications/contexts/ItemSpecificationContext';
import useResetSpecificationItems from 'modules/item-specifications/hooks/useResetSpecificationItems';
import useSpecificationItemContextActions from 'modules/item-specifications/hooks/useSpecificationItemContextActions';
import useSpecificationItemsQuery from 'modules/item-specifications/hooks/useSpecificationItemsQuery';
import Header from 'modules/item-specifications/sections/Header';
import PhraseSeAnnotator from "modules/item-specifications/sections/PhraseSeAnnotator";
import PhraseTagClassAnnotator from 'modules/item-specifications/sections/PhraseTagClassAnnotator';
import SpecificationAnnotator from 'modules/item-specifications/sections/SpecificationAnnotator';
import { transformVariables } from 'modules/item-specifications/utils';
import DataPageProvider from 'providers/DataPageProvider';

export default function ItemSpecifications() {
  const resetSpecificationItems = useResetSpecificationItems();

  const {
    open: sortModalOpen,
    value: sortModalValue,
    openModal: openSortModal,
    closeModal: closeSortModal,
    setValue: setSortModalValue,
  } = useModalWithValue({ initialValue: INITIAL_SORT_PARAMS, onValueChanged: resetSpecificationItems });

  const {
    open: filterModalOpen,
    value: filterModalValue,
    meta: filterMeta,
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    setValue: setFilterModalValue,
    resetValue: resetFilter,
  } = useModalWithValue({ initialValue: INITIAL_FILTER_PARAMS, onValueChanged: resetSpecificationItems });

  const actions = useMemo(
    () => ({
      sort: {
        handler: openSortModal,
      },
      filter: {
        meta: filterMeta,
        handler: openFilterModal,
      },
    }),
    [openSortModal, openFilterModal, filterMeta],
  );
  const contextActions = useSpecificationItemContextActions()
  const dataVariables = useMemo(() => transformVariables(filterModalValue, sortModalValue, {}), [filterModalValue, sortModalValue]);

  return (
    <DataPageProvider>
      <DataPage
        title="Specification Items"
        useQuery={useSpecificationItemsQuery}
        dataVariables={dataVariables}
        actions={actions}
        contextActions={contextActions}
      >
        {({ currentItemId }: DataPageRenderProps) => (
          <>
            <ItemSpecificationProvider itemId={currentItemId}>
              {({ currentItem }) => (
                <Header>
                  {({ annotationMode, helpers }) => (
                    <>
                      {annotationMode === AnnotationMode.Method_Phrase_Tag && (
                        <AnnotationContextMenuContextProvider>
                          <PhraseTagClassAnnotator
                            itemSpecification={currentItem}
                            onModified={(dirty) => helpers.setDirty(dirty)}
                          />
                        </AnnotationContextMenuContextProvider>
                      )}
                      {annotationMode === AnnotationMode.Method_Phrase_SE && (
                        <AnnotationContextMenuContextProvider>
                          <PhraseSeAnnotator
                            itemSpecification={currentItem}
                            onModified={(dirty) => helpers.setDirty(dirty)}
                          />
                        </AnnotationContextMenuContextProvider>
                      )}
                      {annotationMode === AnnotationMode.Method_Specification && (
                        <SpecificationAnnotator
                          itemSpecification={currentItem}
                          onModified={(dirty) => helpers.setDirty(dirty)}
                        />
                      )}
                    </>
                  )}
                </Header>
              )}
            </ItemSpecificationProvider>
            {sortModalOpen && (
              <SortModal
                value={sortModalValue}
                options={SORT_OPTIONS}
                onChange={setSortModalValue}
                onClose={closeSortModal}
              />
            )}
            {filterModalOpen && (
              <FilterModal
                value={filterModalValue}
                onChange={setFilterModalValue}
                onClose={closeFilterModal}
                onReset={resetFilter}
              />
            )}
          </>
        )}
      </DataPage>
    </DataPageProvider>
  );
}

ItemSpecifications.getLayout = (page: any) => (
  <Authenticated>
    <MainLayout>
      {page}
    </MainLayout>
  </Authenticated>
);
