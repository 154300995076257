import { gql } from '@apollo/client';

import { DIN_276_COST_GROUPS, GROUPS_OF_SIMILAR_WORK, TRADES } from "modules/classes/constants";

export const TAGS_BY_CLASS_TRADES = gql`
  query tagsByClassTrades {
    graphApiTags(filterableClass: "${TRADES}") {
      edges {
        node {
          id
          _id
          name
        }
      }
    }
  }
`;
export const TAGS_BY_CLASS_DIN_276_COST_GROUPS = gql`
  query tagsByDin276CostGroups {
    graphApiTags(filterableClass: "${DIN_276_COST_GROUPS}") {
      edges {
        node {
          id
          _id
          name
        }
      }
    }
  }
`;

export const TAGS_BY_CLASS_GROUPS_OF_SIMILAR_WORK_ITEMS = gql`
  query tagsByGroupsOfSimilarWorkItems($name: String!) {
    graphApiTags(name: $name, filterableClass: "${GROUPS_OF_SIMILAR_WORK}") {
      edges{
        node{
          id
          _id
          name
        }
      }
    }
  }
`;
