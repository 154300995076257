import { memo } from 'react';

import { darkTheme, lightTheme } from 'components/icons/constants';

type Props = {
  light?: boolean;
  selected?: boolean;
};

function SortIcon({ light, selected }: Props) {
  const theme = light ? lightTheme : darkTheme;
  const opacity = selected ? theme.opacity.selected : theme.opacity.defaults;
  const fill = selected ? theme.fill.selected : theme.fill.defaults;

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity} clipPath="url(#clip0)">
        <path
          d="M0.944243 5.16831H10.4597V3.27979H0.944243C0.422776 3.27979 0 3.70256 0 4.22403C0 4.7455 0.422776 5.16831 0.944243 5.16831Z"
          fill={fill}
        />
        <path
          d="M0.944243 8.95761H10.4597V7.06909H0.944243C0.422776 7.06909 0 7.49187 0 8.01337C0 8.53487 0.422776 8.95761 0.944243 8.95761Z"
          fill={fill}
        />
        <path
          d="M0.944243 10.8584V10.8584C0.422776 10.8584 0 11.2812 0 11.8027C0 12.3242 0.422776 12.7469 0.944243 12.7469H7.55487C7.48019 12.5169 7.44077 12.2744 7.44077 12.0256C7.44077 11.6089 7.55003 11.2092 7.7541 10.8584H0.944243Z"
          fill={fill}
        />
        <path
          d="M16.6503 11.1813C16.184 10.715 15.4279 10.715 14.9615 11.1813L13.9811 12.1617V1.95557C13.9811 1.29606 13.4464 0.761475 12.7869 0.761475C12.1274 0.761475 11.5928 1.2961 11.5928 1.95557V12.1617L10.6123 11.1813C10.146 10.715 9.38995 10.715 8.92361 11.1813C8.45727 11.6476 8.45727 12.4037 8.92361 12.87L11.9424 15.8888C12.4078 16.3543 13.1648 16.3559 13.6315 15.8888L16.6503 12.87C17.1166 12.4037 17.1166 11.6476 16.6503 11.1813Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="17" height="17" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(SortIcon);
