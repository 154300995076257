import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Classes_selector } from 'modules/classes/store';

export default function useResetClasses() {
  const reset = useResetRecoilState(Classes_selector);

  return useCallback(() => {
    reset();
  }, [reset]);
}
