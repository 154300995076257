import DataGroup from 'components/DataGroup';
import DataSection from 'components/DataSection';
import useConsensus from 'hooks/useConsensus';
import FilterModal from 'modules/morphs/components/FilterModal';
import SortModal from 'modules/morphs/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/morphs/constants';
import { transformVariables } from 'modules/morphs/utils';
import { DataItem } from 'types';

import useAnnotations from './useAnnotations'
import useContextActions from './useContextActions'
import { useConsensusAnnotationsQuery, useOptionsQuery } from './useQueries'

type Props = {
  parentItem: DataItem;
};

const menuAcl = {
  Morphs_Options: {
    ASSIGN_METHOD_TO_ME: false,
    ASSIGN_METHOD_TO_ANOTHER_CONTACT: false,
  }
};

export default function MorphsDataSection({ parentItem }: Props) {
  const { consensus } = useConsensus();
  const { confirmAnnotation, declineAnnotation } = useAnnotations({ parentItem })

  const contextActions = useContextActions({ parentItem })

  return (
    <DataGroup
      title="Morphs"
      structure={{
        menuId: 'Morpheme_Morphs',
        menuAcl,
      }}
      contextActions={contextActions}
      consensusAnnotations={
        <DataSection
          parentItem={parentItem}
          useQuery={useConsensusAnnotationsQuery}
          structure={{
            id: 'Morphs_Consensus_Annotations',
            title: 'Consensus & Annotations',
            countField: 'count_Morph_has_Morpheme',
            readonly: consensus,
          }}
          consensus={consensus}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
      options={
        <DataSection
          parentItem={parentItem}
          useQuery={useOptionsQuery}
          structure={{
            id: 'Morphs_Options',
            title: 'Options',
            countField: 'count_Morph_has_Morpheme',
            orangeLabel: false,
          }}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
    />
  );
}
