import { useState } from 'react';
import { useMutation } from '@apollo/client';

import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import PersonTwoToneIcon from '@material-ui/icons/PersonTwoTone';

import { CALCULATE_CONSENSUS } from 'api/mutations';
import { useAuth } from 'modules/auth/hooks/useAuth';

import useStyles from './styles';

export default function UserMenu() {
  const { user } = useAuth();
  const classes = useStyles();

  const onCompleted = () => {
    alert('Calculate consensus finished');
    window.location.reload();
  }
  const [calculateConsensus] = useMutation(CALCULATE_CONSENSUS, { onCompleted });

  const [anchorEl, setAnchorEl] = useState<any>(null)
  const handleMenuOpen = (e: any) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleCalculateConsensus = () => {
    calculateConsensus();
    handleMenuClose();
    setTimeout(() => {
      alert('Calculate consensus started');
    }, 0);
  };

  return (
    <>
      <div
        className={classes.root}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <Typography variant="body1" noWrap>
          {user?.name}
        </Typography>
        <Avatar alt={user?.name}>
          <PersonTwoToneIcon />
        </Avatar>
      </div>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleCalculateConsensus}>Calculate Consensus</MenuItem>
      </Menu>
    </>
  );
}
