import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { SemanticEntities_selector } from 'modules/semantic-entities/store';

export default function useResetSemanticEntities() {
  const reset = useResetRecoilState(SemanticEntities_selector);

  return useCallback(() => {
    reset();
  }, [reset]);
}
