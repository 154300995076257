import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  SemanticEntity_Phrases_Consensus_Annotations_Entities_State,
  SemanticEntity_Phrases_Consensus_Annotations_Ids_State,
  SemanticEntity_Phrases_Options_Entities_State,
  SemanticEntity_Phrases_Options_Ids_State,
  SemanticEntity_Phrases_Predictions_Entities_State,
  SemanticEntity_Phrases_Predictions_Ids_State,
} from './atoms';

export const SemanticEntity_Phrases_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/semantic-entities/@selectors/Phrases_Consensus_Annotations/list',
  SemanticEntity_Phrases_Consensus_Annotations_Entities_State,
  SemanticEntity_Phrases_Consensus_Annotations_Ids_State
);

export const SemanticEntity_Phrases_Predictions_selector = listSelector<DataItem>(
  'graph/semantic-entities/@selectors/Phrases_Predictions/list',
  SemanticEntity_Phrases_Predictions_Entities_State,
  SemanticEntity_Phrases_Predictions_Ids_State,
);

export const SemanticEntity_Phrases_Options_selector = listSelector<DataItem>(
  'graph/semantic-entities/@selectors/Phrases_Options/list',
  SemanticEntity_Phrases_Options_Entities_State,
  SemanticEntity_Phrases_Options_Ids_State,
);
