import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  Tag_Childs_Tags_Consensus_Annotations_Entities_State,
  Tag_Childs_Tags_Options_Entities_State,
  Tag_Childs_Tags_Predictions_Entities_State,
  Tag_Parent_Tags_Consensus_Annotations_Entities_State,
  Tag_Parent_Tags_Options_Entities_State,
  Tag_Parent_Tags_Predictions_Entities_State,
  Tag_Synonyms_Tags_Consensus_Annotations_Entities_State,
  Tag_Synonyms_Tags_Options_Entities_State,
  Tag_Synonyms_Tags_Predictions_Entities_State,
  Tags_Entities_State,
} from 'modules/tags/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateTagEntities(params: Params) {
  const { transform } = params;

  const update_Tags = useSetRecoilState(Tags_Entities_State);

  // Parent Tags
  const update_Parent_Tags_Consensus_Annotations = useSetRecoilState(Tag_Parent_Tags_Consensus_Annotations_Entities_State);
  const update_Parent_Tags_Predictions = useSetRecoilState(Tag_Parent_Tags_Predictions_Entities_State);
  const update_Parent_Tags_Options = useSetRecoilState(Tag_Parent_Tags_Options_Entities_State);

  // Child Tags
  const update_Childs_Tags_Consensus_Annotations = useSetRecoilState(Tag_Childs_Tags_Consensus_Annotations_Entities_State);
  const update_Childs_Tags_Predictions = useSetRecoilState(Tag_Childs_Tags_Predictions_Entities_State);
  const update_Childs_Tags_Options = useSetRecoilState(Tag_Childs_Tags_Options_Entities_State);

  // Synonyms Tags
  const update_Synonyms_Tags_Consensus_Annotations = useSetRecoilState(Tag_Synonyms_Tags_Consensus_Annotations_Entities_State);
  const update_Synonyms_Tags_Predictions = useSetRecoilState(Tag_Synonyms_Tags_Predictions_Entities_State);
  const update_Synonyms_Tags_Options = useSetRecoilState(Tag_Synonyms_Tags_Options_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const);
    const updater = entitiesUpdaterFactory(entries);

    // Tags
    update_Tags(updater);
    // Parent Tags
    update_Parent_Tags_Consensus_Annotations(updater);
    update_Parent_Tags_Predictions(updater);
    update_Parent_Tags_Options(updater);
    // Childs Tags
    update_Childs_Tags_Consensus_Annotations(updater);
    update_Childs_Tags_Predictions(updater);
    update_Childs_Tags_Options(updater);
    // Synonyms Tags
    update_Synonyms_Tags_Consensus_Annotations(updater);
    update_Synonyms_Tags_Predictions(updater);
    update_Synonyms_Tags_Options(updater);
  }, [
    transform,
    // Tags
    update_Tags,
    // Parent Tags
    update_Parent_Tags_Consensus_Annotations,
    update_Parent_Tags_Predictions,
    update_Parent_Tags_Options,
    // Childs Tags
    update_Childs_Tags_Consensus_Annotations,
    update_Childs_Tags_Predictions,
    update_Childs_Tags_Options,
    // Synonyms Tags
    update_Synonyms_Tags_Consensus_Annotations,
    update_Synonyms_Tags_Predictions,
    update_Synonyms_Tags_Options
  ]);
}
