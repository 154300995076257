import { ATTRIBUTE_ON_BUILDING_MATERIAL_ID, BUILDING_MATERIAL_ID } from 'modules/classes/constants';
import { Span } from 'modules/item-specifications/components/BaseTextAnnotator';
import { Operation, Source, Status } from 'shared/constants';
import { debug, uid } from 'shared/utils';
import { ItemSpecification, ItemSpecificationInput, PhraseTag, PhraseTagInput, SemanticEntity, SemanticEntityGroup, SemanticEntityGroupInput, SemanticEntityTag } from 'types';

const ANCHOR_BUILDING_MATERIAL_ALLOWED = [`${BUILDING_MATERIAL_ID}`]
const ATTRIBUTE_ON_BUILDING_MATERIAL_ALLOWED = [`${BUILDING_MATERIAL_ID}`, `${ATTRIBUTE_ON_BUILDING_MATERIAL_ID}`]

const keyId = (obj: { id: string, start: number, end: number }) => `${obj.id}_${obj.start}:${obj.end}`;

export const getSpans = (itemSpecification: ItemSpecification, operation: Operation): Span[] => {
  debug("getSpans:semanticEntityGroups", itemSpecification.semanticEntityGroups);

  const description = itemSpecification.description || '';
  const spanMap = new Map<string, Span>();

  const makeSpan = (spanTag: SemanticEntityTag | PhraseTag, group?: SemanticEntityGroup, se?: SemanticEntity): Span => {
    const span = {
      id: uid(),
      text: description.slice(spanTag.start, spanTag.end),
      groupId: group?.id ?? null,
      seId: se?.id ?? null,
      tagId: spanTag.id,
      first_position_in_phrase: spanTag.start,
      last_position_in_phrase: spanTag.end,
      source: group?.source || spanTag.source,
      status: group?.status || spanTag.status,
      anchor: spanTag.anchor,
      dirty: false,
      active: true,
      invisible: group?.id ? true : spanTag.status === Status.declined,
      labels: spanTag.classes?.map(it =>
        ({
          id: it.id,
          name: it.name,
          status: it.status,
          source: it.source,
          dirty: false,
        })
      ),
    } as Span;

    // ANCHOR_BUILDING_MATERIAL
    if (operation === Operation.ANCHOR_BUILDING_MATERIAL) {
      // Display only spans associated with "Building Material"
      span.invisible = span.invisible || !spanTag.classes?.some(it => ANCHOR_BUILDING_MATERIAL_ALLOWED.includes(it.id))
    }

    // ATTRIBUTE_ON_BUILDING_MATERIAL
    if (operation === Operation.ATTRIBUTE_ON_BUILDING_MATERIAL) {
      // Display only spans associated with "Building Material" and "Attribute on Building Material"
      span.invisible = span.invisible || !spanTag.classes?.some(it => ATTRIBUTE_ON_BUILDING_MATERIAL_ALLOWED.includes(it.id))
      // Do not allow to edit anchor building materials
      span.active = !spanTag.anchor;
    }

    return span;
  };

  itemSpecification.phraseTags.filter(phraseTag => phraseTag.source === Source.prediction).forEach(phraseTag => {
    const key = keyId(phraseTag);
    const span = makeSpan(phraseTag);
    spanMap.set(key, span);
  });

  itemSpecification.phraseTags.filter(phraseTag => phraseTag.source === Source.consensus).forEach(phraseTag => {
    const key = keyId(phraseTag);
    const span = makeSpan(phraseTag);
    spanMap.set(key, span);
  });

  itemSpecification.semanticEntityGroups.forEach(group => {
    group.semanticEntities.forEach(se => {
      se.tags.forEach(spanTag => {
        const key = keyId(spanTag);
        const span = makeSpan(spanTag, group, se);
        spanMap.set(key, span);
      });
    });
  });

  const spans: Span[] = [...spanMap.values()];
  debug("getSpans:spans", spans);

  return spans;
};

export const getItemSpecificationInput = (spans: Span[]): ItemSpecificationInput => {
  const semanticEntityGroups: SemanticEntityGroupInput[] = [];
  const phraseTags: PhraseTagInput[] = [];

  const spanMap = new Map<string, Span>();
  // semanticEntityGroups
  const groupSesMap = new Map<string, Set<string>>();
  const groupStatusMap = new Map<string, number>();
  const seSpansMap = new Map<string, Set<string>>();
  // phraseTags
  const phraseTagSpansSet = new Set<string>();

  debug("getItemSpecificationInput:spans", spans);

  // 1. collect spans
  spans.forEach(span => {
    spanMap.set(span.id, span);
  });

  // 2. collect semanticEntityGroups
  spans.filter(span => span.groupId).forEach(span => {
    if (span.source === Source.consensus) {
      const groupId = span.groupId as string;
      const seId = span.seId as string;

      // track group status
      groupStatusMap.set(groupId, span.status);

      // add SE to SEG
      const ses = groupSesMap.get(groupId) || new Set<string>();
      ses.add(seId);
      groupSesMap.set(groupId, ses);

      // add span to SE
      const spans = seSpansMap.get(seId) || new Set<string>();
      spans.add(span.id);
      seSpansMap.set(seId, spans);
    }
  });

  // 3. collect phraseTags
  spans.filter(span => !span.groupId).forEach(span => {
    if (span.source === Source.consensus || span.source === Source.prediction && !span.invisible) {
      phraseTagSpansSet.add(span.id);
    }
  });

  // 4. restore semanticEntityGroups from spans
  groupSesMap.forEach((ses, groupId) => {
    const group = {
      id: groupId,
      name: `SEG-${groupId}`,
      source: Source.consensus,
      status: groupStatusMap.get(groupId),
      semanticEntities: [...ses].map(seId => {
        const seSpans = Array.from(seSpansMap.get(seId) || []).map(it => spanMap.get(it) as Span)

        return {
          id: seId,
          name: `SE-${seId}`,
          tags: seSpans.map(span =>
            ({
              id: span.tagId as string,
              status: span.status,
            })
          )
        };
      }),
    };

    semanticEntityGroups.push(group)
  });

  // 5. restore phraseTags from spans
  phraseTagSpansSet.forEach((spanId) => {
    const span = spanMap.get(spanId) as Span;

    phraseTags.push({
      id: span.tagId as string,
      start: span.first_position_in_phrase,
      end: span.last_position_in_phrase,
      anchor: span.anchor,
      status: span.status,
    })
  });

  debug("getItemSpecificationInput:semanticEntityGroups", semanticEntityGroups);
  debug("getItemSpecificationInput:phraseTags", phraseTags);

  return {
    id: '',
    semanticEntityGroups,
    phraseTags,
  };
};
