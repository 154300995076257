import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Item_Specifications_Entities_State, Item_Specifications_Ids_State } from 'modules/item-specifications/store';

export default function useResetSpecificationItems() {
  const resetEntities = useResetRecoilState(Item_Specifications_Entities_State);
  const resetIds = useResetRecoilState(Item_Specifications_Ids_State);

  return useCallback(() => {
    resetEntities();
    resetIds();
  }, [
    resetEntities,
    resetIds,
  ]);
}
