import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Morphemes_Entities_State } from 'modules/morphemes/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateMorphemeEntities(params: Params) {
  const { transform } = params;

  const update_Morphemes = useSetRecoilState(Morphemes_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const);
    const updater = entitiesUpdaterFactory(entries);
    update_Morphemes(updater);
  }, [
    transform,
    update_Morphemes,
  ]);
}
