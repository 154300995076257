import { useSetRecoilState } from 'recoil';

import { useAnnotationOptions } from 'hooks/useAnnotation';
import useMorphHasMorphemeAnnotations from 'methods/morph-has-morpheme/hooks/useMorphHasMorphemeAnnotations';
import {
  Morph_Morphemes_Consensus_Annotations_Entities_State,
  Morph_Morphemes_Consensus_Annotations_Ids_State,
  Morph_Morphemes_Options_Entities_State,
} from 'modules/morphs/store';
import { Status } from 'shared/constants';
import { AnnotationConfig, DataItem } from 'types';

type useAnnotationsParams = {
  parentItem: DataItem;
};

const annotationConfig: AnnotationConfig = {
  parentIdField: 'baseAssetId',
  itemIdField: 'targetAssetId',
};

export default function useAnnotations(params: useAnnotationsParams) {
  const { parentItem } = params;

  const updateConsensusAnnotationsEntities = useSetRecoilState(Morph_Morphemes_Consensus_Annotations_Entities_State);
  const updateConsensusAnnotationsIds = useSetRecoilState(Morph_Morphemes_Consensus_Annotations_Ids_State);
  const updateOptionsEntities = useSetRecoilState(Morph_Morphemes_Options_Entities_State);

  const settersOrUpdaters = [
    [updateConsensusAnnotationsEntities, updateConsensusAnnotationsIds],
    null,
    updateOptionsEntities,
  ] as const;

  const { onCompleted: onConfirmCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.confirmed });
  const { onCompleted: onDeclineCompleted } = useAnnotationOptions({ settersOrUpdaters, status: Status.declined });
  const annotationsOptions = { onConfirmCompleted, onDeclineCompleted };

  return useMorphHasMorphemeAnnotations(annotationConfig, { parentItem }, annotationsOptions);
}
