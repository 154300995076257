import { makeStyles } from '@material-ui/core/styles';

type Props = {
  dirty: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  buttonArrow: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  buttonSave: (props: Props) => ({
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    backgroundColor: props.dirty ? theme.palette.warning.dark : theme.palette.success.main,
  }),
  actionsContainer: {
    margin: 0,
  },
}));

export default useStyles;
