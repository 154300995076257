import { useSetRecoilState } from 'recoil';

import { useAssignMethodToContact, useAssignMethodToContactOptions }  from 'hooks/useAssignMethodToContact';
import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ContextAction, useContextActions2 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_TAG_HAS_CLASS_TO_CONTACT } from 'methods/tag-has-class/api';
import useUpdateTagEntities from 'modules/classes/hooks/useUpdateTagEntities';
import {
  Class_Tags_Consensus_Annotations_Entities_State,
  Class_Tags_Predictions_Entities_State,
} from 'modules/classes/store';
import { ASSIGN_TAG_TO_CONTACT, MARK_TAG_AS_DONE, MARK_TAG_AS_NOT_DONE } from 'modules/tags/api';
import { Edge_Direction, Methods } from 'shared/constants';
import { AssignMethodConfig, DataItem } from 'types';

const assignMethodConfig: AssignMethodConfig = {
  method: Methods.Tag_has_Class,
  direction: Edge_Direction.INVERSE,
};

type useContextActionsParams = {
  parentItem: DataItem;
};

export default function useContextActions(params: useContextActionsParams): ContextAction[] {
  const { parentItem } = params;

  const update_Tags_Consensus_Annotations = useSetRecoilState(Class_Tags_Consensus_Annotations_Entities_State);
  const update_Tags_Predictions = useSetRecoilState(Class_Tags_Predictions_Entities_State);

  const onAssignToContactCompleted = useUpdateTagEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_TAG_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateTagEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_TAG_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateTagEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_TAG_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const settersOrUpdaters = [
    update_Tags_Consensus_Annotations,
    update_Tags_Predictions,
    null,
    null,
  ] as const;
  const assignMethodToContactOptions = useAssignMethodToContactOptions({ settersOrUpdaters });
  const assignMethodToContact = useAssignMethodToContact(assignMethodConfig, ASSIGN_TAG_HAS_CLASS_TO_CONTACT, parentItem, assignMethodToContactOptions);

  return useContextActions2('/tags', { assignToContact, assignMethodToContact, markAsDone, markAsNotDone });
}
