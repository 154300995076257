import { ReactNode } from 'react';

import Grid from '@material-ui/core/Grid';

import ContextMenu from 'components/ContextMenu';
import DataGrid from 'components/DataGrid';
import DataGroupProvider from 'components/DataGroupProvider';
import { ContextAction, ContextActionAcl } from 'hooks/useContextActions';
import ContextActionsProvider from 'providers/ContextActionsProvider';
import DataSelectionProvider from 'providers/DataSelectionProvider';
import { KeyField } from 'types';

import useStyles from './styles';

type Structure = {
  menuId: string;
  menuAcl?: ContextActionAcl;
  keyField?: KeyField | Array<KeyField>;
};

type Action = {
  icon?: ReactNode;
  meta?: Record<string, any>;
  content?: ReactNode;
  handler: () => void;
};

type Props = {
  title: string;
  structure: Structure;
  actions?: Action[];
  contextActions?: ContextAction[];
  consensusAnnotations?: ReactNode;
  predictions?: ReactNode;
  options?: ReactNode;
};

const DataGroup = (props: Props) => {
  const { title, structure, actions, contextActions, consensusAnnotations, predictions, options } = props;
  const classes = useStyles();

  return (
    <DataGroupProvider structure={structure}>
      <DataSelectionProvider keyField={structure.keyField}>
        <ContextActionsProvider>
          <DataGrid title={title} actions={actions}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item className={classes.item}>
                {consensusAnnotations}
              </Grid>
              <Grid item className={classes.item}>
                {predictions}
              </Grid>
              <Grid item className={classes.item}>
                {options}
              </Grid>
            </Grid>
          </DataGrid>
          <ContextMenu menuId={structure.menuId} menuAcl={structure.menuAcl} actions={contextActions} />
        </ContextActionsProvider>
      </DataSelectionProvider>
    </DataGroupProvider>
  );
};

export default DataGroup;
