import DataTable from 'components/DataTable';
import Page from 'components/Page';
import useModalWithValue from 'hooks/useModalWithValue';
import MainLayout from 'layouts/MainLayout';
import { Authenticated } from 'modules/auth/components/Authenticated';
import EntryModal from 'modules/contacts/components/EntryModal';
import { EntryParamsType, INITIAL_ENTRY_PARAMS } from 'modules/contacts/constants';
import useContact from 'modules/contacts/hooks/useContact';
import useContactsQuery from 'modules/contacts/hooks/useContactsQuery';
import { DataItem } from 'types';

export default function Contacts() {
  const {
    open: entryModalOpen,
    value: entryModalValue,
    openModal: openEntryModal,
    closeModal: closeEntryModal,
    setValue: setEntryModalChange,
  } = useModalWithValue<EntryParamsType>({ initialValue: INITIAL_ENTRY_PARAMS });

  const { createContact, updateContact } = useContact()

  return (
    <Page title="Contacts">
      <DataTable
        useQuery={useContactsQuery}
        onAdd={openEntryModal}
        onEdit={(contact: DataItem) => {
          setEntryModalChange({
            contact: {
              id: contact.id,
              name: contact.name,
              contact_weight: contact.contact_weight,
            },
          });
          openEntryModal();
        }}
      />

      {entryModalOpen && (
        <EntryModal
          onClose={closeEntryModal}
          value={entryModalValue}
          onChange={(values: EntryParamsType) => {
            if (values.contact?.id) {
              updateContact(values.contact);
            } else {
              createContact(values.contact);
            }
            closeEntryModal(true)
          }}
        />
      )}
    </Page>
  );
}

Contacts.getLayout = (page: any) => (
  <Authenticated>
    <MainLayout>
      {page}
    </MainLayout>
  </Authenticated>
);
