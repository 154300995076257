import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const SemanticEntity_Phrases_Consensus_Annotations_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/semantic-entities/@atoms/Phrases_Consensus_Annotations/entities',
  default: ImmutableMap(),
});

export const SemanticEntity_Phrases_Consensus_Annotations_Ids_State = atom<string[]>({
  key: 'graph/semantic-entities/@atoms/Phrases_Consensus_Annotations/ids',
  default: [],
});

export const SemanticEntity_Phrases_Predictions_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/semantic-entities/@atoms/Phrases_Predictions/entities',
  default: ImmutableMap(),
});

export const SemanticEntity_Phrases_Predictions_Ids_State = atom<string[]>({
  key: 'graph/semantic-entities/@atoms/Phrases_Predictions/ids',
  default: [],
});

export const SemanticEntity_Phrases_Options_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/semantic-entities/@atoms/Phrases_Options/entities',
  default: ImmutableMap(),
});

export const SemanticEntity_Phrases_Options_Ids_State = atom<string[]>({
  key: 'graph/semantic-entities/@atoms/Phrases_Options/ids',
  default: [],
});
