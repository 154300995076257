import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import useStyles from './Description.styles';

type Props = {
  name?: string
  description?: string
};

export default function Description({ name, description }: Props) {
  const classes = useStyles();

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Typography variant="h6">{name}</Typography>
        }
        disableTypography
      />
      <CardContent>
        <Typography className={classes.content}>{description}</Typography>
      </CardContent>
    </Card>
  )
}
