import { Fragment, ReactNode, useMemo } from 'react';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import useStyles from './styles';

type ActionType = {
  icon?: (meta: Record<string, any>) => ReactNode;
  meta?: Record<string, any>;
  content?: ReactNode;
  handler: () => void;
};

type Props = {
  title?: string;
  actions?: ActionType[];
  children?: ReactNode;
};

function renderAction(action: ActionType, classes: any): ReactNode | null {
  if (action.icon) {
    return (
      <IconButton className={clsx(classes.actionButton)} onClick={action.handler} disableRipple>
        {action.icon(action.meta as Record<string, any>)}
      </IconButton>
    );
  }

  return null;
}

export default function DataCard({ title, actions = [], children }: Props) {
  const classes = useStyles();

  const headerActions = useMemo(() => {
    const actionClasses = {
      actionButton: classes.actionButton,
    };

    return (
      <div className={classes.actions}>
        {actions.map((action: any, index: any) => (
          <Fragment key={index}>{renderAction(action, actionClasses)}</Fragment>
        ))}
      </div>
    );
  }, [actions, classes.actionButton, classes.actions]);

  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        title={title}
        action={headerActions}
        classes={{
          root: classes.headerRoot,
          title: classes.headerTitle,
          action: classes.headerAction,
        }}
      />
      <Divider />
      <CardContent className={classes.content}>{children}</CardContent>
    </Card>
  );
}
