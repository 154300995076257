import MuiDialogActions from '@material-ui/core/DialogActions';
import { Theme, withStyles } from '@material-ui/core/styles';

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2.25, 2.5, 5),
    justifyContent: 'center',
  },
}))(MuiDialogActions);

export default DialogActions;
