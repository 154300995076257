import { HTMLAttributes, memo, SyntheticEvent, useCallback, useMemo } from 'react'
import clsx from 'clsx'

import Mark from './Mark'
import type { CharacterSpan, TextAnnotatorConfig, TextSpan } from './types';
import { getStartEnd, getText as getTextDefaults, isSelectionEmpty, splitWithOffsets, uid } from './utils';

import styles from './TextAnnotator.module.css';

type SpanProps = CharacterSpan & {
  getText?: (textSpan: TextSpan) => string;
  onClick?: (e: SyntheticEvent, textSpan: TextSpan) => void;
};

const Span = memo((props: SpanProps) => {
  const {
    start,
    end,
    transparent,
    text,
    marks,
    getText = getTextDefaults,
    onClick,
  } = props;

  return (
    <span
      data-start={start}
      data-end={end}
      className={clsx(styles.span, transparent ? styles.transparent : "")}
      style={{
        marginRight: text === '\n' ? '100%' : undefined,
      }}
    >
      {text}
      {marks?.map((mark, index) =>
        <Mark
          key={index}
          text={getText(mark.textSpan)}
          {...mark}
          onClick={onClick}
        />
      )}
    </span>
  )
});

type TextBaseProps<T> = {
  content: string;
  config?: TextAnnotatorConfig;
  value: T[];
  getSpan?: (textSpan: TextSpan) => Promise<T> | T;
  onSpanClick?: (e: SyntheticEvent, textSpan: TextSpan) => void;
  onSpanCreated?: (textSpan: TextSpan) => void;
  onUpdate?: (newValue: T[]) => void;
  disableSelection?: boolean
};

type TextAnnotatorProps<T> = HTMLAttributes<HTMLDivElement> & TextBaseProps<T>;

const TextAnnotator = <T extends TextSpan>(props: TextAnnotatorProps<T>) => {
  const {
    content,
    config,
    value,
    style,
    disableSelection = false,
    getSpan,
    onUpdate,
    onSpanCreated,
    onSpanClick,
  } = props;

  const transformSpan = useCallback((textSpan: TextSpan): Promise<T> => {
    if (getSpan) {
      const promiseOrValue = getSpan?.(textSpan);
      return promiseOrValue instanceof Promise
        ? promiseOrValue
        : Promise.resolve(promiseOrValue);
    }

    return Promise.resolve({
      start: textSpan.start,
      end: textSpan.end,
      text: textSpan.text,
    } as T);
  }, [getSpan]);

  const handleMouseUp = useCallback(async (e: SyntheticEvent) => {
    if (!onUpdate) return

    if(disableSelection) {
      window.getSelection()?.empty();
      return;
    }

    const selection = window.getSelection()
    if (!selection) return;
    if (isSelectionEmpty(selection)) return;

    const anchorNode = selection.anchorNode?.parentNode;
    const focusNode = selection.focusNode?.parentNode;
    if (!anchorNode && !focusNode) return;

    const position = anchorNode?.compareDocumentPosition(focusNode as Node);
    const [anchorStart, anchorEnd] = getStartEnd(anchorNode);
    const [focusStart, focusEnd] = getStartEnd(focusNode);

    let start = -1;
    let end = -1;
    if (!position) {
      start = anchorStart;
      end = anchorEnd;
    } else if (position === Node.DOCUMENT_POSITION_FOLLOWING) {
      start = anchorStart;
      end = focusEnd;
    } else if (position === Node.DOCUMENT_POSITION_PRECEDING) {
      start = focusStart;
      end = anchorEnd;
    }

    if (start === -1 && end === -1) return;

    const textSpan = await transformSpan({ id: uid(), start, end, text: content.slice(start, end) });
    onSpanCreated?.(textSpan);
    onUpdate?.([...value, textSpan]);
    window.getSelection()?.empty();
  }, [content, value, disableSelection, transformSpan, onSpanCreated, onUpdate])

  const items = useMemo(() => splitWithOffsets(content, value), [content, value]);

  return (
    <div style={style} onMouseUp={handleMouseUp}>
      {items.map((it: CharacterSpan) => (
        <Span
          key={`${it.start}-${it.end}`}
          {...it}
          getText={config?.getText}
          onClick={onSpanClick}
        />
      ))}
    </div>
  )
};

export default TextAnnotator;
