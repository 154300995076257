import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { QUERY_LANGUAGES } from 'api/queries';
import config from 'configuration';
import { DataItem, LanguageItem } from 'types';

const filterFactory =
  (term: string) =>
  ({ code, iso_language_name }: LanguageItem) =>
    code.toLowerCase().startsWith(term) || iso_language_name.toLowerCase().startsWith(term);

export default function useLanguages() {
  const { data } = useQuery(QUERY_LANGUAGES);
  const [items, setItems] = useState<LanguageItem[]>([]);

  useEffect(() => {
    if (data?.metadata?.language) {
      setItems([config.defaultLanguage, ...(data?.metadata?.language || [])]);
    }
  }, [data]);

  const getLanguage = useCallback(
    (id: number) => {
      if (id === 0) {
        return config.defaultLanguage;
      }

      return (data?.metadata?.language || []).find((x: LanguageItem) => x.id === id) || null;
    },
    [data],
  );

  const getLanguages = useCallback(
    (search: string) => {
      const languages = data?.metadata?.language || [];

      if (search) {
        const filter = filterFactory(search.toLowerCase());
        setItems(languages.filter(filter));
      } else {
        setItems(languages);
      }
    },
    [data],
  );

  const formatLanguage = useCallback(
    (item: DataItem, key?: string) => {
      const itemKey = (key || 'language') as keyof DataItem;
      return getLanguage((item[itemKey] as number) || 0)?.code || '';
    },
    [getLanguage],
  );

  return {
    languages: items,
    getLanguages,
    getLanguage,
    formatLanguage,
  };
}
