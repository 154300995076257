import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Tag_SemanticEntities_Entities_State,
  Tag_SemanticEntities_Ids_State,
} from './atoms';

export const Tag_SemanticEntities_selector = listSelector<DataItem>(
  'graph/tags/@selectors/SemanticEntities/list',
  Tag_SemanticEntities_Entities_State,
  Tag_SemanticEntities_Ids_State,
);
