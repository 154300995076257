export class CancellableController {
  abortController: AbortController
  public onAbort?: () => void

  constructor() {
    this.abortController = new AbortController()
  }

  get signal() {
    return this.abortController.signal
  }

  abort() {
    this.abortController.abort()
    this.onAbort?.()
  }
}
