import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_SEMANTIC_ENTITIES } from 'modules/semantic-entities/api';
import { SemanticEntities_selector } from 'modules/semantic-entities/store';

export default function useSemanticEntitiesQuery(variables: any) {
  const transform = useCallback((data: any) => data.Semantic_Entities, []);

  return useQuery(QUERY_SEMANTIC_ENTITIES, variables, SemanticEntities_selector, transform);
}
