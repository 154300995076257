import { useApplicationApiQuery } from 'hooks/useQuery';
import { QUERY_ITEM_SPECIFICATIONS } from 'modules/item-specifications/api/applicationApi';
import { Item_Specifications_selector } from 'modules/item-specifications/store';

const transform = (data: any) => data.itemSpecifications.edges.map((it: any) => it.node);

export default function useSpecificationItemsQuery(variables: any) {
  return useApplicationApiQuery(
    QUERY_ITEM_SPECIFICATIONS,
    variables,
    Item_Specifications_selector,
    transform,
    'itemSpecifications'
  );
}
