import { TokenSortOption } from 'modules/tokens/api';
import { DataItem, DictionaryItem } from 'types';

export type SortParamsType = {
  direction: 'DESC' | 'ASC';
  option: TokenSortOption;
};

export type FilterParamsType = {
  token?: DataItem | string | null;
  initialTokens: DataItem[];
  tokenTypes?: DictionaryItem[];
  containsCharacters?: string[];
  partOfClusters?: DataItem[];
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

export const INITIAL_FILTER_PARAMS: FilterParamsType = {
  token: null,
  initialTokens: [],
  tokenTypes: [],
  containsCharacters: [],
  partOfClusters: [],
  onlyAssigned: false,
  onlyDone: false,
};

export const INITIAL_SORT_PARAMS: SortParamsType = {
  direction: 'DESC',
  option: TokenSortOption.BY_COUNT_IN_PHRASES_ALL_POSITIONS,
};

export const SORT_OPTIONS = [
  {
    value: TokenSortOption.BY_COUNT_IN_PHRASES_ALL_POSITIONS,
    label: 'by count in Phrases all positions',
  },
  {
    value: TokenSortOption.BY_COUNT_IN_PHRASES_FIRST_POSITION,
    label: 'by count in Phrases first position',
  },
];
