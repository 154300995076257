import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  Token_Token_Join_Consensus_Annotations_Entities_State,
  Token_Token_Join_Options_Entities_State,
  Token_Token_Join_Predictions_Entities_State,
  Tokens_Entities_State,
} from 'modules/tokens/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

export type UseUpdateTokenEntitiesConfig = {
  Tokens?: boolean;
  Token_Join_Consensus_Annotations?: boolean;
  Token_Join_Predictions?: boolean;
  Token_Join_Options?: boolean;
};

export type UseUpdateTokenEntitiesParams = {
  config?: UseUpdateTokenEntitiesConfig;
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateTokenEntities(params: UseUpdateTokenEntitiesParams) {
  const { config = null, transform } = params;

  // Tokens
  const update_Tokens = useSetRecoilState(Tokens_Entities_State);
  // Token Join
  const update_Token_Join_Consensus_Annotations = useSetRecoilState(Token_Token_Join_Consensus_Annotations_Entities_State);
  const update_Token_Join_Predictions = useSetRecoilState(Token_Token_Join_Predictions_Entities_State);
  const update_Token_Join_Options = useSetRecoilState(Token_Token_Join_Options_Entities_State);

  // TODO: fix transform item (id not exists)
  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const);
    const updater = entitiesUpdaterFactory(entries);

    if (config?.Tokens ?? true) update_Tokens(updater);
    if (config?.Token_Join_Consensus_Annotations ?? true) update_Token_Join_Consensus_Annotations(updater);
    if (config?.Token_Join_Predictions ?? true) update_Token_Join_Predictions(updater);
    if (config?.Token_Join_Options ?? true) update_Token_Join_Options(updater);
  }, [
    config,
    transform,
    update_Tokens,
    update_Token_Join_Consensus_Annotations,
    update_Token_Join_Predictions,
    update_Token_Join_Options,
  ]);
}
