import { Edge_Direction, Methods, Status } from 'shared/constants';
import { CountField, DataItem, KeyField, LanguageItem, NameField } from 'types';

interface AnnotationItem {
  active_contact_method_annotation_status?: number | null;
  consensus_status?: number | null;
}

export const isItemConfirmed = (item: AnnotationItem, consensus: boolean) => {
  if (consensus && item.consensus_status) {
    return item.consensus_status === Status.confirmed;
  }

  if (item.active_contact_method_annotation_status) {
    return item.active_contact_method_annotation_status === Status.confirmed;
  }

  return false;
};

export const isItemDeclined = (item: AnnotationItem, consensus: boolean) => {
  if (consensus && item.consensus_status) {
    return item.consensus_status === Status.logically_depreciated;
  }

  if (item.active_contact_method_annotation_status) {
    return item.active_contact_method_annotation_status === Status.declined;
  }

  return false;
};

export const isMethodAssigned = (item: DataItem) => {
    return item.active_contact_method_annotation_status === Status.assigned;
};

export const isNumber = (candidate: number | string): boolean => {
  if (typeof candidate === 'number') {
    return Number.isFinite(candidate);
  }

  if (typeof candidate === 'string') {
    return candidate.trim() !== '' && Number.isFinite(Number(candidate));
  }

  return false;
};

export const keyId = (item: DataItem, keyFields?: KeyField | Array<KeyField>): string => {
  if (typeof keyFields === 'function') {
    return keyFields(item) as string;
  }

  if (Array.isArray(keyFields)) {
    return keyFields
      .map((keyField: KeyField) => {
        if (typeof keyField === 'function') {
          return keyField(item) as string;
        }

        return item[keyField as keyof DataItem] as string;
      })
      .filter((x: string) => x)
      .join('_');
  }

  return item.id;
};

export const keyName = (item: DataItem, nameField?: NameField): string => {
  if (typeof nameField === 'function') {
    return nameField(item);
  }

  if (typeof nameField === 'string') {
    return item[nameField as keyof DataItem]?.toString() || '';
  }

  return item?.name || '';
};

export const keyCount = (item: DataItem, countFields?: CountField | Array<CountField>): string | null => {
  if (!countFields) return null;

  // NOTE: "foo (bar) (baz)"
  if (!Array.isArray(countFields)) {
    countFields = [countFields];
  }

  return countFields
    .filter((countField: CountField) => {
      if (typeof countField === 'function') return true;
      if (typeof item[countField] === 'number') return true;

      return false;
    })
    .map((countField: CountField) => {
      let result = '';

      if (typeof countField === 'function') {
        result = countField(item)?.toLocaleString();
      }

      if (typeof item[countField as keyof DataItem] === 'number') {
        result = item[countField as keyof DataItem]?.toLocaleString() as string;
      }

      return result ? ` (${result})` : '';
    })
    .join('');
};

export const deleteProperty = <T extends object>(filter: T, property: string) => {
  if (Reflect.has(filter, property) && Object.keys(filter[property as keyof T]).length === 0) {
    Reflect.deleteProperty(filter, property);
  }
};

export const toLanguageString = (item: LanguageItem) => {
  if (item.id) {
    return `${item.iso_language_name} (${item.code})`;
  }

  return item.iso_language_name;
};

export const uncapitalize = (s: string): string => {
  const tmp = [...s];
  return [tmp[0].toLowerCase(), ...tmp.slice(1)].join('');
};

export const generateTypename = (method: Methods, direction: Edge_Direction): string => {
  const parts = [
    'Method',
    method,
    direction === Edge_Direction.DIRECT ? 'Target_Asset' : 'Base_Asset',
  ];

  return parts.join('_');
};

export const uid = () => {
  return String(
    Date.now().toString(32) +
      Math.random().toString(16)
  ).replace(/\./g, '');
};

export const noop = () => undefined;

export const debug = (label: string, args: unknown) => {
  if (window.APP_DEBUG) {
    console.log(label, JSON.stringify(args, null, 2));
  }
};
