import clsx from 'clsx';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';

import { useSeCreationStateUpdated } from 'hooks/useSeCreation';
import { useAuth } from 'modules/auth/hooks/useAuth';
import AssignToContactProvider from 'providers/AssignToContactProvider';

import Consensus from './components/Consensus';
import MainNav from './components/MainNav';
import UserMenu from './components/UserMenu';
import useMainLayout from './hooks/useMainLayout';
import { navItems } from './navItems';
import useStyles from './styles';

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const activeMainNavItems = navItems.filter(item => item.active);

export default function MainLayout({ children }: Props) {
  const classes = useStyles();

  const { logout } = useAuth();
  const { isSidebarOpen, openSidebar, toggleSidebar } = useMainLayout();

  useSeCreationStateUpdated();

  return (
      <AssignToContactProvider>
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" noWrap className={classes.logo}>
                BUILD & CODE
              </Typography>
              <div className={classes.toolbarSpacer} />
              <div className={classes.appbarContents}>
                <Consensus />
                <UserMenu />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={logout}
                >
                  Log Out
                </Button>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: isSidebarOpen,
              [classes.drawerClose]: !isSidebarOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: isSidebarOpen,
                [classes.drawerClose]: !isSidebarOpen,
              }),
            }}
            onMouseEnter={openSidebar}
          >
            <List dense disablePadding className={classes.menuButtonWrapper}>
              <ListItem>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleSidebar}
                  edge="start"
                  className={classes.menuButton}
                >
                  <MenuIcon fontSize="inherit" />
                </IconButton>
                <Typography variant="h6" noWrap className={classes.drawerLogo}>
                  BUILD & CODE
                </Typography>
              </ListItem>
            </List>
            <Divider className={classes.divider} />
            <MainNav items={activeMainNavItems} />
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </main>
        </div>
      </AssignToContactProvider>
  );
}
