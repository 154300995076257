
import { from, HttpLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const httpLink = new HttpLink({ uri: window.GRAPH_API_URL || '' });
const wsLink = new WebSocketLink(
  new SubscriptionClient(window.GRAPH_API_WS_URL || '', {
    reconnect: true,
  }),
);

export const graphApiLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  from([httpLink]),
);
