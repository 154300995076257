import memoize from 'memoize-one';

export const createItemData = memoize(
  (items, nameField, countField, se_creation, selectedItems, onContextMenu, onToggleItem, onChange) => ({
    items,
    nameField,
    countField,
    se_creation,
    selectedItems,
    onContextMenu,
    onToggleItem,
    onChange,
  }),
);
