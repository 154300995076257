import { useMemo } from 'react';

import { AssignToContactParams } from 'hooks/useAssignToContact';
import { MarkAsDoneParams } from 'hooks/useMarkAsDone';
import { MarkAsNotDoneParams } from 'hooks/useMarkAsNotDone';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useAssignToContactContext } from 'providers/AssignToContactProvider';
import { ContactItem } from 'types';

import { ActionVisibleParams, ContextAction, HandlerParams } from './types';

type Values = {
  assignToContact: (params: AssignToContactParams) => void;
  markAsDone?: (params: MarkAsDoneParams) => void;
  markAsNotDone?: (params: MarkAsNotDoneParams) => void;
};

export default function useContextActions1(values: Values): ContextAction[] {
  const { assignToContact, markAsDone, markAsNotDone } = values;

  const { user } = useAuth();
  const { openAssignToContactModal } = useAssignToContactContext();

  const mergedContextActions = useMemo(() => {
    return [
      {
        id: 'ASSIGN_TO_ME',
        title: 'Assign to me',
        meta: {
          actionVisible: () => true,
          actionAllowed: () => true,
        },
        handler: ({ selectedItems, contextItem }: HandlerParams) => {
          const contacts = [{ id: user?.contactId, name: user?.name}] as ContactItem[];
          const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
          assignToContact({ contacts, dataItems });
        },
      },
      {
        id: 'ASSIGN_TO_ANOTHER_CONTACT',
        title: 'Assign to another Contact',
        meta: {
          actionAllowed: () => true,
          actionVisible: () => true,
        },
        handler: ({ selectedItems, contextItem }: HandlerParams) => {
          const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
          openAssignToContactModal({ dataItems }, (params: AssignToContactParams) => {
            assignToContact(params);
          });
        },
      },
      {
        id: 'MARK_AS_DONE',
        title: 'Done',
        meta: {
          actionAllowed: () => true,
          actionVisible: ({ selectedItems, contextItem }: ActionVisibleParams) => Boolean(selectedItems.length > 1 || !contextItem?.active_contact_is_done_by),
        },
        handler: ({ selectedItems, contextItem }: HandlerParams) => {
          const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
          markAsDone?.({ dataItems });
        },
      },
      {
        id: 'MARK_AS_NOT_DONE',
        title: 'Not Done',
        meta: {
          actionAllowed: () => true,
          actionVisible: ({ selectedItems, contextItem }: ActionVisibleParams) => Boolean(selectedItems.length > 1 || contextItem?.active_contact_is_done_by),
        },
        handler: ({ selectedItems, contextItem }: HandlerParams) => {
          const dataItems = selectedItems.length > 1 ? selectedItems : [contextItem];
          markAsNotDone?.({ dataItems });
        },
      },
    ];
  }, [
    user,
    assignToContact,
    openAssignToContactModal,
    markAsDone,
    markAsNotDone,
  ]);

  return mergedContextActions;
}
