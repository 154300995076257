import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const Class_Associated_Tokens_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/classes/@atoms/Associated_Tokens/entities',
  default: ImmutableMap(),
});

export const Class_Associated_Tokens_Ids_State = atom<string[]>({
  key: 'graph/classes/@atoms/Associated_Tokenss/ids',
  default: [],
});
