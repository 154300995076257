import { Field, Form, Formik } from 'formik';

import InputLabel from '@material-ui/core/InputLabel';

import { renderAutoCompleteField, renderTextField } from 'components/withFormik';
import useLanguages from 'hooks/useLanguages';
import { toLanguageString } from 'shared/utils';
import { LanguageItem } from 'types';

import useStyles from './NameForm.styles';

type InitialValues = {
  name: string;
  language: LanguageItem | null;
};

type Props = {
  initialValues: InitialValues;
  formRef?: any;
  onSubmit: (values: any) => void;
};

export default function MorphNameForm({ initialValues, formRef, onSubmit }: Props) {
  const classes = useStyles();
  const { languages, getLanguages } = useLanguages();

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <InputLabel className={classes.label} htmlFor="name">
            Morph Name
          </InputLabel>
          <Field
            name="name"
            component={renderTextField}
            variant="outlined"
            placeholder="Start typing..."
            required
            fullWidth
          />
          <Field
            name="language"
            label="Language"
            component={renderAutoCompleteField}
            options={languages}
            getOptionLabel={toLanguageString}
            onSearchChange={getLanguages}
          />
        </Form>
      )}
    </Formik>
  );
}
