import { useCallback } from 'react';
import Highlighter from 'react-highlight-words';
import { useQuery } from '@apollo/client';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Modal from 'components/Modal';
import { PhrasesParamsType } from 'methods/token-join';
import { QUERY_TOKEN_JOIN_PHRASES_V2 } from 'methods/token-join/api';

type Props = {
  value: PhrasesParamsType;
  onClose: (resetOnClose?: boolean) => void;
};

type Phrase = {
  id: string;
  name: string;
};

export default function PhrasesModal({ value, onClose }: Props) {
  const { loading, data } = useQuery(QUERY_TOKEN_JOIN_PHRASES_V2, {
    variables: {
      where: {
        token_id: value.token?.id && value.token?.id === '0' ? undefined : value.token?.id,
        token_ids: value.token?.Token_Join_sequence,
        name: value.token?.name,
      }
    },
    skip: !value.token,
  });

  const handleClose = useCallback(() => onClose(true), [onClose]);

  return (
    <Modal title="Phrases" open onClose={handleClose}>
      {loading && <h1>Loading</h1>}
      {!loading && (
        <List>
          {(data?.Token_Join_Phrases_v2 || []).map((item: Phrase) => (
            <ListItem key={item.id}>
              <ListItemText
                primary={
                  <Highlighter
                    searchWords={[value.token?.name as string]}
                    autoEscape={true}
                    textToHighlight={item.name}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </Modal>
  );
}
