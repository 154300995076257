import { useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { QUERY_CONTACTS } from 'modules/contacts/api';
import { Contacts_selector } from 'modules/contacts/store';

export default function useContactsQuery() {
  const transform = useCallback((data: any) => data.Contacts, []);

  return useQuery(QUERY_CONTACTS, null, Contacts_selector, transform);
}
