import { gql } from '@apollo/client';

export type ItemSpecification_Filter_Order = {
  updatedAt?: 'ASC' | 'DESC';
}

export type ItemSpecification_has_Tags_Filter_Input = {
  din276CostGroups?: string[];
  groupsOfWorkItems?: string[];
  trades?: string[];
}

export type ItemSpecification_Source_Input = {
  includeSource?: number[];
  excludeSource?: number[];
}

export type ItemSpecification_Query_Input = {
  itemHasTags?: ItemSpecification_has_Tags_Filter_Input;
  source?: ItemSpecification_Source_Input;
  done?: boolean;
  assigned?: boolean;
  first?: number;
  after?: string;
  order?: [ItemSpecification_Filter_Order];
}

export const QUERY_ITEM_SPECIFICATIONS = gql`
  query itemSpecifications(
    $itemHasTags: ItemSpecificationHasTagsFilterInput,
    $source: ItemSpecificationSourceFilterInput,
    $assigned: Boolean,
    $done: Boolean,
    $first: Int,
    $after: String,
    $order: [ItemSpecificationFilter_order]
  ) {
    itemSpecifications(
      source: $source
      assigned: $assigned
      done: $done
      itemHasTags: $itemHasTags
      first: $first
      after: $after
      order: $order
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id: _id
          name
          active_contact_is_assigned_to: assigned
          active_contact_is_done_by: done
        }
      }
    }
  }
`;

export const QUERY_ITEM_SPECIFICATION = gql`
  query Item_Specification($id: String) {
    itemSpecifications(where: { id: $id }) {
      id
      name
      description
    }
  }
`;
