import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Morphemes_selector } from 'modules/morphemes/store';

export default function useResetMorphemes() {
  const reset = useResetRecoilState(Morphemes_selector);

  return useCallback(() => {
    reset();
  }, [reset]);
}
