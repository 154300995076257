import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export * from "../sections/AssociatedNextTokensDataSection/atoms"
export * from "../sections/NextSemanticEntitiesDataSection/atoms"
export * from "../sections/NextTagsDataSection/atoms"
export * from "../sections/PhrasesDataSection/atoms"
export * from "../sections/TagsDataSection/atoms"

export const SemanticEntities_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/semantic-entities/@atoms/entities',
  default: ImmutableMap(),
});

export const SemanticEntities_Ids_State = atom<string[]>({
  key: 'graph/semantic-entities/@atoms/ids',
  default: [],
});
