import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_CLUSTER_CLASSES_CONSENSUS_ANNOTATIONS,
  QUERY_CLUSTER_CLASSES_OPTIONS,
} from 'methods/class-has-cluster/api';
import {
  Cluster_Classes_Consensus_Annotations_selector,
  Cluster_Classes_Options_selector,
} from 'modules/clusters/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.clusters?.[0]?.Classes_Consensus_Annotations || [], []);

  return useNestedQuery(
    QUERY_CLUSTER_CLASSES_CONSENSUS_ANNOTATIONS,
    variables,
    Cluster_Classes_Consensus_Annotations_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.clusters?.[0]?.Classes_Options || [], []);

  return useNestedQuery(
    QUERY_CLUSTER_CLASSES_OPTIONS,
    variables,
    Cluster_Classes_Options_selector,
    transform,
  );
}
