import { Field, Form, Formik } from 'formik';

import {
  RadioGroupOption,
  renderAutoCompleteField,
  renderCheckboxField,
  renderRadioGroupField,
} from 'components/withFormik';
import { useApplicationApiData, useApplicationApiLazyData } from "hooks/useData";
import {
  TAGS_BY_CLASS_DIN_276_COST_GROUPS,
  TAGS_BY_CLASS_GROUPS_OF_SIMILAR_WORK_ITEMS,
  TAGS_BY_CLASS_TRADES
} from 'modules/tags/appApi';
import { Source } from 'shared/constants';
import { DataItem } from 'types';

export type FormValues = {
  trades?: DataItem[];
  groupsOfWorkItems?: DataItem[];
  din276CostGroups?: DataItem[];
  source: string[];
  assigned: AssignedValue;
  done: DoneValue;
};

export enum AssignedValue {
  ALL = 0,
  ASSIGNED = 1,
  NOT_ASSIGNED = 2,
}

export enum DoneValue {
  ALL = 0,
  DONE = 1,
  UNDONE = 2,
}

const sourceOptions = [
  {
    label: 'STLB-Bau Base',
    value: `${Source.STLB_Bau_Base}`,
  },
  {
    label: 'STLB-Bau Not Base',
    value: `${Source.STLB_Bau_Not_Base}`,
  },
  {
    label: 'Non-STLB-Item',
    value: `${Source.Specifications}`,
  },
];

const assignedOptions: RadioGroupOption[] = [
  {
    label: 'assigned to me',
    value: `${AssignedValue.ASSIGNED}`,
  },
  {
    label: 'not assigned to me',
    value: `${AssignedValue.NOT_ASSIGNED}`,
  },
  {
    label: 'both',
    value: `${AssignedValue.ALL}`,
  },
];

const doneOptions = [
  {
    label: 'done',
    value: DoneValue.DONE,
  },
  {
    label: 'not done',
    value: DoneValue.UNDONE,
  },
  {
    label: 'both',
    value: DoneValue.ALL,
  },
];

type Props = {
  initialValues: FormValues;
  formRef?: any;
  onSubmit: (values: FormValues) => void;
};

export default function ItemSpecificationsFilterForm({ initialValues, formRef, onSubmit }: Props) {
  const {
    data: trades,
    loading: isTradesLoading,
    getData: getTrades,
  } = useApplicationApiData(TAGS_BY_CLASS_TRADES, 'graphApiTags')

  const {
    data: groupsOfSimilarWorkItems,
    loading: isGroupsOfSimilarWorkItemsLoading,
    getData: getGroupsOfSimilarWorkItems,
  } = useApplicationApiLazyData(TAGS_BY_CLASS_GROUPS_OF_SIMILAR_WORK_ITEMS, 'graphApiTags');

  const {
    data: din276CostGroups,
    loading: isDin276CostGroupsLoading,
    getData: getDin276CostGroups,
  } = useApplicationApiData(TAGS_BY_CLASS_DIN_276_COST_GROUPS, 'graphApiTags');

  return (
    <Formik innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Field
            name="trades"
            label="All trades"
            component={renderAutoCompleteField}
            multiple
            minLength={2}
            options={trades}
            loading={isTradesLoading}
            onSearchChange={getTrades}
          />
          <Field
            name="groupsOfWorkItems"
            label="All Groups of work items"
            component={renderAutoCompleteField}
            multiple
            minLength={2}
            options={groupsOfSimilarWorkItems}
            loading={isGroupsOfSimilarWorkItemsLoading}
            onSearchChange={getGroupsOfSimilarWorkItems}
          />
          <Field
            name="din276CostGroups"
            label="All DIN 276 cost groups"
            component={renderAutoCompleteField}
            multiple
            minLength={2}
            options={din276CostGroups}
            loading={isDin276CostGroupsLoading}
            onSearchChange={getDin276CostGroups}
          />
          <div style={{ padding: 8 }}>
            {sourceOptions.map(sourceOption => (
              <Field
                key={sourceOption.value}
                name="source"
                component={renderCheckboxField}
                label={sourceOption.label}
                checkboxProps={{
                  color: 'primary',
                  value: sourceOption.value.toString(),
                }}
              />
            ))}
          </div>
          <div style={{ padding: 8 }}>
            <Field
              name="assigned"
              component={renderRadioGroupField}
              options={assignedOptions}
              orientation="horizontal"
              radioProps={{
                color: 'primary',
              }}
            />
          </div>
          <div style={{ padding: 8 }}>
            <Field
              name="done"
              component={renderRadioGroupField}
              options={doneOptions}
              orientation="horizontal"
              radioProps={{
                color: 'primary',
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
