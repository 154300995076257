import { SyntheticEvent } from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';

import DoubleClick, { DoubleClickRenderProps } from 'components/common/DoubleClick';
import ConfirmedIcon from 'components/icons/ConfirmedIcon';
import DeclinedIcon from 'components/icons/DeclinedIcon';
import { isItemConfirmed, isItemDeclined, isMethodAssigned, keyCount, keyId, keyName } from 'shared/utils';

import useStyles from './styles';

export default function DataListItem({ index, style, data }: any) {
  const classes = useStyles();

  const {
    canConfirm,
    canDecline,
    consensus,
    countField,
    horizontal,
    items,
    keyField,
    nameField,
    renderContent,
    readonly,
    se_creation,
    orangeLabel,
    yellowLabel,
    greenLabel,
    selectedItems,
    handleClick,
    handleContext,
    handleConfirm,
    handleDecline,
    handleDoubleClick,
  } = data;

  const item = items[index];
  const name = keyName(item, nameField);
  const id = keyId(item, keyField);

  return (
    <ListItem
      key={id}
      className={clsx(classes.listItem, {
        [classes.horizontalItem]: horizontal,
      })}
      style={style}
      disableGutters
    >
      {
        renderContent
        ? renderContent(item, index)
        : (
          <DoubleClick<HTMLAnchorElement>
            onClick={(e: SyntheticEvent) => handleClick(e, item)}
            onDoubleClick={(e: SyntheticEvent) => handleDoubleClick(e, item)}
          >
            {({ ref }: DoubleClickRenderProps<HTMLAnchorElement>) => (
              <Button
                ref={ref}
                component="a"
                onContextMenu={(e: SyntheticEvent) => handleContext(e, item)}
                classes={{
                  root: clsx(classes.itemButton, {
                    [classes.itemButtonSeCreation]: se_creation,
                    [classes.itemButtonActive]: selectedItems && selectedItems.has(id),
                    [classes.itemButtonWithActions]: canConfirm || canDecline,
                    [classes.itemButtonHorizontal]: horizontal,
                  }),
                  label: classes.itemButtonText,
                }}
                variant="outlined"
                title={name}
              >
                {name}
                {keyCount(item, countField)}
                <span className={classes.tagSection}>
                  {orangeLabel && isMethodAssigned(item) && <span className={clsx(classes.tag, classes.orangeTag)}></span>}
                  {yellowLabel && item?.active_contact_is_assigned_to && <span className={clsx(classes.tag, classes.yellowTag)}></span>}
                  {greenLabel && item?.active_contact_is_done_by && <span className={clsx(classes.tag, classes.greenTag)}></span>}
                </span>
              </Button>
            )}
          </DoubleClick>
        )
      }
      {canConfirm &&
        (isItemConfirmed(item, consensus) ? (
          <IconButton size="small" disabled>
            <ConfirmedIcon active />
          </IconButton>
        ) : (
          <IconButton size="small" disabled={readonly || se_creation} onClick={() => handleConfirm(item)}>
            <ConfirmedIcon />
          </IconButton>
        ))}
      {canDecline &&
        (isItemDeclined(item, consensus) ? (
          <IconButton size="small" disabled>
            <DeclinedIcon active />
          </IconButton>
        ) : (
          <IconButton size="small" disabled={readonly || se_creation} onClick={() => handleDecline(item)}>
            <DeclinedIcon />
          </IconButton>
        ))}
    </ListItem>
  );
}
