import { Field, Form, Formik } from 'formik';

import { renderAutoCompleteField, renderCheckboxField, renderRangeInputField } from 'components/withFormik';
import { useLazyData } from 'hooks/useData';
import useLanguages from 'hooks/useLanguages';
import { SEARCH_MORPHS } from 'modules/morphs/api';
import { toLanguageString } from 'shared/utils';
import { DataItem, LanguageItem } from 'types';

type Values = {
  morph?: DataItem | null;
  language?: LanguageItem | null;
  countCharacters?: {
    min?: number;
    max?: number;
  };
  onlyAssigned?: boolean;
  onlyDone?: boolean;
};

type Props = {
  initialValues: Values;
  formRef?: any;
  onSubmit: (values: Values) => void;
};

export default function FilterForm({ initialValues, formRef, onSubmit }: Props) {
  const {
    data: morphs,
    loading: isMorphsLoading,
    getData: getMorphs,
  } = useLazyData(SEARCH_MORPHS, 'Morphs');
  const { languages, getLanguages } = useLanguages();

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Field
            name="morph"
            label="Morph Name"
            component={renderAutoCompleteField}
            freeSolo
            options={morphs}
            loading={isMorphsLoading}
            onSearchChange={getMorphs}
          />
          <Field
            name="language"
            label="Language"
            component={renderAutoCompleteField}
            getOptionLabel={toLanguageString}
            options={languages}
            onSearchChange={getLanguages}
          />
          <Field name="countCharacters" label="Count of Characters" component={renderRangeInputField} />
          <div>
            <Field name="onlyAssigned" component={renderCheckboxField} label="only assigned" color="primary" />
          </div>
          <div>
            <Field name="onlyDone" component={renderCheckboxField} label="only done" color="primary" />
          </div>
        </Form>
      )}
    </Formik>
  );
}
