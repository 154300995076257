import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 64px - 56px - 32px)',
  },
  listItem: {
    padding: theme.spacing(0.5, 2),
  },
  button: {
    width: '100%',
    justifyContent: 'flex-start',
    textTransform: 'none',
    background: '#F7F9FC',
    padding: theme.spacing(0.25, 1),
    borderColor: '#E0E0E0',
    position: 'relative',
  },
  buttonActive: {
    color: 'white',
    background: '#1565C0',
    borderColor: '#1565C0',
    '&:hover': {
      backgroundColor: '#1565C0',
    },
  },
  buttonSeCreation: {
    color: 'red',
  },
  buttonLabel: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tagSection: {
    color: '#FFFFFF',
    position: 'absolute',
    top: -1,
    right: 5,
    fontSize: 9,
    display: 'flex',
  },
  tag: {
    height: 20,
    borderRadius: '0px 0px 2px 2px',
    minWidth: 10,
    padding: '6px 3px',
    marginLeft: 5,
    lineHeight: 1,
  },
  yellowTag: {
    background: '#FFD704',
  },
  greenTag: {
    background: '#98D4A5',
  },
  orangeTag: {
    background: '#FFAB48',
  },
}));

export default useStyles;
