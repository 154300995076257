import DataGroup from 'components/DataGroup';
import DataSection from 'components/DataSection';
import useConsensus from 'hooks/useConsensus';
import FilterModal from 'modules/clusters/components/FilterModal';
import SortModal from 'modules/clusters/components/SortModal';
import { INITIAL_FILTER_PARAMS, INITIAL_SORT_PARAMS, SORT_OPTIONS } from 'modules/clusters/constants';
import { transformVariables } from 'modules/clusters/utils';
import { DataItem } from 'types';

import useAnnotations from './useAnnotations';
import useContextActions from './useContextActions';
import { useConsensusAnnotationsQuery, useOptionsQuery } from './useQueries';

const menuAcl = {
  Clusters_Options: {
    ASSIGN_METHOD_TO_ME: false,
    ASSIGN_METHOD_TO_ANOTHER_CONTACT: false,
  },
};

type Props = {
  parentItem: DataItem;
};

export default function ClustersDataSection({ parentItem }: Props) {
  const { consensus } = useConsensus();
  const { confirmAnnotation, declineAnnotation } = useAnnotations({ parentItem })
  const contextActions = useContextActions({ parentItem });

  return (
    <DataGroup
      title="Clusters"
      structure={{
        menuId: 'Class_Clusters',
        menuAcl,
      }}
      contextActions={contextActions}
      consensusAnnotations={
        <DataSection
          parentItem={parentItem}
          useQuery={useConsensusAnnotationsQuery}
          structure={{
            id: 'Clusters_Consensus_Annotations',
            title: 'Consensus & Annotations',
            countField: 'count_inverse__Class_has_Cluster',
            readonly: consensus,
          }}
          consensus={consensus}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
      options={
        <DataSection
          parentItem={parentItem}
          useQuery={useOptionsQuery}
          structure={{
            id: 'Clusters_Options',
            title: 'Options',
            countField: 'count_inverse__Class_has_Cluster',
            orangeLabel: false,
          }}
          transformVariables={transformVariables}
          filterComponent={FilterModal}
          filterParams={INITIAL_FILTER_PARAMS}
          sortComponent={SortModal}
          sortParams={INITIAL_SORT_PARAMS}
          sortOptions={SORT_OPTIONS}
          onConfirm={confirmAnnotation}
          onDecline={declineAnnotation}
        />
      }
    />
  );
}
