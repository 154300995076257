import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const Contacts_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/contacts/@atoms/entities',
  default: ImmutableMap(),
});

export const Contacts_Ids_State = atom<string[]>({
  key: 'graph/contacts/@atoms/ids',
  default: [],
});
