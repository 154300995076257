import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_MORPHEME_MORPHS_CONSENSUS_ANNOTATIONS,
  QUERY_MORPHEME_MORPHS_OPTIONS,
} from 'methods/morph-has-morpheme/api';
import {
  Morpheme_Morphs_Consensus_Annotations_selector,
  Morpheme_Morphs_Options_selector
} from 'modules/morphemes/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.morphemes?.[0]?.Morphs_Consensus_Annotations || [], []);

  return useNestedQuery(
    QUERY_MORPHEME_MORPHS_CONSENSUS_ANNOTATIONS,
    variables,
    Morpheme_Morphs_Consensus_Annotations_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.morphemes?.[0]?.Morphs_Options || [], []);

  return useNestedQuery(
    QUERY_MORPHEME_MORPHS_OPTIONS,
    variables,
    Morpheme_Morphs_Options_selector,
    transform,
  );
}
