import { useCallback } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { SetterOrUpdater } from 'recoil';

import useConsensus from 'hooks/useConsensus';
import { Status } from 'shared/constants';
import {
  entitiesUpdaterFactory,
  extractId as defaultExtractId,
  idsRemoverFactory,
  idsUpdaterFactory,
} from 'shared/store';
import { DataItem } from 'types';

const parseSettersOrUpdaters = <
  T1 = SetterOrUpdater<ImmutableMap<string, DataItem>>,
  T2 = SetterOrUpdater<string[]>
>(settersOrUpdaters: T1 | Readonly<[T1, T2]> | null) => {
  if (Array.isArray(settersOrUpdaters)) {
    return settersOrUpdaters;
  }

  if (settersOrUpdaters) {
    return [settersOrUpdaters as T1, null];
  }

  return [null, null];
};

type useAnnotationOptionsParams<
  T1 = SetterOrUpdater<ImmutableMap<string, DataItem>>,
  T2 = SetterOrUpdater<string[]>
> = {
  extractId?: ((item: DataItem) => string);
  settersOrUpdaters: Readonly<[
    T1 | Readonly<[T1, T2]> | null,
    T1 | Readonly<[T1, T2]> | null,
    T1 | Readonly<[T1, T2]> | null
  ]>;
  status: Status;
};

export default function useAnnotationOptions(params: useAnnotationOptionsParams) {
  const {
    extractId = defaultExtractId,
    settersOrUpdaters: [
      consensusAnnotationsSetterOrUpdater,
      predictionsSetterOrUpdater,
      optionsSetterOrUpdater,
    ],
    status,
  } = params;

  const [updateConsensusAnnotationsEntities, updateConsensusAnnotationsIds] = parseSettersOrUpdaters(consensusAnnotationsSetterOrUpdater);
  const [updatePredictionsEntities, updatePredictionsIds] = parseSettersOrUpdaters(predictionsSetterOrUpdater);
  const [, updateOptionsIds] = parseSettersOrUpdaters(optionsSetterOrUpdater);

  const { consensus } = useConsensus();

  const onCompleted = useCallback((_result: any, item: DataItem) => {
    const id = extractId(item);
    const entries = [[id, { active_contact_method_annotation_status: status } as DataItem, item] as const];

    const idsUpdater = idsUpdaterFactory(id);
    const idsRemover = idsRemoverFactory(id);
    const entitiesInserterOrUpdater = entitiesUpdaterFactory(entries, true);
    const entitiesUpdater = entitiesUpdaterFactory(entries);

    if (!consensus) {
      updateConsensusAnnotationsEntities?.(entitiesInserterOrUpdater);
      updateConsensusAnnotationsIds?.(idsUpdater);
    }

    updatePredictionsEntities?.(entitiesUpdater);
    updatePredictionsIds?.(idsRemover);
    updateOptionsIds?.(idsRemover);
  }, [
    extractId,
    consensus,
    status,
    updateConsensusAnnotationsEntities,
    updateConsensusAnnotationsIds,
    updatePredictionsEntities,
    updatePredictionsIds,
    updateOptionsIds,
  ]);

  return {
    onCompleted,
  };
}
