import { useCallback } from 'react';
import produce from 'immer'
import { useRecoilState } from 'recoil';

import { Main_Layout_State } from 'layouts/MainLayout/store';

type UseMainLayoutResult = {
  isSidebarOpen: boolean;
  consensus: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
  toggleConsensus: () => void;
}

export default function useMainLayout(): UseMainLayoutResult {
  const [state, setState] = useRecoilState(Main_Layout_State);

  const openSidebar = useCallback(() => {
    setState(baseState => produce(baseState, draftState => {
      draftState.isSidebarOpen = true;
    }))
  }, [setState]);

  const closeSidebar = useCallback(() => {
    setState(baseState => produce(baseState, draftState => {
      draftState.isSidebarOpen = false;
    }))
  }, [setState]);

  const toggleSidebar = useCallback(() => {
    setState(baseState => produce(baseState, draftState => {
      draftState.isSidebarOpen = !baseState.isSidebarOpen;
    }))
  }, [setState]);

  const toggleConsensus = useCallback(() => {
    setState(baseState => produce(baseState, draftState => {
      draftState.consensus = !baseState.consensus;
    }))
  }, [setState]);

  return {
    isSidebarOpen: state.isSidebarOpen,
    consensus: state.consensus,
    openSidebar,
    closeSidebar,
    toggleSidebar,
    toggleConsensus,
  };
}
