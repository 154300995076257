import { PropsWithChildren, useContext, useState } from 'react';

import { DataItem } from 'types';

import ContextActionsContext, { ContextActionsContextProps } from './ContextActionsContext';

type Props = PropsWithChildren<{}>;

export function useContextActionsContext(): ContextActionsContextProps {
  return useContext(ContextActionsContext);
}

const ContextActionsProvider = ({ children }: Props) => {
  const [contextItem, setContextItem] = useState<DataItem | null>(null);
  const [listId, setListId] = useState<string | null>(null);

  const ctx = {
    contextItem,
    listId,
    setContextItem,
    setListId,
  };

  return <ContextActionsContext.Provider value={ctx}>{children}</ContextActionsContext.Provider>;
};

export default ContextActionsProvider;
