import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  Class_Children_Classes_Consensus_Annotations_Entities_State,
  Class_Children_Classes_Options_Entities_State,
  Class_Children_Classes_Predictions_Entities_State,
  Class_Conversion_Children_Classes_Consensus_Annotations_Entities_State,
  Class_Conversion_Children_Classes_Options_Entities_State,
  Class_Conversion_Parents_Classes_Consensus_Annotations_Entities_State,
  Class_Conversion_Parents_Classes_Options_Entities_State,
  Class_Parents_Classes_Consensus_Annotations_Entities_State,
  Class_Parents_Classes_Options_Entities_State,
  Class_Parents_Classes_Predictions_Entities_State,
  Classes_Entities_State,
} from 'modules/classes/store';
import { entitiesUpdaterFactory } from 'shared/store';
import { DataItem } from 'types';

type Params = {
  transform: (item: DataItem) => DataItem;
};

export default function useUpdateClassEntities(params: Params) {
  const { transform } = params;

  const update_Classes = useSetRecoilState(Classes_Entities_State);
  // Children Classes
  const update_Children_Classes_Consensus_Annotations = useSetRecoilState(Class_Children_Classes_Consensus_Annotations_Entities_State);
  const update_Children_Classes_Predictions = useSetRecoilState(Class_Children_Classes_Predictions_Entities_State);
  const update_Children_Classes_Options = useSetRecoilState(Class_Children_Classes_Options_Entities_State);
  // Parent Classes
  const update_Parents_Classes_Consensus_Annotations = useSetRecoilState(Class_Parents_Classes_Consensus_Annotations_Entities_State);
  const update_Parents_Classes_Predictions = useSetRecoilState(Class_Parents_Classes_Predictions_Entities_State);
  const update_Parents_Classes_Options = useSetRecoilState(Class_Parents_Classes_Options_Entities_State);
  // Conversion Children Classes
  const update_Conversion_Children_Classes_Consensus_Annotations = useSetRecoilState(Class_Conversion_Children_Classes_Consensus_Annotations_Entities_State);
  const update_Conversion_Children_Classes_Options = useSetRecoilState(Class_Conversion_Children_Classes_Options_Entities_State);
  // Conversion Children Classes
  const update_Conversion_Parents_Classes_Consensus_Annotations = useSetRecoilState(Class_Conversion_Parents_Classes_Consensus_Annotations_Entities_State);
  const update_Conversion_Parents_Classes_Options = useSetRecoilState(Class_Conversion_Parents_Classes_Options_Entities_State);

  return useCallback((_result: unknown, items: DataItem[]) => {
    const entries = items.map(item => [item.id, transform(item)] as const);
    const updater = entitiesUpdaterFactory(entries);

    update_Classes(updater);
    update_Children_Classes_Consensus_Annotations(updater);
    update_Children_Classes_Predictions(updater);
    update_Children_Classes_Options(updater);
    update_Parents_Classes_Consensus_Annotations(updater);
    update_Parents_Classes_Predictions(updater);
    update_Parents_Classes_Options(updater);
    update_Conversion_Children_Classes_Consensus_Annotations(updater);
    update_Conversion_Children_Classes_Options(updater);
    update_Conversion_Parents_Classes_Consensus_Annotations(updater);
    update_Conversion_Parents_Classes_Options(updater);
  }, [
    transform,
    update_Classes,
    update_Children_Classes_Consensus_Annotations,
    update_Children_Classes_Predictions,
    update_Children_Classes_Options,
    update_Parents_Classes_Consensus_Annotations,
    update_Parents_Classes_Predictions,
    update_Parents_Classes_Options,
    update_Conversion_Children_Classes_Consensus_Annotations,
    update_Conversion_Children_Classes_Options,
    update_Conversion_Parents_Classes_Consensus_Annotations,
    update_Conversion_Parents_Classes_Options,
  ]);
}
