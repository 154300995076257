import { memo } from 'react';

import { darkTheme, lightTheme } from 'components/icons/constants';

type Props = {
  light?: boolean;
};

function HandleIcon({ light }: Props) {
  const theme = light ? lightTheme : darkTheme;
  const fill = theme.fill.defaults;

  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13.2689" cy="1.73077" r="1.73077" fill={fill} />
      <circle cx="13.2689" cy="7.50006" r="1.73077" fill={fill} />
      <circle cx="7.5003" cy="7.50006" r="1.73077" fill={fill} />
      <circle cx="13.2689" cy="13.2693" r="1.73077" fill={fill} />
      <circle cx="7.5003" cy="13.2693" r="1.73077" fill={fill} />
      <circle cx="1.73077" cy="13.2693" r="1.73077" fill={fill} />
    </svg>
  );
}

export default memo(HandleIcon);
