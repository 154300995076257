import { useState } from 'react';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';

import Modal from 'components/Modal';
import OutlinedWrapper from 'components/OutlinedWrapper';
import { ClassSortOption } from 'modules/classes/api';

import { SortParamsType } from '../../constants';

type Props = {
  onClose: () => void;
  value: SortParamsType;
  onChange: (value: SortParamsType) => void;
  options: { value: string; label: string }[];
};

const RadioControl = <Radio color="primary" />;

export default function ClassesSortModal({ value, onClose, onChange, options }: Props) {
  const [direction, setDirection] = useState(value?.direction);
  const [option, setOption] = useState(value?.option);
  const handleChange = () => {
    onChange({ direction, option });
  };

  const onLocalClose = () => {
    onClose();
    setDirection(value?.direction);
    setOption(value?.option);
  };

  return (
    <Modal
      title="Sort"
      open
      actionsContent={
        <Button variant="contained" color="primary" onClick={handleChange}>
          Sort
        </Button>
      }
      onClose={onLocalClose}
    >
      <FormControl variant="outlined" fullWidth>
        <Select value={direction} onChange={(event) => setDirection(event.target.value as 'DESC' | 'ASC')}>
          <MenuItem value="DESC">Descending</MenuItem>
          <MenuItem value="ASC">Ascending</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <OutlinedWrapper>
          <RadioGroup value={option} onChange={(event) => setOption(event.target.value as ClassSortOption)}>
            {options.map(({ value, label }) => (
              <FormControlLabel key={value} control={RadioControl} value={value} label={label} />
            ))}
          </RadioGroup>
        </OutlinedWrapper>
      </FormControl>
    </Modal>
  );
}
