import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { Tags_selector } from 'modules/tags/store';

export default function useResetTags() {
  const reset = useResetRecoilState(Tags_selector);

  return useCallback(() => {
    reset();
  }, [reset]);
}
