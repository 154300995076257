import { makeStyles } from '@material-ui/core/styles';

type Props = {
  color?: string;
};

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 280,
    maxHeight: 300,
    overflowY: 'auto',
  },
  heading: (props: Props) => ({
    borderBottom: `2px solid ${props.color || 'rgb(255, 196, 0)'}`,
    color: props.color || 'rgb(255, 196, 0)',
    marginBottom: theme.spacing(1,5),
    fontSize: '0.875rem',
  }),
  deleteButton: {
    width: '100%',
    justifyContent: 'flex-start',
    margin: theme.spacing(1, 0),
    color: theme.palette.secondary.main,
  },
  autocomplete: {
    color: '#e0e0e0'
  },
  checkbox: {
    minWidth: '42px',
  },
}));

export default useStyles;
