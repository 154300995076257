import config from 'configuration';
import { DataItem, LanguageItem } from 'types';

export type TokenHasMorphParamsType = {
  token?: DataItem | null;
  targetAssets: DataItem[];
  language: LanguageItem | null;
  action?: 'confirm' | 'decline';
};

export const INITIAL_TOKEN_HAS_MOPRH_PARAMS: TokenHasMorphParamsType = {
  token: null,
  targetAssets: [],
  language: config.defaultLanguage,
};
