import { useMemo } from 'react';

import useAssignToContact, { assignToContactTransform } from 'hooks/useAssignToContact';
import { ActionVisibleParams, HandlerParams, useContextActions1 } from 'hooks/useContextActions';
import useMarkAsDone, { markAsDoneTransform } from 'hooks/useMarkAsDone';
import useMarkAsNotDone, { markAsNotDoneTransform } from 'hooks/useMarkAsNotDone';
import { ASSIGN_MORPH_TO_CONTACT, MARK_MORPH_AS_DONE, MARK_MORPH_AS_NOT_DONE } from 'modules/morphs/api';
import useUpdateMorphEntities from 'modules/morphs/hooks/useUpdateMorphEntities';
import { DataItem } from 'types';


type UseMorphContextActionsValues = {
  editMorph: (item: DataItem) => void;
};

export default function useMorphContextActions({ editMorph }: UseMorphContextActionsValues) {
  const onAssignToContactCompleted = useUpdateMorphEntities({ transform: assignToContactTransform });
  const assignToContact = useAssignToContact(ASSIGN_MORPH_TO_CONTACT, { onCompleted: onAssignToContactCompleted });

  const onMarkAsDoneCompleted = useUpdateMorphEntities({ transform: markAsDoneTransform });
  const markAsDone = useMarkAsDone(MARK_MORPH_AS_DONE, { onCompleted: onMarkAsDoneCompleted });

  const onMarkAsNotDoneCompleted = useUpdateMorphEntities({ transform: markAsNotDoneTransform });
  const markAsNotDone = useMarkAsNotDone(MARK_MORPH_AS_NOT_DONE, { onCompleted: onMarkAsNotDoneCompleted });

  const contextActions = useContextActions1({ assignToContact, markAsDone, markAsNotDone })

  return useMemo(
    () => {
      return [
        ...contextActions,
        {
          id: 'EDIT',
          title: 'Edit',
          meta: {
            actionAllowed: () => true,
            actionVisible: ({ selectedItems }: ActionVisibleParams) => selectedItems.length < 2,
          },
          handler: ({ contextItem }: HandlerParams) => editMorph(contextItem),
        },
      ]
    }, [contextActions, editMorph]
  )
}
