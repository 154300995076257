import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import { Contacts_Entities_State, Contacts_Ids_State } from './atoms';

export const Contacts_selector = listSelector<DataItem>(
  'graph/contacts/@selectors/list',
  Contacts_Entities_State,
  Contacts_Ids_State,
);
