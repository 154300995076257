import config, { TransformVariablesConfig } from 'configuration';
import { PhraseFilterOptionsInput, PhraseFilterWhereInput } from 'modules/phrases/api';
import { deleteProperty } from 'shared/utils';
import { PaginationParams } from 'types';

import { FilterParamsType, SortParamsType } from './constants';

export function transformVariables<T extends object>(
  filterParams: FilterParamsType,
  sortParams: SortParamsType,
  paginationParams: PaginationParams,
  transformVariablesConfig?: TransformVariablesConfig,
): T {
  const { nameProp = 'name_STARTS_WITH' } = {
    ...config.transformVariablesConfig,
    ...transformVariablesConfig,
  };

  // where
  const where: PhraseFilterWhereInput = {};

  if (filterParams) {
    if (filterParams.phrase) {
      if (typeof filterParams.phrase === 'string') {
        where[nameProp as Extract<typeof nameProp, keyof PhraseFilterWhereInput>] = filterParams.phrase;
      } else if (filterParams.phrase?.name) {
        where[nameProp as Extract<typeof nameProp, keyof PhraseFilterWhereInput>] = filterParams.phrase?.name;
      }
    }

    if (filterParams.countLiteralsMin || filterParams.countLiteralsMax) {
      where.count_literals_RANGE = {
        min: filterParams.countLiteralsMin,
        max: filterParams.countLiteralsMax,
      };
    }

    if (filterParams.countTokensMin || filterParams.countTokensMax) {
      where.count_tokens_RANGE = {
        min: filterParams.countTokensMin,
        max: filterParams.countTokensMax,
      };
    }

    if (filterParams.onlyAssigned) {
      where.only_assigned = true;
    }

    if (filterParams.onlyDone) {
      where.only_done = true;
    }
  }

  // options
  const options: PhraseFilterOptionsInput = {
    limit: config.dataPage.limit,
    // sort: {
    //   option: sortParams.option as PhraseSortOption,
    //   direction: sortParams.direction,
    // },
  };

  // filter
  const filter = { where, options } as T;
  deleteProperty<T>(filter, 'where');
  deleteProperty<T>(filter, 'options');

  return filter;
}
