import { useCallback } from 'react';

import { useNestedQuery } from 'hooks/useQuery';
import {
  QUERY_TOKEN_TAGS_CONSENSUS_ANNOTATIONS_V2,
  QUERY_TOKEN_TAGS_OPTIONS_V2,
  QUERY_TOKEN_TAGS_PREDICTIONS_V2,
} from 'methods/token-has-meaning/api';
import {
  Token_Tags_Consensus_Annotations_selector,
  Token_Tags_Options_selector,
  Token_Tags_Predictions_selector,
} from 'modules/tokens/store';

export const useConsensusAnnotationsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Token_has_Meaning_Tags_Consensus_Annotations_v2 || [], []);

  return useNestedQuery(
    QUERY_TOKEN_TAGS_CONSENSUS_ANNOTATIONS_V2,
    variables,
    Token_Tags_Consensus_Annotations_selector,
    transform,
  );
}

export const usePredictionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Token_has_Meaning_Tags_Tags_Predictions_v2 || [], []);

  return useNestedQuery(
    QUERY_TOKEN_TAGS_PREDICTIONS_V2,
    variables,
    Token_Tags_Predictions_selector,
    transform,
  );
}

export const useOptionsQuery = (variables: any) => {
  const transform = useCallback((data: any) => data.Token_has_Meaning_Tags_Tags_Options_v2 || [], []);

  return useNestedQuery(
    QUERY_TOKEN_TAGS_OPTIONS_V2,
    variables,
    Token_Tags_Options_selector,
    transform,
  );
}
