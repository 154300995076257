import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    gap: 30,
  },
  paper: {
    zIndex: 1,
    width: 240,
    position: 'absolute',
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  content: {
    marginLeft: 260,
    paddingTop: 24,
    paddingRight: 24,
    flexGrow: 1,
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
  },
  handle: {
    position: 'absolute',
    right: 0,
    width: 10,
    top: 56,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#E0E0E0',
    cursor: 'ew-resize',
  },
}));

export default useStyles;
