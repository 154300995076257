import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CreateIcon from '@material-ui/icons/Create';
import ListIcon from '@material-ui/icons/List';

import SchemaIcon from "components/icons/SchemaIcon";
import { AnnotationMode } from "modules/item-specifications/constants";

type Props = {
  mode: AnnotationMode;
  onSelect: (newMode: AnnotationMode) => void;
};

const data = [
  {
    id: AnnotationMode.Method_Phrase_Tag,
    icon: <CreateIcon />,
  },
  {
    id: AnnotationMode.Method_Phrase_SE,
    icon: <SchemaIcon currentColor />,
  },
  {
    id: AnnotationMode.Method_Specification,
    icon: <ListIcon />,
  },
]

export default function AnnotationModeGroup(props: Props) {
  const { mode, onSelect } = props;

  return (
    <ButtonGroup disableElevation>
      {data.map(it => (
        <Button
          key={it.id}
          color={it.id === mode ? "primary" : "default"}
          variant="contained"
          onClick={() => onSelect(it.id)}
        >
          {it.icon}
        </Button>
      ))}
    </ButtonGroup>
  )
}
