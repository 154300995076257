import { SyntheticEvent, useCallback, useMemo, useRef } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

import { renderAutoCompleteField } from 'components/withFormik';
import { useLazyData } from 'hooks/useData';
import { SEARCH_CLASSES } from 'modules/classes/api';
import { Action, getColor, Label, Span } from 'modules/item-specifications/components/BaseTextAnnotator';
import { useAnnotationContextMenuContext } from 'modules/item-specifications/contexts/AnnotationContextMenuContext';
import { Source, Status } from 'shared/constants';

import useStyles from './AnnotationContextMenu.styles';

type Props = {
  action: Action;
  onDeleteSpan: (span: Span) => void;
  onSave: (values: FormValues) => void;
};

type Value = {
  id: string;
  name: string;
};

export type FormValues = {
  searchQuery: '',
  tagId: string;
  tagName: string
  classes: Label[]
};

export default function AnnotationContextMenu(props: Props) {
  const { action, onDeleteSpan, onSave } = props;
  const { contextSpan } = useAnnotationContextMenuContext();

  const headingColor = useMemo(() => contextSpan ? getColor(contextSpan, action) : undefined, [contextSpan, action]);
  const classes = useStyles({ headingColor });

  const { data, loading, getData } = useLazyData(SEARCH_CLASSES, 'Classes', { nameField: 'name_STARTS_WITH_REGEXP' });

  const formikRef = useRef<any>();
  const arrayHelpersRef = useRef<FieldArrayRenderProps>();

  const initialValues: FormValues = useMemo(() =>
    ({
      searchQuery: '',
      tagId: contextSpan?.tagId as string,
      tagName: contextSpan?.text as string,
      classes: contextSpan?.labels || []
    }),
    [contextSpan],
  );

  const addClass = useCallback((value: Value | null) => {
    if (!value) return;

    arrayHelpersRef.current?.push({
      id: value.id,
      name: value.name,
      status: Status.confirmed,
      source: Source.consensus,
      dirty: true,
    });
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" className={classes.heading}>
            {contextSpan?.text}
        </Typography>
        <Button
          variant="text"
          startIcon={<DeleteIcon />}
          className={classes.deleteButton}
          onClick={() => onDeleteSpan(contextSpan as Span)}
        >
          Delete
        </Button>
        <Divider />
        <Formik initialValues={initialValues} innerRef={formikRef} onSubmit={onSave}>
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <Field
                name="searchQuery"
                label="Search"
                component={renderAutoCompleteField}
                minLength={1}
                options={data}
                loading={loading}
                onSearchChange={getData}
                onChange={(_: SyntheticEvent, value: Value) => {
                  addClass(value);
                  setFieldValue('searchQuery', '');
                }}
              />
              <FieldArray name="classes">
                {(arrayHelpers) => {
                  arrayHelpersRef.current = arrayHelpers;

                  return values.classes.map((it: Label, index: number) => (
                    <FormControl key={it.id} style={{ display: 'flex'}}>
                      <RadioGroup
                        value={it.status || 0}
                        style={{ flexDirection: 'row', padding: '8px 16px' }}
                        onChange={(_, newStatus) => arrayHelpers.replace(index, {
                          ...it,
                          status: Number(newStatus) as Status,
                          dirty: true,
                        })}
                      >
                        <FormControlLabel
                          label=""
                          value={Status.confirmed}
                          control={
                            <Radio
                              style={{
                                padding: 0,
                                color: it.status ? (it.dirty ? action.colors.dirty : action.colors.default) : action.colors.prediction,
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label=""
                          value={Status.declined}
                          control={
                            <Radio
                              style={{
                                padding: 0,
                                color: it.status ? (it.dirty ? action.colors.dirty : action.colors.default) : action.colors.prediction,
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={it.name}
                          control={<></>}
                          style={{
                            cursor: 'default',
                            color: it.source === Source.prediction ? action.colors.prediction : '',
                          }}

                        />
                      </RadioGroup>

                    </FormControl>
                  ))
                }}
              </FieldArray>
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ pt: 1 }}>
                <Button variant="contained" color="primary" type="submit" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}
