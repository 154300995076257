import { useMemo } from 'react';
import memoizeOne from 'memoize-one';

import DataPage, { DataPageRenderProps } from 'components/DataPage';
import useLanguages from 'hooks/useLanguages';
import useModalWithValue from 'hooks/useModalWithValue';
import MainLayout from 'layouts/MainLayout';
import { Authenticated } from 'modules/auth/components/Authenticated';
import FilterModal from 'modules/morphs/components/FilterModal';
import NameModal from 'modules/morphs/components/NameModal';
import SortModal from 'modules/morphs/components/SortModal';
import {
  INITIAL_FILTER_PARAMS,
  INITIAL_NAME_PARAMS,
  INITIAL_SORT_PARAMS,
  SORT_OPTIONS,
} from 'modules/morphs/constants';
import useMorph from 'modules/morphs/hooks/useMorph';
import useMorphContextActions from 'modules/morphs/hooks/useMorphContextActions';
import useMorphsQuery from 'modules/morphs/hooks/useMorphsQuery';
import useResetMorphs from 'modules/morphs/hooks/useResetMorphs';
import AssociatedTokensDataSection from 'modules/morphs/sections/AssociatedTokensDataSection';
import MorphemesDataSection from 'modules/morphs/sections/MorphemesDataSection';
import { transformVariables } from 'modules/morphs/utils';
import DataPageProvider from 'providers/DataPageProvider';
import { DataItem } from 'types';

const memoizeNameField = memoizeOne(fn => fn);
const memoizeCountField = memoizeOne((...args) => [...args]);

export default function Morphs() {
  const { getLanguage, formatLanguage } = useLanguages();
  const resetMorphs = useResetMorphs();

  const nameField = memoizeNameField((item: DataItem) => `{${item.name}}`);
  const countField = memoizeCountField('count_inverse__Token_has_Morph', formatLanguage);

  const {
    open: sortModalOpen,
    value: sortModalValue,
    openModal: openSortModal,
    closeModal: closeSortModal,
    setValue: setSortModalValue,
  } = useModalWithValue({ initialValue: INITIAL_SORT_PARAMS, onValueChanged: resetMorphs });

  const {
    open: filterModalOpen,
    value: filterModalValue,
    meta: filterMeta,
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    setValue: setFilterModalValue,
    resetValue: resetFilterModal,
  } = useModalWithValue({ initialValue: INITIAL_FILTER_PARAMS, onValueChanged: resetMorphs });

  const {
    open: nameModalOpen,
    value: nameModalValue,
    openModal: openNameModal,
    closeModal: closeNameModal,
    setValue: setNameModalValue,
  } = useModalWithValue({ initialValue: INITIAL_NAME_PARAMS });

  const { editMorph, createOrUpdateMorph } = useMorph({
    onEdit(item: DataItem) {
      setNameModalValue({
        id: item.id,
        name: item.name,
        language: getLanguage(item?.language as number),
      });
      openNameModal();
    },
    onCreateOrUpdate() {
      const resetOnClose = true;
      closeNameModal(resetOnClose);
    },
  })

  const actions = useMemo(
    () => ({
      add: {
        handler: openNameModal,
      },
      sort: {
        handler: openSortModal,
      },
      filter: {
        meta: filterMeta,
        handler: openFilterModal,
      },
    }),
    [openNameModal, openSortModal, openFilterModal, filterMeta],
  );
  const contextActions = useMorphContextActions({ editMorph });
  const dataVariables = useMemo(() => transformVariables(filterModalValue, sortModalValue, {}), [filterModalValue, sortModalValue]);

  return (
    <DataPageProvider>
      <DataPage
        title="Morphs"
        useQuery={useMorphsQuery}
        dataVariables={dataVariables}
        structure={{
          nameField,
          countField
        }}
        actions={actions}
        contextActions={contextActions}
      >
        {({ currentItem }: DataPageRenderProps) => (
          <>
            {currentItem && (
              <>
                <AssociatedTokensDataSection parentItem={currentItem} />
                <MorphemesDataSection parentItem={currentItem} />
              </>
            )}

            {sortModalOpen && (
              <SortModal
                value={sortModalValue}
                options={SORT_OPTIONS}
                onClose={closeSortModal}
                onChange={setSortModalValue}
              />
            )}

            {filterModalOpen && (
              <FilterModal
                value={filterModalValue}
                onClose={closeFilterModal}
                onChange={setFilterModalValue}
                onReset={resetFilterModal}
              />
            )}

            {nameModalOpen && (
              <NameModal
                value={nameModalValue}
                onClose={closeNameModal}
                onChange={createOrUpdateMorph}
              />
            )}
          </>
        )}
      </DataPage>
    </DataPageProvider>
  );
}

Morphs.getLayout = (page: any) => (
  <Authenticated>
    <MainLayout>
      {page}
    </MainLayout>
  </Authenticated>
);
