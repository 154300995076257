import { Item, Menu } from 'react-contexify';
import { useMutation } from '@apollo/client';

import MenuItem from '@material-ui/core/MenuItem';

import { START_CLUSTER_SE_CREATION } from 'modules/clusters/api';

type Props = {
  menuId: string;
};

const ContextMenu = ({ menuId }: Props) => {
  const [startSeCreation] = useMutation(START_CLUSTER_SE_CREATION, { fetchPolicy: 'no-cache' });

  return (
    <Menu id={menuId}>
      <Item onClick={() => startSeCreation()}>
        <MenuItem>Start SE-Creation Process</MenuItem>
      </Item>
    </Menu>
  );
};

export default ContextMenu;
