import { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Modal from 'components/Modal';
import OutlinedWrapper from 'components/OutlinedWrapper';

type Props = {
  onClose: () => void;
  value: number;
  onChange: (value: number) => void;
  options: { value: number; label: string }[];
};

const RadioControl = <Radio color="primary" />;

export default function SemanticEntityModal({ onClose, onChange, value, options }: Props) {
  const [localValue, setLocalValue] = useState(value);
  const handleChange = () => {
    onChange(localValue);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Modal
      title="Set Creation of Semantic Entities"
      open
      actionsContent={
        <Button variant="contained" color="primary" onClick={handleChange}>
          Confirm
        </Button>
      }
      onClose={onClose}
    >
      <FormControl variant="outlined" fullWidth>
        <OutlinedWrapper>
          <RadioGroup value={localValue} onChange={(event) => setLocalValue(Number(event.target.value))}>
            {options.map(({ value, label }) => (
              <FormControlLabel key={value} control={RadioControl} value={value} label={label} />
            ))}
          </RadioGroup>
        </OutlinedWrapper>
      </FormControl>
    </Modal>
  );
}
