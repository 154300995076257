import { onError } from '@apollo/client/link/error';

const notifyError = (error: string) => {
  console.error(error);
  alert(error);
};

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const context = operation.getContext();
  if (context?.ignoreErrors) return;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      notifyError(`[GraphQL error]: Message: ${message}`);
    });
  }

  if (networkError) notifyError(`[Network error]: ${networkError}`);
});
