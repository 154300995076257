import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Phrases_Semantic_Entities_Consensus_Annotations_Entities_State,
  Phrases_Semantic_Entities_Consensus_Annotations_Ids_State,
  Phrases_Semantic_Entities_Options_Entities_State,
  Phrases_Semantic_Entities_Options_Ids_State,
  Phrases_Semantic_Entities_Predictions_Entities_State,
  Phrases_Semantic_Entities_Predictions_Ids_State,
} from './atoms';

export const Phrases_Semantic_Entities_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/phrases/@selectors/Semantic_Entities_Consensus_Annotations/list',
  Phrases_Semantic_Entities_Consensus_Annotations_Entities_State,
  Phrases_Semantic_Entities_Consensus_Annotations_Ids_State,
);

export const Phrases_Semantic_Entities_Predictions_selector = listSelector<DataItem>(
  'graph/phrases/@selectors/Semantic_Entities_Predictions/list',
  Phrases_Semantic_Entities_Predictions_Entities_State,
  Phrases_Semantic_Entities_Predictions_Ids_State,
);

export const Phrases_Semantic_Entities_Options_selector = listSelector<DataItem>(
  'graph/phrases/@selectors/Semantic_Entities_Options/list',
  Phrases_Semantic_Entities_Options_Entities_State,
  Phrases_Semantic_Entities_Options_Ids_State,
);
