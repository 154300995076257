export type Theme = {
  opacity: {
    defaults: string;
    selected: string;
  };
  fill: {
    defaults: string;
    selected: string;
  };
};

export const lightTheme: Theme = {
  opacity: {
    defaults: '1',
    selected: '1',
  },
  fill: {
    defaults: '#E0E0E0',
    selected: '#1565C0',
  },
};

export const darkTheme: Theme = {
  opacity: {
    defaults: '0.3',
    selected: '1',
  },
  fill: {
    defaults: '#FFFFFF',
    selected: '#FFFFFF',
  },
};
