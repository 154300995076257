export enum Edge_Direction {
  DIRECT,
  INVERSE,
}

export enum Methods {
  Class_has_Cluster = 'Class_has_Cluster',
  Class_to_Class_Conversion = 'Class_to_Class_Conversion',
  Class_Merge = 'Class_Merge',
  Morph_has_Morpheme = 'Morph_has_Morpheme',
  Phrase_has_Meaning = 'Phrase_has_Meaning',
  Phrase_Tag_SE = 'Phrase_Tag_SE',
  SE_has_Tag = 'SE_has_Tag',
  Tag_has_Class = 'Tag_has_Class',
  Tag_has_Parent = 'Tag_has_Parent',
  Tag_has_Synonym = 'Tag_has_Synonym',
  Token_has_Meaning = 'Token_has_Meaning',
  Token_has_Morph = 'Token_has_Morph',
  Token_Join = 'Token_Join',
};

export enum Nodes {
  Class = 'Class',
  Cluster = 'Cluster',
  Contact = 'Contact',
  Morpheme = 'Morpheme',
  Morph = 'Morph',
  Phrase = 'Phrase',
  SemanticEntity = 'SemanticEntity',
  Tag = 'Tag',
  Token = 'Token',
};


export enum Status {
  confirmed = 1,
  declined,
  logically_depreciated,
  open,
  paused,
  assigned
};

export enum Source {
  initial = 1,
  logic = 2,
  phrase_synonym = 3,
  consensus = 4,
  prediction = 5,
  custom = 6,
  Google_Translate = 7,
  Wikidata = 8,
  Specifications = 9,
  STLB_Bau_Base = 10,
  STLB_Bau_Not_Base = 11,
};

export enum Operation {
  ANCHOR_BUILDING_MATERIAL = "ANCHOR_BUILDING_MATERIAL",
  ATTRIBUTE_ON_BUILDING_MATERIAL = "ATTRIBUTE_ON_BUILDING_MATERIAL",
  SPANCAT = "SPANCAT",
  DEFAULT = "DEFAULT"
};
