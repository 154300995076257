import { useCallback, useMemo, useRef } from 'react';

import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';
import { AddProductParamsType } from 'modules/item-specifications/constants';

import Form, { FormValues } from './AddProductForm';

type Props = {
  value: AddProductParamsType;
  onClose: () => void;
  onChange: (value: AddProductParamsType) => void;
};

export default function AddProductModal({ value, onClose, onChange }: Props) {
  const formikRef = useRef<any>();

  const initialValues = useMemo(
    () => ({
      product: value.product,
      attributes: value.attributes,
    }),
    [value],
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onChange({
        product: values.product,
        attributes: values.attributes,
      });
      onClose();
    },
    [onClose, onChange],
  );

  const handleAdd = useCallback(() => {
    const { submitForm } = formikRef.current;
    submitForm();
  }, []);

  return (
    <Modal
      title="Add Product Type"
      open
      actionsContent={
        <>
          <Button variant="contained" color="primary" onClick={handleAdd}>
          Add
          </Button>
        </>
      }
      onClose={onClose}
    >
      <Form initialValues={initialValues} formRef={formikRef} onSubmit={handleSubmit} />
    </Modal>
  );
}
