import { listSelector } from 'shared/store';
import { DataItem } from 'types';

import {
  Token_Tags_Consensus_Annotations_Entities_State,
  Token_Tags_Consensus_Annotations_Ids_State,
  Token_Tags_Options_Entities_State,
  Token_Tags_Options_Ids_State,
  Token_Tags_Predictions_Entities_State,
  Token_Tags_Predictions_Ids_State,
} from './atoms';

export const Token_Tags_Consensus_Annotations_selector = listSelector<DataItem>(
  'graph/tokens/@selectors/Tags_Consensus_Annotations/list',
  Token_Tags_Consensus_Annotations_Entities_State,
  Token_Tags_Consensus_Annotations_Ids_State,
);

export const Token_Tags_Predictions_selector = listSelector<DataItem>(
  'graph/tokens/@selectors/Tags_Predictions/list',
  Token_Tags_Predictions_Entities_State,
  Token_Tags_Predictions_Ids_State,
);

export const Token_Tags_Options_selector = listSelector<DataItem>(
  'graph/tokens/@selectors/Tags_Options/list',
  Token_Tags_Options_Entities_State,
  Token_Tags_Options_Ids_State,
);
