import { gql } from '@apollo/client';

export enum TagSortOption {
  by_count_in_Phrases_all_positions = 'by_count_in_Phrases_all_positions',
  by_count_in_Phrases_first_position = 'by_count_in_Phrases_first_position',
  // Tag_has_Synonym
  by_count__Tag_has_Synonym = 'by_count__Tag_has_Synonym',
  by_count__Tag_has_Synonym_predictions = 'by_count__Tag_has_Synonym_predictions',
  by_count__Tag_has_Synonym_predictions_assigned = 'by_count__Tag_has_Synonym_predictions_assigned',
  // Tag_has_Parent
  by_count__Tag_has_Parent = 'by_count__Tag_has_Parent',
  by_count__Tag_has_Parent_predictions = 'by_count__Tag_has_Parent_predictions',
  by_count__Tag_has_Parent_predictions_assigned = 'by_count__Tag_has_Parent_predictions_assigned',
  // Tag_has_Class
  by_count__Tag_has_Class = 'by_count__Tag_has_Class',
  by_count__Tag_has_Class_predictions = 'by_count__Tag_has_Class_predictions',
  by_count__Tag_has_Class_predictions_assigned = 'by_count__Tag_has_Class_predictions_assigned',
  // Offer_has_Tag
  by_count__Offer_has_Tag = 'by_count__Offer_has_Tag',
  by_count__Offer_has_Tag_predictions = 'by_count__Offer_has_Tag_predictions',
  by_count__Offer_has_Tag_predictions_assigned = 'by_count__Offer_has_Tag_predictions_assigned',
  // Deliverable_has_Tag
  by_count__Deliverable_has_Tag = 'by_count__Deliverable_has_Tag',
  by_count__Deliverable_has_Tag_predictions = 'by_count__Deliverable_has_Tag_predictions',
  by_count__Deliverable_has_Tag_predictions_assigned = 'by_count__Deliverable_has_Tag_predictions_assigned',
}

export type TagSortOptionsInput = {
  option: TagSortOption;
  direction: 'DESC' | 'ASC';
};

export type TagFilterWhereInput = {
  has_Classes?: string[];
  name_STARTS_WITH_FULL_TEXT?: string;
  only_assigned?: boolean;
  only_done?: boolean;
};

export type TagFilterOptionsInput = {
  sort?: TagSortOptionsInput;
  offset?: number;
  limit?: number;
};

export type TagFilterInput = {
  where?: TagFilterWhereInput;
  options?: TagFilterOptionsInput;
};

export const QUERY_TAGS = gql`
  query Tags($where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    Tags(where: $where, options: $options) {
      id
      name
      active_contact_is_assigned_to
      active_contact_is_done_by
      active_contact_methods_count_is_assigned
      count_all_positions_in_phrases
      count_first_position_in_phrases
    }
  }
`;

export const QUERY_EXISTS_TAG = gql`
  query Exists_Tag($name: String!) {
    exists_Tag(name: $name)
  }
`;

export const SEARCH_TAGS = gql`
  query Tags($where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    Tags(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const SEARCH_BUILDING_MATERIAL_TAGS = gql`
  query Building_Material_Tags($where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    Building_Material_Tags(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const SEARCH_ATTRIBUTES_ON_BUILDING_MATERIAL_TAGS = gql`
  query Attributes_On_Building_Material_Tags($where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    Attributes_On_Building_Material_Tags(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const SEARCH_TRADES_TAGS = gql`
  query Trades_Tags($where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    Trades_Tags(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const SEARCH_GROUPS_OF_SIMILAR_WORK_ITEMS_TAGS = gql`
  query Groups_Of_Similar_Work_Items_Tags($where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    Groups_Of_Similar_Work_Items_Tags(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const SEARCH_DIN_276_COST_GROUPS_TAGS = gql`
  query Din_276_Cost_Groups_Tags($where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    Din_276_Cost_Groups_Tags(where: $where, options: $options) {
      id
      name
    }
  }
`;

export const ASSIGN_TAG_TO_CONTACT = gql`
  mutation Assign_Tag_to_Contact($input: Assign_to_Contact_Input!) {
    assign_Tag_to_Contact(input: $input) {
      id
    }
  }
`;

export const MARK_TAG_AS_DONE = gql`
  mutation mark_Tag_as_done($ids: [BigInt!]!) {
    mark_Tag_as_done(ids: $ids) {
      id
    }
  }
`;

export const MARK_TAG_AS_NOT_DONE = gql`
  mutation mark_Tag_as_not_done($ids: [BigInt!]!) {
    mark_Tag_as_not_done(ids: $ids) {
      id
    }
  }
`;
