import { createContext } from 'react';

import { DataItem } from 'types';

export type ContextActionsContextProps = {
  contextItem: DataItem | null;
  listId: string | null;
  setContextItem: Function;
  setListId: Function;
};

const ContextActionsContext = createContext<ContextActionsContextProps>({
  contextItem: null,
  listId: null,
  setContextItem: () => null,
  setListId: () => null,
});

export default ContextActionsContext;
