import { Map as ImmutableMap } from 'immutable';
import { atom } from 'recoil';

import { DataItem } from 'types';

export const SemanticEntity_Associated_Next_Tokens_Entities_State = atom<ImmutableMap<string, DataItem>>({
  key: 'graph/semantic-entities/@atoms/Associated_Next_Tokens/entities',
  default: ImmutableMap(),
});

export const SemanticEntity_Associated_Next_Tokens_Ids_State = atom<string[]>({
  key: 'graph/semantic-entities/@atoms/Associated_Next_Tokens/ids',
  default: [],
});
