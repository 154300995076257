import { gql } from '@apollo/client';

export const QUERY_LANGUAGES = gql`
  query Languages {
    metadata {
      language {
        id
        code
        iso_language_name
      }
    }
  }
`;

export const QUERY_TOKEN_TYPES = gql`
  query TokenTypes {
    metadata {
      token_type {
        id
        name
      }
    }
  }
`;
