import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { ITEM_SPECIFICATION_ANNOTATION } from 'modules/item-specifications/api/graphApi'
import { ItemSpecification, ItemSpecificationInput } from 'types';

type UseItemSpecificationAnnotationParams = {
  itemId: string;
};

type UseItemSpecificationAnnotationOptions = {
  onCompleted?: (result: ItemSpecificationAnnotationResult) => void;
};

export type ItemSpecificationAnnotationResult = {
  Item_Specification_Annotation: ItemSpecification;
};

type UseItemSpecificationAnnotationResult = {
  saveAnnotation: (input: ItemSpecificationInput) => void;
};

export default function useItemSpecificationAnnotation(
  params: UseItemSpecificationAnnotationParams,
  options: UseItemSpecificationAnnotationOptions = {},
): UseItemSpecificationAnnotationResult {
  const { itemId } = params;
  const { onCompleted: onPropsCompleted } = options;

  const onCompleted = useCallback((result: unknown) => {
    onPropsCompleted?.(result as ItemSpecificationAnnotationResult);
  }, [onPropsCompleted]);

  const [mutate] = useMutation(ITEM_SPECIFICATION_ANNOTATION, { onCompleted });

  const saveAnnotation = useCallback((input: ItemSpecificationInput) => {
    mutate({
      variables: {
        input: {
          ...input,
          id: itemId,
        },
      },
    });
  }, [itemId, mutate]);

  return {
    saveAnnotation,
  };
}
