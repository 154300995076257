import { gql } from '@apollo/client';


export const QUERY_TAG_CLASSES_CONSENSUS_ANNOTATIONS_V2 = gql`
  query Tag_Classes_Consensus_Annotations_V2(
    $id: BigInt!
    $where: Class_Filter_Where_Input
    $options: Class_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    Tag_has_Class_Classes_Consensus_Annotations_v2(id: $id, where: $where, options: $options, consensus: $consensus) {
      id
      name
      consensus_status
      active_contact_method_annotation_status
      active_contact_is_assigned_to
      active_contact_is_done_by
      # count_inverse__Tag_has_Class
    }
  }
`;

export const QUERY_TAG_CLASSES_PREDICTIONS_V2 = gql`
  query Tag_Classes_Predictions_v2($id: BigInt!, $where: Class_Filter_Where_Input, $options: Class_Filter_Options_Input) {
    Tag_has_Class_Classes_Predictions_v2(id: $id, where: $where, options: $options) {
      id
      name
      active_contact_method_annotation_status
      active_contact_is_assigned_to
      active_contact_is_done_by
      # count_inverse__Tag_has_Class
    }
  }
`;

export const QUERY_TAG_CLASSES_OPTIONS_V2 = gql`
  query Tag_Classes_Options_v2($id: BigInt!, $where: Class_Filter_Where_Input, $options: Class_Filter_Options_Input) {
    Tag_has_Class_Classes_Options_v2(id: $id, where: $where, options: $options) {
      id
      name
      active_contact_method_annotation_status
      active_contact_is_assigned_to
      active_contact_is_done_by
      # count_inverse__Tag_has_Class
    }
  }
`;

// target asset extension
export const QUERY_CLASS_TAGS_CONSENSUS_ANNOTATIONS = gql`
  query Class_Tags_Consensus_Annotations(
    $id: BigInt
    $where: Tag_Filter_Where_Input
    $options: Tag_Filter_Options_Input
    $consensus: Boolean = true
  ) {
    classes(where: { id: $id }) {
      Tags_Consensus_Annotations(where: $where, options: $options, consensus: $consensus) {
        id
        name
        consensus_status
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
      }
    }
  }
`;

export const QUERY_CLASS_TAGS_PREDICTIONS = gql`
  query Class_Tags_Predictions($id: BigInt, $where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    classes(where: { id: $id }) {
      Tags_Predictions(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
      }
    }
  }
`;

export const QUERY_CLASS_TAGS_OPTIONS = gql`
  query Class_Tags_Options($id: BigInt, $where: Tag_Filter_Where_Input, $options: Tag_Filter_Options_Input) {
    classes(where: { id: $id }) {
      Tags_Options(where: $where, options: $options) {
        id
        name
        active_contact_method_annotation_status
        active_contact_is_assigned_to
        active_contact_is_done_by
        count_all_positions_in_phrases
      }
    }
  }
`;

// method node
export const QUERY_TAG_METHOD_TAG_HAS_CLASS_ANNOTATIONS_PREDICTIONS_CLASSES = gql`
  query Tag_Method_Tag_has_Class_Annotations_Predictions_Classes($id: BigInt) {
    tags(where: { id: $id }) {
      Tag_has_Class_Methods_Annotation_Predictions {
        label_Tag_has_Class_Target_Asset {
          id
          name
        }
      }
    }
  }
`;

export const ASSIGN_TAG_HAS_CLASS_TO_CONTACT = gql`
  mutation Assign_Tag_has_Class_to_Contact($input: Method_Assign_to_Contact_Input!) {
    assign_Tag_has_Class_to_Contact(input: $input) {
      methodTagHasClasses {
        id
      }
    }
  }
`;

export const CONFIRM_TAG_HAS_CLASS_OPTION = gql`
  mutation Confirm_Method_Tag_has_Class_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!) {
    confirm_Method_Tag_has_Class_option(input: { base_asset_id: $baseAssetId, target_asset_id: $targetAssetId }) {
      methodTagHasClasses {
        id
      }
    }
  }
`;

export const DECLINE_TAG_HAS_CLASS_OPTION = gql`
  mutation Decline_Method_Tag_has_Class_Option($baseAssetId: BigInt!, $targetAssetId: BigInt!) {
    decline_Method_Tag_has_Class_option(input: { base_asset_id: $baseAssetId, target_asset_id: $targetAssetId }) {
      methodTagHasClasses {
        id
      }
    }
  }
`;
