import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    color: '#000000',
    padding: theme.spacing(1.5, 0),
  },
}));

export default useStyles;
